import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { APIVersions } from "../constants/APIVersions";
import { FeatureFlags } from "../constants/FeatureFlags";

export const getVersion = (flags: LDFlagSet | null) => {
  if (flags && flags[FeatureFlags.GlobalProductCatalogPage]) {
    return APIVersions.TwoDotTwo;
  } else if (flags && flags[FeatureFlags.LabelsPage]) {
    return APIVersions.TwoDotOne;
  } else {
    return APIVersions.Default;
  }
};

import { roles } from "../constants/Roles";
import { Role } from "../interfaces/Role";

export const getRoleDisplayName = (roles: Array<Role>) => {
  if (roles.length > 1) {
    return "Multiple";
  }

  if (roles.length === 1) {
    return roles[0].DisplayName;
  }

  return "None";
};

export const getSelectedRoles = (userRoles: Array<Role>) => {
  let selectedRoles = roles.map((r) => {
    let userRole = userRoles.find((ur) => ur.Name === r.Name);
    r.Selected = userRole != undefined;
    return { ...r };
  });

  return selectedRoles;
};

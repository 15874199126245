import React from "react";

interface IntegrationsProBadgeProps {
    hidden?: boolean;
};

const IntegrationsProBadge: React.FC<IntegrationsProBadgeProps> = (props) => {
    return (
        <>
            <div className="integrations-pro-badge-holder" hidden={props.hidden}>
                <div className="integrations-pro-badge">
                    PRO
                </div>
            </div>
        </>
    );
};

export default IntegrationsProBadge;
import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { ECommerceLocation } from "../../../interfaces/integrations/ecommerce/ECommerceLocation";
import "../../../theme/integrations/ecommerce/ECommerceAccountLocationsTable.css";

interface ECommerceAccountLocationsTableProps {
  useFulfillmentStrategy: boolean;
  locations: ECommerceLocation[];
  onChange: Function;
}

const ECommerceAccountLocationsTable: React.FC<ECommerceAccountLocationsTableProps> = (props) => {
  const onInventoryLocationChanged = (index: number) => {
    let currentLocations = props.locations.map((l) => {
      return { ...l };
    });
    currentLocations[index].isInventoryLocation = !currentLocations[index].isInventoryLocation;
    props.onChange(currentLocations);
  };

  const onSaleLocationChanged = (index: number) => {
    let currentLocations = props.locations.map((l) => {
      return { ...l };
    });
    let currentLocation = currentLocations[index];
    currentLocation.isSaleLocation = !currentLocation.isSaleLocation;
    if (currentLocation.isSaleLocation) {
      currentLocation.isInventoryLocation = true;
      for (let i = 0; i < currentLocations.length; i++) {
        if (i === index) {
          continue;
        }
        currentLocations[i].isSaleLocation = false;
      }
    }

    props.onChange(currentLocations);
  };

  return (
    <>
      <Row className="ecommerce-account-locations-table">
        <Col sm={7}>
          <div className="table-header">
            <Col sm={2} className="inventory">
              Inventory
            </Col>
            <Col sm={2} className="sale">
              Sale
            </Col>
            <Col sm={8} className="store">
              Store
            </Col>
          </div>
          <ListGroup bsPrefix="location-items">
            {props.locations &&
              props.locations.map((l, index) => {
                return (
                  <ListGroup.Item bsPrefix="location-item" key={l.id}>
                    <Col sm={2} className="location-check-box-holder">
                      <input
                        className="location-check-box"
                        type="checkbox"
                        checked={l.isInventoryLocation}
                        onChange={() => onInventoryLocationChanged(index)}
                      />
                    </Col>
                    <Col sm={2} className="location-check-box-holder">
                      <input
                        className="location-check-box"
                        type="checkbox"
                        checked={l.isSaleLocation}
                        onChange={() => onSaleLocationChanged(index)}
                      />
                    </Col>
                    <Col sm={8} className="location-name">
                      {l.name}
                    </Col>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default ECommerceAccountLocationsTable;

import { IonItem, IonText, IonIcon, IonModal } from '@ionic/react';
import { addCircle } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { SpecialTaxEvent, Tax } from '../../../../interfaces/Taxes';
import { usePortal } from '../../../PortalProvider';
import SettingsCard from '../../../SettingsCard';
import AdvancedSpecialTaxEventConfiguration from './AdvancedSpecialTaxEventConfiguration';
import EditSpecialTaxEvent, { SpecialTaxEventApplicableTaxes } from './EditSpecialTaxEvent';
import SpecialTaxEventSummary from './SpecialTaxEventSummary';

import '../../../../theme/locationDetails/taxes/specialTaxEvents/SpecialTaxEventCard.css';

interface SpecialTaxEventCardProps {
    specialTaxEvents: SpecialTaxEvent[];
    setSpecialTaxEvents: Function;
    taxes: Tax[];
    locationId: string;
    saveSpecialTaxEvent: Function;
    getSpecialTaxEvents: Function;
}

const SpecialTaxEventCard: React.FC<SpecialTaxEventCardProps> = (props) => {
    const portal = usePortal();

    const [showSpecialEventAdvanceTaxConfiguration, setShowSpecialEventAdvanceTaxConfiguration] = React.useState(false);
    const [selectedSpecialEvent, setSelectedSpecialEvent] = React.useState<SpecialTaxEvent | undefined>();
    const [showCreateSpecialTaxEvent, setShowCreateSpecialTaxEvent] = React.useState<boolean>(false);
    const [filteredTaxes, setFilteredTaxes] = React.useState<Tax[]>([]);

    useEffect(() => {
        setFilteredTaxes(props.taxes.filter(x => !!x.taxId && x.taxId!.indexOf("temp") < 0 && !!x.locationIds && x.locationIds.indexOf(props.locationId) >= 0));
    }, [props.taxes]);

    const closeSpecialEventModal = (refreshEvents: boolean) => {
        setShowSpecialEventAdvanceTaxConfiguration(false);

        if (refreshEvents) {
            props.getSpecialTaxEvents();
        }
    };

    const closeCreateSpecialTaxEventModal = (refreshEvents: boolean) => {
        setShowCreateSpecialTaxEvent(false);

        if (refreshEvents) {
            props.getSpecialTaxEvents();
        }
        else {
            portal!.navigation.isLoading(false);
        }
    };

    const createTaxEvent = () => {
        setShowCreateSpecialTaxEvent(true);
    };

    const deleteSpecialTaxEvent = (taxEvent: SpecialTaxEvent) => {
        taxEvent.locationIds!.splice(taxEvent.locationIds!.indexOf(props.locationId), 1);
        props.saveSpecialTaxEvent(taxEvent);
    };

    const duplicateSpecialTaxEvent = (taxEvent: SpecialTaxEvent) => {
        taxEvent.eventId = undefined;
        props.saveSpecialTaxEvent(taxEvent);
    };

    const openSpecialEventModal = (specialEvent: SpecialTaxEvent) => {
        setSelectedSpecialEvent(specialEvent);
        setShowSpecialEventAdvanceTaxConfiguration(true);
    };

    return (
        <>
            <SettingsCard
                header="Special Tax Events"
                subHeading="Create special tax events like a Tax-Free Weekend.">
                {props.specialTaxEvents.map(taxEvent => {
                    return (
                        <SpecialTaxEventSummary
                            key={taxEvent.eventId}
                            taxEvent={taxEvent}
                            taxes={filteredTaxes}
                            deleteSpecialTaxEvent={deleteSpecialTaxEvent}
                            duplicateSpecialTaxEvent={duplicateSpecialTaxEvent}
                            closeModal={closeCreateSpecialTaxEventModal}
                            showSpecialEventAdvancedConfiguration={openSpecialEventModal}
                            saveSpecialTaxEvent={props.saveSpecialTaxEvent} />
                    )
                })}
                <IonItem className="action-items" onClick={() => createTaxEvent()} lines="none">
                    <IonIcon color="primary" src={addCircle} className="add-icon" />
                    <IonText color="primary">
                        Create a Special Tax Event...
                     </IonText>
                </IonItem>
            </SettingsCard>
            <IonModal
                isOpen={showSpecialEventAdvanceTaxConfiguration}
                onDidDismiss={() => closeSpecialEventModal(false)}
                className="taxes-modal">
                <AdvancedSpecialTaxEventConfiguration
                    taxes={filteredTaxes}
                    specialTaxEvent={selectedSpecialEvent}
                    closeModal={closeSpecialEventModal}
                    saveSpecialTaxEvent={props.saveSpecialTaxEvent} />
            </IonModal>
            <IonModal
                isOpen={showCreateSpecialTaxEvent}
                onDidDismiss={() => closeCreateSpecialTaxEventModal(false)}
                className="create-special-tax-event-modal">
                <EditSpecialTaxEvent
                    locationId={props.locationId}
                    saveSpecialTaxEvent={props.saveSpecialTaxEvent}
                    taxes={filteredTaxes.map(tax => {
                        return (
                            {
                                taxId: tax.taxId,
                                name: tax.description,
                                checked: false
                            } as SpecialTaxEventApplicableTaxes
                        )
                    })}
                    closeModal={closeCreateSpecialTaxEventModal} />
            </IonModal>
        </>
    );
};

export default SpecialTaxEventCard;

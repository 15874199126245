import React from "react";
import { Image } from "react-bootstrap";
import { ECommerceVendor } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupInfoModalProps extends ECommerceAccountSetupModalPropsBase {
  hideInventorySection?: boolean;
  hidePricingSection?: boolean;
  hideCustomersSection?: boolean;
  overrideClassName?: string;
}

const ECommerceAccountSetupInfoModal: React.FC<ECommerceAccountSetupInfoModalProps> = (props) => {
  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: `Connect to ${props.vendor}`,
    headerSubTitle: `It’s easy to sell on ${props.vendor} with RICS Software!`,
    hideGoBackButton: true
  };

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  const getIntegrationDetails = () => {
    if (props.vendor == ECommerceVendor.Amazon) return "Seller Central";
    else return props.vendor;
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body-row")}>
          <div className={getClass("body-col")}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/verified.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Authorize</div>
                <div className={getClass("body-section-holder-text-sub-title")}>
                  Authorize RICS to communicate with a {getIntegrationDetails()} account
                </div>
              </div>
            </div>
          </div>
          <div className={getClass("body-col")}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/settings.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Integration Details</div>
                <div className={getClass("body-section-holder-text-sub-title")}>Manage Integration name and contact info</div>
              </div>
            </div>
          </div>
        </div>
        <div className={getClass("body-row")}>
          <div className={getClass("body-col")}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/locations.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Locations</div>
                <div className={getClass("body-section-holder-text-sub-title")}>Select inventory location(s) and sales location(s)</div>
              </div>
            </div>
          </div>
          <div className={getClass("body-col")} hidden={props.hideInventorySection}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/inventory.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Additional Settings</div>
                <div className={getClass("body-section-holder-text-sub-title")}>Customize additional integration settings</div>
              </div>
            </div>
          </div>
        </div>
        <div className={getClass("body-row")}>
          <div className={getClass("body-col")} hidden={props.hidePricingSection}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/money.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Pricing</div>
                <div className={getClass("body-section-holder-text-sub-title")}>Send prices from RICS or manage separately in {props.vendor}</div>
              </div>
            </div>
          </div>
          <div className={getClass("body-col")} hidden={props.hideCustomersSection}>
            <div className={getClass("body-section-holder")}>
              <div className={getClass("body-section-holder-image-holder")}>
                <Image className={getClass("body-section-holder-image")} src="assets/img/team.svg" />
              </div>
              <div className={getClass("body-section-holder-text")}>
                <div className={getClass("body-section-holder-text-title")}>Customers</div>
                <div className={getClass("body-section-holder-text-sub-title")}>Manage saving of customer information</div>
              </div>
            </div>
          </div>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupInfoModal;

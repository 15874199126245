import { IonButton, IonIcon, IonItem, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { Tax } from "../../../interfaces/Taxes";

interface AdvancedTaxesContainerProps {
  taxes: Tax[];
  taxEventName?: string;
  closeModal: Function;
  selectedTaxId: string;
  onChangeSelectedTax: Function;
  onSaveChanges: Function;
  children?: React.ReactNode;
}

const AdvancedTaxesContainer: React.FC<AdvancedTaxesContainerProps> = (
  props
) => {
  const saveChanges = () => {
    props.closeModal();
    props.onSaveChanges();
  };

  return (
    <>
      <div className="advanced-taxes-header">
        <IonIcon
          className="ion-float-right advanced-taxes-modal-close"
          src={closeOutline}
          onClick={() => props.closeModal()}
        />
        <div className="advanced-taxes-title">
          {!!props.taxEventName
            ? `Advanced Tax Event Configuration: ${props.taxEventName}`
            : "Advanced Tax Configuration"}
        </div>
        <div className="advanced-taxes-subtext">
          Choose a tax jurisdiction from the left, then click cells in the table
          to edit specific product classes.
        </div>
      </div>
      <div className="advanced-taxes-body">
        <table className="advanced-grid">
          <tbody>
            <tr>
              <td className="advanced-grid-jurisdictions">
                {props.taxes.map((tax) => {
                  return (
                    <IonItem
                      key={tax.taxId}
                      onClick={() => props.onChangeSelectedTax(tax.taxId!)}
                      className={
                        props.selectedTaxId === tax.taxId
                          ? "selected-tax"
                          : "advanced-tax-item"
                      }
                    >
                      <IonText className="advanced-grid-jurisdictions-text">
                        {tax.description}
                      </IonText>
                    </IonItem>
                  );
                })}
              </td>
              <td className="advanced-grid-product-conditions">
                {props.children}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="advanced-taxes-footer">
        <IonButton
          slot="start"
          className="advanced-taxes-cancel-button"
          onClick={() => props.closeModal()}
        >
          Cancel
        </IonButton>
        <IonButton className="ion-float-right" onClick={() => saveChanges()}>
          Save Configuration
        </IonButton>
      </div>
    </>
  );
};

export default AdvancedTaxesContainer;

import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonInput, IonLabel, IonRow, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { SpecialTaxEvent } from "../../../../interfaces/Taxes";
import { usePortal } from "../../../PortalProvider";

interface EditSpecialTaxEventProps {
  taxes: SpecialTaxEventApplicableTaxes[];
  locationId?: string;
  specialTaxEvent?: SpecialTaxEvent;
  saveSpecialTaxEvent: Function;
  closeModal: Function;
}

export interface SpecialTaxEventApplicableTaxes {
  taxId: string;
  name: string;
  checked: boolean;
}

const EditSpecialTaxEvent: React.FC<EditSpecialTaxEventProps> = (props) => {
  const portal = usePortal();
  const [eventName, setEventName] = React.useState<string | null | undefined>(props.specialTaxEvent?.eventName);
  const [startDate, setStartDate] = React.useState<Date>(props.specialTaxEvent?.startDate ?? new Date());
  const [endDate, setEndDate] = React.useState<Date | undefined>(props.specialTaxEvent?.endDate);
  const [applicableTaxes, setApplicableTaxes] = React.useState<SpecialTaxEventApplicableTaxes[]>(props.taxes);
  const [applyToEverything, setApplyToEverything] = React.useState<boolean>(props.specialTaxEvent?.applyToEverything ?? true);

  const onApplicableTaxesChanged = (checked: boolean, taxId: string) => {
    let taxes = [...applicableTaxes];
    let specificTax = taxes.find((x) => x.taxId === taxId);
    specificTax!.checked = checked;
    setApplicableTaxes(taxes);
  };

  useEffect(() => {
    setApplicableTaxes(props.taxes);
  }, [props.taxes]);

  const saveSpecialTax = () => {
    let taxEvent: SpecialTaxEvent;

    if (!!props.specialTaxEvent) {
      taxEvent = props.specialTaxEvent;
      taxEvent.applicableTaxIds = applicableTaxes
        .filter((x) => x.checked)
        .map((tax) => {
          return tax.taxId;
        });
      taxEvent.eventName = eventName ?? "";
      taxEvent.startDate = startDate;
      taxEvent.endDate = endDate;
      taxEvent.applyToEverything = applyToEverything;
    } else {
      taxEvent = {
        tenantId: portal!.State.tenantInfo.tenantId,
        locationIds: [props.locationId],
        applicableTaxIds: applicableTaxes
          .filter((x) => x.checked)
          .map((tax) => {
            return tax.taxId;
          }),
        eventName: eventName,
        startDate: startDate,
        endDate: endDate,
        applyToEverything: applyToEverything
      } as SpecialTaxEvent;
    }

    if (!!taxEvent.endDate) {
      taxEvent.endDate = new Date(taxEvent.endDate);
      taxEvent.endDate.setHours(23);
      taxEvent.endDate.setMinutes(59);
      taxEvent.endDate.setSeconds(59);
    }

    props.saveSpecialTaxEvent(taxEvent);
    props.closeModal();
  };

  return (
    <div className="edit-special-event-modal-content">
      <IonIcon className="edit-special-event-modal-close" src={closeOutline} onClick={() => props.closeModal()} />
      <div className="edit-tax-event-header">
        <div className="edit-tax-event-title">{!!props.specialTaxEvent ? "Save Changes" : "Create a Tax Event"}</div>
        <div>
          <div className="edit-tax-event-sub-header">General Information</div>
          <div className="edit-tax-event-description">Provide some basic details about this tax event.</div>
        </div>
      </div>
      <div className="edit-tax-event-grid-container">
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonText className="edit-tax-event-field-description">Event Name</IonText>
              <IonInput
                value={eventName}
                onIonChange={(e) => setEventName(e.detail.value)}
                placeholder="e.g. 2020 Tax Free Weekend"
                className="edit-tax-event-name"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonText className="edit-tax-event-field-description">Start Date</IonText>
              <Flatpickr
                value={startDate}
                onChange={(dates) => setStartDate(dates[0])}
                options={{ dateFormat: "m-d-Y" }}
                className="edit-tax-event-start-date"
              />
              <IonIcon src="img/calendar.svg" className="edit-tax-event-start-date-icon" />
            </IonCol>
            <IonCol>
              <IonText className="edit-tax-event-field-description">End Date</IonText>
              <Flatpickr
                value={endDate}
                onChange={(dates) => setEndDate(dates[0])}
                options={{ dateFormat: "m-d-Y" }}
                className="edit-tax-event-end-date"
              />
              <IonIcon src="img/calendar.svg" className="edit-tax-event-end-date-icon" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonText className="edit-tax-event-field-description">
                Note that the tax event will begin at 12:01 AM of the date entered in the start date field and end at 11:59 PM of the date entered in
                the end date field.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonText className="edit-tax-event-field-description">Select the tax jurisdiction below that are affected by this tax event:</IonText>
              {props.taxes.map((tax) => {
                return (
                  <div className="edit-tax-event-taxes" key={tax.taxId}>
                    <div className="edit-tax-event-checkbox-container">
                      <IonCheckbox
                        className="edit-tax-event-taxes-item"
                        checked={tax.checked}
                        onIonChange={(e) => onApplicableTaxesChanged(e.detail.checked, tax.taxId)}
                      />
                    </div>
                    <div className="edit-tax-event-jurisdiction-label-container">
                      <IonLabel className="edit-tax-event-taxes-item">{tax.name}</IonLabel>
                    </div>
                  </div>
                );
              })}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonText className="edit-tax-event-field-description">What will be tax free?</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonButton
                color={applyToEverything ? "primary" : "lightgray"}
                onClick={() => setApplyToEverything(true)}
                className="edit-tax-event-tax-free-button">
                Everything Sold
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton
                color={applyToEverything ? "lightgray" : "primary"}
                onClick={() => setApplyToEverything(false)}
                className="edit-tax-event-tax-free-button">
                Specific Products or Categories
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <div className="edit-tax-event-button-container">
        <IonButton slot="start" className="edit-tax-event-cancel-button" onClick={() => props.closeModal()}>
          Cancel
        </IonButton>
        <IonButton className="ion-float-right edit-tax-event-save-button" onClick={() => saveSpecialTax()} disabled={!eventName}>
          {!!props.specialTaxEvent ? "Save Changes" : "Create Tax Event"}
        </IonButton>
      </div>
    </div>
  );
};

export default EditSpecialTaxEvent;

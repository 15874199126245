import { IonText, IonRadioGroup, IonRadio, IonItem, IonButton, IonLabel, IonToggle, IonInput } from '@ionic/react';
import React, { useEffect } from 'react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import SettingsCard from './SettingsCard';
import LeftRightContent from './LeftRightContent';
import { SaleSetting } from '../interfaces/salesettings/SaleSettings';
import { usePortal } from './PortalProvider';
import { useDataService } from './DataServiceProvider';
import { CustomerSettings } from '../interfaces/salesettings/CustomerSettings';

export interface CustomerSettingsProps {
  locationSettings: SaleSetting;
  locationId?: string;
  onboardingWorkflow?: Boolean;
  onComplete?: Function;
}

const POSCustomerSettings: React.FC<CustomerSettingsProps> = ({ locationSettings, locationId, onboardingWorkflow = false, onComplete }) => {
  //initial field values to check against current when IonInputs change, for save button enablement
const customerRequiredForSaleInitialValue = locationSettings.customerSettings?.customerRequiredForSale;

  const dollarAmountBaseCssClass = "input-dollar-amount";
  const percentBaseCssClass = "input-percent";
  const invalidControlCssClass = "control-invalid";
  const dataService = useDataService();
  const portal = usePortal();
  const [applyCustomerRequiredForSale, setCustomerRequiredForSale] = React.useState(customerRequiredForSaleInitialValue);
  const [locationsCanOverride, setLocationsCanOverride] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [canSave, setCanSave] = React.useState(false);
  const [returnThresholdCssClass, setReturnThresholdCssClass] = React.useState(dollarAmountBaseCssClass);

  useEffect(() => {
    let customerSettings = portal!.State.saleSettings['Tenant'].customerSettings;
    let canOverride = true;

    if (customerSettings) {
      canOverride = customerSettings.locationsCanOverride ?? true;
    }

    if (locationId && !canOverride) {
      setIsDisabled(true);
    }

    setLocationsCanOverride(canOverride);
  }, []);

  const save = () => {
    portal!.navigation.isLoading(true);

    let salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings['Tenant'];
    }
    else {
      salesSettings = portal!.State.saleSettings['Tenant'];
    }

    let settingsToSave: CustomerSettings = {
      customerRequiredForSale: applyCustomerRequiredForSale,
    }

    if (!salesSettings.customerSettings) {
      salesSettings.customerSettings = {};
    }

    Object.assign(salesSettings.customerSettings, settingsToSave);

    if (!locationId) {
      salesSettings.customerSettings!.locationsCanOverride = locationsCanOverride;
    }
    else {
      salesSettings.locationId = locationId;
      salesSettings.customerSettings!.locationsCanOverride = undefined;
    }

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      alert('Something unexpected went wrong');
    };

    let onSuccess = function () {
      portal!.navigation.isLoading(true);

      let onSuccess2 = function () {
        portal!.navigation.isLoading(false);
        setCanSave(false);
      };

      dataService!.getSaleSettings(onSuccess2, onFailure);
    };

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);

    if (onboardingWorkflow === true && onComplete) {
      onComplete(1);
    }

  };

  const onBack = () => {
    if (onComplete) {
      onComplete(-1);
    }
  };

  const onSetLocationsCanOverride = (canOverride: boolean) => {
    setLocationsCanOverride(canOverride);
    setCanSave(true);
  };

  const onSetCustomerRequiredForSale = (value: boolean) => {
    setCustomerRequiredForSale(value);
    setCanSave(true);
  };


  //POS Device Settings must keep track of the initial values of the fields, because some of them are controlled by IonInputs.
  //IonInputs fire OnIonChanged events, which triggers setting canSave=true if the values for the fields are valid.
  //So, to keep the button disabled when nothing has changed, we must check if the current values match the initial values.
  const haveAnyFieldsChanged = () => {

    let applyCustomerRequiredForSaleChanged = applyCustomerRequiredForSale != customerRequiredForSaleInitialValue;

    return applyCustomerRequiredForSaleChanged;
  };

  return (
    <>
      <SettingsCard
        header="Customer Settings"
        subHeading="Configure preferences and settings pertaining to customers."
        LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
        isDisabled={locationId !== undefined}>
        <LeftRightContent heading="Customer Required" preamble="Do you want to require a customer to be assigned to every sale?">
        <IonItem lines="none" className="setting">
            <IonToggle
              checked={applyCustomerRequiredForSale}
              className="ion-align-self-center"
              disabled={isDisabled}
              onIonChange={e => onSetCustomerRequiredForSale(e.detail.checked)}
              data-testid="apply-customer-required-toggle" />
            <IonText>Require a customer assigned to all sales</IonText>
        </IonItem>
         </LeftRightContent>
        <div className='buttonsHolder' hidden={onboardingWorkflow === true}>
          <IonButton data-testid="save-settings-page" disabled={isDisabled || !canSave} onClick={() => save()} className="save-setting">Save Changes</IonButton>
        </div>
        <div className="modal-buttons-holder" hidden={onboardingWorkflow === false}>
          <IonButton data-testid="back-onboarding-workflow" onClick={() => onBack()} className="save-setting modal-back-button">Go Back</IonButton>
          <IonButton data-testid="save-onboarding-workflow" color='primary' onClick={() => save()} className="save-setting ion-float-right">
            <IonLabel>Done with Settings</IonLabel>
          </IonButton>
        </div>
      </SettingsCard>
    </>
  );
};

export default POSCustomerSettings;
import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { Claims } from "../constants/Claims";
import { FeatureFlags } from "../constants/FeatureFlags";
import { UserPermissions } from "../interfaces/ApplicationPortal";

export const hasPermissionTo = (claim: string, permissions: Array<UserPermissions> | undefined) => {
  if (!permissions) {
    return false;
  }

  let allClaims = permissions.flatMap((u) => u.claims);
  if (allClaims.some((c) => c == claim)) {
    return true;
  }

  return false;
};

export const isAuthorized = (userPermissions?: Array<UserPermissions>, claim?: string, flags?: LDFlagSet) => {
  var flagsMatch = flags && (flags[FeatureFlags.LabelsPage] || flags[FeatureFlags.GlobalProductCatalogPage]);
  var portalAccessPermission = hasPermissionTo(flagsMatch ? Claims.Portal : Claims.Owner, userPermissions);
  var pageAccess = flagsMatch && claim ? hasPermissionTo(claim, userPermissions) : true;
  if (portalAccessPermission && pageAccess) {
    return true;
  }

  return false;
};

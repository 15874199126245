import { IonContent, IonPage } from '@ionic/react';
import TenantLevelPageHeader from '../components/TenantLevelPageHeader';
import "../theme/Reputation.css";
import React from 'react';
const Reputation: React.FC = () => {
    return (
        <IonPage>
            <TenantLevelPageHeader
                headerText="Reputation"
                headerIconFileUrl="assets/img/integration.svg">
            </TenantLevelPageHeader>
            <IonContent>
                <div className="reputation-content-holder">
                        <div className="reputation-frame-holder">
                            <iframe src="reputation.rics.io"></iframe>
                        </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default Reputation;
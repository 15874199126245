import { IonContent, IonPage, IonImg } from '@ionic/react';
import React from 'react';
import '../theme/AccountSetupComplete.css';
const AccountSetupComplete: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="accountSetupCompletePage">
          <div id="accountSetupCompleteHolder">
            <div className="topSection centerContents">
            <div className="logoHolder">
                <IonImg src="img/rics-logo.svg" title="RICS-logo-full-colorAsset 1" style={ {"width": "240px"} }></IonImg>
            </div>
            <div className="heading">Account Setup Complete</div>
            <div className="subHeadingHolder">
                <div className="subHeading">Please download the RICS Checkout app to start using your new point of sale:</div>
                    <a className="appStoreIconHolder" href="https://apps.apple.com/us/app/rics-checkout/id1470394564?ls=1">
                        <IonImg src="img/download-on-app-store.svg" style={ {"height": "40px"} }></IonImg>
                    </a>
                </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default AccountSetupComplete;
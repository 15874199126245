import { IonButton, IonIcon, IonInput, IonItem, IonRadio, IonRadioGroup, IonText, IonToggle } from "@ionic/react";
import React, { useEffect, useRef } from "react";

/* Theme variables */
import "../theme/variables.css";

import LeftRightContent from "../components/LeftRightContent";
import SettingsCard from "../components/SettingsCard";
import { MerchantSettings, MerchantTypes, MerchantTypesLabels } from "../interfaces/salesettings/MerchantSettings";
import { SaleSetting } from "../interfaces/salesettings/SaleSettings";
import "../theme/PaymentProcessing.css";
import { useDataService } from "./DataServiceProvider";
import { usePortal } from "./PortalProvider";
import { useXhrService } from "./XhrServiceProvider";

export interface PaymentProcessingProps {
  locationId?: string;
  locationSettings: SaleSetting;
  ricsPayEnabled: boolean;
}

const PaymentProcessing: React.FC<PaymentProcessingProps> = ({ locationId, locationSettings, ricsPayEnabled }) => {
  const xhrService = useXhrService();
  const dataService = useDataService();
  const portal = usePortal();
  const nonProdMerchantTrackUrl = "https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn";
  const prodMerchantTrackUrl = "https://merchanttrack.fullsteampay.net/Account/SingleSignOn";
  const merchantTrackUrl = portal!.common.environment === "prod" ? prodMerchantTrackUrl : nonProdMerchantTrackUrl;
  const baseMerchantIdClass = "merchant-id";
  const merchantIdErrorClass = `${baseMerchantIdClass} merchant-id-error`;
  const merchantIdSuccessClass = `${baseMerchantIdClass} merchant-id-success`;
  const dollarAmountBaseCssClass = "input-dollar-amount";
  const invalidControlCssClass = "control-invalid";

  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = React.useState<MerchantTypes | undefined>(
    locationSettings?.merchantSettings?.merchantType
  );
  const [locationsCanOverride, setLocationsCanOverride] = React.useState<boolean>(locationSettings?.merchantSettings?.locationsCanOverride ?? true);
  const [canSave, setCanSave] = React.useState<boolean>(false);
  const [verifyEnabled, setVerifyEnabled] = React.useState<boolean>(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState<string | undefined>(locationSettings?.merchantSettings?.merchantId ?? undefined);
  const [merchantIdClass, setMerchantIdClass] = React.useState(baseMerchantIdClass);
  const [isRicsPaySetup, setIsRicsPaySetup] = React.useState(!!locationSettings?.merchantSettings?.merchantId);
  const [storeAndForwardEnabled, setStoreAndForward] = React.useState(locationSettings?.merchantSettings?.storeAndForwardEnabled ?? false);
  const [storeAndForwardTransactionThreshold, setStoreAndForwardTransactionThreshold] = React.useState(
    locationSettings?.merchantSettings?.storeAndForwardTransactionThreshold
  );
  const [storeAndForwardOutageThreshold, setStoreAndForwardOutageThreshold] = React.useState(
    locationSettings?.merchantSettings?.storeAndForwardOutageThreshold
  );
  const [storeAndForwardTransactionCssClass, setStoreAndForwardTransactionCssClass] = React.useState(dollarAmountBaseCssClass);
  const [isValidated, setIsValidated] = React.useState(!!locationSettings?.merchantSettings?.merchantId);
  const [storeAndForwardOutageCssClass, setStoreAndForwardOutageCssClass] = React.useState(dollarAmountBaseCssClass);
  const ssoSubmitForm = React.createRef<HTMLFormElement>();
  const ssoHiddenField = React.createRef<HTMLInputElement>();
  const userHasPhoneNumber = !!portal!.State.currentUser.phoneNumber;

  const locationName = !locationId ? portal!.State.tenantInfo.businessName : portal!.State.locations.find((x) => x.locationId === locationId)!.name;
  const isInitialMount = useRef(true);

  useEffect(() => {
    let canOverride = portal!.State.saleSettings["Tenant"].merchantSettings?.locationsCanOverride ?? true;

    setLocationsCanOverride(canOverride);
    if (!!locationId) {
      setIsDisabled(!canOverride);
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      checkValidation();
    }
  }, [selectedPaymentProcessor]);

  const setStateVariables = () => {
    setCanSave(false);

    let isTenant = !locationId;

    let tenantIsNonIntegrated = portal!.State.saleSettings["Tenant"].merchantSettings?.merchantType == MerchantTypes.Manual;
    let locationsCanOverride = locationSettings?.merchantSettings?.locationsCanOverride ?? true;

    setLocationsCanOverride(locationsCanOverride);

    if (isTenant || !locationsCanOverride) {
      setIsDisabled(!isTenant && !locationsCanOverride);

      if (tenantIsNonIntegrated) {
        setSelectedPaymentProcessor(MerchantTypes.Manual);
      }
    } else {
      let locationIsNonIntegrated = locationSettings?.merchantSettings?.merchantType == MerchantTypes.Manual;

      if (locationIsNonIntegrated) {
        setSelectedPaymentProcessor(MerchantTypes.Manual);
      } else {
        if (tenantIsNonIntegrated) {
          setSelectedPaymentProcessor(MerchantTypes.Manual);
        }
      }
    }
  };

  const refreshPaymentSettings = () => {
    portal!.navigation.isLoading(true);

    let onSuccess = () => {
      if (!ricsPayEnabled) {
        setStateVariables();
      }
      setVerifyEnabled(true);
      portal!.navigation.isLoading(false);
    };

    let onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    let refreshSalesSettings = () => {
      dataService!.getSaleSettings(onSuccess, onFailure);
    };

    dataService!.loadConfigurations(null, refreshSalesSettings, onFailure);
  };

  const checkValidation = () => {
    if (selectedPaymentProcessor == MerchantTypes.RicsPay) {
      setIsValidated(false);
      setVerifyEnabled(true);
    } else {
      setIsValidated(true);
      setVerifyEnabled(false);
    }
  };

  const updatePaymentProcessor = (selectedProcessor: MerchantTypes) => {
    setSelectedPaymentProcessor(selectedProcessor);
    setMerchantId(undefined);
    setIsRicsPaySetup(false);

    if (selectedProcessor !== MerchantTypes.Manual) {
      setCanSave(false);
      setMerchantIdClass(merchantIdErrorClass);
    } else {
      setCanSave(true);
    }
  };

  const saveChanges = () => {
    portal!.navigation.isLoading(true);

    let onSuccess = () => {
      setIsRicsPaySetup(true);
      refreshPaymentSettings();
    };

    let onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    let salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings["Tenant"];
      dataService!.enqueueLocationForUpdate(locationId);
    } else {
      salesSettings = portal!.State.saleSettings["Tenant"];
      portal!.State.shouldUpdateAllLocations = true;
    }

    let settingsToSave: MerchantSettings = {
      merchantType: selectedPaymentProcessor!,
      merchantId: merchantId,
      storeAndForwardEnabled: storeAndForwardEnabled,
      storeAndForwardTransactionThreshold: storeAndForwardTransactionThreshold,
      storeAndForwardOutageThreshold: storeAndForwardOutageThreshold
    };

    Object.assign(salesSettings.merchantSettings as {}, settingsToSave);

    if (!locationId) {
      salesSettings.merchantSettings!.locationsCanOverride = locationsCanOverride;
    } else {
      salesSettings.locationId = locationId;
      salesSettings.merchantSettings!.locationsCanOverride = undefined;
    }

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);
  };

  const onSetLocationsCanOverride = (canOverride: boolean) => {
    setLocationsCanOverride(canOverride);
    setCanSave(true);
  };

  const logIntoMerchantTrack = () => {
    portal!.navigation.isLoading(true);
    let endpoint = "v1/RicsPay/ssotoken";

    let url = encodeURI(window.location.href);
    url = url.replace(/#/g, "%23");

    if (!!locationId) {
      endpoint = `${endpoint}?locationId=${locationId}&returnUrl=${url}`;
    } else {
      endpoint = `${endpoint}?returnUrl=${url}`;
    }

    let onSuccess = function (response: string) {
      portal!.navigation.isLoading(false);
      ssoHiddenField!.current!.value = response;
      ssoSubmitForm!.current!.submit();
    };

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to log into MerchantTrack. Please verify your Merchant ID and try again.");
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const disconnectRicsPay = () => {
    portal!.navigation.isLoading(true);
    setMerchantId(undefined);
    setSelectedPaymentProcessor(MerchantTypes.Manual);

    let onSuccess = () => {
      setIsRicsPaySetup(false);
      refreshPaymentSettings();
    };

    let onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    let salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings["Tenant"];
    } else {
      salesSettings = portal!.State.saleSettings["Tenant"];
    }

    let settingsToSave: MerchantSettings = {
      merchantType: MerchantTypes.Manual,
      merchantId: undefined
    };

    Object.assign(salesSettings.merchantSettings as {}, settingsToSave);

    if (!locationId) {
      salesSettings.merchantSettings!.locationsCanOverride = locationsCanOverride;
    } else {
      salesSettings.locationId = locationId;
      salesSettings.merchantSettings!.locationsCanOverride = undefined;
    }

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);
  };

  const onUpdateMerchantId = (value: string | undefined) => {
    setMerchantId(value);

    if (!!value || selectedPaymentProcessor === MerchantTypes.Manual) {
      setMerchantIdClass(baseMerchantIdClass);
    } else {
      setCanSave(false);
      setMerchantIdClass(merchantIdErrorClass);
    }
  };

  const onSetStoreAndForward = (value: boolean) => {
    setStoreAndForward(value);
    validateStoreAndForward(value, storeAndForwardTransactionThreshold, storeAndForwardOutageThreshold);
  };

  const onSetStoreAndForwardTransactionThreshold = (value: string | null | undefined) => {
    let isValid = false;
    let numberValue: number | undefined = undefined;

    if (value) {
      numberValue = Number(value);
      isValid = numberValue > 0;

      setStoreAndForwardTransactionThreshold(numberValue);
    } else {
      setStoreAndForwardTransactionThreshold(undefined);
    }

    validateStoreAndForward(storeAndForwardEnabled, numberValue, storeAndForwardOutageThreshold);
  };

  const onSetStoreAndForwardOutageThreshold = (value: string | null | undefined) => {
    let isValid = false;
    let numberValue: number | undefined = undefined;

    if (value) {
      numberValue = Number(value);
      isValid = numberValue > 0;

      setStoreAndForwardOutageThreshold(numberValue);
    } else {
      setStoreAndForwardOutageThreshold(undefined);
    }

    validateStoreAndForward(storeAndForwardEnabled, storeAndForwardTransactionThreshold, numberValue);
  };

  const validateStoreAndForward = (
    storeAndForwardEnabledValue: boolean,
    storeAndForwardTransactionThresholdValue: number | undefined,
    storeAndForwardOutageThresholdValue: number | undefined
  ) => {
    let canSave = true;

    if (storeAndForwardEnabledValue === false) {
      setStoreAndForwardTransactionCssClass(dollarAmountBaseCssClass);
      setStoreAndForwardOutageCssClass(dollarAmountBaseCssClass);
      setCanSave(canSave);
      return;
    }

    if (storeAndForwardEnabledValue === true) {
      let transactionThresholdValid =
        storeAndForwardTransactionThresholdValue !== undefined && storeAndForwardTransactionThresholdValue > 0 ? true : false;
      setStoreAndForwardTransactionCssClass(
        transactionThresholdValid ? dollarAmountBaseCssClass : `${dollarAmountBaseCssClass} ${invalidControlCssClass}`
      );

      let outageThresholdValid = storeAndForwardOutageThresholdValue !== undefined && storeAndForwardOutageThresholdValue > 0 ? true : false;
      setStoreAndForwardOutageCssClass(outageThresholdValid ? dollarAmountBaseCssClass : `${dollarAmountBaseCssClass} ${invalidControlCssClass}`);

      canSave = transactionThresholdValid && outageThresholdValid;
    }
    setCanSave(canSave);
  };
  const verifyMerchantId = () => {
    portal!.navigation.isLoading(true);
    let endpoint = "v1/RicsPay/VerifyMerchantId";

    let url = encodeURI(window.location.href);
    url = url.replace(/#/g, "%23");

    if (!!locationId) {
      endpoint = `${endpoint}?locationId=${locationId}&merchantId=${merchantId}&returnUrl=${url}`;
    } else {
      endpoint = `${endpoint}?returnUrl=${url}&merchantId=${merchantId}`;
    }

    let onSuccess = function () {
      portal!.navigation.isLoading(false);
      setCanSave(true);
      setMerchantIdClass(merchantIdSuccessClass);
      setIsValidated(true);
    };

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      setIsRicsPaySetup(false);
      setCanSave(false);
      setMerchantIdClass(merchantIdErrorClass);
      alert("You have entered an invalid Merchant ID. Please verify your Merchant ID and try again.");
      setIsValidated(false);
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  return (
    <SettingsCard
      header="Payment Processing"
      subHeading="Specify how you would like to process credit card payments."
      LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
      isDisabled={locationId !== undefined}>
      <LeftRightContent
        heading="Credit Card Processing"
        preamble={[
          `We recommend using ${MerchantTypesLabels.get(MerchantTypes.RicsPay)}`,
          <span>&#8482;</span>,
          ` for fully-integrated support, but you can manually collect payments via any non-integrated credit card processing device.`
        ]}>
        <IonRadioGroup value={selectedPaymentProcessor} onIonChange={(e) => updatePaymentProcessor(e.detail.value)}>
          <IonItem lines="none" className="setting" onClick={() => updatePaymentProcessor(MerchantTypes.Manual)}>
            <IonRadio value={MerchantTypes.Manual} disabled={isDisabled} />
            <IonText>Non-Integrated Payment Processor (credit card machine)</IonText>
          </IonItem>
          <IonItem lines="none" className="setting" hidden={!ricsPayEnabled} onClick={() => updatePaymentProcessor(MerchantTypes.RicsPay)}>
            <IonRadio value={MerchantTypes.RicsPay} disabled={isDisabled} />
            <IonText>Integrated Payment Processor ({MerchantTypesLabels.get(MerchantTypes.RicsPay)}&#8482;)</IonText>
          </IonItem>
        </IonRadioGroup>
      </LeftRightContent>
      <div hidden={selectedPaymentProcessor !== MerchantTypes.RicsPay || !ricsPayEnabled}>
        <LeftRightContent
          heading=""
          preamble={[
            `Enter your ${MerchantTypesLabels.get(MerchantTypes.RicsPay)}`,
            <span>&#8482;</span>,
            ` Merchant ID to finish setting up your account`
          ]}>
          <div className="merchant-id-holder">
            <input
              type="text"
              disabled={!verifyEnabled || isRicsPaySetup}
              value={merchantId}
              className={merchantIdClass}
              onChange={(e) => onUpdateMerchantId(e.target.value)}
            />
            <IonButton
              color="primary"
              disabled={!verifyEnabled || isRicsPaySetup}
              onClick={() => verifyMerchantId()}
              className="merchant-id-verify-btn">
              Verify
            </IonButton>
          </div>
        </LeftRightContent>
        <LeftRightContent
          hidden={!isRicsPaySetup}
          heading=""
          preamble={[`Manage your ${MerchantTypesLabels.get(MerchantTypes.RicsPay)}`, <span>&#8482;</span>, ` Account`]}>
          <IonItem color="primary" className="ricspay-active-account">
            <IonIcon src={"img/success.svg"} className="ricspay-active-icon" />
            <IonText>{locationName} RICS Pay Account</IonText>
          </IonItem>
          <br />
          <IonText hidden={isDisabled} className="disconnect-ricspay" color="danger" onClick={() => disconnectRicsPay()}>
            Disconnect {MerchantTypesLabels.get(MerchantTypes.RicsPay)}&#8482; Account
          </IonText>
          <br />
          <IonButton color="primary" disabled={!userHasPhoneNumber} onClick={() => logIntoMerchantTrack()} className="merchant-track-button">
            Log into MerchantTrack
          </IonButton>
          <div>
            <IonText hidden={userHasPhoneNumber} color="danger">
              Phone number is required to log into MerchantTrack. Please navigate to the All Users page and update your account with a valid phone
              number.
            </IonText>
          </div>
          <form hidden={true} action={merchantTrackUrl} method="post" target="_blank" ref={ssoSubmitForm}>
            <input type="hidden" id="AuthenticationKey" name="AuthenticationKey" ref={ssoHiddenField} />
          </form>
        </LeftRightContent>
      </div>
      <div hidden={selectedPaymentProcessor !== MerchantTypes.RicsPay || !isRicsPaySetup}>
        <LeftRightContent heading="Store &amp; Forward" preamble="Capture credit transactions during a payment outage.">
          <IonItem lines="none" className="setting">
            <IonToggle
              checked={storeAndForwardEnabled}
              className="ion-align-self-center"
              disabled={isDisabled}
              onIonChange={(e) => onSetStoreAndForward(e.detail.checked)}
              data-testid="store-forward-toggle"
            />
            <IonText>Enable Store &amp; Forward</IonText>
          </IonItem>
          <div hidden={!storeAndForwardEnabled}>
            <IonText color="gray">
              <div className="ion-padding-top ion-padding-bottom">
                During a payment outage, these thresholds are the maximum dollar amount that can be processed per terminal.
              </div>
            </IonText>
            <IonItem lines="none" className="setting subSetting">
              <IonText>Transaction Threshold Dollar Amount:</IonText>
              <IonInput
                value={storeAndForwardTransactionThreshold}
                disabled={isDisabled}
                required={storeAndForwardEnabled}
                className={storeAndForwardTransactionCssClass}
                placeholder="e.g. 100"
                type="number"
                onIonChange={(e) => onSetStoreAndForwardTransactionThreshold(e.detail.value)}
                data-testid="storeForward-transaction-dollar-threshold"
              />
            </IonItem>
            <br />
            <IonItem lines="none" className="setting subSetting">
              <IonText>Outage Threshold Dollar Amount:</IonText>
              <IonInput
                value={storeAndForwardOutageThreshold}
                disabled={isDisabled}
                required={storeAndForwardEnabled}
                className={storeAndForwardOutageCssClass}
                placeholder="e.g. 1000"
                type="number"
                onIonChange={(e) => onSetStoreAndForwardOutageThreshold(e.detail.value)}
                data-testid="storeForward-outage-dollar-threshold"
              />
            </IonItem>
          </div>
        </LeftRightContent>
      </div>
      <div className="buttonsHolder">
        <IonButton
          color="primary"
          onClick={() => saveChanges()}
          disabled={isDisabled || !canSave || !isValidated}
          className="save-setting"
          data-testid="save-changes">
          Save Changes
        </IonButton>
      </div>
    </SettingsCard>
  );
};

export default PaymentProcessing;

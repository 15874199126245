import { IonCheckbox, IonCol, IonFabButton, IonIcon, IonInput, IonRow } from "@ionic/react";
import React, { useEffect } from "react";

/* Theme variables */
import "../../theme/variables.css";

import "../../theme/UserGridRow.css";
import { User } from "../../interfaces/User";
import UserLocationsPopover from "./UserLocationsPopover";
import UserRolesPopover from "./UserRolesPopover";

interface UserGridRowProps {
  user: User;
  checked: boolean;
  index: number;
  onUpdateUser: (index: number, updatedUser: User) => void;
  openUserActionsPopover: Function;
  allUsersIsChecked: boolean;
}

const UserGridRow: React.FC<UserGridRowProps> = ({ user, checked, index, onUpdateUser, openUserActionsPopover, allUsersIsChecked }) => {
  const inputCssClass = "user-grid-row-input";
  const invalidInputCssClass = `${inputCssClass} user-grid-row-input-invalid`;
  const [isChecked, setIsChecked] = React.useState(checked);
  const [isFirstTime, setIsFirstTime] = React.useState(true);

  const [phoneNumber, setPhoneNumber] = React.useState<string | null | undefined>(user.PhoneNumber);
  const [userEmail, setUserEmail] = React.useState<string | null | undefined>(user.Email);
  const [phoneStyle, setPhoneStyle] = React.useState(inputCssClass);
  const [emailStyle, setEmailStyle] = React.useState(inputCssClass);
  const [timer, setTimer] = React.useState<NodeJS.Timeout>(setTimeout(() => { }, 1));

  useEffect(() => {
    setIsChecked(allUsersIsChecked);
  }, [allUsersIsChecked]);

  useEffect(() => {
    if (user.IsSelected !== isChecked) {
      setIsChecked(user.IsSelected);
    }
  }, [user.IsSelected]);

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
    } else {
      resetTimer();
      let isValid = phoneIsValid(phoneNumber) && emailIsValid(userEmail);
      setIsChecked(isValid);
    }
  }, [phoneNumber, userEmail]);

  useEffect(() => {
    setPhoneNumber(user.PhoneNumber);
    setUserEmail(user.Email);
  }, [user]);

  const onUserChanged = (updatedUser: User) => {
    setIsChecked(true);
    updatedUser.IsSelected = true;
    onUpdateUser(index, updatedUser);
  };

  const onUserSelected = (checkedValue: boolean) => {
    setIsChecked(checkedValue);
    let updatedUser: User = { ...user };
    updatedUser.IsSelected = checkedValue;
    onUpdateUser(index, updatedUser);
  };

  const updatePhone = (phone: string | null | undefined) => {
    setPhoneNumber(phone);
    let isValid = phoneIsValid(phone);
    setPhoneStyle(isValid ? inputCssClass : invalidInputCssClass);
  };

  const updateEmail = (email: string | null | undefined) => {
    setUserEmail(email);
    let isValid = emailIsValid(email);
    setEmailStyle(isValid ? inputCssClass : invalidInputCssClass);
  };

  const updateUser = () => {
    let updatedUser = user;
    updatedUser.PhoneNumber = phoneNumber ?? "";
    updatedUser.Email = userEmail ?? "";
    updatedUser.Valid = phoneIsValid(phoneNumber) && emailIsValid(userEmail);
    onUpdateUser(index, updatedUser);
  };

  const resetTimer = () => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        updateUser();
      }, 500)
    );
  };

  function phoneIsValid(phone: string | null | undefined) {
    if (!phone || phone === "") {
      return true;
    }

    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone);
  }

  function emailIsValid(email: string | null | undefined) {
    if (!email || email === "") {
      return true;
    }

    return /^[a-zA-Z0-9.+-_]*@\w+([\.-]?\w+)*(\.\w{2,})+$/im.test(email);
  }

  return (
    <>
      <IonRow key={user.UserId} class="user-grid-row" hidden={user.Hidden}>
        <IonCol size="1">
          <IonCheckbox checked={isChecked} onIonChange={(e) => onUserSelected(e.detail.checked)} />
        </IonCol>
        <IonCol size="1">{user.FirstName}</IonCol>
        <IonCol size="2">{user.LastName}</IonCol>
        <IonCol size="3">
          <IonInput value={userEmail} type="email" className={emailStyle} placeholder="Email..." onIonInput={(e) => updateEmail(e.detail.value)} />
        </IonCol>
        <IonCol size="2">
          <IonInput
            value={phoneNumber}
            type="tel"
            className={phoneStyle}
            placeholder="Phone..."
            onIonInput={(e) => updatePhone(e.detail.value)}
            maxlength={15}
          />
        </IonCol>
        <IonCol size="2">
          <UserLocationsPopover
            user={user}
            index={index}
            onUserChanged={onUserChanged}
          />
        </IonCol>
        <IonCol size="2">
          <UserRolesPopover
            user={user}
            onUserChanged={onUserChanged}
          />
        </IonCol>
        <IonCol size="1">
          <IonFabButton className="user-actions-button" onClick={(e) => openUserActionsPopover(user, e)}>
            <IonIcon className="user-actions-icon" src="assets/img/overflow.svg" />
          </IonFabButton>
        </IonCol>
      </IonRow>
    </>
  );
};

export default UserGridRow;

import { IonText, IonToggle, IonLabel, IonItem, IonButton, IonGrid, IonRow, IonCol, IonReorderGroup, IonReorder, IonSelect, IonSelectOption, IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';
import SettingsCard from '../components/SettingsCard';


/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import LeftRightContent from '../components/LeftRightContent';
import { usePortal } from './PortalProvider';
import { SaleSetting } from '../interfaces/salesettings/SaleSettings';
import { useDataService } from './DataServiceProvider';
import { ReceiptSettings } from '../interfaces/salesettings/ReceiptSettings';

const selectOptions = {
  cssClass: 'select-setting',
  showBackdrop: false
};

export interface ReceiptProps {
  locationSettings: SaleSetting;
  locationId?: string;
  onboardingWorkflow?: Boolean;
  onComplete?: Function;
  posDeviceSettingsEnabled?: Boolean;
}

const POSReceiptSettings: React.FC<ReceiptProps> = ({locationSettings, locationId, onboardingWorkflow = false, onComplete, posDeviceSettingsEnabled }) => {
  const portal = usePortal();
  const dataService = useDataService();
  const [receiptOptions, setReceiptOptions] = React.useState(locationSettings.receiptOptionSettings?.receiptOptions);
  const [isReceiptOptionsValid, setIsReceiptOptionsValid] = React.useState(!locationSettings.receiptOptionSettings?.receiptOptions!.every(x => x.enabled === false));
  const [locationsCanOverride, setLocationsCanOverride] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [canSave, setCanSave] = React.useState(false);
  
  useEffect(() => {
    var canOverride = portal!.State.saleSettings['Tenant'].receiptOptionSettings?.locationsCanOverride ?? true;
    if (locationId && !canOverride) {
      setIsDisabled(true);
    }

    setLocationsCanOverride(canOverride)
  }, []);


  const onUpdateItems = (index: number) => {
    var currentOptions = receiptOptions!;
    currentOptions[index].enabled = !(currentOptions[index].enabled);
    setReceiptOptions(currentOptions)
    var isReceiptOptionsValid = !currentOptions.every(x => x.enabled === false);
    setIsReceiptOptionsValid(isReceiptOptionsValid);
    setCanSave(isReceiptOptionsValid);
  };

  const save = () => {
    portal!.navigation.isLoading(true);
    setCanSave(false);

    var salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings['Tenant'];
    }
    else {
      salesSettings = portal!.State.saleSettings['Tenant'];
    }

    let settingsToSave: ReceiptSettings = {
      locationsCanOverride: locationsCanOverride,
      receiptOptions: receiptOptions
    }

    if (!salesSettings.receiptOptionSettings) {
      salesSettings.receiptOptionSettings = {};
    }

    Object.assign(salesSettings.receiptOptionSettings, settingsToSave);

    if (!locationId) {
      salesSettings.receiptOptionSettings!.locationsCanOverride = locationsCanOverride;
    }
    else {
      if (salesSettings.receiptOptionSettings) {
        salesSettings.receiptOptionSettings.locationsCanOverride = undefined;
      }
    }

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert('Something unexpected went wrong');
    };

    var onSuccess = function () {
      dataService!.enqueueLocationForUpdate(locationId);
      portal!.navigation.isLoading(true);

      var onSuccess2 = function () {
      portal!.navigation.isLoading(false);
      };

      dataService!.getSaleSettings(onSuccess2, onFailure);

    };

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);

    if (onboardingWorkflow === true && onComplete) {
      onComplete(1);
    }
  };


  const onBack = () => {
    if (onComplete) {
      onComplete(-1);
    }
  };

  const onSetLocationsCanOverride = (canOverride: boolean) => {
    setLocationsCanOverride(canOverride);
    setCanSave(true);
  };

  return (
    <SettingsCard
      header="Receipt Settings"
      subHeading="Specify supported receipt options."
      LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
      isDisabled={locationId !== undefined}>
      <LeftRightContent heading="Receipt Options" preamble="What types of receipts should be enabled?">
        <IonGrid>
          <IonRow>
            {receiptOptions!.map((receiptOption, index) => {
              return (
                <IonCol key={receiptOption.receiptOptionId} size='6'>
                  <IonItem lines='none' className="setting">
                    <IonToggle checked={receiptOption.enabled} disabled={isDisabled} onClick={() => onUpdateItems(index)} />
                    <IonText>{receiptOption.receiptOptionDisplayName}</IonText>
                  </IonItem>
                  
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
        <IonLabel hidden={isReceiptOptionsValid} color='danger'>You must enable at least one receipt type.</IonLabel>
      </LeftRightContent>
      <div className='buttonsHolder' hidden={onboardingWorkflow === true}>
        <IonButton data-testid="save-receipt-settings" color='primary' disabled={isDisabled || !canSave} onClick={() => save()} className="save-setting">Save Changes</IonButton>
      </div>
      <div className="modal-buttons-holder" hidden={onboardingWorkflow === false}>
        <IonButton onClick={() => onBack()} className="save-setting modal-back-button">Go Back</IonButton>
        <IonButton color='primary' onClick={() => save()} className="save-setting ion-float-right">
    
          {!posDeviceSettingsEnabled &&
            <IonLabel>Done with Settings</IonLabel>
          }
        </IonButton>
      </div>
    </SettingsCard>
  );
};

export default POSReceiptSettings;
import { IonIcon } from "@ionic/react";
import React from "react";
import IntegrationsProBadge from "./IntegrationsProBadge";

interface IntegrationIconProps {
    isConfigured: boolean;
    isPro: boolean;
}

const IntegrationIcon: React.FC<IntegrationIconProps> = (props) => {
    return (
        <div className="integration-card-icon">
            <div className="integration-card-icon-circle" hidden={!props.isConfigured}>
                <IonIcon className="integration-card-icon-check" src="assets/img/success.svg"></IonIcon>
            </div>
            <IntegrationsProBadge hidden={props.isConfigured || !props.isPro} />
        </div>
    );
};

export default IntegrationIcon;
import { IonContent, IonPage } from '@ionic/react';

import React, { useState } from 'react';

import '../theme/AllDevices.css';
import DevicesGrid from '../components/DevicesGrid';
import TenantLevelPageHeader from '../components/TenantLevelPageHeader';
import RicsToolbar from '../components/RicsToolbar';
import { locationDetailsTabs } from '../constants/LocationDetailsTabs';
import { useXhrService } from '../components/XhrServiceProvider';
import { usePortal } from '../components/PortalProvider';

const AllDevices: React.FC = () => {
  const portal = usePortal();
  portal!.State.tenantDevices = portal!.State.tenantDevices || [];
  const xhrService = useXhrService();
  const [devicesCount, setDevicesCount] = useState(0);

  const getTenantDevices = (setListState: Function) => {
    portal!.navigation.isLoading(true);
    var tenantId = portal!.State.tenantInfo.tenantId;

    // don't hit the api if we already have results cached
    var cachedTenantDevices = portal!.State.tenantDevices && portal!.State.tenantDevices[tenantId];
    if (cachedTenantDevices) {
      setListState(cachedTenantDevices);
      setDevicesCount(cachedTenantDevices.length);
      portal!.navigation.isLoading(false);
      return;
    }

    var endpoint = 'v1/Devices/';

    var onSuccess = function (response: string) {
      let devices = JSON.parse(response);
      if (!devices) {
        onFailure();
        return;
      }
      portal!.State.tenantDevices[tenantId] = devices;
      setListState(devices);
      setDevicesCount(devices.length);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to retrieve devices.");
      return;
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  return (
    <IonPage>
      <TenantLevelPageHeader
        headerText="All Devices"
        headerIconFileUrl="assets/img/tablet.svg"
        itemsCount={devicesCount}
      />
      <IonContent class="all-devices-content">
        <DevicesGrid
          cssClass="all-devices-grid"
          getDevices={getTenantDevices}
          setDevicesCount={setDevicesCount}
          selectedTab={locationDetailsTabs.filter(t => t.tabKey === "Devices")[0]}
          locationId={undefined}
        />
      </IonContent>
    </IonPage>
  );
};

export default AllDevices;
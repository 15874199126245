import React from 'react';

import { IonImg } from '@ionic/react';
import { Location } from '../../interfaces/Location';
import { usePortal } from '../PortalProvider';

interface LocationLogoProps {
    Location: Location;
}

const LocationLogo: React.FC<LocationLogoProps> = (props) => {
    let portal = usePortal();
    var getCorrectLogo = function (location: Location) {
        var imageUrl;

        var locationSettings = portal!.State.brandingSettings[location.locationId];
        var tenantSettings = portal!.State.brandingSettings['Tenant'];

        if (locationSettings && locationSettings.logos) {
            imageUrl = locationSettings.logos.smallLogoUrl;
        }

        if (!imageUrl && tenantSettings && tenantSettings.logos) {
            imageUrl = tenantSettings.logos.smallLogoUrl;
        }

        if (!imageUrl) {
            if (portal!.State.tenantInfo.images) {
                imageUrl = portal!.State.tenantInfo.images.image500x500;
            }
            imageUrl = location.imageUrl || imageUrl;
        }

        return imageUrl;
    };
    return (
        <div className="locationImageRow">
            <div className="locationImageHolder">
                <IonImg src={getCorrectLogo(props.Location)}></IonImg>
            </div>
        </div>
    );
};

export default LocationLogo;
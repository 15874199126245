export interface ECommerceAccountBase {
  accountId: string;
  accountStatus: ECommerceAccountStatus;
  adapterStartDate: Date;
  contactEmails: Array<string>;
  dateCreated: Date;
  dateUpdated: Date;
  inventoryThreshold?: number;
  label: string;
  orderSaleConfiguration: ECommerceOrderSaleConfiguration;
  pickOrderFeatureEnabled?: boolean;
  pricingStrategy?: ECommercePricingStrategy;
  retailPriceOffset?: number;
  ricsSourcingLocations?: Array<string>;
  saleLocations?: Array<string>;
  saveCustomersOnSale: boolean;
  storeName?: string;
  syncInventory?: boolean;
  syncOrders?: boolean;
  syncPricing: boolean;
  syncProductCache?: boolean;
  syncReturns?: boolean;
  tenantId: string;
  useFulfillmentStrategy: boolean;
  vendor: ECommerceVendor;
  workPartition: number;
}

export interface CreateECommerceAccountBase {
  adapterStartDate: Date;
  contactEmails?: Array<string>;
  contactEmail: string;
  inventoryThreshold?: number;
  label: string;
  orderSaleConfiguration: ECommerceOrderSaleConfiguration;
  pricingStrategy?: ECommercePricingStrategy;
  retailPriceOffset?: number;
  ricsSourcingLocations?: Array<string>;
  saleLocations?: Array<string>;
  saveCustomersOnSale: boolean;
  syncPricing: boolean;
  useFulfillmentStrategy: boolean;
}

export enum ECommerceVendor {
  Amazon = "Amazon",
  BigCommerce = "BigCommerce",
  Shopify = "Shopify",
  WooCommerce = "WooCommerce"
}

export enum ECommerceAccountStatus {
  Active = "Active",
  Inactive = "Inactive"
}

export enum ECommerceOrderSaleConfiguration {
  PaymentCaptured = "paymentcaptured",
  PaymentCapturedAndFulfilled = "paymentcapturedandfulfilled"
}

export enum ECommercePricingStrategy {
  Retail = "retail",
  Manual = "manual"
}

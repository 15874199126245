import { RicsSideMenuTab } from "../interfaces/RicsSideMenuTab";

export const locationDetailsTabs: RicsSideMenuTab[] = [
  { tabKey: "Details", tabButtonText: "Location Details", tabHeaderText: "Location Details", tabIconFileUri: "assets/img/info-alt.svg" },
  { tabKey: "Users", tabButtonText: "Users", tabHeaderText: "Users", tabIconFileUri: "assets/img/user-alt.svg" },
  { tabKey: "Taxes", tabButtonText: "Taxes", tabHeaderText: "Taxes", tabIconFileUri: "assets/img/pen.svg" },
  { tabKey: "Branding", tabButtonText: "Branding", tabHeaderText: "Brand Manager", tabIconFileUri: "assets/img/designer.svg" },
  { tabKey: "Devices", tabButtonText: "Devices", tabHeaderText: "Devices", tabIconFileUri: "assets/img/tablet.svg" },
  { tabKey: "DrawerHistory", tabButtonText: "Drawer History", tabHeaderText: "Drawer History", tabIconFileUri: "assets/img/register.svg" },
  { tabKey: "Settings", tabButtonText: "Settings", tabHeaderText: "Settings", tabIconFileUri: "assets/img/settings.svg" }
]
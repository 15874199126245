import React from "react";
import { Button } from "react-bootstrap";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupAuthorizeResponseModalProps extends ECommerceAccountSetupModalPropsBase {
  onContinue: Function;
  success: boolean;
}

const ECommerceAccountSetupAuthorizeResponseModal: React.FC<ECommerceAccountSetupAuthorizeResponseModalProps> = (props) => {
  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: props.success ? "Authorization Successful!" : "Authorization Failed",
    hideFooter: true
  };

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body-continue-button-holder")}>
          <Button onClick={() => props.onContinue()} className="button">
            {props.success ? "Continue" : "Retry"}
          </Button>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupAuthorizeResponseModal;

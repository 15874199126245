import React from 'react';

import { Location } from '../../interfaces/Location';

import '../../theme/AllUsers.css';
import '../../theme/UsersGrid.css';
import '../../theme/locationDetails/taxes/LocationDetailsDetails.css';
import { RicsSideMenuTab } from '../../interfaces/RicsSideMenuTab';
import { IonInput } from '@ionic/react';
import RicsSideMenuTabHeader from '../RicsSideMenuTabHeader';
export interface LocationDetailsDetailsProps {
    selectedTab: RicsSideMenuTab;
    currentLocation: Location;
}

const LocationDetailsDetails: React.FC<LocationDetailsDetailsProps> = (props) => {
    let address = props.currentLocation.contact?.address;
    return (
        <>
            <RicsSideMenuTabHeader selectedTab={props.selectedTab} />
            <div id="location-details-details">
                <div className="tabbedSection">
                    <div className="tabContent">
                        <div className="tabInnerContent" id="Details">
                            <div className="contentSection">
                                <div className="headingHolder">
                                    <div className="heading">Store Location Information</div>
                                    <div className="headingNote">Manage in RICS9 Back Office</div>
                                </div>
                                <div className="row">
                                    <div className="leftContent textContent">Address</div>
                                    <div className="rightContent textContent">
                                        <div className="editFieldHolder hundred">
                                            <div className="heading">Location Name</div>
                                            <IonInput disabled={true} value={props.currentLocation.name} />
                                        </div>
                                        <div className="editFieldHolder fifty">
                                            <div className="heading">Address Line 1</div>
                                            <IonInput disabled={true} value={address?.line1} />
                                        </div>
                                        <div className="editFieldHolder fifty">
                                            <div className="heading">Building or Suite Number (optional)</div>
                                            <IonInput disabled={true} value={address?.line2} />
                                        </div>
                                        <div className="editFieldHolder fifty">
                                            <div className="heading">City</div>
                                            <IonInput disabled={true} value={address?.city} />
                                        </div>
                                        <div className="editFieldHolder twentyfive">
                                            <div className="heading">State</div>
                                            <IonInput disabled={true} value={address?.state} />
                                        </div>
                                        <div className="editFieldHolder twentyfive">
                                            <div className="heading">Zip</div>
                                            <IonInput disabled={true} value={address?.zipCode} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LocationDetailsDetails;
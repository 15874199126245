import { IonToolbar, IonHeader, IonIcon, IonTitle, IonText } from '@ionic/react';
import React from 'react';

import '../theme/TenantLevelPageHeader.css';

interface TenantLevelPageHeaderProps {
  headerText: string;
  headerIconFileUrl: string;
  itemsCount?: number;
  children?: React.ReactNode;
}

const TenantLevelPageHeader: React.FC<TenantLevelPageHeaderProps> = (props) => {
  return (
    <IonHeader className="tenant-header">
      <IonToolbar className="tenant-header-toolbar">
        <div className="header-circle" slot="start">
          <IonIcon size="large" src={props.headerIconFileUrl} className="tenant-header-icon"></IonIcon>
        </div>
        <IonTitle className="tenant-header-title" slot="start">
          <IonText>{props.headerText}</IonText>
          <IonText hidden={!props.itemsCount} class="items-count">({props.itemsCount})</IonText>
        </IonTitle>
        {props.children}
      </IonToolbar>
    </IonHeader>
  );
};

export default TenantLevelPageHeader;

import { IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonToolbar } from "@ionic/react";
import { arrowBack, arrowForward } from "ionicons/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { FeatureFlags } from "../constants/FeatureFlags";
import { RicsSideMenuTab } from "../interfaces/RicsSideMenuTab";

import "../theme/RicsSideMenu.css";

const selectOptions = {
  cssClass: "rics-menu-select"
};

interface SideMenuProps {
  selectedTab: RicsSideMenuTab;
  tabs: Array<RicsSideMenuTab>;
  contentId: string;
  navigationEnabled: boolean;
  setSelectedTab: Function;
  getCustomHeader?: Function;
}

const RicsSideMenu: React.FC<SideMenuProps> = ({ selectedTab, tabs, contentId, navigationEnabled, setSelectedTab, getCustomHeader }) => {
  const flags = useFlags();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [cashCountFeatureEnabled, setCashCountFeatureEnabled] = React.useState(false);

  useEffect(() => {
    var menu = document.getElementById("rics-menu");
    const properWidth = menuCollapsed ? "56px" : "264px";
    menu?.style.setProperty("--side-width", properWidth);
  }, [menuCollapsed]);

  useEffect(() => {
    getCashCountFeatureFlag();
  }, [flags]);

  function collapseMenu(collapsed: boolean) {
    var menu = document.getElementById("rics-menu");
    menu?.style.setProperty("transition", "width .25s");

    setMenuCollapsed(collapsed);

    const properWidth = collapsed ? "56px" : "264px";
    menu?.style.setProperty("--side-width", properWidth);
  }

  const getCashCountFeatureFlag = () => {
    var cashCountFeatureFlag = flags[FeatureFlags.CashCount];
    setCashCountFeatureEnabled(cashCountFeatureFlag);
  };

  return (
    <IonMenu id="rics-menu" contentId={contentId} class="rics-menu">
      {getCustomHeader ? getCustomHeader(menuCollapsed) : <IonHeader />}
      <IonContent class="rics-menu">
        <IonList>
          {tabs.map(
            (tab) =>
              (tab.tabKey !== "DrawerHistory" || cashCountFeatureEnabled) && (
                <IonItem
                  key={tab.tabKey}
                  disabled={!navigationEnabled}
                  detail={selectedTab.tabKey === tab.tabKey && !menuCollapsed}
                  className={"rics-menu-item" + (selectedTab.tabKey === tab.tabKey ? " selected" : "")}
                  lines="none"
                  button
                  onClick={() => setSelectedTab(tab.tabKey)}>
                  <IonIcon src={tab.tabIconFileUri} className="rics-menu-img" />
                  {!menuCollapsed && <IonLabel class="rics-menu-label">{tab.tabButtonText}</IonLabel>}
                </IonItem>
              )
          )}
        </IonList>
      </IonContent>
      <IonFooter className="rics-menu-footer">
        <IonToolbar class="rics-menu-title">
          <IonItem className="rics-menu-item" lines="none" button onClick={() => collapseMenu(!menuCollapsed)}>
            <IonIcon color="white" icon={menuCollapsed ? arrowForward : arrowBack} className="rics-menu-img" />
            {!menuCollapsed && <IonLabel class="rics-menu-label">Collapse Menu</IonLabel>}
          </IonItem>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default RicsSideMenu;

import React from "react";
import { Button, Modal } from "react-bootstrap";

import "../../../../theme/integrations/ecommerce/amazon/AmazonAuthorizationModal.css";

interface AmazonAuthorizationModalProps {
    show: boolean;
    onHide: Function;
    onAuthorize: Function;
};

const AmazonAuthorizationModal: React.FC<AmazonAuthorizationModalProps> = (props) => {
    const baseClassName = "amazon-account-auth-modal";

    return (
        <>
            <Modal
                className={baseClassName}
                show={props.show}
                onHide={() => props.onHide()}
                size="lg"
                centered
                dialogClassName={`${baseClassName}-dialog`}>
                <Modal.Header
                    bsPrefix={`${baseClassName}-header`}>
                    <Modal.Title
                        bsPrefix={`${baseClassName}-title`}>
                        Authorize Amazon Account
                    </Modal.Title>
                    <div>Authorization is required to allow RICS to make API calls on your behalf.</div>
                </Modal.Header>
                <Modal.Body
                    bsPrefix={`${baseClassName}-body`}>
                        <Button className="authorize-button" onClick={() => props.onAuthorize()}>Authorize</Button>
                    </Modal.Body>
            </Modal>
        </>
    );
};

export default AmazonAuthorizationModal;
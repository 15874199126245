import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonText } from "@ionic/react";
import { addCircle } from "ionicons/icons";
import React from "react";
import { Tax } from "../../../../interfaces/Taxes";
import { usePortal } from "../../../PortalProvider";
import SettingsCard from "../../../SettingsCard";
import AdvancedTaxConfiguration from "./AdvancedTaxConfiguration";

import "../../../../theme/locationDetails/taxes/jurisdictions/TaxJurisdictionsCard.css";
import TaxJurisdictionRow from "./TaxJurisdictionRow";

interface TaxJurisdictionsCardProps {
  locationId: string;
  taxes: Tax[];
  setTaxes: Function;
  saveTaxes: Function;
}

const TaxJurisdictionsCard: React.FC<TaxJurisdictionsCardProps> = (props) => {
  const portal = usePortal();
  const [nextTempId, setNextTempId] = React.useState(0);
  const [showAdvanceTaxConfiguration, setShowAdvanceTaxConfiguration] = React.useState(false);
  const [canSave, setCanSave] = React.useState(true);

  const addTax = () => {
    let id = `temp-${nextTempId}`;
    setNextTempId(nextTempId + 1);
    let newTax: Tax = {
      tenantId: portal!.State.tenantInfo.tenantId,
      taxId: id,
      locationIds: [props.locationId],
      description: "",
      taxRate: undefined,
      taxRateFormValue: undefined,
      onlyTaxAboveThreshold: false
    };
    let currentTaxes = [...props.taxes];
    currentTaxes.push(newTax);
    props.setTaxes(currentTaxes);
    checkCanSave(currentTaxes);
  };

  const removeTax = (taxId: string) => {
    let currentTaxes = [...props.taxes];

    if (taxId.includes("temp-")) {
      currentTaxes.splice(
        currentTaxes.findIndex((x) => x.taxId === taxId),
        1
      );
    } else {
      let taxToRemove = currentTaxes.find((x) => x.taxId === taxId);
      taxToRemove!.locationIds = undefined;
    }

    props.setTaxes(currentTaxes);
    checkCanSave(currentTaxes);
  };

  const updateTaxDescription = (value: string, taxId: string) => {
    let currentTaxes = [...props.taxes];
    let taxToUpdate = currentTaxes.find((x) => x.taxId === taxId);
    taxToUpdate!.description = value;
    props.setTaxes(currentTaxes);
    checkCanSave(currentTaxes);
  };

  const updateTaxRate = (value: string, taxId: string) => {
    let currentTaxes = [...props.taxes];
    let taxToUpdate = currentTaxes.find((x) => x.taxId === taxId);
    let parsedValue = Number(value);

    //Ensure it's a number and don't allow empty strings which are parsed as 0
    if (!isNaN(parsedValue)) {
      if (value.trim() == "") {
        taxToUpdate!.taxRate = undefined;
        taxToUpdate!.taxRateFormValue = undefined;
        props.setTaxes(currentTaxes);
      } else {
        taxToUpdate!.taxRate = parsedValue / 100;
        taxToUpdate!.taxRateFormValue = value;
        props.setTaxes(currentTaxes);
      }
    }
    checkCanSave(currentTaxes);
  };

  const checkCanSave = (taxes: Tax[]) => {
    function taxRateHasTooManyDecimals(x: Tax) {
      if (!x.taxRateFormValue) {
        return;
      }

      let maxLengthRate = x.taxRateFormValue?.match(/^-?\d+(?:\.\d{0,4})?/);
      let maxRateLength = 0;
      if (!!maxLengthRate?.length) {
        maxRateLength = maxLengthRate[0].length;
      }

      let taxRateTooLong = x.taxRateFormValue != null && maxRateLength > 0 && x.taxRateFormValue?.length > maxRateLength;
      return taxRateTooLong;
    }
    let invalid = taxes.some(
      (x) =>
        !x.description || x.taxRate == null || x.taxRate == undefined || isNaN(x.taxRate) || x.description.length < 4 || taxRateHasTooManyDecimals(x)
    );
    setCanSave(!invalid);
  };

  const closeModal = () => {
    setShowAdvanceTaxConfiguration(false);
  };

  return (
    <>
      <SettingsCard
        header="Configure your store's tax details"
        subHeading="Add tax jurisdictions based on your location. Locations generally have at least one tax jurisdiction, but may have several (state, county, district, etc):">
        <IonGrid className="taxes-grid">
          <IonRow>
            <IonCol size="8" className="tax-name-column tax-jurisdiction-header">
              Tax Jurisdiction
            </IonCol>
            <IonCol size="2" className="tax-rate-column tax-jurisdiction-header">
              Tax Rate
            </IonCol>
          </IonRow>
          {props.taxes
            .filter((x) => x.locationIds?.includes(props.locationId))
            .map((tax) => {
              return (
                <TaxJurisdictionRow
                  key={tax.taxId}
                  tax={tax}
                  removeTax={removeTax}
                  updateTaxDescription={updateTaxDescription}
                  updateTaxRate={updateTaxRate}
                />
              );
            })}
        </IonGrid>
        <IonItem className="action-items create-tax-jurisdiction" onClick={() => addTax()} lines="none">
          <IonIcon color="primary" src={addCircle} className="add-icon" />
          <IonText color="primary">Create a Tax Jurisdiction...</IonText>
        </IonItem>
        <IonItem className="action-items advanced-tax-configuration" onClick={() => setShowAdvanceTaxConfiguration(true)} lines="none">
          <IonIcon color="primary" src="img/filters.svg" className="advanced-taxes-icon" />
          <IonText color="primary">Click for Advanced Tax Configuration...</IonText>
        </IonItem>
        <div>
          <IonButton disabled={!canSave} onClick={() => props.saveTaxes()}>
            Save Changes
          </IonButton>
        </div>
        <div className="taxes-validation-message">
          <IonLabel color="danger" hidden={canSave}>
            Each tax jurisdiction must be named at least 4 characters and include a tax rate up to 4 decimal places.
          </IonLabel>
        </div>
      </SettingsCard>
      <IonModal isOpen={showAdvanceTaxConfiguration} onDidDismiss={() => closeModal()} className="taxes-modal">
        <AdvancedTaxConfiguration taxes={props.taxes} closeModal={closeModal} saveTaxes={props.saveTaxes} />
      </IonModal>
    </>
  );
};

export default TaxJurisdictionsCard;

import React, { useEffect, useState } from "react";
import { AmazonConstants, ECommerceWizardSteps } from "../../../../../constants/ECommerceConstants";
import { Paths } from "../../../../../constants/Paths";
import { CreateAmazonAccount } from "../../../../../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import { CreateECommerceAccountBase, ECommerceVendor } from "../../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { useAmazonService } from "../../../../../services/providers/AmazonServiceProvider";
import { usePortal } from "../../../../PortalProvider";
import ECommerceAccountSetupAuthorizeModal from "../../accountSetup/ECommerceAccountSetupAuthorizeModal";
import ECommerceAccountSetupAuthorizeResponseModal from "../../accountSetup/ECommerceAccountSetupAuthorizeResponseModal";
import ECommerceAccountSetupEmailVerificationModal from "../../accountSetup/ECommerceAccountSetupEmailVerificationModal";
import ECommerceAccountSetupFinishedModal from "../../accountSetup/ECommerceAccountSetupFinishedModal";
import ECommerceAccountSetupGeneralInfoModal from "../../accountSetup/ECommerceAccountSetupGeneralInfoModal";
import ECommerceAccountSetupInfoModal from "../../accountSetup/ECommerceAccountSetupInfoModal";
import ECommerceAccountSetupInventoryModal from "../../accountSetup/ECommerceAccountSetupInventoryModal";
import ECommerceAccountSetupLocationsModal from "../../accountSetup/ECommerceAccountSetupLocationsModal";
import ECommerceAccountSetupPricingModal from "../../accountSetup/ECommerceAccountSetupPricingModal";
import ECommerceAccountSetupUseFulfillmentStrategyModal from "../../accountSetup/ECommerceAccountSetupUseFulfillmentStrategyModal";
import AmazonAccountTypeModal from "./AmazonAccountTypeModal";
import AmazonFbaLocationModal from "./AmazonFbaLocationModal";

interface AmazonAccountSetupModalProps {
  wizardStep: number;
  setWizardStep: Function;
  onAuthorize: Function;
}

const AmazonAccountSetupModal: React.FC<AmazonAccountSetupModalProps> = (props) => {
  const portal = usePortal();
  const amazonService = useAmazonService();

  const [account, setAccount] = useState<CreateAmazonAccount | undefined>(JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!));

  useEffect(() => {
    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    setAccount(tempAccount);
  }, [props.wizardStep]);

  const show = (stepNum: number) => props.wizardStep === stepNum;

  const updateAccount = (account: CreateECommerceAccountBase) => {
    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);

    tempAccount = {
      ...account,
      lwaAccessToken: tempAccount.lwaAccessToken,
      lwaRefreshToken: tempAccount.lwaRefreshToken,
      lwaAccessTokenExpiration: tempAccount.lwaAccessTokenExpiration,
      mwsAuthorizationToken: tempAccount.mwsAuthorizationToken,
      sellerId: tempAccount.sellerId,
      isFba: tempAccount.isFba,
      fbaLocationIds: tempAccount.fbaLocationIds
    };

    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(tempAccount));
    setAccount(tempAccount);
  };

  const createAccount = async () => {
    let accountToCreate: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    accountToCreate.contactEmails = new Array<string>(accountToCreate.contactEmail);

    let createdAccount = await amazonService!.createAccount(accountToCreate);
    if (createdAccount === undefined) {
      alert("Unable to create Amazon account");
      return false;
    }

    return true;
  };

  return (
    <>
      {/* Setup Info Modal */}
      <ECommerceAccountSetupInfoModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.info)}
        logoUrl="assets/img/amazon-logo.png"
        onNext={() => props.setWizardStep(ECommerceWizardSteps.auth)}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
        hideCustomersSection={true}
      />
      {/* Authorization Modal */}
      <ECommerceAccountSetupAuthorizeModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.auth)}
        logoUrl="assets/img/amazon-logo.png"
        onAuthorize={props.onAuthorize}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
      />
      {/* Authorization Success Modal */}
      <ECommerceAccountSetupAuthorizeResponseModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.authSuccess)}
        onContinue={() => props.setWizardStep(ECommerceWizardSteps.generalInfo)}
        success={true}
        logoUrl="assets/img/amazon-logo.png"
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
      />
      {/* Authorization Failure Modal */}
      <ECommerceAccountSetupAuthorizeResponseModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.authFailure)}
        onContinue={props.onAuthorize}
        success={false}
        logoUrl="assets/img/amazon-logo.png"
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
      />
      {/* General Info Modal */}
      <ECommerceAccountSetupGeneralInfoModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.generalInfo)}
        logoUrl="assets/img/amazon-logo.png"
        onNext={() => props.setWizardStep(ECommerceWizardSteps.emailVerification)}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
        updateAccount={updateAccount}
      />
      {/* Email Verification */}
      {account && (
        <ECommerceAccountSetupEmailVerificationModal
          vendor={ECommerceVendor.Amazon}
          show={show(ECommerceWizardSteps.emailVerification)}
          logoUrl="assets/img/amazon-logo.png"
          onNext={() => props.setWizardStep(ECommerceWizardSteps.amzAccountType)}
          onGoBack={() => props.setWizardStep(ECommerceWizardSteps.generalInfo)}
          onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
          email={account.contactEmail}
        />
      )}
      {/* FBA vs MFN Modal */}
      <AmazonAccountTypeModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.amzAccountType)}
        logoUrl="assets/img/amazon-logo.png"
        onNext={() => props.setWizardStep(ECommerceWizardSteps.fulfillment)}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
      />
      {/* FBA Locations Modal */}
      {account && account.isFba && (
        <AmazonFbaLocationModal
          vendor={ECommerceVendor.Amazon}
          show={show(ECommerceWizardSteps.fulfillment)}
          logoUrl="assets/img/amazon-logo.png"
          onNext={() => props.setWizardStep(ECommerceWizardSteps.finish)}
          onGoBack={() => props.setWizardStep(ECommerceWizardSteps.amzAccountType)}
          onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
        />
      )}
      {/* Use Fulfillment Strategy Modal */}
      {account && !account.isFba && (
        <ECommerceAccountSetupUseFulfillmentStrategyModal
          vendor={ECommerceVendor.Amazon}
          show={show(ECommerceWizardSteps.fulfillment)}
          logoUrl="assets/img/amazon-logo.png"
          onNext={() => props.setWizardStep(ECommerceWizardSteps.locations)}
          onGoBack={() => props.setWizardStep(ECommerceWizardSteps.amzAccountType)}
          onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
          updateAccount={updateAccount}
        />
      )}
      {/* Locations Modal */}
      {account && (
        <ECommerceAccountSetupLocationsModal
          vendor={ECommerceVendor.Amazon}
          useFulfillmentStrategy={account.useFulfillmentStrategy}
          show={show(ECommerceWizardSteps.locations)}
          logoUrl="assets/img/amazon-logo.png"
          onNext={() => props.setWizardStep(ECommerceWizardSteps.inventory)}
          onGoBack={() => props.setWizardStep(ECommerceWizardSteps.fulfillment)}
          onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
          updateAccount={updateAccount}
        />
      )}
      {/* Inventory Modal */}
      <ECommerceAccountSetupInventoryModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.inventory)}
        logoUrl="assets/img/amazon-logo.png"
        onNext={() => props.setWizardStep(ECommerceWizardSteps.pricing)}
        onGoBack={() => props.setWizardStep(ECommerceWizardSteps.locations)}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
        updateAccount={updateAccount}
      />
      {/* Pricing Modal */}
      <ECommerceAccountSetupPricingModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.pricing)}
        logoUrl="assets/img/amazon-logo.png"
        onNext={() => props.setWizardStep(ECommerceWizardSteps.finish)}
        onGoBack={() => props.setWizardStep(ECommerceWizardSteps.inventory)}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
        updateAccount={updateAccount}
      />
      {/* Finish Modal */}
      <ECommerceAccountSetupFinishedModal
        vendor={ECommerceVendor.Amazon}
        show={show(ECommerceWizardSteps.finish)}
        logoUrl="assets/img/amazon-logo.png"
        createAccount={createAccount}
        redirectPath={Paths.Amazon}
        onClose={() => props.setWizardStep(ECommerceWizardSteps.close)}
      />
    </>
  );
};

export default AmazonAccountSetupModal;

export const ECommerceOrderSaleConfigurationConstants = {
  PaymentCaptured: "When Payment is Received",
  PaymentCapturedAndFulfilled: "When Order is Paid and Fulfilled"
} as const;

export const ECommercePricingStrategyConstants = {
  None: "Choose...",
  Retail: "Retail Price with Offset",
  Manual: "Manual Pricing"
} as const;

export const AmazonConstants = {
  oAuthUrl: "https://sellercentral.amazon.com/apps/authorize/consent",
  tempAccountKey: "amz-new-account"
} as const;

export const ECommerceConstants = {
  emailCodeKey: "ecommerce-email-code",
  tempAccountKey: "ecommerce-new-account"
} as const;

export const ECommerceWizardSteps = {
  close: -1,
  info: 0,
  auth: 1,
  authSuccess: 2,
  authFailure: 3,
  generalInfo: 4,
  emailVerification: 5,
  amzAccountType: 6,
  fulfillment: 7,
  locations: 8,
  inventory: 9,
  pricing: 10,
  customers: 11,
  finish: 12
} as const;

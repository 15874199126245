import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { ECommerceIntegrations, IntegrationsConstants } from "../../../constants/IntegrationsConstants";
import { ECommerceVendor } from "../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { Integration } from "../../../interfaces/integrations/Integration";
import { useAmazonService } from "../../../services/providers/AmazonServiceProvider";
import { usePortal } from "../../PortalProvider";
import IntegrationsAccordionItem from "../IntegrationsAccordionItem";

interface ECommerceIntegrationsAccordionItemProps {
    isAllIntegrations: boolean;
}

const ECommerceIntegrationsAccordionItem: React.FC<ECommerceIntegrationsAccordionItemProps> = (props) => {
    const portal = usePortal();
    const flags = useFlags();
    const amazonService = useAmazonService();

    const [integrationsToInclude, setIntegrationsToInclude] = useState(new Array<Integration>());

    useEffect(() => {
        getAmazonAccounts();
    }, []);

    const getAmazonAccounts = async () => {
        portal?.navigation.isLoading(true);

        let accounts = await amazonService!.getAccounts(false);

        let amzIntegration = ECommerceIntegrations.find(i => i.Vendor === ECommerceVendor.Amazon);

        if (accounts?.length > 0 && !props.isAllIntegrations && flags[amzIntegration?.IsEnabledFF || ""]) {
            AddIntegration(amzIntegration);
        }
        else if (accounts?.length <= 0 && !props.isAllIntegrations) {
            RemoveIntegration(amzIntegration);
        }
        else if (accounts?.length <= 0 && props.isAllIntegrations) {
            AddIntegration(amzIntegration);
        }

        portal?.navigation.isLoading(false);
    };

    const AddIntegration = (integration?: Integration) => {
        let index = integrationsToInclude.findIndex(i => i.Name === integration?.Name);
        if (index > -1) return;
        else if (integration) setIntegrationsToInclude([...integrationsToInclude, {...integration, IsConfigured: !props.isAllIntegrations}]);
    };

    const RemoveIntegration = (integration?: Integration) => {
        let index = integrationsToInclude.findIndex(i => i.Name === integration?.Name);
        if (index > -1) setIntegrationsToInclude([...integrationsToInclude.splice(index, 1)]);
        else return;
    };

    return (
        <>
            {
                integrationsToInclude?.length > 0 ?
                <IntegrationsAccordionItem header={IntegrationsConstants.eCommerceHeader} integrations={integrationsToInclude} /> :
                <></>
            }
        </>
    )
};

export default ECommerceIntegrationsAccordionItem;
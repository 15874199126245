import { IonIcon, IonInput, IonToggle } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ProductTaxCondition } from "../../../../interfaces/Taxes";

interface AdvancedTaxConfigurationRowProps {
  availableProductTypes: string[];
  productTaxCondition: ProductTaxCondition;
  setAvailableProductTypes: Dispatch<SetStateAction<string[]>>;
}

const AdvancedTaxConfigurationRow: React.FC<
  AdvancedTaxConfigurationRowProps
> = (props) => {
  const initialAvailableTypes = () => {
    let types = [...props.availableProductTypes];
    const productType = props.productTaxCondition?.productType;

    if (productType && !types.includes(productType)) {
      types.push(productType);
    }

    return types.sort();
  };

  const [availableTypes, setAvailableTypes] = React.useState<string[]>(
    initialAvailableTypes
  );
  const [productTaxCondition, setProductTaxCondition] = React.useState(
    props.productTaxCondition
  );
  const [selectedProductType, setSelectedProductType] = React.useState<
    string | undefined
  >(props.productTaxCondition?.productType);
  const [taxRate, setTaxRate] = React.useState<number | undefined>(
    !!props.productTaxCondition?.taxRate
      ? props.productTaxCondition?.taxRate * 100
      : undefined
  );
  const [threshold, setThreshold] = React.useState<number | undefined>(
    props.productTaxCondition?.threshold
  );
  const [taxAboveThreshold, setTaxAboveThreshold] = React.useState<boolean>(
    props.productTaxCondition?.onlyTaxAboveThreshold ?? false
  );

  useEffect(() => {
    let updatedTypes = [...props.availableProductTypes];
    const productType = props.productTaxCondition?.productType;

    if (productType && !updatedTypes.includes(productType)) {
      updatedTypes.push(productType);
    }

    updatedTypes.sort();

    setAvailableTypes(updatedTypes);
  }, [props.availableProductTypes, props.productTaxCondition?.productType]);

  const onProductTypeChange = (value: string | undefined) => {
    const previousValue = selectedProductType;
    setSelectedProductType(value);

    let productTax = productTaxCondition;
    if (!productTax) {
      productTax = {
        productType: undefined,
        taxRate: undefined,
        threshold: undefined,
        onlyTaxAboveThreshold: false,
      };
    }

    productTax.productType = value;
    setProductTaxCondition(productTax);

    let updatedAvailableTypes = [...props.availableProductTypes];

    if (previousValue && !updatedAvailableTypes.includes(previousValue)) {
      updatedAvailableTypes.push(previousValue);
    }

    if (value) {
      updatedAvailableTypes = updatedAvailableTypes.filter(
        (type) => type !== value
      );
    }

    updatedAvailableTypes.sort();
    props.setAvailableProductTypes(updatedAvailableTypes);
  };

  const onTaxRateChange = (value: string | null | undefined) => {
    let productTax = productTaxCondition;

    if (!productTax) {
      productTax = {
        productType: undefined,
        taxRate: undefined,
        threshold: undefined,
        onlyTaxAboveThreshold: false,
      };
    }

    if (!!value) {
      let taxRate = +value;
      setTaxRate(taxRate);
      taxRate = taxRate / 100;
      productTax!.taxRate = taxRate;
    } else {
      setTaxRate(undefined);
      productTax!.taxRate = undefined;
    }
    setProductTaxCondition(productTax);
  };

  const onThresholdChange = (value: string | null | undefined) => {
    let productTax = productTaxCondition;

    if (!productTax) {
      productTax = {
        productType: undefined,
        taxRate: undefined,
        threshold: undefined,
        onlyTaxAboveThreshold: false,
      };
    }

    if (!!value) {
      let threshold = +value;
      setThreshold(threshold);
      productTax.threshold = threshold;
    } else {
      productTax.threshold = undefined;
      setThreshold(undefined);
    }

    setProductTaxCondition(productTax);
  };

  const onTaxAboveThresholdChange = () => {
    let productTax = productTaxCondition;

    if (!productTax) {
      productTax = {
        productType: undefined,
        taxRate: undefined,
        threshold: undefined,
        onlyTaxAboveThreshold: false,
      };
    }
    productTax.onlyTaxAboveThreshold = !taxAboveThreshold;
    setTaxAboveThreshold(!taxAboveThreshold);
    setProductTaxCondition(productTax);
  };

  const clearRow = () => {
    setSelectedProductType(undefined);
    setTaxRate(undefined);
    setThreshold(undefined);
    setTaxAboveThreshold(false);
    let productTax = productTaxCondition;
    productTax.productType = undefined;
    productTax.taxRate = undefined;
    productTax.threshold = undefined;
    productTax.onlyTaxAboveThreshold = false;

    setProductTaxCondition(productTax);
  };

  return (
    <tr className="advanced-tax-row">
      <td className="advanced-product-type-column advanced-tax-row-data">
        <select
          value={selectedProductType}
          onChange={(e) => onProductTypeChange(e.target.value)}
          placeholder="Please Select a Product Type"
          className={!!selectedProductType ? "" : "unselected"}
        >
          <option value="">Please Select a Product Type</option>
          {availableTypes.map((type) => {
            return (
              <option key={type} value={type}>
                {type}
              </option>
            );
          })}
        </select>
      </td>
      <td className="advanced-tax-rate-column advanced-tax-row-data">
        <IonInput
          type="number"
          onIonChange={(e) => onTaxRateChange(e.detail.value)}
          value={taxRate}
          placeholder="0.0"
          className="tax-input-percent"
        ></IonInput>
      </td>
      <td className="advanced-threshold-column advanced-tax-row-data">
        <IonInput
          type="number"
          onIonChange={(e) => onThresholdChange(e.detail.value)}
          value={threshold}
          placeholder="0.00"
          className="tax-input-dollar"
        ></IonInput>
      </td>
      <td className="advanced-tax-above-column advanced-tax-row-data">
        <IonToggle
          onClick={() => onTaxAboveThresholdChange()}
          checked={taxAboveThreshold}
        ></IonToggle>
      </td>
      <td className="advanced-delete-column">
        <IonIcon src={closeCircle} onClick={() => clearRow()}></IonIcon>
      </td>
    </tr>
  );
};

export default AdvancedTaxConfigurationRow;

import { IonContent, IonPage, IonImg } from '@ionic/react';

import React, { useEffect, useState } from 'react';

import '../theme/DeviceActivation.css';

interface DeviceActivationProps{
  activated: boolean;
}

const DeviceActivation: React.FC<DeviceActivationProps> = ({activated}) => {
  let [brandingLogoUrl, setBrandingLogoUrl] = useState("assets/img/customer_brand_placeholder.png");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    let brandingLogoUrlParam = urlParams.get('brandingLogoUrl');
    var urlPattern = /^((http|https):\/\/)/;
    if(brandingLogoUrlParam && urlPattern.test(brandingLogoUrlParam)){
      setBrandingLogoUrl(brandingLogoUrlParam);
    }
  }, []);
  return (
    <IonPage>
      <IonContent>
        <div className="activation-page">
          <div className="activation-content">
            <div className="activation-logo-transparent-border activation-circle-border">
              <div className="activation-logo-white-border activation-circle-border">
                <IonImg className="activation-logo-image" src={brandingLogoUrl}></IonImg>
              </div>
            </div>
            <p className="activation-header activation-centered-text">
              {activated && "Device Activated!"}
              {!activated && "Request Declined"}
            </p>
            <p className="activation-text activation-centered-text">
              {activated && "The requested device has been approved for use as a valid POS. The original requester has been notified."}
              {!activated && "This device authentication request has been declined and the original requester has been notified."}
            </p>
            <p className="activation-subtext activation-centered-text">
              No further action is required. You may close this browser tab.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DeviceActivation;
import React, { useState } from "react";

import "../../theme/ListAndCardsToggle.css";
import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { menuOutline, searchOutline, close } from "ionicons/icons";

interface ListAndCardsToggleProps {
  isCardMode: boolean;
  setIsCardMode: Function;
  showDisabledUsers: boolean;
  onChangeSearchText: Function;
  toggleShowDisabledUsers: Function;
  isDisabledUserEnabled: boolean;
  showSearchInput: boolean;
  setShowSearchInput: Function;
  searchText: string;
  setSearchText: Function;
  searchPlaceholder: string;
}

const ListAndCardsToggle: React.FC<ListAndCardsToggleProps> = (props) => {
  const selectedIconStyle = "toggle-view-selected square-icon";
  const unselectedIconStyle = "square-icon";
  const selectedButtonStyle = "toggle-view-selected-button square-button";
  const unselectedButtonStyle = "toggle-view-unselected-button square-button";
  const baseListButtonStyle = "toggle-list-button ";
  const baseCardButtonStyle = "toggle-cards-button ";

  const searchInputRef = React.useRef<HTMLIonInputElement>(null);

  const [listIconStyle, setListIconStyle] = useState(
    props.isCardMode ? unselectedIconStyle : selectedIconStyle
  );
  const [cardIconStyle, setCardIconStyle] = useState(
    props.isCardMode ? selectedIconStyle : unselectedIconStyle
  );
  const [listButtonStyle, setListButtonStyle] = useState(
    baseListButtonStyle +
    (props.isCardMode ? unselectedButtonStyle : selectedButtonStyle)
  );
  const [cardButtonStyle, setCardButtonStyle] = useState(
    baseCardButtonStyle +
    (props.isCardMode ? selectedButtonStyle : unselectedButtonStyle)
  );

  const toggleSearchInput = () => {
    if (props.showSearchInput) {
      props.setSearchText("");
      searchUsers("");
    } else {
      props.setSearchText("");
      focusSearchInput();
    }

    props.setShowSearchInput(!props.showSearchInput);
  };

  const focusSearchInput = () => {
    searchInputRef.current?.setFocus();
  };

  const searchUsers = (searchText: string) => {
    props.setSearchText(searchText);
    props.onChangeSearchText(searchText);
  };

  const setButtonStyle = (isCardSelected: boolean) => {
    setListIconStyle(isCardSelected ? unselectedIconStyle : selectedIconStyle);
    setCardIconStyle(isCardSelected ? selectedIconStyle : unselectedIconStyle);
    setListButtonStyle(
      baseListButtonStyle +
      (isCardSelected ? unselectedButtonStyle : selectedButtonStyle)
    );
    setCardButtonStyle(
      baseCardButtonStyle +
      (isCardSelected ? selectedButtonStyle : unselectedButtonStyle)
    );

    props.setIsCardMode(isCardSelected);
  };

  return (
    <>
      <IonInput
        slot="end"
        hidden={!props.showSearchInput}
        ref={searchInputRef}
        value={props.searchText}
        className="user-search-input"
        placeholder={props.searchPlaceholder}
        type="search"
        size={80}
        onIonInput={(e) => searchUsers(e.detail.value as string)}
      />
      <IonButton
        fill="clear"
        slot="end"
        className="search-list-button square-button"
        onClick={() => toggleSearchInput()}
      >
        <IonIcon
          icon={props.showSearchInput ? close : searchOutline}
          slot="icon-only"
          className="search-list-icon square-icon"
        />
      </IonButton>
      <IonButton
        fill="clear"
        slot="end"
        className={listButtonStyle}
        onClick={() => setButtonStyle(false)}
      >
        <IonIcon
          icon={menuOutline}
          slot="icon-only"
          className={listIconStyle}
        />
      </IonButton>
      <IonButton
        fill="clear"
        slot="end"
        className={cardButtonStyle}
        onClick={() => setButtonStyle(true)}
      >
        <IonIcon
          src="img/Grid.svg"
          slot="icon-only"
          className={cardIconStyle}
        />
      </IonButton>
    </>
  );
};

export default ListAndCardsToggle;

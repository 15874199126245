import { IonButton, IonContent, IonIcon, IonItem, IonPopover, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { closeOutline } from "ionicons/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { roleNames, roles } from "../../constants/Roles";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import { Location } from "../../interfaces/Location";
import { RicsSideMenuTab } from "../../interfaces/RicsSideMenuTab";
import { Identity, User } from "../../interfaces/User";
import "../../theme/AllUsers.css";
import "../../theme/UsersGrid.css";
import { useDataService } from "../DataServiceProvider";
import { usePortal } from "../PortalProvider";
import RicsSideMenuTabHeader from "../RicsSideMenuTabHeader";
import AddExistingUsers from "../users/AddExistingUsers";
import UserImport from "../users/UserImport";
import UsersGrid from "../users/UsersGrid";
export interface LocationDetailsUsersProps {
  locationId: string;
  selectedTab: RicsSideMenuTab;
  deleteUserEnabled: boolean;
}

const LocationDetailsUsers: React.FC<LocationDetailsUsersProps> = (props) => {
  const dataService = useDataService();
  const portal = usePortal();
  const flags = useFlags();
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([]);
  const [checkedUsers, setCheckedUsers] = useState<Map<string, boolean>>(new Map());
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [showAddExistingModal, setShowAddExistingModal] = React.useState(false);
  const [showGlobalAlert, setShowGlobalAlert] = React.useState<boolean>(true);
  const [showPopover, setShowPopover] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined
  });

  useEffect(() => {
    getUsers();
  }, []);

  const checkAllUsers = (checked: boolean) => {
    let updatedCheckedUsers = new Map(checkedUsers);
    Array.from(updatedCheckedUsers.keys()).forEach((key) => {
      updatedCheckedUsers.set(key, checked);
    });
    setCheckedUsers(updatedCheckedUsers);
  };

  const getUsers = () => {
    portal!.navigation.isLoading(true);
    const version = getVersion(flags);

    var onSuccess = function (identities: Array<Identity>) {
      let mappedUsers = [];
      if (!identities) {
        onFailure();
        return;
      }

      for (var i = 0; i < identities.length; i++) {
        let identity = identities[i];
        let userObject = getUserFromIdentity(identity);
        let hasOwnerRole = userObject.Roles.some((r) => r.Name === roleNames.Owner);

        if (hasOwnerRole || userObject.Locations.includes(props.locationId)) {
          mappedUsers.push(userObject);
          continue;
        }
      }

      setFilteredUsers(mappedUsers);
      setCheckedUsers(new Map(mappedUsers.map((d: User) => [d.UserId, false])));
      //TODO: Remove once Portal is no longer using RicsX for Identities
      dataService!.refreshTenantIdentities(version);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to retrieve users.");
      return;
    };

    dataService.identities.getIdentities(version, onSuccess, onFailure);
  };

  var getUserFromIdentity = function (identity: Identity) {
    let locationIds = identity.roles.map((x) => x.locationId);
    let locations = locationIds.filter((x, i) => locationIds.indexOf(x) === i);
    let userRoles = roles.filter((r) => identity.roles.some((ir) => ir.identityRole == r.Name));

    var mappedUser: User = {
      UserId: identity.identityId,
      Email: identity.emailAddress,
      FirstName: identity.firstName,
      LastName: identity.lastName,
      DeletedOn: identity.deletedOn,
      Login: "",
      PhoneNumber: identity.phoneNumber,
      RicsUserId: identity.rics9UserId,
      RicsXIdentityId: identity.identityId,
      LocationLabel: formatLocationsText(locations),
      Locations: locations,
      Roles: userRoles,
      SelectedToImport: false,
      SelectedToEdit: false,
      ErrorString: "",
      Valid: emailIsValid(identity.emailAddress),
      IsActivated: identity.isActivated,
      IsVerified: identity.isVerified,
      IsSelected: false
    };
    return mappedUser;
  };

  function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  var formatLocationsText = function (locations: Array<string | undefined>) {
    if (locations.length === 0) {
      return "No Locations";
    } else if (locations.length === 1) {
      return portal!.State.locations.find((x: Location) => x.locationId === locations[0])?.name ?? "No Locations";
    } else if (locations.length > 1) {
      return "Multiple";
    }

    return "No Locations";
  };

  const closeUserImportModalWithoutSaving = () => {
    setShowImportModal(false);
  };

  const closeAddExistingUsersModalWithoutSaving = () => {
    setShowAddExistingModal(false);
  };

  const onImportSuccess = () => {
    getUsers();
    setShowImportModal(false);
  };

  const onAddExistingSuccess = () => {
    getUsers();
    setShowAddExistingModal(false);
  };

  const importClicked = () => {
    setShowPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);
    setShowImportModal(true);
  };

  const addExistingClicked = () => {
    setShowPopover({ open: false, event: undefined });
    setShowAddExistingModal(true);
  };

  return (
    <>
      <RicsSideMenuTabHeader selectedTab={props.selectedTab}>
        <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
          className="add-user-popover">
          <IonButton fill="clear" className="add-user-popover-buttons" onClick={() => addExistingClicked()}>
            <IonIcon src="assets/img/user-alt.svg" className="button-left-icon add-existing-user-icon" />
            Add an Existing User
          </IonButton>
          <IonButton fill="clear" className="add-user-popover-buttons" onClick={() => importClicked()}>
            <IonIcon src="img/download.svg" className="button-left-icon add-user-popover-icon" />
            Import User from RICS 9
          </IonButton>
        </IonPopover>
        <IonButton fill="clear" slot="end" className="add-user-button" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
          Add User
        </IonButton>
        <AddExistingUsers
          isOpen={showAddExistingModal}
          setIsOpen={setShowAddExistingModal}
          closeModalWithoutSaving={closeAddExistingUsersModalWithoutSaving}
          onImportSuccess={onAddExistingSuccess}
          locationId={props.locationId}
        />
        <UserImport
          isOpen={showImportModal}
          setIsOpen={setShowImportModal}
          closeModalWithoutSaving={closeUserImportModalWithoutSaving}
          onImportSuccess={onImportSuccess}
          locationId={props.locationId}
        />
      </RicsSideMenuTabHeader>
      <IonContent id="locationsPageContentInjectionContainer" className="users-page">
        <div className="tabbedSection">
          <div className="tabContent">
            <div id="Users">
              <div className="users-alert-cover user-cards-content" hidden={!showGlobalAlert}>
                <IonItem className="users-alert">
                  <IonIcon src="assets/img/info.svg" className="icon-alert" />
                  <IonText>To update a user's role, please go to the All Users page.</IonText>
                  <IonIcon src={closeOutline} className="icon-alert-close" slot="end" onClick={() => setShowGlobalAlert(false)} />
                </IonItem>
              </div>
              <div className={showGlobalAlert ? "user-alert-padding" : ""}>
                <UsersGrid
                  cssClass="all-users-grid"
                  users={filteredUsers}
                  checkedUsers={checkedUsers}
                  setCheckedUsers={setCheckedUsers}
                  checkAllUsers={checkAllUsers}
                  locationId={undefined}
                  isCardMode={true}
                  deleteUserIsEnabled={props.deleteUserEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default LocationDetailsUsers;

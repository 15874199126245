import moment from "moment";
import { ECommerceConstants } from "../constants/ECommerceConstants";
import { getRandomNumber, numberToStringWithPad } from "../helpers/numberHelpers";
import { EmailVerification } from "../interfaces/integrations/ecommerce/EmailVerification";
import { DoRicsApiXhr } from "./XhrService";

export const sendVerificationEmail = async (email: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const onSuccess = () => resolve(true);

    const onFailure = () => resolve(false);

    const endpoint = "v1/ecommerce/email/verify";

    let code = getRandomNumber(0, 999999);
    let emailCode: EmailVerification = {
      code: numberToStringWithPad(code, 6),
      expiration: moment().add(10, "m").toDate()
    };

    window.sessionStorage.setItem(ECommerceConstants.emailCodeKey, btoa(JSON.stringify(emailCode)));

    const payload = {
      Email: email,
      Code: code
    };

    DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
  });
};

import React, { useEffect, useState } from "react";
import { ECommerceConstants } from "../../../../constants/ECommerceConstants";
import { CreateECommerceAccountBase } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { useECommerceService } from "../../../../services/providers/ECommerceServiceProvider";
import { usePortal } from "../../../PortalProvider";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupGeneralInfoModalProps extends ECommerceAccountSetupModalPropsBase {
  updateAccount(account: CreateECommerceAccountBase): void;
}

const ECommerceAccountSetupGeneralInfoModal: React.FC<ECommerceAccountSetupGeneralInfoModalProps> = (props) => {
  const portal = usePortal();
  const ecommerceService = useECommerceService();

  const [label, setLabel] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    updateCanSave();
  }, [label, email]);

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  const getDate = (dateString: string): Date => {
    return new Date(dateString);
  };

  const updateCanSave = () => {
    let labelChanged = label !== "";
    let emailChanged = email !== "";

    setCanSave(labelChanged && emailChanged);
  };

  const updateTempAccount = () => {
    let tempAccount: CreateECommerceAccountBase = JSON.parse(window.sessionStorage.getItem(ECommerceConstants.tempAccountKey)!);
    tempAccount.contactEmail = email;
    tempAccount.label = label;

    let adapterStartDate = new Date(startDate);
    adapterStartDate.setUTCHours(0, 0, 0, 0);
    tempAccount.adapterStartDate = adapterStartDate;

    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(tempAccount));

    props.updateAccount(tempAccount);
  };

  const sendCode = async () => {
    portal!.navigation.isLoading(true);

    await ecommerceService!.sendVerificationEmail(email);

    portal!.navigation.isLoading(false);
  };

  const onNext = () => {
    updateTempAccount();
    sendCode();

    if (props.onNext) {
      props.onNext();
    }
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Integration Details",
    onNext: onNext,
    disableNextButton: !canSave,
    hideGoBackButton: true
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body")}>
          <div className={getClass("body-edit-field-holder")}>
            <label className={getClass("body-edit-field-holder-header")}>Integration Name</label>
            <input className={getClass("body-edit-field-holder-input")} value={label} onChange={(e) => setLabel(e.target.value)} required />
          </div>
          <div className={getClass("body-edit-field-holder")}>
            <label className={getClass("body-edit-field-holder-header")}>Contact Email</label>
            <input
              className={getClass("body-edit-field-holder-input")}
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={getClass("body-edit-field-holder")}>
            <label className={getClass("body-edit-field-holder-header")}>Start Date</label>
            <input
              className={getClass("body-edit-field-holder-input")}
              type="date"
              value={startDate.toISOString().slice(0, 10)}
              onChange={(e) => setStartDate(getDate(e.target.value))}
              required
            />
          </div>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupGeneralInfoModal;

import React, { useEffect } from 'react';
import { IonText, IonGrid, IonRow, IonCol, IonLabel, IonContent, IonPage } from '@ionic/react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/OnboardingWorkflow.css';
import POSWorkflowCard from "../components/onboardingworkflow/POSWorkflowCard";
import BrandingWorkflowCard from "../components/onboardingworkflow/BrandingWorkflowCard";
import PaymentWorkflowCard from "../components/onboardingworkflow/PaymentWorkflowCard";
import UsersWorkflowCard from '../components/onboardingworkflow/UsersWorkflowCard';
import WorkflowCard from '../components/onboardingworkflow/WorkflowCard';
import { RouteComponentProps, useHistory } from 'react-router';
import RicsToolbar from '../components/RicsToolbar';
import { useXhrService } from '../components/XhrServiceProvider';
import { useDataService } from '../components/DataServiceProvider';
import { usePortal } from '../components/PortalProvider';
import { MerchantTypes } from '../interfaces/salesettings/MerchantSettings';
import { Paths } from '../constants/Paths';

interface OnboardingWorkflowProps {
    reentryPointSetup: Function;
}

const OnboardingWorkflow: React.FC<OnboardingWorkflowProps> = (props) => {
    const portal = usePortal();
    const [isPaymentComplete, setIsPaymentComplete] = React.useState(false);
    const [isPOSSettingsComplete, setIsPOSSettingsComplete] = React.useState(false);
    const [isBrandingComplete, setIsBrandingComplete] = React.useState(false);
    const [isImportUsersComplete, setIsImportUsersComplete] = React.useState(false);
    const [isFinalizeLocationsReady, setFinalizeLocationsReady] = React.useState(false);
    const [isFinalizeLocationsComplete, setIsFinalizeLocationsComplete] = React.useState(false);
    const [ricsPayEnabled, setRicsPayEnabled] = React.useState(false);
    const xhrService = useXhrService();
    const dataService = useDataService();
    let history = useHistory();

    useEffect(() => {
        getWorkflowStatus();
        getRicsPayFeatureEnabledStatus();
    }, []);

    const getRicsPayFeatureEnabledStatus = () => {
        let endpoint = "v1/Features/RicsPayForPortal";
        var onSuccess = function (response: string) {
            var feature = JSON.parse(response);
            setRicsPayEnabled(feature.isEnabled);
        };
        var onFailure = function () {
            setRicsPayEnabled(false);
        };
        xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
    };

    const getWorkflowStatus = () => {
        var paymentsState = getPaymentsState();
        var posSettingsState = getPOSSettingsState();
        var brandingState = getBrandingSetup();
        var usersState = getUsersImported();

        if (paymentsState && posSettingsState && brandingState && usersState) {
            setFinalizeLocationsReady(true);
            getLocationsAreFinalized();
        }
    };

    const getPaymentsState = () => {
        var configurationValue = getTenantConfiguration(portal!.configurations.SetupPaymentWizardComplete);
        if (configurationValue) {
            setIsPaymentComplete(configurationValue);
            return configurationValue;
        }

        var isManualProcess = portal!.State.saleSettings['Tenant'].merchantSettings?.merchantType == MerchantTypes.Manual;
        if (isManualProcess) {
            setIsPaymentComplete(true);
            return true;
        }

        return false;
    };

    const getTenantConfiguration = (configuration: string) => {
        if (portal!.configurations.configurations && portal!.configurations.configurations[configuration]) {
            var configurationValue = false;
            for (var i = 0; i < portal!.configurations.configurations[configuration].length; i++) {
                var tempPaymentConfiguration = portal!.configurations.configurations[configuration][i];
                if (!tempPaymentConfiguration.LocationId) {
                    configurationValue = tempPaymentConfiguration.ConfigurationValue === 'true';
                    break;
                }
            }

            return configurationValue;
        }

        return false;
    };

    const getPOSSettingsState = () => {
        var configurationValue = getTenantConfiguration(portal!.configurations.SetupSettingsWizardComplete);
        setIsPOSSettingsComplete(configurationValue);
        return configurationValue;
    };

    const getBrandingSetup = () => {
        var brandingConfigurationValue = getTenantConfiguration(portal!.configurations.SetupBrandingWizardComplete);
        if (brandingConfigurationValue) {
            setIsBrandingComplete(brandingConfigurationValue);
            return brandingConfigurationValue;
        }
        else if (portal!.configurations.configurations && portal!.configurations.configurations[portal!.configurations.ReceiptIntroText] && portal!.configurations.configurations[portal!.configurations.ReceiptFooterText]) {
            var footerExists = false;
            var introExists = false;

            for (var i = 0; i < portal!.configurations.configurations[portal!.configurations.ReceiptIntroText].length; i++) {
                var tempIntro = portal!.configurations.configurations[portal!.configurations.ReceiptIntroText][i];
                if (!tempIntro.LocationId) {
                    introExists = true;
                    break;
                }
            }

            for (var i = 0; i < portal!.configurations.configurations[portal!.configurations.ReceiptFooterText].length; i++) {
                var tempFooter = portal!.configurations.configurations[portal!.configurations.ReceiptFooterText][i];
                if (!tempFooter.LocationId) {
                    footerExists = true;
                    break;
                }
            }

            var brandingSettingsComplete = footerExists && introExists;
            setIsBrandingComplete(brandingSettingsComplete);
            return (brandingSettingsComplete);
        }

        return false;
    };

    const getUsersImported = () => {
        var usersImported = getTenantConfiguration(portal!.configurations.SetupUserWizardComplete);
        setIsImportUsersComplete(usersImported);
        return usersImported;
    };

    const getLocationsAreFinalized = () => {
        for (var i = 0; i < portal!.State.locations.length; i++) {
            var location = portal!.State.locations[i];
            var setupComplete = location.isActive;
            if (setupComplete) {
                setIsFinalizeLocationsComplete(true);
                return;
            }
        }
    };

    const completePaymentSettings = () => {
        var onSuccess = function () {
            portal!.navigation.isLoading(false);
        };

        var onFailure = function () {
            portal!.navigation.isLoading(false);
        };

        portal!.navigation.isLoading(true);
        setIsPaymentComplete(true);
        setupCompleteConfigurations(portal!.configurations.SetupPaymentWizardComplete, onSuccess, onFailure);
        if (isPOSSettingsComplete && isBrandingComplete && isImportUsersComplete) {
            setFinalizeLocationsReady(true);
        }
    };

    const completePOSSettings = () => {
        var onSuccess = function () {
            portal!.navigation.isLoading(false);
        };

        var onFailure = function () {
            portal!.navigation.isLoading(false);
        };

        portal!.navigation.isLoading(true);
        setIsPOSSettingsComplete(true);
        setupCompleteConfigurations(portal!.configurations.SetupSettingsWizardComplete, onSuccess, onFailure);
        if (isPaymentComplete && isBrandingComplete && isImportUsersComplete) {
            setFinalizeLocationsReady(true);
        }
    };
    const completeBrandingSettings = () => {
        var onSuccess = function () {
            portal!.navigation.isLoading(false);
        };

        var onFailure = function () {
            portal!.navigation.isLoading(false);
        };

        portal!.navigation.isLoading(true);
        setIsBrandingComplete(true);
        setupCompleteConfigurations(portal!.configurations.SetupBrandingWizardComplete, onSuccess, onFailure);
        if (isPaymentComplete && isPOSSettingsComplete && isImportUsersComplete) {
            setFinalizeLocationsReady(true);
        }
    };
    const completeImportUsersSettings = () => {
        var onSuccess = function () {
            portal!.navigation.isLoading(false);
        };

        var onFailure = function () {
            portal!.navigation.isLoading(false);
        };

        portal!.navigation.isLoading(true);
        setIsImportUsersComplete(true);
        setupCompleteConfigurations(portal!.configurations.SetupUserWizardComplete, onSuccess, onFailure);
        if (isPaymentComplete && isPOSSettingsComplete && isBrandingComplete) {
            setFinalizeLocationsReady(true);
        }
    };

    const setWizardComplete = () => {
        var onSuccess = function () {
            history.push(Paths.Locations);
            portal!.navigation.isLoading(false);
        };

        var onFailure = function () {
            portal!.navigation.isLoading(false);
        };

        portal!.navigation.isLoading(true);
        setupCompleteConfigurations(portal!.configurations.SetupWizardComplete, onSuccess, onFailure)
    };

    const setupCompleteConfigurations = function (configuration: string, successCallback: Function, failureCallback: Function) {
        var setupCompleteConfiguration = {
            ConfigurationName: configuration,
            ConfigurationValue: true
        };

        var endpoint = 'v1/Configurations';

        var onSuccess = function () {
            dataService!.loadConfigurations(null, successCallback, failureCallback);
        };

        xhrService!.DoRicsApiXhr(endpoint, setupCompleteConfiguration, onSuccess, failureCallback, 'POST');
    };

    return (
        <IonPage>
            <IonContent class="onboarding-content">
                <IonGrid class="workflow-grid">
                    <IonRow>
                        <IonCol class="ion-text-center">
                            <h1>Welcome to RICS Retail!</h1>
                            <IonText>
                                <div className="welcome-text">
                                    Let's get you setup to start running your stores.
                                    <br />
                                    Complete the items below to get things rolling.
                                </div>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <PaymentWorkflowCard
                                reentryPointSetup={props.reentryPointSetup}
                                isComplete={isPaymentComplete}
                                setComplete={completePaymentSettings}
                                isRicsPayEnabled={ricsPayEnabled} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <POSWorkflowCard
                                isComplete={isPOSSettingsComplete}
                                setComplete={completePOSSettings} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <BrandingWorkflowCard
                                isComplete={isBrandingComplete}
                                setComplete={completeBrandingSettings} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <UsersWorkflowCard
                                isComplete={isImportUsersComplete}
                                setComplete={completeImportUsersSettings} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <WorkflowCard
                                title="Finalize Locations"
                                text="Add taxes specific to your locale so that each transaction is covered."
                                icon="assets/img/locations.svg"
                                isComplete={isFinalizeLocationsComplete}
                                isEnabled={isFinalizeLocationsReady}
                                onButtonClick={setWizardComplete} />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol class="ion-text-center">
                            <IonLabel color="primary" onClick={setWizardComplete} class="click-label">Skip for now</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default OnboardingWorkflow;

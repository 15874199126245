import React from "react";

import "../theme/Modal.css";

type ModalProps = {
  children: React.ReactNode;
  hidden?: boolean;
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return (
    <div className="rics-modal-overlay" hidden={props.hidden}>
      <div className="rics-modal">{props.children}</div>
    </div>
  );
};

export default Modal;

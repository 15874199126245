import React from 'react';

import { IonImg } from '@ionic/react';
import { Location } from '../../interfaces/Location';
import { usePortal } from '../PortalProvider';
import { ApplicationPortal } from '../../interfaces/ApplicationPortal';

interface LocationStatusProps {
    Location: Location;
    clickToBegin: boolean;
    setShowModal: Function;
}

const LocationStatus: React.FC<LocationStatusProps> = (props) => {
    let portal = usePortal();

    const onFinishClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        portal!.State!.currentLocation = props.Location;
        showFinishAndInviteModal(portal);
    };

    const showFinishAndInviteModal = function (portal: ApplicationPortal | null) {
        var location = portal!.State.currentLocation;
        if (location.setupWizard.isFinishAndInviteUsersCompleted) {
            return;
        }

        props.setShowModal(true, props.Location);
    };

    const readyToInvite = () => {
        return !props.Location.setupWizard.isFinishAndInviteUsersCompleted
            && props.Location.setupWizard.arePaymentsSetup
            && props.Location.setupWizard.isBrandingSetup
            && props.Location.setupWizard.areUsersSetup
            && props.Location.setupWizard.areSettingsSetup
            && props.Location.setupWizard.areTaxesSetup
    }

    const renderHolder = (done: boolean) => {
        let holder = done ?
            <div className="progressIconHolder done">
                <IonImg src="img/success.svg" class="done"></IonImg>
            </div>
            : <div className="progressIconHolder notdone">
                <IonImg src="img/success.svg" class="notdone"></IonImg>
            </div>
        return holder;
    }

    return (
        <>
            {!props.Location.isActive &&
                <div className="progressBarHolder">
                    {readyToInvite() ?
                        <div className="labelButton noSelect finishAndInviteButton" style={props.clickToBegin ? { "marginTop": "10px" } : {}} onClick={(e) => onFinishClick(e)}>
                            <div>Finish &amp; Invite Users</div>
                            <IonImg src="img/send.svg"></IonImg>
                        </div>
                        :
                        <>
                            <div hidden={!props.clickToBegin} className="progressBarTopText">Click to begin setup...</div>
                            <div className="progressBar">
                                {renderHolder(props.Location.setupWizard.arePaymentsSetup)}
                                {renderHolder(props.Location.setupWizard.isBrandingSetup)}
                                {renderHolder(props.Location.setupWizard.areUsersSetup)}
                                {renderHolder(props.Location.setupWizard.areSettingsSetup)}
                                {renderHolder(props.Location.setupWizard.areTaxesSetup)}
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
};

export default LocationStatus;
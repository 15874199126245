import React from 'react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import TenantLevelPageHeader from '../components/TenantLevelPageHeader';
import SettingsGrid from '../components/SettingsGrid';
import { IonPage } from '@ionic/react';
import RicsToolbar from '../components/RicsToolbar';
import { locationDetailsTabs } from '../constants/LocationDetailsTabs';


const Settings: React.FC = () => {
    return (
        <>
            <IonPage>
                <TenantLevelPageHeader
                    headerText="Settings"
                    headerIconFileUrl="assets/img/settings.svg" />
                <SettingsGrid
                    selectedTab={locationDetailsTabs.filter(t => t.tabKey === "Settings")[0]}
                />
            </IonPage>
        </>
    );
};

export default Settings

import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Paths } from "../constants/Paths";
import { Link } from 'react-router-dom';
import '../theme/PrivacyPolicy.css';

const Error: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="sectionContainer">
          <div className="sectionBody" id="errorSection">
            <div id="errorPageMain">
              <div className="topbar">
                <div className="heading">Resource Not Found</div>
              </div>
              <div id="content">
                The page you requested does not exist. Please navigate to a section using the sidebar or&nbsp;
                <Link to={Paths.Default} target="_parent">go to the portal homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Error;
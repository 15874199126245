import { IonContent, IonImg, IonInput, IonPage } from "@ionic/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDataService } from "../components/DataServiceProvider";
import { usePortal } from "../components/PortalProvider";
import { useXhrService } from "../components/XhrServiceProvider";
import { Paths } from "../constants/Paths";
import { Identity } from "../interfaces/User";

import { getVersion } from "../helpers/apiVersionsHelpers";
import "../theme/Password.css";

interface PasswordProps {
  reentryPointSetup: Function;
}

const Password: React.FC<PasswordProps> = (props) => {
  const xhrService = useXhrService();
  let history = useHistory();
  const portal = usePortal();
  const dataService = useDataService();
  const flags = useFlags();

  var identityId = "";
  const [identityIdState, setIdentityIdState] = React.useState("");

  const [emailInputValue, setEmailInputValue] = React.useState("");
  const [settingNewPassword, setSettingNewPassword] = React.useState(false);
  const [valuesState, setValueState] = useState<any>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confirmPassword: ""
  });

  const forgotPasswordCall = function (forgotPasswordInputParameters: any) {
    portal!.navigation.isLoading(true);

    const onSuccess = function () {
      alert("An email was sent to the provided address to reset your password");
      //todo: use router to reload the page...
      portal!.navigation.isLoading(false);
    };

    const onFailure = function () {
      alert("Could not send an email to the provided address");
      portal!.navigation.isLoading(false);
    };

    const endpoint = "v1/Auth/ForgotPassword";
    xhrService!.DoRicsApiXhr(endpoint, forgotPasswordInputParameters, onSuccess, onFailure, "PUT");
  };

  const checkPassword = function () {
    let newPassword = document.querySelector("#newPassword") as HTMLInputElement;
    let confirmPassword = document.querySelector("#confirmPassword") as HTMLInputElement;
    let continueButton: any = document.getElementById("passwordSubmitButton") as HTMLButtonElement;
    let hasNumber = /\d/.test(newPassword.value);

    if (newPassword.value.length < 8 || newPassword.value !== confirmPassword.value || !hasNumber) {
      continueButton!.classListAdd("disabled");
      continueButton!.disabled = true;
      setValue("password", "");
      setValue("confirmPassword", "");
    } else {
      continueButton.classList.remove("disabled");
      continueButton.disabled = false;
      setValue("password", newPassword.value);
      setValue("confirmPassword", confirmPassword.value);
    }
  };

  const formatPhoneText = function (formattedValue: any) {
    formattedValue = formattedValue.trim().replace(/-/g, "");

    if (formattedValue.length > 3 && formattedValue.length <= 6) {
      formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
    } else if (formattedValue.length > 6) {
      formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3, 6) + "-" + formattedValue.slice(6);
    }

    setValue("phoneNumber", formattedValue);
  };

  const validatePhoneNumber = function (value: string) {
    let phoneRegExp = new RegExp(/^\d{3}-\d{3}-\d{4}$/);
    return value === "" ? true : phoneRegExp.test(value);
  };

  const setPassword = function (setPasswordInputParameters: any) {
    portal!.navigation.isLoading(true);

    const onSuccess = function () {
      getAuthToken(valuesState["emailAddress"], valuesState["password"]);
    };

    const onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong setting your password. Please try again.");
      setValue("password", "");
      setValue("confirmPassword", "");
      history.go(0);
    };

    let endpoint = "v1/Auth/ChangePassword";
    xhrService!.DoRicsApiXhr(endpoint, setPasswordInputParameters, onSuccess, onFailure, "PUT");
  };

  const getAuthToken = function (emailAddress: string, password: string) {
    let payload = {
      emailAddress: emailAddress,
      password: password
    };

    let onFailure = function () {
      alert("Incorrect username or password.");
      portal!.navigation.isLoading(false);
    };

    let endpoint = "v1/Auth/CreateToken";
    xhrService!.DoRicsApiXhr(endpoint, payload, parseAuthResponse, onFailure, "PUT");
  };

  const parseAuthResponse = function (response: any) {
    response = JSON.parse(response);
    if (!response.token) {
      alert("Invalid username/password combination. Please try again.");
      portal!.navigation.isLoading(false);
      return;
    }

    //success
    window.localStorage.setItem("Token", response.token);
    window.localStorage.setItem("RefreshToken", response.refreshToken);

    const successCallback = function () {
      clearValues();
      setSettingNewPassword(false);
      document.onkeydown = null;
      props.reentryPointSetup();
      history.push(Paths.Default);
    };

    const failureCallback = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong trying to update you account. Try to login using your email and new password.");
      window.localStorage.removeItem("Token");
      clearValues();
      document.onkeydown = null;
      props.reentryPointSetup();
    };

    let identityIdEmpty = identityId === "";
    let properIdentityId = identityIdEmpty ? identityIdState : identityId;

    updateIdentity(properIdentityId, successCallback, failureCallback);
  };

  const updateIdentity = function (identityId: string, successCallback: () => void, failureCallback: () => void) {
    const version = getVersion(flags);
    const onFailure = () => {
      failureCallback();
    };

    const onSuccessfulGet = (identity: Identity) => {
      const onSuccess = () => {
        createPinIfMissing(identityId, successCallback, failureCallback);
      };

      identity.firstName = valuesState["firstName"];
      identity.lastName = valuesState["lastName"];
      identity.phoneNumber = valuesState["phoneNumber"];

      let params = {
        emailAddress: identity.emailAddress,
        firstName: identity.firstName,
        lastName: identity.lastName,
        phoneNumber: identity.phoneNumber,
        identityId: identity.identityId,
        tenants: identity.tenants
      } as Identity;

      dataService.identities.saveIdentity(params, false, version, onSuccess, onFailure); // TODO: Fix api to not override the roles
    };

    dataService.identities.getIdentity(identityId, version, onSuccessfulGet, onFailure);
  };

  const createPinIfMissing = function (identityId: string, onSuccess: () => void, failureCallback: () => void) {
    const version = getVersion(flags);
    const onFailure = () => {
      dataService.identities.createIdentityPin(valuesState["emailAddress"], version, onSuccess, failureCallback);
    };

    dataService.identities.getIdentityPin(identityId, version, onSuccess, onFailure);
  };

  const setValue = (valueName: string, value: string) => {
    let updatedValuesState = valuesState;
    updatedValuesState[valueName] = value;
    setValueState(updatedValuesState);
  };

  const clearValues = function () {
    identityId = "";
    setIdentityIdState("");
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("phoneNumber", "");
    setValue("emailAddress", "");
    setValue("password", "");
    setValue("confirmPassword", "");
  };

  const onSubmit = function () {
    let validPhoneNumber = validatePhoneNumber(valuesState["phoneNumber"]);

    if (!validPhoneNumber) {
      let phoneInput = document.querySelector("#phoneNumber");
      phoneInput!.setAttribute("style", "border-color: red;border-style: double;");
      return;
    }

    // Get token from url
    let verificationToken = window.location.href.split("/password/")[1];
    let setPasswordInputParameters = {
      IdentityId: valuesState["identityId"],
      NewPassword: valuesState["password"],
      ConfirmPassword: valuesState["confirmPassword"],
      VerificationToken: verificationToken
    };

    setPassword(setPasswordInputParameters);
  };

  const onConfirm = function () {
    let forgotPasswordInputParameters: any = {};
    let forgotPasswordEmailInput: any = document.getElementById("forgotPasswordEmailInput");
    forgotPasswordInputParameters.EmailAddress = forgotPasswordEmailInput.value;
    forgotPasswordCall(forgotPasswordInputParameters);
  };

  const beforeLoadThen = async function (thenCallback: Function) {
    portal!.navigation.isLoading(true);
    let verificationToken = window.location.href.split("/password/")[1];
    if (verificationToken === "" || verificationToken === undefined) {
      portal!.navigation.isLoading(false);
      clearValues();
      thenCallback();
      return;
    }

    let version = getVersion(flags);
    dataService.auth
      .getIdentityByVerificationToken(verificationToken, version)
      .then((identity) => {
        setIdentityIdState(identity.identityId);
        setValue("identityId", identity.identityId);
        setValue("firstName", identity.firstName);
        setValue("lastName", identity.lastName);
        setValue("emailAddress", identity.emailAddress);
        setValue("phoneNumber", identity.phoneNumber);
        thenCallback();
        portal!.navigation.isLoading(false);
      })
      .catch(() => {
        window.localStorage.removeItem("Token");
        alert(
          "A password has already been set for this account or the link has expired. Use the Forgot Password link on the Portal login page to reset your password."
        );
        history.push(Paths.Login);
        thenCallback();
        portal!.navigation.isLoading(false);
      });
  };

  // HACK: This shouldn't be necessary, but the JSX bound events are not firing in this component
  const hookupEvents = () => {
    let token = window.location.href.split("/password/")[1];
    if (token) {
      // New password events
      setSettingNewPassword(true);

      // Enter event
      document.onkeydown = function (e) {
        let submitButton: any = document.getElementById("passwordSubmitButton");
        if (e.keyCode === 13 && !submitButton.disabled) {
          onSubmit();
        }
      };
    } else {
      // Forgot password events
      setSettingNewPassword(false);

      // Enter event
      document.onkeydown = function (e) {
        let submitButton: any = document.getElementById("forgotPasswordSubmitButton");
        if (e.keyCode === 13 && !submitButton.disabled) {
          onConfirm();
        }
      };
    }

    window.localStorage.removeItem("Token");
  };

  const forgotPasswordEmailInputOnChange = function (e: any) {
    var forgotPasswordSubmitButton = document.getElementById("forgotPasswordSubmitButton") as HTMLButtonElement;
    var forgotPasswordEmailInput = document.getElementById("forgotPasswordEmailInput") as HTMLInputElement;

    if (forgotPasswordEmailInput.value === "") {
      forgotPasswordSubmitButton.classList.add("disabled");
      forgotPasswordSubmitButton.disabled = true;
    } else {
      forgotPasswordSubmitButton.classList.remove("disabled");
      forgotPasswordSubmitButton.disabled = false;
    }
    setEmailInputValue(forgotPasswordEmailInput.value);
  };

  useEffect(() => {
    beforeLoadThen(hookupEvents);
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="passwordPage">
          <div id="passwordFormHolder" className="layout-column centerContents" hidden={!settingNewPassword}>
            <div className="topSection centerContents">
              <div className="logoHolder">
                <IonImg src="img/rics-logo.svg" style={{ width: "240px" }}></IonImg>
              </div>
              <div className="heading">Welcome to RICS Checkout!</div>
              <div className="subHeading">Please confirm your information and create a password for your account.</div>
            </div>
            <div className="passwordFormSection layout-column">
              <div className="passwordFormContent layout-column">
                <div className="passwordFormContentRow layout-row">
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">First Name</div>
                    <IonInput
                      id="firstName"
                      type="text"
                      className="formFieldElement"
                      value={valuesState["firstName"]}
                      onIonInput={(e: any) => {
                        setValue("firstName", e.target.value);
                      }}
                      onIonChange={(e: any) => {
                        setValue("firstName", e.target.value);
                      }}
                    />
                  </div>
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">Last Name</div>
                    <IonInput
                      id="lastName"
                      type="text"
                      className="formFieldElement"
                      value={valuesState["lastName"]}
                      onIonInput={(e: any) => {
                        setValue("lastName", e.target.value);
                      }}
                      onIonChange={(e: any) => {
                        setValue("lastName", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="passwordFormContentRow layout-row">
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">Email Address</div>
                    <IonInput
                      id="emailAddress"
                      type="text"
                      className="formFieldElement"
                      disabled
                      value={valuesState["emailAddress"]}
                      onIonInput={(e: any) => {
                        setValue("emailAddressInput", e.target.value);
                      }}
                      onIonChange={(e: any) => {
                        setValue("emailAddressInput", e.target.value);
                      }}
                    />
                  </div>
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">Phone Number</div>
                    <IonInput
                      id="phoneNumber"
                      type="text"
                      className="formFieldElement"
                      maxlength={12}
                      value={valuesState["phoneNumber"]}
                      onIonInput={(e: any) => {
                        formatPhoneText(e.target.value);
                      }}
                      onIonChange={(e: any) => {
                        formatPhoneText(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="passwordFormContentRow layout-row">
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">Password</div>
                    <IonInput
                      id="newPassword"
                      type="password"
                      className="formFieldElement"
                      onIonInput={(e: any) => {
                        checkPassword();
                      }}
                      onIonChange={(e: any) => {
                        checkPassword();
                      }}
                    />
                  </div>
                  <div className="formFieldHolder layout-column flex50">
                    <div className="formFieldHeader">Confirm Password</div>
                    <IonInput
                      id="confirmPassword"
                      type="password"
                      className="formFieldElement"
                      onIonInput={(e: any) => {
                        checkPassword();
                      }}
                      onIonChange={(e: any) => {
                        checkPassword();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="formFooterMessage">Passwords must be at least 8 characters and include at least one number.</div>
              <div className="buttonsHolder layout-row">
                <button className="buttonDefault submitButton disabled" id="passwordSubmitButton" onClick={onSubmit}>
                  Change My Password
                </button>
              </div>
            </div>
          </div>
          <div id="passwordFormHolder" className="layout-column centerContents" hidden={settingNewPassword}>
            <div className="topSection centerContents">
              <div className="logoHolder">
                <IonImg src="img/rics-logo.svg" style={{ width: "240px" }}></IonImg>
              </div>
              <div className="heading">Forgot your Password?</div>
              <div className="subHeading">Please enter the email on your account and you will receive instructions to reset your password.</div>
            </div>
            <div className="passwordFormSection layout-column">
              <div className="passwordFormContent layout-column">
                <div className="passwordFormContentRow layout-row">
                  <div className="formFieldHolder layout-column flex">
                    <IonInput
                      id="forgotPasswordEmailInput"
                      placeholder="Email..."
                      type="text"
                      className="formFieldElement"
                      value={emailInputValue}
                      onIonChange={forgotPasswordEmailInputOnChange}
                    />
                    <div className="buttonsHolder layout-row">
                      <button className="buttonDefault submitButton disabled" id="forgotPasswordSubmitButton" onClick={onConfirm}>
                        Reset My Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Password;

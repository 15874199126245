import React from "react";
import Accordion from "react-bootstrap/esm/Accordion";
import { Integration } from "../../interfaces/integrations/Integration";
import IntegrationCard from "./IntegrationCard";

interface IntegrationsAccordionItemProps {
  header: string;
  integrations: Array<Integration>;
  hidden?: boolean;
}

const IntegrationsAccordionItem: React.FC<IntegrationsAccordionItemProps> = (props) => {
  return (
    <>
      <Accordion.Item className="integrations-accordion-item" eventKey={props.header} hidden={props.hidden} key={props.header}>
        <Accordion.Header className="integrations-accordion-header">{props.header}</Accordion.Header>
        <Accordion.Body className="integrations-accordion-body">
          {props.integrations && props.integrations.map((integration, i) => <IntegrationCard integration={integration} key={integration.Name} />)}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default IntegrationsAccordionItem;

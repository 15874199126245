import React, { useEffect } from 'react';
import PaymentDetails from './PaymentDetails';
import Refunds from './Refunds';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import { IonGrid, IonRow, IonCol, IonIcon, IonText, IonContent, IonItem } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import POSDeviceSettings from './POSDeviceSettings';
import PaymentProcessing from './PaymentProcessing';
import Scrollspy from './ScrollSpy';
import { ApplicationPortal } from '../interfaces/ApplicationPortal';
import { RicsSideMenuTab } from '../interfaces/RicsSideMenuTab';
import { useXhrService } from './XhrServiceProvider';
import ClutchSettingsCard from './ClutchSettingsCard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../constants/FeatureFlags';
import POSCustomerSettings from './POSCustomerSettings';
import POSReceiptSettings from './POSReceiptSettings';

// for accessing global objects from "old" code
var portal = window.portal as ApplicationPortal;

interface SettingsProps {
    selectedTab: RicsSideMenuTab;
    LocationId?: string;
    children?: React.ReactNode;
}

const SettingsGrid: React.FC<SettingsProps> = (props) => {
    const xhrService = useXhrService();
    const flags = useFlags();
    const [settings, setSettings] = React.useState(portal.State.saleSettings[props.LocationId ?? 'Tenant'] ? portal.State.saleSettings[props.LocationId ?? 'Tenant'] : portal.State.saleSettings['Tenant']);
    const [showGlobalAlert, setShowGlobalAlert] = React.useState<boolean>(true);
    const [ricsPayEnabled, setRicsPayEnabled] = React.useState(true);
    const [clutchEnabled, setClutchEnabled] = React.useState(false);
    const [receiptOptionsFeatureFlag, setReceiptOptionsFeatureFlag] = React.useState(false);
    const [standAloneReturnsFeatureFlag, setStandAloneReturnsFeatureFlag] = React.useState(false);
    const [enableStandAloneReturns, setEnableStandAloneReturns] = React.useState(portal.State.saleSettings[props.LocationId ?? 'Tenant'] ? portal.State.saleSettings[props.LocationId ?? 'Tenant'].enableStandAloneReturns : false);
    const settingsElementsDefault = [
        { id: "payment-processing", displayName: "Payment Processing" },
        { id: "payment-details", displayName: "Payment Details" },
        { id: "refunds", displayName: "Refunds" },
        { id: "pos-device-settings", displayName: "POS Device Settings" },
        { id: "customer-settings", displayName: "Customer Settings" },
        { id: "receipt-settings", displayName: "Receipt Settings" }
    ];
    const [settingsElements, setSettingsElements] = React.useState(settingsElementsDefault)

    const tenantAlertText = "These are global settings that will apply to all child locations. Please note that these settings can be overridden at the location level.";
    const locationAlertText = "These are location level settings. Once a setting is updated here, it will no longer inherit the settings from the global settings page.";

    useEffect(() => {
        if (props.LocationId) {
            var locationSettings = portal.State.saleSettings[props.LocationId] ? portal.State.saleSettings[props.LocationId] : portal.State.saleSettings['Tenant'];
            setSettings(locationSettings);
        }

        getRicsPayFeatureToggle();
        getClutchFeatureFlag();
        getReceiptOptionsFeatureFlag();
        getStandAloneReturnsFeatureFlag();
    }, [flags]);

    const getRicsPayFeatureToggle = () => {
        portal!.navigation.isLoading(true);

        let finish = function () {
            portal!.navigation.isLoading(false);
        };
        getRicsPayFeatureEnabledStatus(finish);
    };

    const getReceiptOptionsFeatureFlag = () => {
        var featureFlag = flags[FeatureFlags.ReceiptOptions];
        setReceiptOptionsFeatureFlag(featureFlag);
      };

    const getStandAloneReturnsFeatureFlag = () => {
        var featureFlag = flags[FeatureFlags.StandAloneReturns];
        setStandAloneReturnsFeatureFlag(featureFlag);
      };

    const getClutchFeatureFlag = () => {
        var clutchFF = flags[FeatureFlags.Clutch];
        var clutchPaymentEnabled = settings?.tenderTypeOptions?.find(x => x.tenderTypeOptionId == "GiftCard" && x.tenderTypeOptionSubTypeId == "Clutch")?.enabled ?? false;
        setClutchEnabled(clutchFF && clutchPaymentEnabled);
        setSettingsElements(props.LocationId && clutchFF && clutchPaymentEnabled ? [...settingsElementsDefault, { id: "clutch-settings", displayName: "Clutch Settings" }] : settingsElementsDefault);
    }

    const getRicsPayFeatureEnabledStatus = (onContiue: Function) => {
        let endpoint = "v1/Features/RicsPayForPortal";
        var onSuccess = function (response: string) {
            var feature = JSON.parse(response);
            setRicsPayEnabled(feature.isEnabled);
            onContiue();
        };
        var onFailure = function () {
            setRicsPayEnabled(false);
            onContiue();
        };
        xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
    };

    const onClutchSettingsUpdated = () => {
        getClutchFeatureFlag();
    };

    return (
        <>
            <IonContent
                id="settings-content"
                className={!!props.LocationId ? "settings-page" : "settings-page-tenant"}>
                <IonGrid id="settings-grid" className="settings-grid">
                    <IonRow hidden={!showGlobalAlert}>
                        <IonCol size="12" className="settings-alert-holder">
                            <div className="settings-alert-cover">
                                <IonItem className="settings-alert">
                                    <IonIcon src="assets/img/info.svg" className="icon-alert" />
                                    <IonText>{!!props.LocationId ? locationAlertText : tenantAlertText}</IonText>
                                    <IonIcon src={closeOutline} className="icon-alert-close" slot="end" onClick={() => setShowGlobalAlert(false)} />
                                </IonItem>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="4">
                            <Scrollspy elements={settingsElements}
                                itemContainerClassName="scrollSpyContainer"
                                activeItemClassName="active"
                                itemClassName="spyItemClass"
                                ionContentId="settings-content"
                                ionGridId="settings-grid" />
                        </IonCol>
                        <IonCol size="8">
                            <div id="main">
                                <section id="payment-processing">
                                    <div className="settings-grid-container">
                                        <PaymentProcessing locationSettings={settings} locationId={props.LocationId} ricsPayEnabled={ricsPayEnabled} />
                                    </div>
                                </section>
                                <section id="payment-details">
                                    <div className="settings-grid-container">
                                        <PaymentDetails locationSettings={settings} locationId={props.LocationId} onClutchEnabledChanged={() => onClutchSettingsUpdated()} />
                                    </div>
                                </section>
                                <section id="refunds">
                                    <div className="settings-grid-container">
                                        <Refunds locationSettings={settings} showStandAloneReturnsOption={standAloneReturnsFeatureFlag} locationId={props.LocationId} onEnableStandAloneReturnsChanged={setEnableStandAloneReturns} onClutchEnabledChanged={() => onClutchSettingsUpdated()} />
                                    </div>
                                </section>
                                <section id="pos-device-settings">
                                    <div className="settings-grid-container">
                                        <POSDeviceSettings locationSettings={settings} locationId={props.LocationId} showStandAloneReturnsOption={standAloneReturnsFeatureFlag} enableStandAloneReturns={enableStandAloneReturns} />
                                    </div>
                                </section>
                                <section id="customer-settings">
                                    <div className="settings-grid-container">
                                        <POSCustomerSettings locationSettings={settings} locationId={props.LocationId} />
                                    </div>
                                </section>
                                {

                                (receiptOptionsFeatureFlag) &&
                                <section id="receipt-settings">
                                    <div className="settings-grid-container">
                                        <POSReceiptSettings locationSettings={settings} locationId={props.LocationId} />
                                    </div>
                                </section>
                                }
                                {
                                    (props.LocationId && clutchEnabled) &&
                                    <section id="clutch-settings">
                                        <div className="settings-grid-container">
                                            <ClutchSettingsCard locationSettings={settings} locationId={props.LocationId} />
                                        </div>
                                    </section>
                                }
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>
    );
};

export default SettingsGrid;

import { IonContent, IonPage } from '@ionic/react';
import packageInfo from '../../package.json'
import React from 'react';
const About: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div>
            Version: {packageInfo.version}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default About;

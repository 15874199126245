import { IonButton, IonChip, IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { roleNames, roles } from "../../constants/Roles";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import { Location } from "../../interfaces/Location";
import { Identity, Rics9User, ScopedRole, User } from "../../interfaces/User";
import "../../theme/UserImport.css";
import { useDataService } from "../DataServiceProvider";
import { usePortal } from "../PortalProvider";
import { useXhrService } from "../XhrServiceProvider";
import UserImportRow from "./UserImportRow";

export interface UserImportProps {
  isOpen: boolean;
  closeModalWithoutSaving: Function;
  onImportSuccess: Function;
  setIsOpen: Function;
  locationId?: string;
}

const UserImport: React.FC<UserImportProps> = (props) => {
  const xhrService = useXhrService();
  const dataService = useDataService();
  const portal = usePortal();
  const flags = useFlags();
  const baseModalCss = "modal-card import-users-card";
  const tenantModalCss = "import-users-card-tenant";
  const locationModalCss = "import-users-card-location";
  const tenantOrLocationModalCss = !!props.locationId ? locationModalCss : tenantModalCss;
  const modalCss = `${baseModalCss} ${tenantOrLocationModalCss}`;
  const [allUsersSelected, setAllUsersSelected] = React.useState(false);
  const [users, setUsers] = React.useState(Array<User>());
  const [numberChecked, setNumberChecked] = React.useState(0);
  const [failures, setFailures] = React.useState(Array<User>());
  const [showFailureAlert, setShowFailureAlert] = React.useState(false);

  useEffect(() => {
    if (props.isOpen) {
      getUsersFromRics9();
    }
  }, [props.isOpen]);

  const updateAllChecks = () => {
    let isChecked = !allUsersSelected;
    let numberSelected = 0;
    let updatedUsers = users.map((user) => {
      if (user.Valid) {
        user.SelectedToImport = isChecked;
        numberSelected++;
      }
      return user;
    });
    setUsers(updatedUsers);
    setAllUsersSelected(isChecked);

    if (isChecked) {
      setNumberChecked(numberSelected);
    } else {
      setNumberChecked(0);
    }
  };

  const updateUser = (index: number, user: User) => {
    let currentUsers = [...users];
    currentUsers[index] = user;
    setUsers(currentUsers);
  };

  const updateSelected = (isSelected: boolean) => {
    if (isSelected) {
      setNumberChecked((current) => current + 1);
    } else {
      setNumberChecked((current) => current - 1);
    }
  };

  const importUsers = () => {
    portal!.navigation.isLoading(true);
    const version = getVersion(flags);

    let onFinalSuccess = function () {
      props.locationId ? dataService!.enqueueLocationForUpdate(props.locationId) : (portal!.State.shouldUpdateAllLocations = true);
      let successCallback = function () {
        props.onImportSuccess();
        portal!.navigation.isLoading(false);
      };

      setUsers(Array<User>());
      dataService!.refreshTenantIdentities(version, null, successCallback, successCallback);
    };

    createIdentities(onFinalSuccess);
  };

  const createIdentities = function (finalSuccess: Function) {
    let selectedUsers = Array<User>();
    for (let i = 0; i < users.length; i++) {
      if (users[i].SelectedToImport === true) {
        selectedUsers.push(users[i]);
      }
    }

    let count = -1;
    let failedIdentities = Array<User>();

    let onFailure = function () {
      setFailures(failedIdentities);
      setShowFailureAlert(true);
      portal!.navigation.isLoading(false);
    };

    let onNext = function () {
      count++;

      if (count >= selectedUsers.length) {
        if (failedIdentities.length > 0) {
          onFailure();
        } else {
          finalSuccess();
        }
        return;
      }

      let currentEmployee = selectedUsers[count];
      let failureWrapper = function (response: any) {
        response = JSON.parse(response);
        response = JSON.stringify(response["title"]);
        currentEmployee.ErrorString = response;
        failedIdentities.push(currentEmployee);

        onNext();
      };

      createIdentity(currentEmployee, onNext, failureWrapper);
    };

    onNext();
  };

  const createIdentity = function (userToCreate: User, resolve: Function, reject: Function) {
    let ownerRole = userToCreate.Roles.find((r) => r.Name === roleNames.Owner);
    let locationIds = Array<string>();
    userToCreate.Locations.forEach((locationId) => {
      if (locationId) {
        locationIds.push(locationId);
      }
    });

    let userRoles: Array<ScopedRole> = [];
    userToCreate.Roles.forEach((r) => {
      let scopedRole = {
        tenantId: portal!.State.tenantInfo.tenantId,
        identityRole: r.Name
      } as ScopedRole;
      if (ownerRole) {
        userRoles.push(scopedRole);
        return;
      }

      locationIds.forEach((l) => {
        scopedRole.locationId = l;
        userRoles.push(scopedRole);
      });
    });

    let identity: Identity = {
      identityId: userToCreate.UserId,
      emailAddress: userToCreate.Email,
      firstName: userToCreate.FirstName,
      lastName: userToCreate.LastName,
      phoneNumber: userToCreate.PhoneNumber,
      rics9UserId: userToCreate.RicsUserId,
      tenants: [portal!.State.tenantInfo.tenantId],
      roles: userRoles,
      isActivated: userToCreate.IsActivated,
      isVerified: userToCreate.IsVerified
    };

    const onSuccess = (newIdentity: Identity) => {
      sendWelcomeEmails(newIdentity.identityId as string, locationIds, resolve);
    };

    const onFailure = () => {
      reject();
    };

    const version = getVersion(flags);
    dataService.identities.saveIdentity(identity, true, version, onSuccess, onFailure);
  };

  const sendWelcomeEmails = function (identityId: string, locationIds: (string | undefined)[], onResolve: Function) {
    let count = -1;

    let onNext = () => {
      count++;

      if (count >= locationIds.length) {
        onResolve();
        return;
      }

      if (locationIds[count]) {
        let currentLocation = portal!.State.locations.find((x: Location) => x.locationId === locationIds[count]);

        if (currentLocation!.isActive) {
          sendWelcomeEmail(identityId, locationIds[count] as string, onNext);
        } else {
          onNext();
        }
      }
    };

    onNext();
  };

  const sendWelcomeEmail = function (identityId: string, locationId: string, resolve: Function) {
    const endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    let redirectUri = `${baseUrl}/password/`;

    let payload = {
      identityId: identityId,
      tenantId: portal!.State.tenantInfo.tenantId,
      locationId: locationId,
      redirectUri: redirectUri
    };

    xhrService!.DoRicsApiXhr(endpoint, payload, resolve, resolve, "PUT");
  };

  const getUsersFromRics9 = () => {
    portal!.navigation.isLoading(true);
    let onSuccess = function () {
      function mapUser(user: Rics9User) {
        let hasLocationId = !!props.locationId;

        let mappedUser: User = {
          UserId: "",
          Email: user.Email,
          FirstName: user.FirstName,
          LastName: user.LastName,
          Login: user.Login,
          PhoneNumber: user.PhoneNumber,
          RicsUserId: user.RicsUserId,
          RicsXIdentityId: user.RicsXIdentityId ?? "",
          LocationLabel: "",
          Locations: hasLocationId ? [props.locationId!] : [],
          Roles: roles.filter((r) => r.Name === "Salesperson"),
          SelectedToImport: false,
          SelectedToEdit: false,
          ErrorString: "",
          Valid: false,
          IsActivated: false,
          IsVerified: false,
          IsSelected: false
        };
        return mappedUser;
      }

      let rics9users: User[] = [];

      for (let i = 0; i < portal!.State.allImportableUsers.length; i++) {
        let employee = portal!.State.allImportableUsers[i];
        if (employee.RicsXIdentityId && employee.RicsXIdentityId.length > 1) {
          continue;
        }

        rics9users.push(mapUser(employee));
      }

      setUsers(rics9users);
      setNumberChecked(0);
      portal!.navigation.isLoading(false);
    };

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong retrieving your RICS9 users. Please contact support.");
    };

    prepareRics9Data(onSuccess, onFailure);
  };

  const prepareRics9Data = function (resolve: any, reject: any) {
    let resolveAfterRics9Token = function () {
      getRics9Users(resolve, reject);
    };

    getRics9Token(resolveAfterRics9Token, reject);
  };

  const getRics9Token = function (resolve: any, reject: any) {
    if (portal!.State.rics9Token) {
      resolve();
      return;
    }

    let endpoint = "v2/Rics9/ConfigurationToken";

    let onSuccess = function (response: any) {
      response = JSON.parse(response);
      portal!.State.rics9Token = response.rics9Token;
      resolve();
    };

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      resolve();
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  };

  const getRics9Users = function (resolve: any, reject: any) {
    let payload = {};

    let onSuccess = function (response: any) {
      response = JSON.parse(response);
      portal!.State.allImportableUsers = response.Users;
      resolve();
    };

    let onFailure = function () {
      resolve();
    };

    xhrService!.DoRics9Xhr(portal!.State.rics9Token, "Manufacturer/GetUsersToImport", payload, onSuccess, onFailure);
  };

  return (
    <>
      <IonModal isOpen={props.isOpen} onDidDismiss={() => props.setIsOpen(false)} className={modalCss}>
        <div>
          <IonIcon
            className="ion-float-right user-close-button"
            src={closeOutline}
            onClick={() => props.closeModalWithoutSaving()}
            data-testid="user-import-close-icon"
          />
          <div>
            <div className="ion-text-center import-user-heading">
              <h2>Import Users</h2>
              <div>
                Select the users that you wish to import into Rics Retail. In order to access the point-of-sale system, users must have a valid email
                address added to their record.
              </div>
            </div>
          </div>
          <div className="user-grid-padding">
            <table className="user-header-table">
              <tbody>
                <tr className="user-lineItem">
                  <th className="user-selected">
                    <div className="checkboxHolder">
                      <label className="checkbox checkmarkEnabled">
                        <input type="checkbox" checked={allUsersSelected} onChange={() => updateAllChecks()} data-testid="user-import-select-all" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </th>
                  <th className="user-header first-name">First Name</th>
                  <th className="user-header last-name">Last Name</th>
                  <th className="user-header email">Email</th>
                  <th className="user-header role">Role</th>
                  {!props.locationId && <th className="user-header locations">Location(s)</th>}
                </tr>
              </tbody>
            </table>
            <IonContent className="user-import-grid">
              <table>
                <tbody>
                  {users.map((user, index) => {
                    return (
                      <UserImportRow
                        key={index}
                        user={user}
                        index={index}
                        onUpdateUser={updateUser}
                        onUpdateSelected={updateSelected}
                        shouldValidateUser={allUsersSelected}
                        locationId={props.locationId}
                        allUsersIsChecked={allUsersSelected}
                      />
                    );
                  })}
                </tbody>
              </table>
            </IonContent>
          </div>
          <div className="ion-float-left selected-employees">
            <IonChip slot="left" className="chip-no-click ion-justify-content-center" data-testid="user-import-selected-chip">
              <IonLabel>{numberChecked}</IonLabel>
            </IonChip>
            <IonText color="primary" slot="left">
              Selected Employees
            </IonText>
          </div>
          <IonButton
            disabled={!(numberChecked > 0)}
            onClick={() => importUsers()}
            className="ion-float-right import-users-button"
            data-testid="user-import-button">
            <IonText>Import Users</IonText>
          </IonButton>
        </div>
      </IonModal>
      <IonPopover className="user-popover failure-popover" isOpen={showFailureAlert} onDidDismiss={() => setShowFailureAlert(false)}>
        <div>
          <IonText>
            <h2>Failed to add users</h2>
          </IonText>
          <IonText>The following users were unable to be added:</IonText>
        </div>
        <IonList class="failures-list">
          {failures.map((user, index) => {
            return (
              <>
                <IonItem lines="none" key={index}>
                  <IonText>
                    {user.FirstName} {user.LastName}
                  </IonText>
                  <div></div>
                </IonItem>
                <IonItem>
                  <IonText>Error: {user.ErrorString}</IonText>
                </IonItem>
              </>
            );
          })}
        </IonList>
        <IonButton color="primary" className="ion-float-right" onClick={() => setShowFailureAlert(false)}>
          OK
        </IonButton>
      </IonPopover>
    </>
  );
};

export default UserImport;

import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { isEmpty } from "../../../../helpers/stringHelpers";
import { ECommerceVendor } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import "../../../../theme/integrations/ecommerce/ECommerceAccountSetupWizard.css";

export interface ECommerceAccountSetupModalPropsBase {
  show: boolean;
  logoUrl: string;
  vendor: ECommerceVendor;
  onNext?: Function;
  onClose?: Function;
  onGoBack?: Function;
  overrideClassName?: string;
}

export interface ECommerceAccountSetupModalProps extends ECommerceAccountSetupModalPropsBase {
  headerTitle: string;
  headerSubTitle?: string;
  hideCloseBtn?: boolean;
  onHide?: Function;
  hideFooter?: boolean;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideGoBackButton?: boolean;
  overrideNextButtonText?: string;
  children?: React.ReactNode;
}

export const getClassName = (suffix?: string, overrideClassName?: string) => {
  let ending = `${!isEmpty(suffix) ? "-" : ""}${!isEmpty(suffix) ? suffix : ""}`;
  let baseClassName = `ecommerce-account-setup-modal${ending}`;
  let additionalClassName = !isEmpty(overrideClassName) ? `${overrideClassName}${ending}` : "";

  let className = !isEmpty(additionalClassName) ? `${additionalClassName} ${baseClassName}` : baseClassName;

  return className;
};

const ECommerceAccountSetupModal: React.FC<ECommerceAccountSetupModalProps> = (props) => {
  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  return (
    <>
      <Modal className={getClass()} show={props.show} onHide={() => props.onHide && props.onHide()} size="lg" centered>
        <Modal.Header className={getClass("header")}>
          <div className={getClass("header-top-row")}>
            <Image className={getClass("header-logo")} src={props.logoUrl} />
            <Button className={getClass("header-close-btn")} hidden={props.hideCloseBtn} onClick={() => props.onClose && props.onClose()}>
              <Image src="assets/img/close.svg" className={getClass("header-close-btn-img")} />
            </Button>
          </div>
          <div className={getClass("header-title")}>{props.headerTitle}</div>
          <div className={getClass("header-sub-title")}>{props.headerSubTitle}</div>
        </Modal.Header>
        <Modal.Body className={getClass("body")}>
          <div className={getClass("body-container")}>{props.children}</div>
        </Modal.Body>
        <Modal.Footer className={getClass("footer")} hidden={props.hideFooter}>
          <Button
            className="button"
            hidden={props.hideGoBackButton}
            onClick={() => {
              props.onGoBack && props.onGoBack();
            }}>
            Go Back
          </Button>
          <Button
            className="next-button button"
            onClick={() => {
              props.onNext && props.onNext();
            }}
            disabled={props.disableNextButton}>
            <div className="next-button-text">{props.overrideNextButtonText ? props.overrideNextButtonText : "Next"}</div>
            <Image className="next-button-image" src="assets/img/arrow-right.svg" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ECommerceAccountSetupModal;

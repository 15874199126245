import React, { useState } from "react";
import "../theme/Dropdown.css";

export type DropdownSelectValue = {
  id: string;
  displayValue: string;
  disabled?: boolean;
};

type DropdownProps = {
  placeholder: string;
  values: Array<DropdownSelectValue>;
  onSelect: (value: string) => void;
  selectedValue?: string;
  className?: string;
  disabled?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({ placeholder, values, className, onSelect, disabled, selectedValue }) => {
  const [showContent, setShowContent] = useState(false);

  const onDropdownClick = () => {
    if (disabled) {
      return;
    }

    setShowContent(!showContent);
  };

  const onDismiss = () => {
    setShowContent(false);
  };

  return (
    <>
      <div className={`dropdown${className ? ` ${className}` : ""}${disabled ? " disabled" : ""}`} onClick={onDropdownClick}>
        <div className="dropdown-inner-content">
          <p className="dropdown-placeholder" hidden={!!selectedValue}>
            {placeholder}
          </p>
          <p className="dropdown-selected-value" hidden={selectedValue === undefined}>
            {selectedValue}
          </p>
          <img src="assets/img/caret-down.svg" />
        </div>
        <div className="dropdown-content" hidden={!showContent}>
          {values.map((v) => {
            return (
              <a key={v.id} onClick={() => !v.disabled && onSelect(v.id)} className={v.disabled ? "disabled" : ""}>
                {v.displayValue}
              </a>
            );
          })}
        </div>
      </div>
      <div className="dropdown-overlay" hidden={!showContent} onClick={onDismiss} />
    </>
  );
};

export default Dropdown;

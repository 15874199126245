import React from 'react';
import { IonItem, IonLabel, IonCol, IonRow } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

import { Location } from '../../interfaces/Location';
import LocationStatus from './LocationStatus';
import LocationOwner from './LocationOwner';
import LocationLogo from './LocationLogo';
import { Link } from 'react-router-dom';
import { Paths } from '../../constants/Paths';

interface LocationGridRowProps {
    location: Location;
    checkAllLocations: Function;
    setShowModal: Function;
}

const LocationGridRow: React.FC<LocationGridRowProps> = ({ location, setShowModal }) => {
    const formatAddress = () => {
        let line1 = location.contact?.address?.line1 ? location.contact?.address?.line1 : "";
        let line2 = location.contact?.address?.line2 ? location.contact?.address?.line2 : "";
        return line1 + " " + line2;
    }

    return (
        <>
            <IonRow key={location.locationId} class="location-grid-row" hidden={location.hidden}>
                <IonCol size="1">
                    <LocationLogo Location={location}></LocationLogo>
                </IonCol>
                <IonCol size="3">
                    <Link to={Paths.LocationDetails + "/" + location.locationId}>{location.locationId}</Link>
                </IonCol>
                <IonCol size="2">
                    {formatAddress()}
                </IonCol>
                <IonCol size="1">
                    {location.contact?.address?.city}
                </IonCol>
                <IonCol size="1">
                    {location.contact?.address?.state}
                </IonCol>
                <IonCol size="1">
                    {location.contact?.address?.zipCode}
                </IonCol>
                <IonCol size="1">
                    <LocationOwner Location={location}></LocationOwner>
                </IonCol>
                <IonCol size="2">
                    {location.isActive &&
                        <IonItem lines="none" className={"location-status-item active"}>
                            <IonLabel class="location-status-label">Active</IonLabel>
                        </IonItem>
                    }
                    {!location.isActive &&
                        <LocationStatus Location={location} clickToBegin={false} setShowModal={setShowModal}></LocationStatus>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default LocationGridRow;
import { ConfigurationsWrapper } from "./ApplicationPortal";

export interface Configuration {
  LocationId: string;
  ConfigurationValue: string;
}

export const showWelcomeModal = (configurations: ConfigurationsWrapper) => {
  var workflowComplete = false;
  if (configurations.configurations && configurations.configurations[configurations.SetupWizardComplete]) {
    for (let i = 0; i < configurations.configurations[configurations.SetupWizardComplete].length; i++) {
      var tempPaymentConfiguration = configurations.configurations[configurations.SetupWizardComplete][i];
      if (!tempPaymentConfiguration.LocationId) {
        workflowComplete = tempPaymentConfiguration.ConfigurationValue === "true";
      }
    }
  }

  return !workflowComplete;
};

import {
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonIcon,
  IonPopover,
  IonFabButton,
  IonButton,
  IonAlert,
} from "@ionic/react";

import React, { useState, SyntheticEvent, useEffect } from "react";

import { User, Identity } from "../../interfaces/User";

import "../../theme/UsersGrid.css";
import UserCard from "./UserCard";
import {
  saveOutline,
  trashBinOutline,
  mailOutline
} from "ionicons/icons";
import UserGridRow from "./UserGridRow";
import { usePortal } from "../PortalProvider";
import { useXhrService } from "../XhrServiceProvider";

interface UsersGridProps {
  users: Array<User>;
  setUsers?: Function;
  updateSingleUser?: Function;
  updateAllSelectedUsers?: Function;
  checkedUsers: Map<string, boolean>;
  setCheckedUsers: Function;
  checkAllUsers: Function;
  locationId?: string;
  cssClass: string;
  isCardMode: boolean;
  deleteUserIsEnabled: boolean;
  numberSelected?: number;
}

const UsersGrid: React.FC<UsersGridProps> = (props) => {
  const portal = usePortal();
  const xhrService = useXhrService();

  const [clickedUser, setClickedUser] = useState<User>();
  const [clickedUserIdentity, setClickedUserIdentity] = useState<Identity>();
  const [allUsersIsChecked, setAllUsersIsChecked] = useState(false);

  const [showUserActionsPopover, setShowUserActionsPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [showAllUsersActionsPopover, setShowAllUsersActionsPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openUserActionsPopover = (
    user: User | undefined,
    event: SyntheticEvent
  ) => {
    setClickedUser(user);
    setShowUserActionsPopover({ open: true, event: event.nativeEvent });
    setIsLoading(!isLoading);
  };

  const updateUser = (index: number, user: User) => {
    var currentUsers = [...props.users];
    currentUsers[index] = user;
    if (!!props.setUsers) {
      props.setUsers(currentUsers);
    }
  };

  const updateSelectedUser = () => {
    setShowUserActionsPopover({ open: false, event: undefined });

    if (clickedUser && !!props.updateSingleUser) {
      props.updateSingleUser(clickedUser);
    }
  };

  const deleteSelectedUser = () => {
    setShowUserActionsPopover({ open: false, event: undefined });

    if (clickedUser && !!props.updateSingleUser) {
      clickedUser.DeletedOn = new Date();
      props.updateSingleUser(clickedUser);
    }
  };

  const clickUpdateAllSelectedUsers = () => {
    setShowAllUsersActionsPopover({ open: false, event: undefined });
    if (!!props.updateAllSelectedUsers) {
      props.updateAllSelectedUsers();
    }
  };

  const sendWelcomeEmail = function (identityId: string, locationId: string) {
    setShowUserActionsPopover({ open: false, event: undefined });
    const endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    let redirectUri = `${baseUrl}/password/`;

    let payload = {
      identityId: identityId,
      tenantId: portal!.State.tenantInfo.tenantId,
      locationId: locationId,
      redirectUri: redirectUri,
    };
    var onSuccess = function () {
      alert('Please check the provided email address to finish account setup.')
    };

    var onFailure = function () {
      alert("Something went wrong while trying to send the activation email. Please try again.");
    };

    xhrService!.DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");

  };

  useEffect(() => {
    setClickedUserIdentity(
      portal!.State.tenantIdentities?.find(
        (x) => x.identityId === clickedUser?.UserId
      )
    );
  }, [clickedUser, clickedUserIdentity, showUserActionsPopover, portal]);

  return (
    <>
      {!props.isCardMode && (
        <IonGrid class={`users-grid ${props.cssClass}`} fixed={true}>
          <IonRow class="user-grid-header">
            <IonCol size="1">
              <IonCheckbox checked={allUsersIsChecked} onIonChange={(e) => setAllUsersIsChecked(e.detail.checked)} />
            </IonCol>
            <IonCol size="1">First Name</IonCol>
            <IonCol size="2">Last Name</IonCol>
            <IonCol size="3">Email</IonCol>
            <IonCol size="2">Phone Number</IonCol>
            <IonCol size="2">Location(s)</IonCol>
            <IonCol size="2">Role(s)</IonCol>
            <IonCol size="1">
              <IonFabButton
                className="user-actions-button"
                onClick={(e) =>
                  setShowAllUsersActionsPopover({
                    open: true,
                    event: e.nativeEvent,
                  })
                }
              >
                <IonIcon
                  className="user-actions-icon"
                  src="assets/img/settings.svg"
                />
              </IonFabButton>
            </IonCol>
          </IonRow>
          <IonPopover
            className="device-actions-popover"
            isOpen={showAllUsersActionsPopover.open}
            event={showAllUsersActionsPopover.event}
            showBackdrop={false}
            onDidDismiss={() =>
              setShowAllUsersActionsPopover({ open: false, event: undefined })
            }
          >
            <IonButton
              fill="clear"
              expand="full"
              className="device-actions-popover-buttons"
              onClick={() => clickUpdateAllSelectedUsers()}
              disabled={props.numberSelected === 0}
            >
              <span className="ion-button-inner-contents">
                <IonIcon
                  md={saveOutline}
                  mode="md"
                  className="button-left-icon device-menu-button-icon"
                />
                Update Selected Users
              </span>
            </IonButton>
          </IonPopover>

          {props.users.map((user, index) => {
            return (
              <UserGridRow
                key={index}
                user={user}
                checked={props.checkedUsers.get(user.UserId) ?? false}
                index={index}
                onUpdateUser={updateUser}
                openUserActionsPopover={openUserActionsPopover}
                allUsersIsChecked={allUsersIsChecked}
              />
            );
          })}

          {/* User Actions Popover */}
          <IonPopover
            className="device-actions-popover"
            isOpen={showUserActionsPopover.open}
            event={showUserActionsPopover.event}
            showBackdrop={false}
            onDidDismiss={() =>
              setShowUserActionsPopover({ open: false, event: undefined })
            }
          >
            <IonButton
              fill="clear"
              expand="full"
              className="device-actions-popover-buttons"
              disabled={!clickedUser?.Valid}
              onClick={() => updateSelectedUser()}
            >
              <span className="ion-button-inner-contents">
                <IonIcon
                  md={saveOutline}
                  mode="md"
                  className="button-left-icon device-menu-button-icon"
                />
                Update User
              </span>
            </IonButton>
            {!clickedUserIdentity?.isVerified && (
              <IonButton
                fill="clear"
                expand="full"
                className="device-actions-popover-buttons"
                disabled={!clickedUser?.Valid}
                onClick={() =>
                  sendWelcomeEmail(clickedUser!.UserId, props.locationId!)
                }
              >
                <span className="ion-button-inner-contents">
                  <IonIcon
                    md={mailOutline}
                    mode="md"
                    className="button-left-icon device-menu-button-icon"
                  />
                  Resend Verification Email
                </span>
              </IonButton>
            )}
            {props.deleteUserIsEnabled && (
              <>
                <IonButton
                  fill="clear"
                  expand="full"
                  className="device-actions-popover-buttons divider-button"
                ></IonButton>
                <IonButton
                  fill="clear"
                  expand="full"
                  className="device-actions-popover-buttons"
                  onClick={() => setShowAlert(true)}
                >
                  <span className="ion-button-inner-contents">
                    <IonIcon
                      md={trashBinOutline}
                      mode="md"
                      className="button-left-icon device-menu-button-icon"
                    />
                    Delete User
                  </span>
                </IonButton>
                <IonAlert
                  cssClass="delete-user-alert"
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header="Confirm"
                  subHeader="Delete user?"
                  buttons={[
                    {
                      text: "No",
                      role: "cancel",
                      handler: () => {
                        setShowAlert(false);
                      },
                    },
                    {
                      text: "Yes",
                      role: "confirm",
                      handler: () => {
                        deleteSelectedUser();
                      },
                    },
                  ]}
                />
              </>
            )}
          </IonPopover>
        </IonGrid>
      )}
      {props.isCardMode && (
        <div id="usersGrid">
          <div className="user-cards-content">
            {props.users &&
              props.users.map((user, i) => (
                <UserCard
                  key={i}
                  User={user}
                  deleteUserIsEnabled={props.deleteUserIsEnabled}
                ></UserCard>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UsersGrid;

import React from 'react';

import { Location } from '../../interfaces/Location';
import { usePortal } from '../PortalProvider';
import LocationStatus from './LocationStatus';
import LocationOwner from './LocationOwner';
import LocationLogo from './LocationLogo';
import { useHistory } from 'react-router';
import { Paths } from '../../constants/Paths';

interface LocationCardProps {
    Location: Location;
    setShowModal: Function;
}

const LocationCard: React.FC<LocationCardProps> = (props) => {
    let history = useHistory();
    return (
        <div className="gridPanel" onClick={() => history.push(Paths.LocationDetails + "/" + props.Location.locationId)} >
            <div className="content">
                <LocationLogo Location={props.Location}></LocationLogo>
                <div className="heading">{props.Location.name}</div>
                <div className="subHeading"></div>
                <div className="subHeading"></div>
                <LocationOwner Location={props.Location}></LocationOwner>
                <LocationStatus Location={props.Location} clickToBegin={true} setShowModal={props.setShowModal}></LocationStatus>
            </div>
        </div>
    );
};

export default LocationCard;

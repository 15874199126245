import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonModal, IonRow, IonText } from "@ionic/react";
import React from "react";

/* Theme variables */
import "../../theme/variables.css";

import { MerchantSettings, MerchantTypes, MerchantTypesLabels } from "../../interfaces/salesettings/MerchantSettings";
import { SaleSetting } from "../../interfaces/salesettings/SaleSettings";
import "../../theme/PaymentWorkflowCard.css";
import { useDataService } from "../DataServiceProvider";
import { usePortal } from "../PortalProvider";
import WorkflowCard from "./WorkflowCard";

interface WorkflowCardProps {
  isComplete: boolean;
  setComplete: Function;
  isRicsPayEnabled: boolean;
  reentryPointSetup: Function;
}

const PaymentWorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  const portal = usePortal();
  let dataService = useDataService();
  const defaultModalCssClass = "modal-card payment-card";
  const merchantIdEntryCssClass = "modal-card payment-card-merchant-id";
  const multiAccountEntryCssClass = "modal-card payment-card-multi-account";

  const [showModal, setShowModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(0);
  const [merchantId, setMerchantId] = React.useState<string | null | undefined>();
  const [modalCssClass, setModalCssClass] = React.useState(defaultModalCssClass);

  const setTenantAccount = () => {
    setModalCssClass(merchantIdEntryCssClass);
    setModalView(2);
  };

  const saveSettings = (merchantType: MerchantTypes) => {
    portal!.navigation.isLoading(true);

    let salesSettings = {} as SaleSetting;

    salesSettings = portal!.State.saleSettings["Tenant"];

    let settingsToSave: MerchantSettings = {
      merchantType: merchantType,
      merchantId: merchantId,
      locationsCanOverride: false
    };

    if (!salesSettings.merchantSettings) {
      salesSettings.merchantSettings = settingsToSave;
    } else {
      Object.assign(salesSettings.merchantSettings, settingsToSave);
    }

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    let onSuccess = function () {
      portal!.navigation.isLoading(true);

      let onSuccess2 = function () {
        portal!.navigation.isLoading(false);
        completeSetup();
      };

      dataService!.getSaleSettings(onSuccess2, onFailure);
    };

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);
  };

  const selectMultipleAccounts = () => {
    if (props.isRicsPayEnabled) {
      setModalCssClass(multiAccountEntryCssClass);
      setModalView(3);
    } else {
      completeSetup();
    }
  };

  const dismissModal = () => {
    setModalView(0);
    setModalCssClass(defaultModalCssClass);
    setShowModal(false);
  };

  const completeSetup = () => {
    props.setComplete();
    dismissModal();
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <WorkflowCard
      title="Set Up Credit Card Processing"
      text="Specify how you would like to process credit card payments."
      icon="assets/img/register.svg"
      isComplete={props.isComplete}
      onButtonClick={openModal}>
      <IonModal onDidDismiss={() => dismissModal()} isOpen={showModal} className={modalCssClass}>
        <IonGrid hidden={modalView !== 0}>
          <IonRow>
            <IonCol>
              <IonText>
                <h2 className="payment-heading">Credit Card Processing</h2>
                <div className="payment-sub-heading">To get started, how will you be processing credit cards?</div>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonButton className="modal-button" onClick={() => setModalView(1)}>
                <IonGrid>
                  <IonRow>
                    <IonCol className="rics-pay-column">
                      <IonIcon src="assets/img/rics-pay-logo.svg" className="rics-pay-icon" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div className="modal-button-heading">Integrated Payment Processing</div>
                        <div className="modal-button-text">
                          For a fully optimized and integrated experience, credit card transactions are captured and processed via{" "}
                          {MerchantTypesLabels.get(MerchantTypes.RicsPay)}&#8482;.
                        </div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton className="modal-button" onClick={() => saveSettings(MerchantTypes.Manual)}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonIcon src="assets/img/manualpaymentprocessing.svg" className="manual-icon" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div className="modal-button-heading">Manual Payment Processing</div>
                        <div className="modal-button-text">
                          Credit card transactions are captured manually using any unintegrated processor. Processing is supported by a third party.
                        </div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid hidden={modalView !== 1}>
          <IonRow>
            <IonCol>
              <IonText>
                <h2 className="payment-heading">Multi-Location Payment Setup</h2>
                <div className="payment-sub-heading">
                  It looks like you manage multiple locations. Where would you like to collect revenue? Choose from the options below. Don't worry,
                  you can change this later.
                </div>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonButton className="modal-button" onClick={() => setTenantAccount()}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonIcon src="assets/img/bank-account.svg" className="bank-icon" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div className="modal-button-heading">One Shared Account</div>
                        <div className="modal-button-text">
                          Sales revenue from all locations will go into a single, shared {MerchantTypesLabels.get(MerchantTypes.RicsPay)}&#8482;
                          account.
                        </div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton className="modal-button" onClick={() => selectMultipleAccounts()}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonIcon src="assets/img/bank-account.svg" className="bank-icon" />
                      <IonIcon src="assets/img/bank-account.svg" className="bank-icon" />
                      <IonIcon src="assets/img/bank-account.svg" className="bank-icon" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div className="modal-button-heading">Unique Accounts for Each Location</div>
                        <div className="modal-button-text">
                          Sales revenue will go into unique {MerchantTypesLabels.get(MerchantTypes.RicsPay)}&#8482; accounts created for each
                          location.
                        </div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid hidden={modalView !== 2}>
          <IonRow className="merchant-id-header">
            <IonCol>
              <h2>Enter Merchant ID</h2>
            </IonCol>
          </IonRow>
          <IonRow className="merchant-id-input">
            <IonCol>
              <IonInput onIonChange={(e) => setMerchantId(e.detail.value)} />
            </IonCol>
          </IonRow>
          <IonRow className="merchant-id-text">
            <IonCol>
              <IonText>Don't have a Merchant ID? This can be added later.</IonText>
            </IonCol>
          </IonRow>
          <IonRow className="merchant-id-button">
            <IonCol>
              <IonButton className="add-later ion-float-left" onClick={() => completeSetup()}>
                Add Later
              </IonButton>
              <IonButton className="save ion-float-right" disabled={!merchantId} onClick={() => saveSettings(MerchantTypes.RicsPay)}>
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid hidden={modalView !== 3}>
          <IonRow className="multi-account-text">
            <IonCol>
              <h2>Navigate to the Location level settings pages to enter the MerchantIDs for each location.</h2>
            </IonCol>
          </IonRow>
          <IonRow className="multi-account-button">
            <IonCol>
              <IonButton onClick={() => completeSetup()}>Ok</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </WorkflowCard>
  );
};

export default PaymentWorkflowCard;

import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { AmazonConstants } from "../../../../../constants/ECommerceConstants";
import { CreateAmazonAccount } from "../../../../../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import { ECommerceLocation } from "../../../../../interfaces/integrations/ecommerce/ECommerceLocation";
import { usePortal } from "../../../../PortalProvider";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase
} from "../../accountSetup/ECommerceAccountSetupModal";

interface AmazonFbaLocationModalProps extends ECommerceAccountSetupModalPropsBase {}

const AmazonFbaLocationModal: React.FC<AmazonFbaLocationModalProps> = (props) => {
  const portal = usePortal();

  const [locations, setLocations] = useState(
    (portal &&
      portal.State.locations.map((l) => {
        let location: ECommerceLocation = {
          id: l.originalLocationId ?? l.locationId,
          name: l.name,
          isInventoryLocation: false,
          isSaleLocation: false
        };

        return location;
      })) ||
      new Array<ECommerceLocation>()
  );
  const [footerButtonDisabled, setFooterButtonDisabled] = useState(true);

  const onClick = (location: ECommerceLocation) => {
    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    tempAccount.fbaLocationIds = new Array<string>(location.id);
    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(tempAccount));

    setFooterButtonDisabled(false);
  };

  const onGoBack = () => {
    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    tempAccount.fbaLocationIds = undefined;
    tempAccount.isFba = false;
    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(tempAccount));

    setFooterButtonDisabled(true);
    if (props.onGoBack) props.onGoBack();
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "FBA Locations",
    headerSubTitle: "Select the location where orders will be recorded",
    overrideNextButtonText: "Finish",
    disableNextButton: footerButtonDisabled,
    onGoBack: onGoBack
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <ListGroup className="locations-list-group">
          {locations.map((l) => {
            return (
              <ListGroup.Item key={l.id} action className="locations-list-group-item" onClick={() => onClick(l)}>
                {l.name}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default AmazonFbaLocationModal;

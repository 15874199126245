import { APIVersions } from "../constants/APIVersions";
import { IAuthService } from "../interfaces/services/IAuthService";
import { Identity } from "../interfaces/User";
import { DoRicsApiXhr } from "./XhrService";

const getIdentityByVerificationToken = (verificationToken: string, version: string): Promise<Identity> => {
  return new Promise((resolve, reject) => {
    if (version == APIVersions.TwoDotOne) {
      getIdentityEntityByVerificationToken(verificationToken, version, resolve, reject);
      return;
    }

    getIdentityDocumentByVerificationToken(verificationToken, version, resolve, reject);
  });
};

const getIdentityDocumentByVerificationToken = (
  verificationToken: string,
  version: string,
  resolve: (identity: Identity) => void,
  reject: (reason?: any) => void
) => {
  let endpoint = `v${version}/Auth/GetIdentityByVerificationToken`;
  let payload = {
    VerificationToken: verificationToken
  };

  const onSuccess = (response: string) => {
    let identityDocument = JSON.parse(response);
    let identity = {
      identityId: identityDocument.IdentityId,
      firstName: identityDocument.FirstName,
      lastName: identityDocument.LastName,
      emailAddress: identityDocument.EmailAddress,
      phoneNumber: identityDocument.PhoneNumber
    } as Identity;
    resolve(identity);
  };

  const onFailure = () => {
    let reason = `Failed to get identity with verification token ${verificationToken}`;
    console.error(reason);
    reject(reason);
  };

  DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
};

const getIdentityEntityByVerificationToken = (
  verificationToken: string,
  version: string,
  resolve: (identity: Identity) => void,
  reject: (reason?: any) => void
) => {
  let endpoint = `v${version}/Auth/GetIdentityByVerificationToken?verificationToken=${verificationToken}`;

  const onSuccess = (response: string) => {
    let identity: Identity = JSON.parse(response);
    resolve(identity);
  };

  const onFailure = () => {
    let reason = `Failed to get identity with verification token ${verificationToken}`;
    console.error(reason);
    reject(reason);
  };

  DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
};

export const AuthService: IAuthService = {
  getIdentityByVerificationToken: getIdentityByVerificationToken
};

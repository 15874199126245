import { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonIcon, IonButton, IonPopover, IonFabButton, IonItem, IonModal, IonContent, IonToolbar, IonHeader, IonTitle, IonList } from '@ionic/react';

import React, { useState, SyntheticEvent, useEffect } from 'react';

import { Device } from "../interfaces/Device";

import '../theme/DevicesGrid.css';
import { trash, checkmark, close } from 'ionicons/icons';
import { DeviceLocation } from '../interfaces/DeviceLocation';
import { RicsSideMenuTab } from '../interfaces/RicsSideMenuTab';
import { useXhrService } from './XhrServiceProvider';
import { usePortal } from './PortalProvider';

interface DevicesGridProps {
  selectedTab: RicsSideMenuTab;
  setDevicesCount: Function;
  getDevices: Function;
  locationId?: string;
  cssClass: string;
}

const DevicesGrid: React.FC<DevicesGridProps> = ({ setDevicesCount, getDevices, locationId, cssClass, selectedTab }) => {
  const portal = usePortal();
  const xhrService = useXhrService();
  portal!.State.tenantDevices = portal!.State.tenantDevices || [];
  portal!.State.locationDevices = portal!.State.locationDevices || [];
  var tenantDevicesCache = portal!.State.tenantDevices[portal!.State.tenantInfo.tenantId];
  var locationDevicesCache = portal!.State.locationDevices;

  const apiVersion = "v1";
  const controllerName = "Devices";
  const controllerBaseUrl = `${apiVersion}/${controllerName}`;

  const isTenantLevel = !locationId;

  const [devices, setDevices] = useState<Array<Device>>([]);
  const [clickedDevice, setClickedDevice] = useState<Device>();
  const [checkedDevices, setCheckedDevices] = useState<Map<string, boolean>>(new Map());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceActionsPopover, setShowDeviceActionsPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  useEffect(() => {
    getDevices(setListState);
  }, []);

  const setListState = (devices: Array<Device>) => {
    setDevices(devices);
    setCheckedDevices(new Map(devices.map(d => [d.id, false])));
  }

  const hasOnlyOneLocation = (device: Device) => {
    return device.deviceLocations.length === 1;
  }

  const checkDevice = (elementClassList: DOMTokenList, id: string) => {
    // If the user is just trying to select text in the row, like for copy and paste, don't check the row
    if (!window.getSelection()?.toString()) {
      // If the user is clicking the actions button for the row, don't check the row
      if (!(elementClassList.contains("device-actions-button") || elementClassList.contains("device-actions-icon"))) {
        let updatedCheckedDevices = new Map(checkedDevices);
        updatedCheckedDevices.set(id, !checkedDevices.get(id));
        setCheckedDevices(updatedCheckedDevices);
      }
    }
  }

  const checkAllDevices = (checked: boolean) => {
    let updatedCheckedDevices = new Map(checkedDevices);
    Array.from(updatedCheckedDevices.keys()).forEach((key) => {
      updatedCheckedDevices.set(key, checked);
    });
    setCheckedDevices(updatedCheckedDevices);
  }

  const openDeviceActionsPopover = (device: Device | undefined, event: SyntheticEvent) => {
    setClickedDevice(device);
    setShowDeviceActionsPopover({ open: true, event: event.nativeEvent });
  }

  const resetPopover = () => {
    setShowDeviceActionsPopover({ open: false, event: undefined });
    setClickedDevice(undefined);
  }

  // updates component state and caches for a single devce
  const updateDeviceFromServer = (updatedDevice: Device, wasDeleted: boolean = false) => {
    updateLocalStateDevice(updatedDevice, wasDeleted);
    updateCachedDevice(updatedDevice, wasDeleted);
  }

  // updates component state and caches for multiple devices
  const updateDevicesFromServer = (updatedDevices: Array<Device>, wasDeleted: boolean = false) => {
    updateLocalStateDevices(updatedDevices, wasDeleted);
    updateCachedDevices(updatedDevices, wasDeleted);
  }

  // updates the component state for a single device change
  const updateLocalStateDevice = (updatedDevice: Device, wasDeleted: boolean = false) => {
    let updatedDevices = [...devices];
    let deviceToUpdateIndex = updatedDevices.map(d => d.id).indexOf(updatedDevice.id);
    if (wasDeleted) {
      updatedDevices.splice(deviceToUpdateIndex, 1);
    }
    else {
      updatedDevices[deviceToUpdateIndex] = updatedDevice;
    }
    setDevices(updatedDevices);
    if (wasDeleted) {
      setDevicesCount(updatedDevices.length);
    }
  }

  // updates the component state for multiple devices changes
  const updateLocalStateDevices = (updatedDevices: Array<Device>, wasDeleted: boolean = false) => {
    let updatedLocalDevices = [...devices];
    updatedDevices.forEach((updatedDevice: Device) => {
      let deviceToUpdateIndex = updatedLocalDevices.map(d => d.id).indexOf(updatedDevice.id);
      if (wasDeleted) {
        updatedLocalDevices.splice(deviceToUpdateIndex, 1);
      }
      else {
        updatedLocalDevices[deviceToUpdateIndex] = updatedDevice;
      }
    });
    setDevices(updatedLocalDevices);
    if (wasDeleted) {
      setDevicesCount(updatedLocalDevices.length);
    }
  }

  // updates or removes a device from each level of cache
  const updateCachedDevice = (updatedDevice: Device, wasDeleted: boolean = false) => {
    updateCachedLocationDevices(updatedDevice, wasDeleted);
    updateCachedTenantDevice(updatedDevice, wasDeleted);
  }

  // updates or removes multiple devices from each level of cache
  const updateCachedDevices = (updatedDevices: Array<Device>, wasDeleted: boolean = false) => {
    updatedDevices.forEach((updatedDevice: Device) => {
      updateCachedLocationDevices(updatedDevice, wasDeleted);
      updateCachedTenantDevice(updatedDevice, wasDeleted);
    });
  }

  // updates or removes a device from the cached array of tenant devices
  const updateCachedTenantDevice = (updatedDevice: Device, wasDeleted: boolean = false) => {
    if (tenantDevicesCache && tenantDevicesCache.length > 0) {
      let tenantDevicesCacheIndexes = tenantDevicesCache.map((d: Device) => d.id);
      let deviceToUpdateTenantCacheIndex = tenantDevicesCacheIndexes.indexOf(updatedDevice.id);
      if (wasDeleted) {
        if (isTenantLevel || hasOnlyOneLocation(updatedDevice)) {
          tenantDevicesCache.splice(deviceToUpdateTenantCacheIndex, 1);
        }
      } else {
        tenantDevicesCache[deviceToUpdateTenantCacheIndex] = updatedDevice;
      }
    }
  }

  // updates or removes a device from one cached array of location devices
  const updateCachedLocationDevice = (updatedDevice: Device, wasDeleted: boolean = false, locationId: string) => {
    let locationDevices = locationDevicesCache[locationId!];
    if (locationDevices && locationDevices.length > 0) {
      let deviceToUpdateLocationCacheIndex = locationDevices.map((d: Device) => d.id).indexOf(updatedDevice.id);
      if (wasDeleted) {
        locationDevices.splice(deviceToUpdateLocationCacheIndex, 1);
      } else {
        locationDevices[deviceToUpdateLocationCacheIndex] = updatedDevice;
      }
    }
  }

  // updates or removes a device from each of the cached arrays of location devices
  const updateCachedLocationDevices = (updatedDevice: Device, wasDeleted: boolean = false) => {
    if (isTenantLevel) {
      let deviceLocationIds = updatedDevice.deviceLocations.map((d: DeviceLocation) => d.locationId);
      deviceLocationIds.forEach(locationId => {
        updateCachedLocationDevice(updatedDevice, wasDeleted, locationId);
      });
    }
    else {
      updateCachedLocationDevice(updatedDevice, wasDeleted, locationId!);
    }
  }

  const revokeClicked = () => {
    if (clickedDevice) {
      revokeDeviceActivation(clickedDevice.id);
    }
    else {
      let checkedDeviceIds = new Array<string>();
      checkedDevices.forEach((value, key) => {
        if (value) {
          checkedDeviceIds.push(key)
        }
      });
      revokeDeviceActivations(checkedDeviceIds);
    }
  }

  const revokeDeviceActivation = (id: string) => {
    portal!.navigation.isLoading(true);
    setShowDeviceActionsPopover({ open: false, event: undefined });

    let endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/${id}/DeclineActivation` : `${controllerBaseUrl}/${id}/DeclineActivation`;

    var onSuccess = function (response: string) {
      var updatedDevice = JSON.parse(response);
      updateDeviceFromServer(updatedDevice);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to revoke device activation.");
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const revokeDeviceActivations = (ids: string[]) => {
    setShowDeviceActionsPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);

    let endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/DeclineActivation` : `${controllerBaseUrl}/DeclineActivation/`;

    var onSuccess = function (response: string) {
      let updatedDevicesResponse = JSON.parse(response);
      let updatedDevicesResults = updatedDevicesResponse.devices as Array<Device>;
      updateDevicesFromServer(updatedDevicesResults);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to revoke device activation(s).");
    };

    xhrService!.DoRicsApiXhr(endpoint, ids, onSuccess, onFailure, 'POST');
  }

  const activateClicked = () => {
    if (clickedDevice) {
      activateDevice(clickedDevice.id);
    }
    else {
      let checkedDeviceIds = new Array<string>();
      checkedDevices.forEach((value, key) => {
        if (value) {
          checkedDeviceIds.push(key)
        }
      });
      activateDevices(checkedDeviceIds);
    }
  }

  const activateDevice = (id: string) => {
    setShowDeviceActionsPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);

    let endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/${id}/Activate` : `${controllerBaseUrl}/${id}/Activate`;

    var onSuccess = function (response: string) {
      var updatedDevice = JSON.parse(response);
      updateDeviceFromServer(updatedDevice);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to activate the device.");
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const activateDevices = (ids: string[]) => {
    setShowDeviceActionsPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);

    let endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/Activate` : `${controllerBaseUrl}/Activate/`;

    var onSuccess = function (response: string) {
      let updatedDevicesResponse = JSON.parse(response);
      let updatedDevicesResults = updatedDevicesResponse.devices as Array<Device>;
      updateDevicesFromServer(updatedDevicesResults);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to active the device(s).");
    };

    xhrService!.DoRicsApiXhr(endpoint, ids, onSuccess, onFailure, 'POST');
  }

  const deleteClicked = () => {
    if (clickedDevice) {
      deleteDevice(clickedDevice.id);
    }
    else {
      setShowDeviceActionsPopover({ open: false, event: undefined });
      setShowDeleteModal(true);
    }
  }

  const deleteDevice = (id: string) => {
    setShowDeviceActionsPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);

    var endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/${id}` : `${controllerBaseUrl}/${id}`;

    var onSuccess = function () {
      let deletedDevice = devices.filter(d => d.id === id)[0];
      updateDeviceFromServer(deletedDevice, true)
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to delete the device.");
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure, 'DELETE');
  };

  const deleteDevices = (ids: string[]) => {
    setShowDeleteModal(false);
    portal!.navigation.isLoading(true);

    var endpoint = locationId ? `${controllerBaseUrl}/Locations/${locationId}/Delete` : `${controllerBaseUrl}/Delete`;

    var onSuccess = function (response: string) {
      let deletedDevicesResponse = JSON.parse(response);

      let deletedDeviceIds = ids;
      let erroredDeviceIds = Object.keys(deletedDevicesResponse.errors);
      if (erroredDeviceIds && erroredDeviceIds.length > 0) {
        deletedDeviceIds = ids.filter(id => erroredDeviceIds.indexOf(id) < 0);
      }
      let deletedDevices = devices.filter(d => deletedDeviceIds.indexOf(d.id) > -1);

      updateDevicesFromServer(deletedDevices, true);
      portal!.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to delete the device(s).");
    };

    xhrService!.DoRicsApiXhr(endpoint, ids, onSuccess, onFailure, 'DELETE');
  }

  const deleteSelectedDevices = () => {
    let checkedDeviceIds = new Array<string>();
    checkedDevices.forEach((value, key) => {
      if (value) {
        checkedDeviceIds.push(key)
      }
    });
    deleteDevices(checkedDeviceIds);
  }

  const getRequesterField = (device: Device) => {
    if (locationId) {
      return device.deviceLocations.filter(dl => dl.locationId === locationId)[0]?.activationRequesterName;
    }
    let lastActiveDeviceLocation = device.deviceLocations.length > 0 && device.deviceLocations.reduce((prev, current) => (prev.lastActive > current.lastActive) ? prev : current);
    return lastActiveDeviceLocation ? lastActiveDeviceLocation.activationRequesterName : "No Requester Data";
  }

  const getLastActiveField = (device: Device) => {
    let deviceLocations = device.deviceLocations;
    if (deviceLocations === undefined || deviceLocations.length === 0) {
      return "No Activity"
    }
    if (locationId) {
      let specificLocationDate = Date.parse(device.deviceLocations.filter(dl => dl.locationId === locationId)[0]?.lastActive);
      return formatDateCol(specificLocationDate);
    }
    let latestDate = Math.max.apply(Math, device.deviceLocations.map(dl => Date.parse(dl.lastActive)));
    return formatDateCol(latestDate);
  }

  const getStatusField = (device: Device) => {
    if (locationId) {
      return device.deviceLocations.filter(dl => dl.locationId === locationId)[0]?.status;
    }
    let aggregatedStatus = device.deviceLocations.some(dl => dl.status === "Active") ? "Active" : "ActivationDenied";
    return aggregatedStatus;
  }

  const formatDateCol = (dateNumber: number) => {
    if (dateNumber) {
      const date = new Date(dateNumber);
      const dateTimeFormat = new Intl.DateTimeFormat('en', { month: '2-digit', day: '2-digit', year: 'numeric' });
      const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date)
      return `${month}/${day}/${year}`;
    } else {
      return "None";
    }
  }

  return (
    <>
      <IonGrid class={`devices-grid ${cssClass}`} fixed={true}>
        <IonRow class="device-grid-header">
          <IonCol size="1">
            <IonCheckbox onIonChange={(e) => checkAllDevices((e.target as HTMLIonCheckboxElement).checked)}></IonCheckbox>
          </IonCol>
          <IonCol size="2">
            Device Name
          </IonCol>
          <IonCol size="1">
            Device Type
          </IonCol>
          <IonCol size="4">
            Device ID
          </IonCol>
          <IonCol size="2">
            Requester Name
          </IonCol>
          <IonCol size="2">
            Last Active
          </IonCol>
          <IonCol size="1">
            Status
          </IonCol>
          <IonCol size="1">
            <IonFabButton className="device-actions-button" onClick={(e) => openDeviceActionsPopover(undefined, e)}>
              <IonIcon className="device-actions-icon" src="assets/img/overflow.svg" />
            </IonFabButton>
          </IonCol>
        </IonRow>
        {
          devices && devices.map((device, i) =>
            <IonRow key={device.id} onClick={(e) => checkDevice((e.target as Element).classList, device.id)} class="ion-row-cursor device-grid-row">
              <IonCol size="1">
                <IonCheckbox checked={checkedDevices.get(device.id)} disabled={true} class="disabled"></IonCheckbox>
              </IonCol>
              <IonCol size="2" class="device-name-col">{device.name}</IonCol>
              <IonCol size="1">{device.model}</IonCol>
              <IonCol size="4">{device.id}</IonCol>
              <IonCol size="2">{getRequesterField(device)}</IonCol>
              <IonCol size="2">{getLastActiveField(device)}</IonCol>
              <IonCol size="1">
                <IonItem lines="none" className={"device-status-item " + (getStatusField(device) === "Active" ? "active" : "")}>
                  <IonLabel class="device-status-label">{getStatusField(device) === "Active" ? "Activated" : "Deactivated"}</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="1">
                <IonFabButton className="device-actions-button" onClick={(e) => openDeviceActionsPopover(device, e)}>
                  <IonIcon className="device-actions-icon" src="assets/img/overflow.svg" />
                </IonFabButton>
              </IonCol>
            </IonRow>
          )
        }
        <IonPopover
          className="device-actions-popover"
          isOpen={showDeviceActionsPopover.open}
          event={showDeviceActionsPopover.event}
          showBackdrop={false}
          onDidDismiss={resetPopover}
        >
          <IonButton
            fill="clear"
            expand="full"
            className="device-actions-popover-buttons"
            onClick={revokeClicked}
            disabled={clickedDevice ? getStatusField(clickedDevice) !== "Active" : !Array.from(checkedDevices.values()).includes(true)}
          >
            <span className="ion-button-inner-contents">
              <IonIcon md={close} mode="md" className="button-left-icon device-menu-button-icon" />
              {"Revoke Access" + (clickedDevice ? "" : " for selected")}
            </span>
          </IonButton>
          <IonButton fill="clear" expand="full" className="device-actions-popover-buttons divider-button"></IonButton>
          <IonButton
            fill="clear"
            expand="full"
            className="device-actions-popover-buttons"
            onClick={activateClicked}
            disabled={clickedDevice ? getStatusField(clickedDevice) === "Active" : !Array.from(checkedDevices.values()).includes(true)}
          >
            <span className="ion-button-inner-contents">
              <IonIcon md={checkmark} mode="md" className="button-left-icon device-menu-button-icon" />
              {"Activate" + (clickedDevice ? "" : " selected")}
            </span>
          </IonButton>
          <IonButton fill="clear" expand="full" className="device-actions-popover-buttons divider-button"></IonButton>
          <IonButton
            fill="clear"
            expand="full"
            className="device-actions-popover-buttons device-delete-button"
            onClick={deleteClicked}
            disabled={clickedDevice ? false : !Array.from(checkedDevices.values()).includes(true)}
          >
            <span className="ion-button-inner-contents">
              <IonIcon md={trash} mode="md" className="button-left-icon device-menu-button-icon" />
              {"Delete" + (clickedDevice ? "" : " selected")}
            </span>
          </IonButton>
        </IonPopover>
      </IonGrid>
      <IonModal isOpen={showDeleteModal} className='delete-devices-modal' onDidDismiss={() => setShowDeleteModal(false)}>
        <IonHeader class="ion-no-border">
          <IonToolbar mode="ios">
            <IonTitle class="delete-devices-title">
              Confirm Delete Devices
            </IonTitle>
            <IonIcon slot="end" className="ion-margin close-button" icon={close} onClick={() => setShowDeleteModal(false)} />
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding delete-modal-content">
          <p>Are you sure you want to delete all of the selected devices?</p>
          <IonList>
            <IonItem lines="none">
              <IonButton className="delete-all-devices-button" onClick={deleteSelectedDevices}>Delete Devices</IonButton>
            </IonItem>
            <IonItem lines="none">
              <IonButton fill="clear" className="nevermind-button" onClick={() => setShowDeleteModal(false)}>Nevermind</IonButton>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default DevicesGrid;

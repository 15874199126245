import React, { useEffect } from "react";
import {
  ProductTaxEventCondition,
  SpecialTaxEvent,
  Tax,
} from "../../../../interfaces/Taxes";
import AdvancedTaxesContainer from "../AdvancedTaxesContainer";
import AdvancedSpecialTaxEventConfigurationGrid from "./AdvancedSpecialTaxEventConfigurationGrid";
import { usePortal } from "../../../PortalProvider";

import "../../../../theme/locationDetails/taxes/specialTaxEvents/AdvancedSpecialTaxEventConfiguration.css";

interface AdvancedSpecialTaxEventConfigurationProps {
  taxes: Tax[];
  specialTaxEvent?: SpecialTaxEvent;
  closeModal: Function;
  saveSpecialTaxEvent: Function;
}

const AdvancedSpecialTaxEventConfiguration: React.FC<
  AdvancedSpecialTaxEventConfigurationProps
> = (props) => {
  const portal = usePortal();

  const [selectedTaxId, setSelectedTaxId] = React.useState<string>(
    props.taxes[0].taxId!
  );

  const [productTaxes, setProductTaxes] = React.useState<
    ProductTaxEventCondition[]
  >(props.specialTaxEvent?.productTaxes ?? []);
  const [filteredProductTaxes, setFilteredProductTaxes] = React.useState<
    ProductTaxEventCondition[]
  >([]);
  const [availableProductTypes, setAvailableProductTypes] = React.useState<
    string[]
  >([]);

  useEffect(() => {
    createProductTaxes(productTaxes, props.taxes[0].taxId!);
  }, []);

  useEffect(() => {
    const currentlyUsedProductTypes = filteredProductTaxes
      .filter((tax) => tax.taxId === selectedTaxId)
      .map((tax) => tax.productType);
    const availableTypes = Object.keys(portal!.constants.productTypes).filter(
      (type) => !currentlyUsedProductTypes.includes(type)
    );
    setAvailableProductTypes(availableTypes);
  }, [filteredProductTaxes, portal, selectedTaxId]);

  const onChangeSelectedTaxId = (taxId: string) => {
    let previousTaxId = selectedTaxId;
    setSelectedTaxId(taxId);

    let productTaxes = combineProductTaxLists(previousTaxId);
    createProductTaxes(productTaxes, taxId);
  };

  const combineProductTaxLists = (taxId: string) => {
    let combinedProductTaxes = productTaxes.filter((x) => x.taxId !== taxId);
    let completeProductTaxes = filteredProductTaxes.filter(
      (x) => !!x.productType
    );
    for (let i = 0; i < completeProductTaxes.length; i++) {
      combinedProductTaxes.push(completeProductTaxes[i]);
    }

    setProductTaxes(combinedProductTaxes);

    return combinedProductTaxes;
  };

  const createProductTaxes = (
    productTaxes: ProductTaxEventCondition[] | undefined,
    taxId: string
  ) => {
    let currentProductTaxes: ProductTaxEventCondition[] = [];
    let filteredProductTaxes: ProductTaxEventCondition[] = [];
    if (!!productTaxes) {
      filteredProductTaxes = productTaxes.filter((x) => x.taxId === taxId);
    }

    for (let i = 0; i < 30; i++) {
      if (i < filteredProductTaxes.length) {
        currentProductTaxes.push(filteredProductTaxes[i]);
      } else {
        currentProductTaxes.push({
          productType: undefined,
          taxRate: undefined,
          taxId: taxId,
        } as ProductTaxEventCondition);
      }
    }

    setFilteredProductTaxes(currentProductTaxes);
  };

  const saveSpecialEventTax = () => {
    let taxToUpdate = props.specialTaxEvent!;
    taxToUpdate.productTaxes = combineProductTaxLists(selectedTaxId);

    props.saveSpecialTaxEvent(taxToUpdate);
  };

  return (
    <>
      <AdvancedTaxesContainer
        taxes={props.taxes}
        taxEventName={props.specialTaxEvent!.eventName}
        closeModal={props.closeModal}
        selectedTaxId={selectedTaxId}
        onChangeSelectedTax={onChangeSelectedTaxId}
        onSaveChanges={saveSpecialEventTax}
      >
        <AdvancedSpecialTaxEventConfigurationGrid
          availableProductTypes={availableProductTypes}
          eventId={selectedTaxId}
          productTaxes={filteredProductTaxes}
          setAvailableProductTypes={setAvailableProductTypes}
        />
      </AdvancedTaxesContainer>
    </>
  );
};

export default AdvancedSpecialTaxEventConfiguration;

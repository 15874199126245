export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const numberToStringWithPad = (value: number, size?: number) => {
  let stringValue = value.toString();

  if (size) {
    while (stringValue.length < size) stringValue = "0" + stringValue;
  }

  return stringValue;
};

import React, { createRef, FormEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { EmailVerification } from "../../../../interfaces/integrations/ecommerce/EmailVerification";
import { useECommerceService } from "../../../../services/providers/ECommerceServiceProvider";
import { usePortal } from "../../../PortalProvider";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupEmailVerificationModalProps extends ECommerceAccountSetupModalPropsBase {
  email: string;
}

const ECommerceAccountSetupEmailVerificationModal: React.FC<ECommerceAccountSetupEmailVerificationModalProps> = (props) => {
  const ecommerceService = useECommerceService();
  const portal = usePortal();
  const digit1Input = createRef<HTMLInputElement>();
  const digit2Input = createRef<HTMLInputElement>();
  const digit3Input = createRef<HTMLInputElement>();
  const digit4Input = createRef<HTMLInputElement>();
  const digit5Input = createRef<HTMLInputElement>();
  const digit6Input = createRef<HTMLInputElement>();
  const submitButton = createRef<HTMLButtonElement>();

  const [disableNextButton, setDisableNextButton] = useState(true);
  const [digit1, setDigit1] = useState<number | undefined>();
  const [digit2, setDigit2] = useState<number | undefined>();
  const [digit3, setDigit3] = useState<number | undefined>();
  const [digit4, setDigit4] = useState<number | undefined>();
  const [digit5, setDigit5] = useState<number | undefined>();
  const [digit6, setDigit6] = useState<number | undefined>();
  const [canSave, setCanSave] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showVerificationStatus, setShowVerificationStatus] = useState(false);

  useEffect(() => {
    updateCanSave();
  }, [digit1, digit2, digit3, digit4, digit5, digit6]);

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  const onChange = (e: FormEvent<HTMLInputElement>, setDigit: Function, nextControl: HTMLElement | null) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\B\d|[^\d]/g, "");
    let digitValue = parseInt(e.currentTarget.value, 10);
    if (digitValue >= 0 && digitValue <= 9) {
      setDigit(digitValue);
      if (nextControl) nextControl.focus();
    }
  };

  const onFocus = (currentControl: HTMLInputElement | null) => {
    if (currentControl) currentControl.select();
  };

  const updateCanSave = () => {
    let digit1Set = digit1 !== undefined;
    let digit2Set = digit2 !== undefined;
    let digit3Set = digit3 !== undefined;
    let digit4Set = digit4 !== undefined;
    let digit5Set = digit5 !== undefined;
    let digit6Set = digit6 !== undefined;

    setCanSave(digit1Set && digit2Set && digit3Set && digit4Set && digit5Set && digit6Set);
  };

  const verifyCode = () => {
    let enteredCode = "" + digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
    let { code, expiration }: EmailVerification = JSON.parse(atob(window.sessionStorage.getItem("ecommerce-email-code") || ""));
    let dateMatch = new Date() < new Date(expiration);
    let codeMatch = enteredCode === code;
    let match = codeMatch && dateMatch;

    setVerified(match);
    setShowVerificationStatus(true);
    setDisableNextButton(!match);

    if (!match && digit1Input.current) digit1Input.current.focus();
    else if (match) window.sessionStorage.removeItem("ecommerce-email-code");
  };

  const resendCode = async () => {
    portal!.navigation.isLoading(true);

    await ecommerceService!.sendVerificationEmail(props.email);

    portal!.navigation.isLoading(false);
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Email Verification",
    headerSubTitle: `Please enter the 6 digit code sent to ${props.email}. This code will expire in 10 minutes.`,
    disableNextButton: disableNextButton
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("email-verification")}>
          <div className={getClass("email-verification-code")}>
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-1"
              ref={digit1Input}
              onInput={(e) => onChange(e, setDigit1, digit2Input.current)}
              onFocus={() => onFocus(digit1Input.current)}
              disabled={verified}
            />
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-2"
              ref={digit2Input}
              onInput={(e) => onChange(e, setDigit2, digit3Input.current)}
              onFocus={() => onFocus(digit2Input.current)}
              disabled={verified}
            />
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-3"
              ref={digit3Input}
              onInput={(e) => onChange(e, setDigit3, digit4Input.current)}
              onFocus={() => onFocus(digit3Input.current)}
              disabled={verified}
            />
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-4"
              ref={digit4Input}
              onInput={(e) => onChange(e, setDigit4, digit5Input.current)}
              onFocus={() => onFocus(digit4Input.current)}
              disabled={verified}
            />
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-5"
              ref={digit5Input}
              onInput={(e) => onChange(e, setDigit5, digit6Input.current)}
              onFocus={() => onFocus(digit5Input.current)}
              disabled={verified}
            />
            <input
              type="number"
              className={getClass("email-verification-digit")}
              name="code-6"
              ref={digit6Input}
              onInput={(e) => onChange(e, setDigit6, submitButton.current)}
              onFocus={() => onFocus(digit6Input.current)}
              disabled={verified}
            />
          </div>
          <div className={getClass("email-verification-footer")}>
            <div className="verified" hidden={!showVerificationStatus || !verified}>
              Verified
            </div>
            <div className="not-verified" hidden={!showVerificationStatus || verified}>
              Invalid Code
            </div>
            <Button disabled={!canSave} hidden={verified} className="submit-btn button" onClick={verifyCode} ref={submitButton}>
              Confirm
            </Button>
            <Button hidden={verified} className="resend-btn" onClick={resendCode}>
              Resend Code
            </Button>
          </div>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupEmailVerificationModal;

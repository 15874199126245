import React from "react";
import { ApplicationPortal } from "../interfaces/ApplicationPortal";

const { createContext, useContext } = React;

const PortalContext = createContext<ApplicationPortal | null>(null);

export const PortalProvider = (props: any) => {
  const value: ApplicationPortal = props.portal ?? window.portal;

  return <PortalContext.Provider value={value}>{props.children}</PortalContext.Provider>;
};

export const usePortal = () => {
  return useContext(PortalContext);
};

import { IonGrid, IonRow, IonCol } from '@ionic/react';

import React, { useState } from 'react';

import { Location } from "../../interfaces/Location";
import FinishAndInviteUsersModal from './InviteUsersModal';

import LocationCard from './LocationCard';
import LocationGridRow from './LocationGridRow';

interface LocationsGridProps {
  locations: Array<Location>;
  cssClass: string;
  isCardMode: boolean;
  numberSelected?: number;
  checkAllLocations: Function;
  checkedLocations: Map<string, boolean>
  setCheckedLocations: Function;
  checkLocation: Function;
}

const LocationsGrid: React.FC<LocationsGridProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [inviteLocation, setInviteLocation] = useState<Location>();

  const showModalForLocation = (showModal: boolean, location?: Location,) => {
    setInviteLocation(location);
    setShowModal(showModal);
  }

  return (
    <>
      <div id="locationGrid">
        {!props.isCardMode &&
          <IonGrid class={`locations-grid ${props.cssClass}`} fixed={true}>
            <IonRow class="user-grid-header">
              <IonCol size="1"></IonCol>
              <IonCol size="3">
                Store No.
              </IonCol>
              <IonCol size="2">
                Address
              </IonCol>
              <IonCol size="1">
                City
              </IonCol>
              <IonCol size="1">
                State
              </IonCol>
              <IonCol size="1">
                Zip
              </IonCol>
              <IonCol size="1">
                Store Owner
              </IonCol>
              <IonCol size="2">
                Status
              </IonCol>
            </IonRow>
            {props.locations.map((location, index) => {
              return (
                <LocationGridRow
                  key={index}
                  location={location}
                  checkAllLocations={props.checkAllLocations}
                  setShowModal={showModalForLocation}
                />
              )
            })}
          </IonGrid>
        }
        {props.isCardMode && props.locations.filter(l => (l.isActive === false) && !l.hidden).length > 0 &&
          <>
            <div className="heading" style={{ "marginTop": "0px" }}>Pending Setup</div>
            <div id="usersGrid">
              <div>
                {props.locations && props.locations.filter(l => (l.isActive === false) && !l.hidden).map((location, i) =>
                  <LocationCard key={location.locationId} Location={location} setShowModal={showModalForLocation}></LocationCard>
                )}
              </div>
            </div>
          </>
        }
        {props.isCardMode && props.locations.filter(l => (l.isActive === true) && !l.hidden).length > 0 &&
          <>
            <div className="heading">Active</div>
            <div id="usersGrid">
              <div>
                {props.locations && props.locations.filter(l => (l.isActive === true) && !l.hidden).map((location, i) =>
                  <LocationCard key={location.locationId} Location={location} setShowModal={showModalForLocation}></LocationCard>
                )}
              </div>
            </div>
          </>
        }
      </div>
      <FinishAndInviteUsersModal showModal={showModal} setShowModal={showModalForLocation} Location={inviteLocation}/>
    </>
  );
};

export default LocationsGrid;

import { history } from "../components/ExtendedRouter";
import { Paths } from "../constants/Paths";
import { isEmpty } from "../helpers/stringHelpers";
import { ApplicationPortal } from "../interfaces/ApplicationPortal";
import { IDictionary } from "../interfaces/IDictionary";
import { getToken, logout } from "./DataService";

const portal: ApplicationPortal = window.portal || {};
portal.xhr = portal.xhr || {};
portal.common = portal.common || {};
portal.common.baseUrl = portal.common.baseUrl || "";

export const DoXhr = function (url: string, payload: any, method: string, callback: Function, failCallback: Function) {
  var xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", function () {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      var parsedResponse = JSON.parse(this.responseText);
      if (parsedResponse._header && !parsedResponse._header.Success) {
        failCallback(this.responseText);
        return;
      }

      if (callback) {
        callback(this.responseText);
      }
    } else if (failCallback) {
      failCallback(this.responseText);
    }
  });

  xhr.open(method, url);

  var headers: IDictionary<string> = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  for (var header in headers) {
    xhr.setRequestHeader(header, headers[header]);
  }

  var data = JSON.stringify(payload);
  xhr.send(data);
};

export const DoRicsXhr = function (endpoint: string, payload: any | null, callback: Function | null, failCallback: Function | null) {
  var xhr = new XMLHttpRequest();
  var xhrCallback = function (this: any) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      var parsedResponse = JSON.parse(this.responseText);
      if (parsedResponse._header && !parsedResponse._header.Success && failCallback) {
        failCallback(this.responseText);
      } else {
        if (callback) {
          callback(this.responseText);
        }
      }
    } else if (this.status === 202) {
      var requestNumber = (this.responseText && JSON.parse(this.responseText))._header.MessageNumber;
      if (requestNumber) {
        setTimeout(function () {
          var asyncXhr = new XMLHttpRequest();
          //asyncXhr.original_xhr = xhr.original_xhr || xhr;
          xhr = asyncXhr;

          sendXhr("GetResult", "{ MessageNumber: requestNumber }");
        }, 5000);
        return;
      }
    } else if (this.status === 401) {
      logout();
      history.push(Paths.Login);
    } else if (failCallback) {
      failCallback(this.responseText);
    }
  };

  var sendXhr = function (_endpoint: string, _payload: string) {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    xhr.open("POST", portal.common.baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", getToken()!);

    xhr.addEventListener(
      "cancelXhr",
      function (e) {
        xhr.abort();
      },
      false
    );

    xhr.send((_payload && JSON.stringify(_payload)) || "");
  };

  sendXhr(endpoint, payload);
};

export const DoRicsApiXhr = function (endpoint: string, payload: any, callback: Function, failCallback: Function, verb = "GET") {
  var xhr = new XMLHttpRequest();

  var sendXhr = function (_endpoint: any, _payload: any, token: string = getToken()!) {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    var baseUrl = process.env.REACT_APP_API_URL;

    xhr.open(verb, baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", token);

    xhr.addEventListener(
      "cancelXhr",
      function (e) {
        xhr.abort();
      },
      false
    );

    xhr.send((_payload && JSON.stringify(_payload)) || "");
  };

  var xhrCallback = function (this: any) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      try {
        var parsedResponse = JSON.parse(this.responseText);
        if (parsedResponse._header && !parsedResponse._header.Success && failCallback) {
          failCallback(this.responseText);
        } else {
          if (callback) {
            callback(this.responseText);
          }
        }
      } catch (e) {
        if (callback) {
          callback(this.responseText);
        }
      }
    } else if (this.status === 204) {
      if (callback) {
        callback(this.responseText);
      }
    } else if (this.status === 202) {
      var requestNumber = (this.responseText && JSON.parse(this.responseText))._header.MessageNumber;
      if (requestNumber) {
        setTimeout(function () {
          var asyncXhr = new XMLHttpRequest();
          //asyncXhr.original_xhr = xhr.original_xhr || xhr;
          xhr = asyncXhr;

          sendXhr("GetResult", { MessageNumber: requestNumber });
        }, 5000);
        return;
      }
    } else if (this.status === 401) {
      if (!portal.features.refreshTokenEnabled || portal.State.xhrRetryAttempted) {
        portal.State.xhrRetryAttempted = false;
        failCallback();
      } else {
        let onRefreshRetryCall = (token: string) => sendXhr(endpoint, payload, token);

        portal.State.xhrRetryAttempted = true;
        refreshToken(onRefreshRetryCall, logout);
      }
    } else if (failCallback) {
      failCallback(this.responseText);
    }
  };

  sendXhr(endpoint, payload);
};

export const refreshToken = function (successCallback: Function, failCallback: Function) {
  let refreshToken = window.localStorage.getItem("RefreshToken");
  let loginEmailAddress = window.localStorage.getItem("LoginEmailAddress");
  let currentUserEmailAddress = portal.State.currentUser?.emailAddress;

  let cancelRefresh = () => {
    failCallback();
    return;
  };

  if (isEmpty(refreshToken)) {
    cancelRefresh();
  }

  if (isEmpty(loginEmailAddress)) {
    if (!isEmpty(currentUserEmailAddress)) {
      loginEmailAddress = currentUserEmailAddress;
    } else {
      cancelRefresh();
    }
  }

  let endpoint = "v1/Auth/RefreshToken";

  let payload = {
    EmailAddress: loginEmailAddress,
    RefreshToken: refreshToken
  };

  let onSuccess = function (response: any) {
    response = JSON.parse(response);
    window.localStorage.setItem("Token", response.token);
    successCallback(response.token);
  };

  DoRicsApiXhr(endpoint, payload, onSuccess, failCallback, "PUT");
};

export const DoRics9Xhr = function (token: any, endpoint: string, payload: any, callback: Function, failCallback: Function) {
  var xhr = new XMLHttpRequest();
  var xhrCallback = function (this: any) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      var parsedResponse = JSON.parse(this.responseText);
      if (parsedResponse._header && !parsedResponse._header.Success) {
        failCallback(this.responseText);
      } else {
        callback(this.responseText); // Success
      }
    } else if (failCallback) {
      failCallback(this.responseText);
    } else {
      //failCallback(this.responseText);
    }
  };

  var sendXhr = function (_endpoint: string, _payload: any) {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    var baseUrl = process.env.REACT_APP_RICS9_URL;

    xhr.open("POST", baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", token);

    xhr.addEventListener(
      "cancelXhr",
      function (e) {
        xhr.abort();
      },
      false
    );

    xhr.send((_payload && JSON.stringify(_payload)) || "");
  };

  sendXhr(endpoint, payload);
};

export const FileUploadXhr = function (endpoint: string, payload: any, method: string, callback: Function, failCallback: Function) {
  var baseUrl = process.env.REACT_APP_API_URL;

  var xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      if (this.status === 200 || this.status === 204) {
        callback(this.responseText);
      } else {
        if (failCallback) {
          failCallback();
        }
      }
    }
  });

  xhr.open(method, baseUrl + endpoint);
  xhr.setRequestHeader("Token", getToken()!);
  xhr.send(payload);
};

import { iosTransitionAnimation } from "@ionic/core/components";
import { IonContent, IonRouterOutlet } from "@ionic/react";
import { createBrowserHistory } from "history";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { Claims } from "../constants/Claims";
import { FeatureFlags } from "../constants/FeatureFlags";
import { Paths } from "../constants/Paths";
import About from "../pages/About";
import AccountSetupComplete from "../pages/AccountSetupComplete";
import AllDevices from "../pages/AllDevices";
import AllLocations from "../pages/AllLocations";
import AllUsers from "../pages/AllUsers";
import DeviceActivation from "../pages/DeviceActivation";
import Error from "../pages/Error";
import Home from "../pages/Home";
import LocationDetails from "../pages/LocationDetails";
import Login from "../pages/Login";
import ManageLabels from "../pages/ManageLabels";
import OnboardingWorkflow from "../pages/OnboardingWorkflow";
import Password from "../pages/Password";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Settings from "../pages/Settings";
import Integrations from "../pages/integrations/Integrations";
import Amazon from "../pages/integrations/ecommerce/amazon/Amazon";
import AmazonAccountManagement from "../pages/integrations/ecommerce/amazon/AmazonAccountManagement";
import "../theme/Settings.css";
/* Theme variables */
import { IonReactRouter } from "@ionic/react-router";
import { getVersion } from "../helpers/apiVersionsHelpers";
import { isAuthorized } from "../helpers/claimsHelper";
import { getDefaultPath } from "../helpers/pathsHelper";
import { showWelcomeModal } from "../interfaces/Configuration";
import GlobalProductCatalog from "../pages/GlobalProductCatalog";
import Reputation from "../pages/Reputation";
import "../theme/variables.css";
import { useDataService } from "./DataServiceProvider";
import { usePortal } from "./PortalProvider";
import RicsToolbar from "./RicsToolbar";
import { useXhrService } from "./XhrServiceProvider";

export const history = createBrowserHistory();

const RicsRouter: React.FC = (props) => {
  const xhrService = useXhrService();
  const ldClient = useLDClient();
  const flags = useFlags();
  const portal = usePortal();
  const dataService = useDataService();
  const windowPortal = window.portal;

  const [portalPageTransitionsEnabled, setPortalPageTransitionsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPortalPageTransitionsFeatureEnabledStatus();
  }, [flags]);

  useEffect(() => {
    initialize();
  }, []);

  const getPortalPageTransitionsFeatureEnabledStatus = () => {
    let endpoint = "v1/Features/PortalPageTransitions";
    var onSuccess = function (response: string) {
      var feature = JSON.parse(response);
      setPortalPageTransitionsEnabled(feature.isEnabled);
    };
    var onFailure = function () {
      setPortalPageTransitionsEnabled(false);
    };
    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const loadData = (ldFlags: LDFlagSet | null, onSuccess: () => void, onFailure: () => void) => {
    const version = getVersion(ldFlags);
    dataService.getIdentity(version, onSuccess, onFailure);
  };

  const initializeLD = (tenantId: string, onSuccess: () => void, onFailure: () => void) => {
    portal?.navigation.isLoading(true);

    let user = windowPortal.State.currentUser
      ? {
        key: windowPortal.State.currentUser.emailAddress,
        name: windowPortal.State.currentUser.emailAddress,
        email: windowPortal.State.currentUser.emailAddress,
        custom: {
          TenantId: tenantId
        }
      }
      : {
        anonymous: true,
        custom: {
          TenantId: tenantId
        }
      };

    ldClient?.identify(user, undefined, (err, ldFlags) => {
      if (err) {
        console.log(err.message);
        if (onFailure) {
          onFailure();
        }
        return;
      }

      loadData(ldFlags, onSuccess, onFailure);
    });
  };

  const initialize = (onSuccess?: () => void, onFailure?: () => void) => {
    portal?.navigation.isLoading(true);
    setLoading(true);

    const onSuccessCallback = () => {
      portal?.navigation.isLoading(false);
      setLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    };

    const onFailureCallback = () => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setLoading(false);
      if (onFailure) {
        onFailure();
      }
      portal?.navigation.isLoading(false);
    };

    if (window.localStorage.getItem("Token")) {
      dataService.tenants
        .getCurrentTenant()
        .then((tenant) => {
          initializeLD(tenant.tenantId, onSuccessCallback, onFailureCallback);
        })
        .catch(() => {
          console.log("Unable to get Tenant for current identity token.");
          onFailureCallback();
        });
      return;
    }

    onFailureCallback();
  };

  const redirectIfUnauthed = (component: JSX.Element, claim?: string) => {
    if (!isAuthenticated()) {
      return <Redirect to={Paths.Login} />;
    }

    if (!isAuthorized(portal!.State.userPermissions, claim, flags)) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      return <Redirect to={Paths.AccountSetupComplete} />;
    }

    return component;
  };

  const isAuthenticated = () => {
    var token = window.localStorage.getItem("Token");
    return !!token;
  };

  const getDefaultRedirectPath = () => {
    return getDefaultPath(showWelcomeModal(portal!.configurations), portal!.State.userPermissions, flags);
  };

  return loading ? (
    <></>
  ) : (
    <IonReactRouter>
      <Switch>
        <Route exact path={Paths.Default} render={() => <Redirect to={getDefaultRedirectPath()} />}></Route>
        <Route path={Paths.Home} component={Home} />
        <Route
          path={[
            Paths.Login,
            Paths.Password,
            Paths.AccountSetupComplete,
            Paths.PrivacyPolicy,
            Paths.DeviceActivationApproved,
            Paths.DeviceActivationDeclined,
            Paths.About
          ]}>
          <IonContent>
            <IonRouterOutlet animated={portalPageTransitionsEnabled} animation={iosTransitionAnimation}>
              {/* Public routes */}
              <Route
                path={Paths.Login}
                exact={true}
                render={(props) => (isAuthenticated() ? <Redirect to={getDefaultRedirectPath()} /> : <Login {...props} initialize={initialize} />)}
              />
              <Route path={Paths.Password} render={() => <Password reentryPointSetup={initialize} />} />
              <Route path={Paths.AccountSetupComplete} component={AccountSetupComplete} exact={true} />
              <Route path={Paths.PrivacyPolicy} component={PrivacyPolicy} exact={true} />
              <Route path={Paths.DeviceActivationApproved} exact={true} render={(props) => <DeviceActivation activated={true} {...props} />} />
              <Route path={Paths.DeviceActivationDeclined} exact={true} render={(props) => <DeviceActivation activated={false} {...props} />} />
              <Route path={Paths.About} component={About} exact={true} />
            </IonRouterOutlet>
          </IonContent>
        </Route>
        <Route
          path={[
            Paths.Welcome,
            Paths.Locations,
            Paths.LocationDetails,
            Paths.Users,
            Paths.Devices,
            Paths.Settings,
            Paths.Integrations,
            Paths.Amazon,
            Paths.AmazonAccounts,
            Paths.ManageLabels,
            Paths.GlobalProductCatalog,
            Paths.Reputation
          ]}>
          <RicsToolbar />
          <IonContent>
            <IonRouterOutlet animated={portalPageTransitionsEnabled} animation={iosTransitionAnimation}>
              {/* Authorized routes */}
              <Route
                path={Paths.Welcome}
                exact={true}
                render={(props) => redirectIfUnauthed(<OnboardingWorkflow reentryPointSetup={initialize} {...props} />, Claims.Owner)}
              />
              <Route path={Paths.Locations} exact={true} render={() => redirectIfUnauthed(<AllLocations />, Claims.Owner)} />
              <Route path={Paths.LocationDetails} exact={false} render={() => redirectIfUnauthed(<LocationDetails />, Claims.Owner)} />
              <Route path={Paths.Users} exact={true} render={() => redirectIfUnauthed(<AllUsers />, Claims.Owner)} />
              <Route path={Paths.Devices} exact={true} render={() => redirectIfUnauthed(<AllDevices />, Claims.Owner)} />
              <Route path={Paths.Settings} exact={true} render={() => redirectIfUnauthed(<Settings />, Claims.Owner)} />
              <Route
                path={Paths.Integrations}
                exact={true}
                render={() => redirectIfUnauthed(flags[FeatureFlags.IntegrationsPage] ? <Integrations /> : <Error />, Claims.Owner)}
              />
              <Route
                path={Paths.Amazon}
                exact={true}
                render={() =>
                  redirectIfUnauthed(
                    flags[FeatureFlags.IntegrationsPage] && flags[FeatureFlags.AmazonIntegrationsPage] ? <Amazon /> : <Error />,
                    Claims.Owner
                  )
                }
              />
              <Route
                path={Paths.AmazonAccounts}
                exact={true}
                render={() =>
                  redirectIfUnauthed(
                    flags[FeatureFlags.IntegrationsPage] && flags[FeatureFlags.AmazonIntegrationsPage] ? <AmazonAccountManagement /> : <Error />,
                    Claims.Owner
                  )
                }
              />
              <Route
                path={Paths.ManageLabels}
                exact={true}
                render={() =>
                  redirectIfUnauthed(flags[FeatureFlags.LabelsPage] ? <ManageLabels /> : <Redirect to={getDefaultRedirectPath()} />, Claims.Labels)
                }
              />

              <Route
                path={Paths.GlobalProductCatalog}
                exact={true}
                render={() =>
                  redirectIfUnauthed(
                    flags[FeatureFlags.GlobalProductCatalogPage] ? <GlobalProductCatalog /> : <Redirect to={getDefaultRedirectPath()} />,
                    Claims.GlobalProductCatalogPage
                  )
                }
              />

              <Route
                path={Paths.Reputation}
                exact={true}
                render={() => redirectIfUnauthed(flags[FeatureFlags.ReputationPage] ? <Reputation /> : <Error />, Claims.Owner)}
              />

            </IonRouterOutlet>
          </IonContent>
        </Route>
        {/* Unmatched route */}
        <Route component={Error} />
      </Switch>
    </IonReactRouter>
  );
};

export default RicsRouter;

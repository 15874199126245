import React, { createContext, useContext } from "react";
import { IECommerceService } from "../../interfaces/integrations/ecommerce/ECommerceService";
import { sendVerificationEmail } from "../ECommerceService";

const ECommerceServiceContext = createContext<IECommerceService | undefined>(undefined);

interface ECommerceServiceProviderProps {
    children?: React.ReactNode;
}

export const ECommerceServiceProvider: React.FC<ECommerceServiceProviderProps> = ({children}) => {
    const ecommerceService: IECommerceService = {
        sendVerificationEmail: sendVerificationEmail,
    };

    return (
        <>
            <ECommerceServiceContext.Provider value={ecommerceService}>{children}</ECommerceServiceContext.Provider>
        </>
    );
};

export const useECommerceService = () => {
    return useContext(ECommerceServiceContext);
};

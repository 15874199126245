import React, { useState } from "react";

import { IonButton, IonFabButton, IonIcon, IonPopover } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";
import { getRoleDisplayName } from "../../helpers/rolesHelpers";
import { User } from "../../interfaces/User";
import "../../theme/UserCard.css";

interface UserCardProps {
  User: User;
  deleteUserIsEnabled: boolean;
}

const UserCard: React.FC<UserCardProps> = (props) => {
  const [showUserActionsPopover, setShowUserActionsPopover] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined
  });
  return (
    <>
      <div className="gridPanel contentSection">
        <div className="content">
          {props.deleteUserIsEnabled && (
            <IonFabButton className="user-card-actions-button" onClick={(e) => setShowUserActionsPopover({ open: true, event: e.nativeEvent })}>
              <IonIcon className="device-actions-icon" src="assets/img/overflow.svg" />
            </IonFabButton>
          )}
          <div className="userImageRow">
            <div className="userImageHolder placeHolder">
              <div>
                {(props.User.FirstName ? props.User.FirstName[0] : props.User.Email[0].toUpperCase()) +
                  (props.User.LastName ? props.User.LastName[0] : props.User.Email[0].toUpperCase())}
              </div>
            </div>
          </div>
          <div className="heading">
            {props.User.FirstName || props.User.LastName ? props.User.FirstName + " " + props.User.LastName : props.User.Email}
          </div>
          <div className="subHeading">{props.User.Email}</div>
          <div className="roleHolder">
            <div>{getRoleDisplayName(props.User.Roles)}</div>
          </div>
        </div>
      </div>
      <IonPopover
        className="device-actions-popover"
        isOpen={showUserActionsPopover.open}
        event={showUserActionsPopover.event}
        showBackdrop={false}
        onDidDismiss={() => setShowUserActionsPopover({ open: false, event: undefined })}>
        {props.deleteUserIsEnabled && (
          <>
            <IonButton fill="clear" expand="full" className="device-actions-popover-buttons">
              <span className="ion-button-inner-contents">
                <IonIcon md={close} mode="md" className="button-left-icon device-menu-button-icon" />
                Enable User
              </span>
            </IonButton>
            <IonButton fill="clear" expand="full" className="device-actions-popover-buttons divider-button"></IonButton>
            <IonButton fill="clear" expand="full" className="device-actions-popover-buttons">
              <span className="ion-button-inner-contents">
                <IonIcon md={checkmark} mode="md" className="button-left-icon device-menu-button-icon" />
                Disable User
              </span>
            </IonButton>
          </>
        )}
      </IonPopover>
    </>
  );
};

export default UserCard;

import React from "react"
import Accordion from "react-bootstrap/Accordion";
import { usePortal } from "../PortalProvider";
import IntegrationsAccordionInner from "./IntegrationsAccordionInner";

const IntegrationsAccordion: React.FC = () => {
    return (
        <>
            <Accordion defaultActiveKey={["my-integrations", "all-integrations"]} alwaysOpen>
                <Accordion.Item className="integrations-accordion-item" eventKey="my-integrations">
                    <Accordion.Header className="integrations-accordion-header">My Integrations</Accordion.Header>
                    <Accordion.Body className="integrations-accordion-body">
                        <IntegrationsAccordionInner isAllIntegrations={false} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="integrations-accordion-item" eventKey="all-integrations">
                    <Accordion.Header className="integrations-accordion-header">All Integrations</Accordion.Header>
                    <Accordion.Body className="integrations-accordion-body">
                        <IntegrationsAccordionInner isAllIntegrations={true} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default IntegrationsAccordion;
import React from 'react';

import { IonImg } from '@ionic/react';
import { Location } from '../../interfaces/Location';

interface LocationOwnerProps {
    Location: Location;
}

const LocationOwner: React.FC<LocationOwnerProps> = (props) => {
    const formatContactName = () => {
        let firstName = props.Location.contact?.FirstName ? props.Location.contact?.FirstName : "";
        let lastName = props.Location.contact?.LastName ? props.Location.contact?.LastName : "";
        let name = firstName + " " + lastName;
        if (name === " ") {
            name = "Unassigned";
        }
        return name;
    }

    return (
        <div className="storeContactHolder">
            <IonImg src="assets/img/user-alt.svg" class="storeContactIcon"></IonImg>
            <div>{formatContactName()}</div>
        </div>
    );
};

export default LocationOwner;
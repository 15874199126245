import React, { useEffect, useState } from "react";
import { ECommerceConstants } from "../../../../constants/ECommerceConstants";
import { CreateECommerceAccountBase } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { ECommerceLocation } from "../../../../interfaces/integrations/ecommerce/ECommerceLocation";
import { usePortal } from "../../../PortalProvider";
import ECommerceAccountLocationsTable from "../ECommerceAccountLocationsTable";
import ECommerceAccountSetupModal, { ECommerceAccountSetupModalProps, ECommerceAccountSetupModalPropsBase } from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupLocationsModalProps extends ECommerceAccountSetupModalPropsBase {
  useFulfillmentStrategy: boolean;
  updateAccount(account: CreateECommerceAccountBase): void;
}

const ECommerceAccountSetupLocationsModal: React.FC<ECommerceAccountSetupLocationsModalProps> = (props) => {
  const portal = usePortal();

  let eCommerceLocations =
    (portal?.State.locations &&
      portal.State.locations.map((l) => {
        let eCommerceLocation: ECommerceLocation = {
          id: l.originalLocationId || l.locationId,
          name: l.name,
          isInventoryLocation: false,
          isSaleLocation: false
        };

        return eCommerceLocation;
      })) ||
    new Array<ECommerceLocation>();

  const [locations, setLocations] = useState(eCommerceLocations);
  const [hasInventoryLocations, setHasInventoryLocations] = useState(false);
  const [hasSaleLocations, setHasSaleLocations] = useState(false);

  useEffect(() => {
    setHasInventoryLocations(locations && locations.filter((l) => l.isInventoryLocation).length > 0);
    setHasSaleLocations(locations && locations.filter((l) => l.isSaleLocation).length > 0);
  }, [locations]);

  const onGoBack = () => {
    setLocations(eCommerceLocations);

    if (props.onGoBack) props.onGoBack();
  };

  const onNext = () => {
    let tempAccount: CreateECommerceAccountBase = JSON.parse(window.sessionStorage.getItem(ECommerceConstants.tempAccountKey)!);

    let inventoryLocationIds = locations.filter((l) => l.isInventoryLocation).map((l) => l.id);
    let saleLocationIds = locations.filter((l) => l.isSaleLocation).map((l) => l.id);

    tempAccount.ricsSourcingLocations = inventoryLocationIds;
    tempAccount.saleLocations = saleLocationIds;

    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(tempAccount));

    props.updateAccount(tempAccount);

    if (props.onNext) props.onNext();
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Locations",
    headerSubTitle: `Select Inventory Location(s) and a Sale Location ${props.useFulfillmentStrategy ? "" : "(optional)"}`,
    disableNextButton: !hasInventoryLocations || (props.useFulfillmentStrategy && !hasSaleLocations),
    onGoBack: onGoBack,
    onNext: onNext
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className="locations-setup">
          <ECommerceAccountLocationsTable useFulfillmentStrategy={props.useFulfillmentStrategy} locations={locations} onChange={setLocations} />
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupLocationsModal;

import { IonCol, IonIcon, IonInput, IonRow } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React from "react";
import { Tax } from "../../../../interfaces/Taxes";

interface TaxJurisdictionRowProps {
  tax: Tax;
  removeTax: Function;
  updateTaxDescription: Function;
  updateTaxRate: Function;
}

const formatRateString = (taxRate: number) => {
  let percentRate = taxRate * 100;
  let truncatedRate = percentRate.toFixed(4);
  let rateWithoutTrailingZeros = Number(truncatedRate).toString();
  return rateWithoutTrailingZeros;
};

const isTaxRateInvalid = (taxRate: string | undefined) => {
  let maxLengthRate = taxRate?.match(/^-?\d+(?:\.\d{0,4})?/);
  let maxRateLength = 0;
  if (!!maxLengthRate?.length) {
    maxRateLength = maxLengthRate[0].length;
  }

  let isNullOrUndefined = !taxRate && taxRate !== "0";
  let badRegexParse = maxRateLength == 0;
  let taxRateTooLong = taxRate != null && maxRateLength > 0 && taxRate?.length > maxRateLength;

  return isNullOrUndefined || badRegexParse || taxRateTooLong;
};

const TaxJurisdictionRow: React.FC<TaxJurisdictionRowProps> = (props) => {
  const errorClass = "invalid";
  const validTaxRateClass = "tax-rate-input";
  const invalidTaxRateClass = `${errorClass} ${validTaxRateClass}`;
  const [taxRate, setTaxRate] = React.useState<string | undefined>(
    props.tax.taxRate !== null && props.tax.taxRate !== undefined ? formatRateString(props.tax.taxRate) : undefined
  );

  const onSetTaxRate = (value: string) => {
    setTaxRate(value);
    props.updateTaxRate(value, props.tax.taxId!);
  };

  return (
    <IonRow className="tax-jurisdiction-row">
      <IonCol size="8" className="tax-name-column">
        <IonInput
          value={props.tax.description}
          onIonChange={(e) => props.updateTaxDescription(e.detail.value ?? "", props.tax.taxId!)}
          className={!props.tax.description || props.tax.description.length < 4 ? errorClass : ""}
        />
      </IonCol>
      <IonCol size="2" className="tax-rate-column">
        <IonInput
          className={isTaxRateInvalid(taxRate) ? invalidTaxRateClass : validTaxRateClass}
          value={taxRate}
          onIonChange={(e) => onSetTaxRate(e.detail.value ?? "")}
        />
      </IonCol>
      <IonCol size="2">
        <IonIcon src={closeCircle} className="delete-icon" onClick={() => props.removeTax(props.tax.taxId!)}></IonIcon>
      </IonCol>
    </IonRow>
  );
};

export default TaxJurisdictionRow;

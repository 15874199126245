import { ECommerceVendor } from "../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { ECommerceIntegration } from "../interfaces/integrations/ecommerce/ECommerceIntegration";
import { FeatureFlags } from "./FeatureFlags";
import { Paths } from "./Paths";

export const IntegrationsConstants = {
  eCommerceHeader: "eCommerce"
} as const;

export const ECommerceIntegrations: ECommerceIntegration[] = [
  {
    Vendor: ECommerceVendor.Amazon,
    Name: ECommerceVendor.Amazon.toString(),
    Description: "Amazon eCommerce Integration",
    LogoUrl: "assets/img/amazon.png",
    IsConfigured: false,
    Route: Paths.Amazon,
    UnconfiguredRoute: `${Paths.Amazon}?setup=true`,
    IsEnabledFF: FeatureFlags.AmazonIntegrationsPage,
    IsPro: true
  }
];

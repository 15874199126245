import { IonContent, IonPage, IonSplitPane } from '@ionic/react';

import React, { useEffect, useState } from 'react';
import RicsSideMenu from '../components/RicsSideMenu';

import { locationDetailsTabs } from "../constants/LocationDetailsTabs";

import '../theme/LocationDetails.css';
import DevicesGrid from '../components/DevicesGrid';
import SettingsGrid from '../components/SettingsGrid';
import LocationDetailsUsers from '../components/locationDetails/LocationDetailsUsers';
import LocationDetailsTaxes from '../components/locationDetails/taxes/LocationDetailsTaxes';
import LocationDetailsDetails from '../components/locationDetails/LocationDetailsDetails';
import LocationDetailsBranding from '../components/locationDetails/LocationDetailsBranding';
import { ApplicationPortal } from '../interfaces/ApplicationPortal';
import { useXhrService } from '../components/XhrServiceProvider';
import LocationDetailsRicsSideMenuHeader from '../components/locationDetails/LocationDetailsRicsSideMenuHeader';
import RicsSideMenuTabHeader from '../components/RicsSideMenuTabHeader';
import { LocationDetailsDrawerHistory } from '../components/locationDetails/drawerHistory/LocationDetailsDrawerHistory';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../constants/FeatureFlags';

// for accessing global objects from "old" code
var portal = window.portal as ApplicationPortal;
portal.State.locationDevices = portal.State.locationDevices || [];
portal.features = portal.features || {};

const LocationDetails: React.FC = () => {
  const xhrService = useXhrService();
  const flags = useFlags();
  const [cashCountFeatureEnabled, setCashCountFeatureEnabled] = React.useState(false);

  var locationGroupMatches = window.location.href.match(/locationdetails\/(.*)/);
  var locationId = "";

  if (locationGroupMatches) {
    locationId = locationGroupMatches[1];
  }

  locationId = decodeURIComponent(locationId);
  let locationMatchFound = false;

  for (let i = 0; i < portal.State.locations.length; i++) {
    var tempLocation = portal.State.locations[i];
    if (tempLocation.locationId === locationId) {
      portal.State.currentLocation = tempLocation;
      locationMatchFound = true;
      break;
    }
  }

  var locationNamesAndIds = portal.State.locations.map(
    function (l) {
      return { Name: l.name, LocationId: l.locationId }
    });

  var selectedLocation = { Name: portal.State.currentLocation?.name, LocationId: portal.State.currentLocation?.locationId };

  let activeTab = "Details";
  if (!portal.State.currentLocation?.isActive) {
    activeTab = 'Taxes';
  }

  let location = selectedLocation;
  let locationSelectOptions = locationNamesAndIds;
  let page = activeTab;
  let navigationEnabled = true;

  const menuShowCondition = true;

  const [devicesCount, setDevicesCount] = useState(0);
  const [deleteUserEnabled, setDeleteUserEnabled] = React.useState(false);

  useEffect(() => {
    getDeleteUserFeatureEnabledStatus();
  }, []);

  useEffect(() => {
    getCashCountFeatureFlag();
  }, [flags]);

  const getCashCountFeatureFlag = () => {
    var cashCountFeatureFlag = flags[FeatureFlags.CashCount];
    setCashCountFeatureEnabled(cashCountFeatureFlag);
  };
  const initialCurrentTab = page == null
    ? locationDetailsTabs.filter(t => t.tabKey === "Details")[0]
    : locationDetailsTabs.filter(t => t.tabKey === page)[0];

  const [currentTab, setCurrentTab] = useState(initialCurrentTab);
  function updateCurrentTab(tabKey: string) {
    setCurrentTab(locationDetailsTabs.filter(t => t.tabKey === tabKey)[0]);
  }

  const getLocationDevices = (setListState: Function) => {
    portal.navigation.isLoading(true);

    // don't hit the api if we already have results cached
    var cachedLocationDevices = portal.State.locationDevices && portal.State.locationDevices[location.LocationId];
    if (cachedLocationDevices) {
      setListState(cachedLocationDevices);
      setDevicesCount(cachedLocationDevices.length);
      portal.navigation.isLoading(false);
      return;
    }

    var endpoint = 'v1/Devices/Locations/' + location.LocationId;

    var onSuccess = function (response: string) {
      let devices = JSON.parse(response);
      if (!devices) {
        onFailure();
        return;
      }
      portal.State.locationDevices[location.LocationId] = devices;
      setListState(devices);
      setDevicesCount(devices.length);
      portal.navigation.isLoading(false);
    };

    var onFailure = function () {
      portal.navigation.isLoading(false);
      alert("Something went wrong while trying to retrieve devices.");
      return;
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const getDeleteUserFeatureEnabledStatus = () => {
    let endpoint = "v1/Features/DisableUser";
    var onSuccess = function (response: string) {
      var feature = JSON.parse(response);
      setDeleteUserEnabled(feature.isEnabled);
    };
    var onFailure = function () {
      setDeleteUserEnabled(false);
    };
    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const getLocationDetailsRicsSideMenuHeader = (menuCollapsed: boolean) => {
    return <LocationDetailsRicsSideMenuHeader
      location={location}
      locationSelectOptions={locationSelectOptions}
      menuCollapsed={menuCollapsed} />
  };

  return (
    <>
      <IonPage>
        <IonContent id="pageContent">
          <IonSplitPane when={menuShowCondition} contentId="locationsPageContent">
            <RicsSideMenu
              selectedTab={currentTab}
              tabs={locationDetailsTabs}
              navigationEnabled={navigationEnabled}
              contentId="locationsPageContent"
              setSelectedTab={updateCurrentTab}
              getCustomHeader={getLocationDetailsRicsSideMenuHeader} />
            <IonContent
              id="locationsPageContent"
              className="location-details-content"
              scrollY={false}>

              {locationMatchFound && <>
                {currentTab.tabKey === "Details" &&
                  <LocationDetailsDetails
                    selectedTab={currentTab}
                    currentLocation={portal.State.currentLocation} />
                }

                {currentTab.tabKey === "Branding" &&
                  <LocationDetailsBranding
                    selectedTab={currentTab}
                    currentLocation={portal.State.currentLocation} />
                }

                {currentTab.tabKey === "Devices" &&
                  <div className="tabbedSection">
                    <div className="tabContent">
                      <div id="Devices">
                        <RicsSideMenuTabHeader selectedTab={currentTab} />
                        <DevicesGrid
                          cssClass="location-devices-grid"
                          getDevices={getLocationDevices}
                          setDevicesCount={setDevicesCount}
                          selectedTab={currentTab}
                          locationId={location.LocationId} />
                      </div>
                    </div>
                  </div>
                }

                {currentTab.tabKey === "Settings" &&
                  <div className="tabbedSection">
                    <div className="tabContent">
                      <div id="Settings">
                        <RicsSideMenuTabHeader selectedTab={currentTab} />
                        <SettingsGrid LocationId={location.LocationId}
                          selectedTab={currentTab} />
                      </div>
                    </div>
                  </div>
                }

                {currentTab.tabKey === "Users" &&
                  <LocationDetailsUsers
                    locationId={location.LocationId}
                    selectedTab={currentTab}
                    deleteUserEnabled={deleteUserEnabled} />
                }

                {currentTab.tabKey === "Taxes" &&
                  <LocationDetailsTaxes
                    selectedTab={currentTab}
                    locationId={location.LocationId} />
                }

                {currentTab.tabKey === "DrawerHistory" && cashCountFeatureEnabled &&
                  <LocationDetailsDrawerHistory
                    selectedTab={currentTab}
                    locationId={location.LocationId}
                    getDevices={getLocationDevices} />
                }
              </>
              }
              {!locationMatchFound && locationGroupMatches &&
                <p>
                  No location was found that matches the ID in the URL
                </p>
              }
            </IonContent>
          </IonSplitPane>
        </IonContent>
      </IonPage>
    </>
  );
};

export default LocationDetails;

import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import TenantLevelPageHeader from "../components/TenantLevelPageHeader";

const GlobalProductCatalog: React.FC = () => {
  return (
    <>
      <IonPage>
        <TenantLevelPageHeader headerText="Global Product Catalog" headerIconFileUrl="assets/img/integration.svg"></TenantLevelPageHeader>
        <IonContent />
      </IonPage>
    </>
  );
};

export default GlobalProductCatalog;

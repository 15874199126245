import React from "react";
import { Button, Modal } from "react-bootstrap";

interface IntegrationsNoAccessModalProps {
    show: boolean;
    onHide: Function;
};

const IntegrationsNoAccessModal: React.FC<IntegrationsNoAccessModalProps> = (props) => {
    const baseClassName = "integrations-no-access-modal";

    return (
        <>
            <Modal
                className={baseClassName}
                show={props.show}
                onHide={() => props.onHide()}
                centered
                size="lg">
                <Modal.Header className={baseClassName + "-header"}>
                    <div>You do not have access to this integration. Please contact RICS support for assistance. Thank you.</div>
                </Modal.Header>
                <Modal.Footer className={baseClassName + "-footer"}>
                    <Button className={baseClassName + "-ok-button"} onClick={() => props.onHide()}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default IntegrationsNoAccessModal;
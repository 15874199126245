import { APIVersions } from "../constants/APIVersions";
import { IStockLabel } from "../interfaces/IStockLabel";
import { IStockLabelService } from "../interfaces/services/IStockLabelService";
import { DoRicsApiXhr } from "../services/XhrService";

export type StockLabelGroup = {
  [ProductId: string]: Array<IStockLabel>;
};

const getStockLabels = (skip?: number, take?: number): Promise<Array<IStockLabel>> => {
  return new Promise((resolve, reject) => {
    let endpoint = `v${APIVersions.TwoDotOne}/stocklabels`;

    if (skip !== undefined && take !== undefined) {
      endpoint += `?skip=${skip}&take=${take}`;
    }

    const onSuccess = (response: string) => {
      let results: Array<IStockLabel> = JSON.parse(response);
      resolve(results);
    };

    DoRicsApiXhr(endpoint, null, onSuccess, reject, "GET");
  });
};

const getStockLabelsForLocation = (locationId: string, skip?: number, take?: number): Promise<Array<IStockLabel>> => {
  return new Promise((resolve, reject) => {
    let endpoint = `v${APIVersions.TwoDotOne}/stocklabels/${locationId}`;

    if (skip !== undefined && take !== undefined) {
      endpoint += `?skip=${skip}&take=${take}`;
    }

    const onSuccess = (response: string) => {
      let results: Array<IStockLabel> = JSON.parse(response);
      resolve(results);
    };

    DoRicsApiXhr(endpoint, null, onSuccess, reject, "GET");
  });
};

const deleteStockLabels = (stockLabelIds: Array<string>): Promise<void> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${APIVersions.TwoDotOne}/stocklabels?ids=${stockLabelIds.join("&ids=")}`;

    DoRicsApiXhr(endpoint, null, resolve, reject, "DELETE");
  });
};

const printAveryLabels = (stockLabels: Array<IStockLabel>, generateMissingUpcs: boolean, locationId?: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${APIVersions.TwoDotOne}/stocklabels/print/avery?generateMissingUpcs=${generateMissingUpcs}${
      locationId ? `&locationId=${locationId}` : ""
    }`;

    DoRicsApiXhr(endpoint, stockLabels, resolve, reject, "PUT");
  });
};

const printZebraLabels = (stockLabels: Array<IStockLabel>, template: string, generateMissingUpcs: boolean, locationId?: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${APIVersions.TwoDotOne}/stocklabels/print/zebra/${template}?generateMissingUpcs=${generateMissingUpcs}${
      locationId ? `&locationId=${locationId}` : ""
    }`;

    DoRicsApiXhr(endpoint, stockLabels, resolve, reject, "PUT");
  });
};

const getLatestPrintSetId = (): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${APIVersions.TwoDotOne}/stocklabels/printsets/latest/id`;

    const onSuccess = (response: string) => {
      let id: string = JSON.parse(response);
      if (id == null) {
        resolve(undefined);
        return;
      }

      resolve(id);
    };

    DoRicsApiXhr(endpoint, null, onSuccess, reject, "GET");
  });
};

const getPrintSet = (printSetId: string, skip?: number, take?: number): Promise<Array<IStockLabel>> => {
  return new Promise((resolve, reject) => {
    let endpoint = `v${APIVersions.TwoDotOne}/stocklabels/printsets/${printSetId}`;

    if (skip !== undefined && take !== undefined) {
      endpoint += `?skip=${skip}&take=${take}`;
    }

    const onSuccess = (response: string) => {
      let results: Array<IStockLabel> = JSON.parse(response);
      resolve(results);
    };

    DoRicsApiXhr(endpoint, null, onSuccess, reject, "GET");
  });
};

const restorePrintSet = (printSetId: string): Promise<Array<IStockLabel>> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${APIVersions.TwoDotOne}/stocklabels/printsets/${printSetId}/restore`;

    const onSuccess = (response: string) => {
      let results: Array<IStockLabel> = JSON.parse(response);
      resolve(results);
    };

    DoRicsApiXhr(endpoint, null, onSuccess, reject, "PUT");
  });
};

export const StockLabelService: IStockLabelService = {
  getStockLabels: getStockLabels,
  deleteStockLabels: deleteStockLabels,
  printAveryLabels: printAveryLabels,
  getLatestPrintSetId: getLatestPrintSetId,
  getPrintSet: getPrintSet,
  printZebraLabels: printZebraLabels,
  getStockLabelsForLocation: getStockLabelsForLocation,
  restorePrintSet: restorePrintSet
};

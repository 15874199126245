import { IonCol, IonGrid, IonHeader, IonRow } from "@ionic/react";
import React from "react";

import '../../../theme/integrations/ecommerce/ECommerceAccountsGrid.css';

interface ECommerceAccountsGridProps {
    storeNameHeader: string
    createGridRows: Function
};

const ECommerceAccountsGrid: React.FC<ECommerceAccountsGridProps> = (props) => {
    return (
        <>
            <IonGrid className="ecommerce-accounts-grid" fixed={true}>
                <div className="ecommerce-accounts-heading">Accounts</div>
                <IonRow className="ecommerce-accounts-grid-header">
                    <IonCol size="2">
                        Label
                    </IonCol>
                    <IonCol size="2">
                        {props.storeNameHeader}
                    </IonCol>
                    <IonCol size="3">
                        Contact Email
                    </IonCol>
                    <IonCol size="2">
                        Start Date
                    </IonCol>
                    <IonCol size="2">
                        Last Updated Date
                    </IonCol>
                    <IonCol size="1">
                        Status
                    </IonCol>
                </IonRow>
                {props.createGridRows()}
            </IonGrid>
        </>
    );
};

export default ECommerceAccountsGrid;
import React, { useState } from "react";
import { ECommerceConstants, ECommerceOrderSaleConfigurationConstants } from "../../../../constants/ECommerceConstants";
import { CreateECommerceAccountBase, ECommerceOrderSaleConfiguration } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupInventoryModalProps extends ECommerceAccountSetupModalPropsBase {
  updateAccount(account: CreateECommerceAccountBase): void;
}

const ECommerceAccountSetupInventoryModal: React.FC<ECommerceAccountSetupInventoryModalProps> = (props) => {
  const [orderSaleConfig, setOrderSaleConfig] = useState(ECommerceOrderSaleConfiguration.PaymentCapturedAndFulfilled);
  const [inventoryThreshold, setInventoryThreshold] = useState<number>(1);

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  const getOrderSaleConfigValue = () => {
    if (orderSaleConfig === ECommerceOrderSaleConfiguration.PaymentCaptured) {
      return ECommerceOrderSaleConfigurationConstants.PaymentCaptured;
    } else {
      return ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled;
    }
  };

  const setOrderSaleConfigValue = (value: string) => {
    if (value === ECommerceOrderSaleConfigurationConstants.PaymentCaptured) {
      setOrderSaleConfig(ECommerceOrderSaleConfiguration.PaymentCaptured);
    } else if (value === ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled) {
      setOrderSaleConfig(ECommerceOrderSaleConfiguration.PaymentCapturedAndFulfilled);
    }
  };

  const updateTempAccount = () => {
    let tempAccount: CreateECommerceAccountBase = JSON.parse(window.sessionStorage.getItem(ECommerceConstants.tempAccountKey)!);
    tempAccount.orderSaleConfiguration = orderSaleConfig;
    tempAccount.inventoryThreshold = inventoryThreshold;

    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(tempAccount));

    props.updateAccount(tempAccount);
  };

  const onGoBack = () => {
    setOrderSaleConfig(ECommerceOrderSaleConfiguration.PaymentCapturedAndFulfilled);
    setInventoryThreshold(1);

    if (props.onGoBack) props.onGoBack();
  };

  const onNext = () => {
    updateTempAccount();

    if (props.onNext) {
      props.onNext();
    }
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Additional Settings",
    headerSubTitle: "Choose when a sale is recorded in RICS and enter an inventory threshold (optional)",
    onNext: onNext,
    onGoBack: onGoBack
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body")}>
          <div className="row">
            <div className={getClass("body-edit-field-holder") + " w-50"}>
              <label className={getClass("body-edit-field-holder-header")}>Record Sale in RICS</label>
              <select
                className="edit-field-holder-select"
                defaultValue={getOrderSaleConfigValue()}
                onChange={(e) => setOrderSaleConfigValue(e.target.value)}>
                <option
                  id={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                  label={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                  value={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                />
                <option
                  id={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                  label={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                  value={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                />
              </select>
            </div>
            <div className={getClass("body-edit-field-holder") + " w-50"}>
              <label className={getClass("body-edit-field-holder-header")}>Inventory Threshold</label>
              <input
                className={getClass("body-edit-field-holder-input")}
                defaultValue={inventoryThreshold}
                value={inventoryThreshold}
                type="number"
                min={0}
                pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                onChange={(e) => setInventoryThreshold(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupInventoryModal;

import React from 'react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import { IonToggle, IonIcon, IonLabel } from '@ionic/react';

interface EnableStandAloneReturnsProps {
    enableStandAloneReturns: boolean;
    isDisabled?: boolean;
    onChange: Function;
}

const EnableStandAloneReturns: React.FC<EnableStandAloneReturnsProps> = (props) => {
    return (
        <div className="ion-float-right refund-settings-tooltip-holder ion-align-self-center">
            <div className="refund-settings ion-align-items-center" >
                <IonToggle className="refund-settings-toggle" checked={props.enableStandAloneReturns} onIonChange={e => props.onChange(e.detail.checked)} disabled={props.isDisabled} />
                <IonLabel className="refund-settings-info">Enable standalone returns</IonLabel>
                <div className="refund-settings-tooltip">
                    <IonIcon src="assets/img/info.svg" className="info"></IonIcon>
                    <span className="tooltiptext">When toggled on, individual locations can issue stand-alone returns without a prior sale. When toggled off, stand-alone returns will be disabled.</span>
                </div>
            </div>
        </div>
    );
};

export default EnableStandAloneReturns;
import { Role } from "../interfaces/Role";

export const roleNames = {
  Salesperson: "Salesperson",
  Manager: "Manager",
  Owner: "Owner",
  User: "User",
  Rics: "Rics",
  Inventory: "Inventory",
  Buyer: "Buyer"
} as const;

export const roles: Role[] = [
  {
    Name: roleNames.Salesperson,
    DisplayName: "Sales Associate",
    Description: "Sales associates can access the POS and process the transactions.",
    Hidden: false,
    Rank: 5,
    Id: 5
  },
  {
    Name: roleNames.Manager,
    DisplayName: "Manager",
    Description: "Managers can access the POS and perform manager actions.",
    Hidden: false,
    Rank: 10,
    Id: 6
  },
  {
    Name: roleNames.Owner,
    DisplayName: "Administrator",
    Description: "Admins can access POS and the web portal to manage all settings.",
    Hidden: false,
    Rank: 15,
    Id: 2
  },
  {
    Name: roleNames.User,
    DisplayName: "User",
    Description: "Hidden",
    Hidden: true,
    Rank: 0,
    Id: 4
  },
  {
    Name: roleNames.Rics,
    DisplayName: "Rics",
    Description: "Hidden",
    Hidden: true,
    Rank: 20,
    Id: 1
  },
  {
    Name: roleNames.Inventory,
    DisplayName: "Inventory",
    Description: "Inventory users can access the portal to manage labels",
    Hidden: false,
    Rank: 1,
    Id: 7
  },
  {
    Name: roleNames.Buyer,
    DisplayName: "Buyer",
    Description: "Buyers can access the portal to manage products",
    Hidden: false,
    Rank: 1,
    Id: 8
  }
];

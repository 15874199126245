import { IonCheckbox, IonItem, IonLabel, IonText } from "@ionic/react";
import React, { useEffect } from "react";
import { Role } from "../../interfaces/Role";

interface UserRoleSelectionItemProps {
  role: Role;
  onSelectedRoleChanged: (role: Role) => void;
}

const UserRoleSelectionItem: React.FC<UserRoleSelectionItemProps> = (props) => {
  const [isSelected, setIsSelected] = React.useState(props.role.Selected ?? false);

  useEffect(() => {
    setIsSelected(props.role.Selected ?? false);
  }, [props.role.Selected]);

  const onCheckboxChange = (checked: boolean) => {
    setIsSelected(checked);
    props.role.Selected = checked;
    props.onSelectedRoleChanged(props.role);
  };

  return (
    <IonItem lines="none" key={props.role.Name}>
      <IonCheckbox className="role-popover-checkbox" key={props.role.Name} onIonChange={(e) => onCheckboxChange(e.detail.checked)} checked={isSelected} />
      <IonLabel className="ion-text-wrap">
        <IonText className="role-popover-title">{props.role.DisplayName}</IonText>
        <br />
        <IonText className="role-popover-description">{props.role.Description}</IonText>
      </IonLabel>
    </IonItem>
  );
};

export default UserRoleSelectionItem;

import { IonImg } from "@ionic/react";
import React from "react";

interface IntegrationLogoProps {
    logoUrl: string;
}

const IntegrationLogo: React.FC<IntegrationLogoProps> = (props) => {
    return (
        <div className="integration-card-logo-holder">
            <IonImg className="integration-card-logo" src={props.logoUrl}></IonImg>
        </div>
    );
};

export default IntegrationLogo;
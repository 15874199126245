import { IonButton, IonCol, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonPopover, IonRow } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";
import React, { SyntheticEvent, useState } from "react";
import { useHistory } from "react-router";
import { ECommerceAccountStatus } from "../../../interfaces/integrations/ecommerce/ECommerceAccountBase";

interface ECommerceAccountsGridRowProps {
    accountId: string,
    label: string,
    storeName: string,
    contactEmail: string,
    startDate: Date,
    lastUpdatedDate?: Date,
    accountStatus: ECommerceAccountStatus,
    route: string
};

const ECommerceAccountsGridRow: React.FC<ECommerceAccountsGridRowProps> = (props) => {
    let history = useHistory();

    const formatDateCol = (date?: Date) => {
        if (date) {
            const dateTime = new Date(date);
            const dateTimeFormat = new Intl.DateTimeFormat('en', { month: '2-digit', day: '2-digit', year: 'numeric' });
            const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(dateTime)
            return `${month}/${day}/${year}`;
        } else {
            return "None";
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: props.route,
            search: `?accountId=${props.accountId}`
        });
    };

    return (
        <>
            <IonRow key={props.accountId} className="ecommerce-accounts-grid-row" onClick={() => onRowClick()}>
                <IonCol size="2" className="ecommerce-account-label-col">
                    {props.label}
                </IonCol>
                <IonCol size="2">
                    {props.storeName}
                </IonCol>
                <IonCol size="3">
                    {props.contactEmail}
                </IonCol>
                <IonCol size="2">
                    {formatDateCol(props.startDate)}
                </IonCol>
                <IonCol size="2">
                    {formatDateCol(props.lastUpdatedDate)}
                </IonCol>
                <IonCol size="1">
                    <IonItem lines="none" className={"ecommerce-account-status-item " + (props.accountStatus === ECommerceAccountStatus.Active ? "active" : "")}>
                        <IonLabel class="ecommerce-account-status-label">{props.accountStatus}</IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>
        </>
    );
};

export default ECommerceAccountsGridRow;

import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonText, IonToggle } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import React, { useEffect } from "react";

/* Theme variables */
import "../theme/variables.css";

import LeftRightContent from "../components/LeftRightContent";
import SettingsCard from "../components/SettingsCard";
import { SaleSetting } from "../interfaces/salesettings/SaleSettings";
import "../theme/Settings.css";
import { useDataService } from "./DataServiceProvider";
import { usePortal } from "./PortalProvider";

interface PaymentDetailsProps {
  locationSettings: SaleSetting;
  locationId?: string;
  onboardingWorkflow?: Boolean;
  onComplete?: Function;
  onClutchEnabledChanged?: Function;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  locationSettings,
  locationId,
  onboardingWorkflow = false,
  onComplete,
  onClutchEnabledChanged
}) => {
  const dataService = useDataService();
  const portal = usePortal();
  const [tenderTypeOptions, setTenderTypeOptions] = React.useState(locationSettings.tenderTypeOptions);
  const [returnMethods, setReturnMethods] = React.useState(locationSettings.returnMethods);
  const [isTenderTypeValid, setIsTenderTypeValid] = React.useState(!tenderTypeOptions!.every((x) => x.enabled === false));
  const [roundingRuleId, setRoundingRuleId] = React.useState(locationSettings.roundingRule);
  const [canSave, setCanSave] = React.useState(false);
  const [locationsCanOverride, setLocationsCanOverride] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
    var canOverride = portal!.State.saleSettings["Tenant"].locationsCanOverridePaymentDetails ?? true;
    if (locationId && !canOverride) {
      setIsDisabled(true);
    }

    setLocationsCanOverride(canOverride);
  }, []);

  const RoundingRuleIds = {
    Nearest: "Nearest",
    Up: "Up",
    Down: "Down",
    NearestFiveCents: "NearestFiveCents"
  };

  //TODO: Clean this up
  const onUpdateItems = (index: number) => {
    var currentOptions = tenderTypeOptions!;
    currentOptions[index].enabled = !currentOptions[index].enabled;
    setTenderTypeOptions(currentOptions);
    var isTenderTypeValid = !currentOptions.every((x) => x.enabled === false);
    setIsTenderTypeValid(isTenderTypeValid);
    setCanSave(isTenderTypeValid);
    if (
      currentOptions[index].tenderTypeOptionId == "GiftCard" &&
      currentOptions[index].tenderTypeOptionSubTypeId == "Clutch" &&
      !currentOptions[index].enabled
    ) {
      var clutchReturnMethod = returnMethods?.find((x) => x.returnMethodId == "GiftCard" && x.returnMethodSubTypeId == "Clutch");
      if (clutchReturnMethod) {
        clutchReturnMethod.enabled = false;
      }

      setReturnMethods(returnMethods);
    }
  };

  const updateSelectRounding = (rule: string) => {
    if (rule === roundingRuleId) {
      return;
    }

    setRoundingRuleId(rule);
    setCanSave(isTenderTypeValid);
  };

  const save = () => {
    portal!.navigation.isLoading(true);
    setCanSave(false);

    var salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings["Tenant"];
    } else {
      salesSettings = portal!.State.saleSettings["Tenant"];
    }

    var settingsToSave: SaleSetting = {
      tenderTypeOptions: tenderTypeOptions,
      returnMethods: returnMethods,
      roundingRule: roundingRuleId,
      locationId: locationId
    };

    Object.assign(salesSettings, settingsToSave);

    if (!locationId) {
      salesSettings.locationsCanOverridePaymentDetails = locationsCanOverride;
    } else {
      if (salesSettings.deviceSettings) {
        salesSettings.deviceSettings.locationsCanOverride = undefined;
      }
      salesSettings.locationsCanOverridePaymentDetails = undefined;
      salesSettings.locationsCanOverrideRefunds = undefined;
    }

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    var onSuccess = function () {
      dataService!.enqueueLocationForUpdate(locationId);
      portal!.navigation.isLoading(true);

      var onSuccess2 = function () {
        if (onClutchEnabledChanged) {
          onClutchEnabledChanged();
        }
        portal!.navigation.isLoading(false);
      };

      dataService!.getSaleSettings(onSuccess2, onFailure);
    };

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);

    if (onboardingWorkflow === true && onComplete) {
      onComplete(1);
    }
  };

  const onSetLocationsCanOverride = (canOverride: boolean) => {
    setLocationsCanOverride(canOverride);
    setCanSave(true);
  };

  return (
    <SettingsCard
      header="Payment Details"
      subHeading="Specify supported tender, currency type, and rounding rules."
      LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
      isDisabled={locationId !== undefined}>
      <LeftRightContent heading="Tender Types" preamble="How can customers pay at the register?">
        <IonGrid>
          <IonRow>
            {tenderTypeOptions!.map((tenderTypeOption, index) => {
              return (
                <IonCol key={tenderTypeOption.tenderTypeOptionId + tenderTypeOption.tenderTypeOptionSubTypeId} size="6">
                  <IonItem lines="none" className="setting">
                    <IonToggle checked={tenderTypeOption.enabled} disabled={isDisabled} onClick={() => onUpdateItems(index)} className="setting" />
                    <IonText>{tenderTypeOption.tenderTypeOptionSubTypeLabel ?? tenderTypeOption.tenderTypeOptionLabel}</IonText>
                  </IonItem>
                  {tenderTypeOption.tenderTypeOptionId === "GiftCard" &&
                  tenderTypeOption.tenderTypeOptionSubTypeId === "Clutch" &&
                  tenderTypeOption.enabled &&
                  !locationId ? (
                    <IonItem lines="none" className="setting">
                      <IonText color="danger">Configure at Location(s)</IonText>
                    </IonItem>
                  ) : (
                    <></>
                  )}
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
        <IonLabel hidden={isTenderTypeValid} color="danger">
          You must enable at least one payment type.
        </IonLabel>
      </LeftRightContent>
      <LeftRightContent
        heading="Rounding Rules"
        preamble="Sometimes discounts cause transaction subtotals to fall on fractions of a cent. In these cases, the system will round the subtotal according to the rule you specify below.">
        <IonRadioGroup className="payment-details-radio-buttons" value={roundingRuleId} onIonChange={(e) => updateSelectRounding(e.detail.value)}>
          <IonItem lines="none" className="setting" onClick={() => updateSelectRounding(RoundingRuleIds.Nearest)}>
            <IonRadio value={RoundingRuleIds.Nearest} disabled={isDisabled} />
            <IonText>
              Round Nearest (Most Common) <span className="muted">(e.g. $50.096 &rarr; $50.10 or $50.093 &rarr; $50.09)</span>
            </IonText>
          </IonItem>
          <IonItem lines="none" className="setting" onClick={() => updateSelectRounding(RoundingRuleIds.Up)}>
            <IonRadio value={RoundingRuleIds.Up} disabled={isDisabled} />
            <IonText>
              Round Up <span className="muted">(e.g. $50.096 &rarr; $50.10 or $50.093 &rarr; $50.10)</span>
            </IonText>
          </IonItem>
          <IonItem lines="none" className="setting" onClick={() => updateSelectRounding(RoundingRuleIds.Down)}>
            <IonRadio value={RoundingRuleIds.Down} disabled={isDisabled} />
            <IonText>
              Round Down <span className="muted">(e.g. $50.096 &rarr; $50.09)</span>
            </IonText>
          </IonItem>
          <IonItem lines="none" className="setting" onClick={() => updateSelectRounding(RoundingRuleIds.NearestFiveCents)}>
            <IonRadio value={RoundingRuleIds.NearestFiveCents} disabled={isDisabled} />
            <IonText>
              Round Nearest 5&cent; <span className="muted">(e.g. $50.03 &rarr; $50.05)</span>
            </IonText>
          </IonItem>
        </IonRadioGroup>
      </LeftRightContent>
      <div className="buttonsHolder">
        <IonButton
          color="primary"
          disabled={isDisabled || !canSave}
          onClick={() => save()}
          className="save-setting"
          hidden={onboardingWorkflow === true}>
          Save Changes
        </IonButton>
        <IonButton
          color="primary"
          onClick={() => save()}
          className="save-setting ion-float-right onboarding-next"
          hidden={onboardingWorkflow === false}>
          <IonLabel>Next: Refunds</IonLabel>
          <IonIcon src={arrowForward} className="icon-icon-arrow-right" />
        </IonButton>
      </div>
    </SettingsCard>
  );
};

export default PaymentDetails;

export const Paths = {
  Default: "/",
  Login: "/login",
  Password: "/password",
  AccountSetupComplete: "/accountsetupcomplete",
  PrivacyPolicy: "/privacypolicy",
  DeviceActivationApproved: "/deviceActivationApproved",
  DeviceActivationDeclined: "/deviceActivationDeclined",
  About: "/about",
  Welcome: "/welcome",
  Locations: "/locations",
  LocationDetails: "/locationdetails",
  Users: "/users",
  Devices: "/devices",
  Settings: "/settings",
  Home: "/home",
  Integrations: "/integrations",
  Amazon: "/integrations/amazon",
  AmazonAccounts: "/integrations/amazon/accounts",
  ManageLabels: "/managelabels",
  GlobalProductCatalog: "/globalproductcatalog",
  Reputation: "/reputation"
} as const;

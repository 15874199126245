import React, {  } from 'react';

/* Theme variables */
import '../../theme/variables.css';

import '../../theme/integrations/Integrations.css';
import TenantLevelPageHeader from '../../components/TenantLevelPageHeader';
import { IonContent, IonPage } from '@ionic/react';
import IntegrationsAccordion from '../../components/integrations/IntegrationsAccordion';

const Integrations: React.FC = () => {
    return (
        <IonPage>
            <TenantLevelPageHeader
                headerText="Integrations"
                headerIconFileUrl="assets/img/integration.svg" />
            <IonContent class="integrations-content">
                <div className="integrations-section">
                    <div className="integrations-layout-container">
                        <IntegrationsAccordion />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Integrations;
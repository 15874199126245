import { IonButton, IonInput, IonItem, IonLabel, IonRadio, IonRadioGroup, IonText, IonToggle } from "@ionic/react";
import React, { useEffect } from "react";

/* Theme variables */
import "../theme/variables.css";

import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlags } from "../constants/FeatureFlags";
import { DeviceSettings } from "../interfaces/salesettings/DeviceSettings";
import { SaleSetting } from "../interfaces/salesettings/SaleSettings";
import "../theme/Settings.css";
import { useDataService } from "./DataServiceProvider";
import LeftRightContent from "./LeftRightContent";
import { usePortal } from "./PortalProvider";
import SettingsCard from "./SettingsCard";

export interface POSDeviceSettingsProps {
  locationSettings: SaleSetting;
  locationId?: string;
  onboardingWorkflow?: Boolean;
  onComplete?: Function;
  showStandAloneReturnsOption?: boolean;
  enableStandAloneReturns?: boolean;
}

const POSDeviceSettings: React.FC<POSDeviceSettingsProps> = ({
  locationSettings,
  locationId,
  onboardingWorkflow = false,
  onComplete,
  showStandAloneReturnsOption,
  enableStandAloneReturns
}) => {
  //initial field values to check against current when IonInputs change, for save button enablement
  const deviceTimeoutIdInitialValue = locationSettings.deviceSettings?.deviceTimeoutId;
  const applyDiscountsEnabledInitialValue = locationSettings.deviceSettings?.managerActions?.applyDiscounts?.enabled ?? false;
  const applyDiscountsDollarAmountInitialValue = locationSettings.deviceSettings?.managerActions?.applyDiscounts?.dollarAmount;
  const applyDiscountsPercentInitialValue = locationSettings.deviceSettings?.managerActions?.applyDiscounts?.percent;
  const modifyPriceInitialValue = locationSettings.deviceSettings?.managerActions?.modifyPrice;
  const returnThresholdEnabledInitialValue = locationSettings.deviceSettings?.managerActions?.returnThreshold?.enabled ?? false;
  const returnThresholdDollarAmountInitialValue = locationSettings.deviceSettings?.managerActions?.returnThreshold?.dollarAmount;
  const manageDrawerInitialValue = locationSettings.deviceSettings?.managerActions?.manageDrawer ?? false;
  const cashCountEnabledInitialValue = locationSettings.deviceSettings?.cashCountEnabled ?? false;
  const allowStandAloneReturnsInitialValue = locationSettings.deviceSettings?.managerActions?.allowStandAloneReturns ?? false;

  const dollarAmountBaseCssClass = "input-dollar-amount";
  const percentBaseCssClass = "input-percent";
  const invalidControlCssClass = "control-invalid";
  const dataService = useDataService();
  const portal = usePortal();
  const flags = useFlags();
  const [deviceTimeoutId, setDeviceTimeoutId] = React.useState(deviceTimeoutIdInitialValue);
  const [applyDiscountsEnabled, setApplyDiscountsEnabled] = React.useState(applyDiscountsEnabledInitialValue);
  const [applyDiscountsDollarAmount, setApplyDiscountsDollarAmount] = React.useState(applyDiscountsDollarAmountInitialValue);
  const [applyDiscountsPercent, setApplyDiscountsPercent] = React.useState(applyDiscountsPercentInitialValue);
  const [modifyPrice, setModifyPrice] = React.useState(modifyPriceInitialValue);
  const [returnThresholdEnabled, setReturnThresholdEnabled] = React.useState(returnThresholdEnabledInitialValue);
  const [returnThresholdDollarAmount, setReturnThresholdDollarAmount] = React.useState(returnThresholdDollarAmountInitialValue);
  const [locationsCanOverride, setLocationsCanOverride] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [canSave, setCanSave] = React.useState(false);
  const [returnThresholdCssClass, setReturnThresholdCssClass] = React.useState(dollarAmountBaseCssClass);
  const [applyDiscountsDollarCssClass, setApplyDiscountsDollarCssClass] = React.useState(dollarAmountBaseCssClass);
  const [applyDiscountsPercentCssClass, setApplyDiscountsPercentCssClass] = React.useState(percentBaseCssClass);
  const [manageDrawer, setManageDrawer] = React.useState(manageDrawerInitialValue);
  const [standAloneReturn, setStandAloneReturn] = React.useState(allowStandAloneReturnsInitialValue);
  const [cashCountEnabled, setCashCountEnabled] = React.useState(cashCountEnabledInitialValue);
  const [cashCountFeatureEnabled, setCashCountFeatureEnabled] = React.useState(false);

  useEffect(() => {
    let deviceSettings = portal!.State.saleSettings["Tenant"].deviceSettings;
    let canOverride = true;

    if (deviceSettings) {
      canOverride = deviceSettings.locationsCanOverride ?? true;
    }

    if (locationId && !canOverride) {
      setIsDisabled(true);
    }

    setLocationsCanOverride(canOverride);
  }, []);

  useEffect(() => {
    getCashCountFeatureFlag();
  }, [flags]);

  const getCashCountFeatureFlag = () => {
    var cashCountFeatureFlag = flags[FeatureFlags.CashCount];
    setCashCountFeatureEnabled(cashCountFeatureFlag);
  };

  const deviceTimeouts = [
    { Id: "Never", DisplayText: "Never" },
    { Id: "OneMinute", DisplayText: "After 1 minute" },
    { Id: "FiveMinutes", DisplayText: "After 5 minutes" },
    { Id: "TenMinutes", DisplayText: "After 10 minutes" },
    { Id: "ThirtyMinutes", DisplayText: "After 30 minutes" },
    { Id: "EverySale", DisplayText: "At the conclusion of every sale" }
  ];

  const save = () => {
    portal!.navigation.isLoading(true);

    let salesSettings = {} as SaleSetting;
    if (locationId) {
      salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings["Tenant"];
    } else {
      salesSettings = portal!.State.saleSettings["Tenant"];
    }

    let settingsToSave: DeviceSettings = {
      deviceTimeoutId: deviceTimeoutId,
      cashCountEnabled: cashCountEnabled,
      managerActions: {
        applyDiscounts: {
          enabled: applyDiscountsEnabled,
          dollarAmount: applyDiscountsDollarAmount,
          percent: applyDiscountsPercent
        },
        modifyPrice: modifyPrice,
        manageDrawer: manageDrawer,
        returnThreshold: {
          enabled: returnThresholdEnabled,
          dollarAmount: returnThresholdDollarAmount
        },
        allowStandAloneReturns: standAloneReturn
      }
    };

    if (!salesSettings.deviceSettings) {
      salesSettings.deviceSettings = {};
    }

    Object.assign(salesSettings.deviceSettings, settingsToSave);

    if (!locationId) {
      salesSettings.deviceSettings!.locationsCanOverride = locationsCanOverride;
    } else {
      salesSettings.locationId = locationId;
      salesSettings.deviceSettings!.locationsCanOverride = undefined;
      salesSettings.locationsCanOverridePaymentDetails = undefined;
      salesSettings.locationsCanOverrideRefunds = undefined;
    }

    let onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    let onSuccess = function () {
      portal!.navigation.isLoading(true);

      let onSuccess2 = function () {
        portal!.navigation.isLoading(false);
        setCanSave(false);
      };

      dataService!.getSaleSettings(onSuccess2, onFailure);
    };

    dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);

    if (onboardingWorkflow === true && onComplete) {
      onComplete(1);
    }
  };

  const onBack = () => {
    if (onComplete) {
      onComplete(-1);
    }
  };

  const onSetLocationsCanOverride = (canOverride: boolean) => {
    setLocationsCanOverride(canOverride);
    setCanSave(true);
  };

  const onSetApplyDiscounts = (value: boolean) => {
    setApplyDiscountsEnabled(value);
    setCanSave(true);
  };

  const onSetReturnThreshold = (value: boolean) => {
    setReturnThresholdEnabled(value);
    setCanSave(true);
  };

  const onSetModifyPrice = (value: boolean) => {
    setModifyPrice(value);
    setCanSave(true);
  };

  const onSetManageDrawer = (value: boolean) => {
    setManageDrawer(value);
    setCanSave(true);
  };

  const onSetStandAloneReturn = (value: boolean) => {
    setStandAloneReturn(value);
    setCanSave(true);
  };

  const onSetCashCountEnabled = (value: boolean) => {
    if (value === false) {
      setManageDrawer(false);
    }

    setCashCountEnabled(value);
    setCanSave(true);
  };

  const onChangeApplyDiscountsDollarAmount = (value: string | null | undefined) => {
    let isValid = true;
    let numberValue: number | undefined = undefined;

    if (value) {
      numberValue = Number(value);
      isValid = numberValue > 0;

      setApplyDiscountsDollarAmount(numberValue);
    } else {
      isValid = true;
      setApplyDiscountsDollarAmount(undefined);
    }

    let fieldsValid = areAllFieldsValid(numberValue, applyDiscountsPercent, returnThresholdDollarAmount);
    let fieldsHaveChanged = haveAnyFieldsChanged(numberValue, applyDiscountsPercent, returnThresholdDollarAmount);
    setCanSave(fieldsValid && fieldsHaveChanged);
    setApplyDiscountsDollarCssClass(isValid ? dollarAmountBaseCssClass : `${dollarAmountBaseCssClass} ${invalidControlCssClass}`);
  };

  const onChangeApplyDiscountsPercentAmount = (value: string | null | undefined) => {
    let isValid = true;
    let numberValue: number | undefined = undefined;

    if (value) {
      numberValue = Number(value);
      isValid = numberValue > 0 && numberValue < 100;

      setApplyDiscountsPercent(numberValue);
    } else {
      setApplyDiscountsPercent(undefined);
    }

    let fieldsValid = areAllFieldsValid(applyDiscountsDollarAmount, numberValue, returnThresholdDollarAmount);
    let fieldsHaveChanged = haveAnyFieldsChanged(applyDiscountsDollarAmount, numberValue, returnThresholdDollarAmount);
    setCanSave(fieldsValid && fieldsHaveChanged);
    setApplyDiscountsPercentCssClass(isValid ? percentBaseCssClass : `${percentBaseCssClass} ${invalidControlCssClass}`);
  };

  const onChangeReturnThresholdDollarAmount = (value: string | null | undefined) => {
    let isValid = true;
    let numberValue: number | undefined = undefined;

    if (value) {
      numberValue = Number(value);
      isValid = numberValue > 0;

      setReturnThresholdDollarAmount(numberValue);
    } else {
      setReturnThresholdDollarAmount(undefined);
    }

    let fieldsValid = areAllFieldsValid(applyDiscountsDollarAmount, applyDiscountsPercent, numberValue);
    let fieldsHaveChanged = haveAnyFieldsChanged(applyDiscountsDollarAmount, applyDiscountsPercent, numberValue);
    setCanSave(fieldsValid && fieldsHaveChanged);
    setReturnThresholdCssClass(isValid ? dollarAmountBaseCssClass : `${dollarAmountBaseCssClass} ${invalidControlCssClass}`);
  };

  const areAllFieldsValid = (
    applyDiscountsDollarAmount: number | undefined,
    applyDiscountsPercent: number | undefined,
    returnThresholdDollarAmount: number | undefined
  ) => {
    let isValid = true;
    if (isValid && applyDiscountsDollarAmount) {
      isValid = applyDiscountsDollarAmount > 0;
    }

    if (isValid && applyDiscountsPercent) {
      isValid = applyDiscountsPercent > 0 && applyDiscountsPercent < 100;
    }

    if (isValid && returnThresholdDollarAmount) {
      isValid = returnThresholdDollarAmount > 0;
    }

    return isValid;
  };

  //POS Device Settings must keep track of the initial values of the fields, because some of them are controlled by IonInputs.
  //IonInputs fire OnIonChanged events, which triggers setting canSave=true if the values for the fields are valid.
  //So, to keep the button disabled when nothing has changed, we must check if the current values match the initial values.
  const haveAnyFieldsChanged = (
    applyDiscountsDollarAmount: number | undefined,
    applyDiscountsPercent: number | undefined,
    returnThresholdDollarAmount: number | undefined
  ) => {
    let applyDiscountsDollarAmountChanged = (applyDiscountsDollarAmount ?? 0) !== (applyDiscountsDollarAmountInitialValue ?? 0);
    let applyDiscountsPercentChanged = (applyDiscountsPercent ?? 0) !== (applyDiscountsPercentInitialValue ?? 0);
    let returnThresholdDollarAmountChanged = (returnThresholdDollarAmount ?? 0) !== (applyDiscountsPercentInitialValue ?? 0);
    let deviceTimeoutIdChanged = deviceTimeoutId !== deviceTimeoutIdInitialValue;
    let applyDiscountsEnabledChanged = applyDiscountsEnabled !== applyDiscountsEnabledInitialValue;
    let modifyPriceChanged = modifyPrice !== modifyPriceInitialValue;
    let returnThresholdEnabledChanged = returnThresholdEnabled !== returnThresholdEnabledInitialValue;
    let manageDrawerChanged = manageDrawer !== manageDrawerInitialValue;
    let cashCountEnabledChanged = cashCountEnabled !== cashCountEnabledInitialValue;
    let standAloneReturnsChanged = standAloneReturn !== allowStandAloneReturnsInitialValue;

    return (
      canSave ||
      applyDiscountsDollarAmountChanged ||
      applyDiscountsPercentChanged ||
      returnThresholdDollarAmountChanged ||
      deviceTimeoutIdChanged ||
      applyDiscountsEnabledChanged ||
      modifyPriceChanged ||
      returnThresholdEnabledChanged ||
      manageDrawerChanged ||
      cashCountEnabledChanged ||
      standAloneReturnsChanged
    );
  };

  const onUpdateDeviceTimeout = (timeoutId: string) => {
    if (timeoutId === deviceTimeoutId) {
      return;
    }

    setDeviceTimeoutId(timeoutId);
    setCanSave(true);
  };

  return (
    <>
      <SettingsCard
        header="Point of Sale Device Settings"
        subHeading="Configure preferences and settings for the POS devices."
        LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
        isDisabled={locationId !== undefined}>
        <LeftRightContent heading="Device Timeout" preamble="When would you like the POS to automatically lock and return to the PIN screen?">
          <IonRadioGroup data-testid="device-timeout" value={deviceTimeoutId} onIonChange={(e) => onUpdateDeviceTimeout(e.detail.value)}>
            {deviceTimeouts.map((deviceTimeout) => (
              <IonItem key={deviceTimeout.Id} lines="none" className="setting" onClick={() => onUpdateDeviceTimeout(deviceTimeout.Id)}>
                <IonRadio data-testid={`device-timeout-${deviceTimeout.Id}`} disabled={isDisabled} value={deviceTimeout.Id} className="setting" />
                <IonText className="setting">{deviceTimeout.DisplayText}</IonText>
              </IonItem>
            ))}
          </IonRadioGroup>
        </LeftRightContent>

        {cashCountFeatureEnabled && (
          <LeftRightContent
            heading="Cash Count"
            preamble="Will require all cash drawers to be counted at the start and end of each day before any sale can be made. If enabled with the manager action 'Manage the POS Drawer', a manager will need to enter their PIN in order to access the cash count feature and open the POS for the day.">
            <IonItem lines="none" className="setting">
              <IonToggle
                checked={cashCountEnabled}
                className="ion-align-self-center"
                disabled={isDisabled}
                onIonChange={(e) => onSetCashCountEnabled(e.detail.checked)}
                data-testid="cash-count-enabled-toggle"
              />
              <IonText>Enable the cash count feature on the POS</IonText>
            </IonItem>
          </LeftRightContent>
        )}

        <LeftRightContent
          heading="Manager Actions"
          preamble="For security purposes, you can require a manager PIN for certain actions. All actions left un-toggled will not require a manager PIN.">
          <IonItem lines="none" className="setting">
            <IonToggle
              checked={applyDiscountsEnabled}
              className="ion-align-self-center"
              disabled={isDisabled}
              onIonChange={(e) => onSetApplyDiscounts(e.detail.checked)}
              data-testid="apply-discounts-toggle"
            />
            <IonText>Apply manual discounts (scanned coupons do not require a PIN)</IonText>
          </IonItem>
          <div className="apply-discounts-optional-text">
            <IonText>Optionally, you can specify thresholds above which a PIN will be required:</IonText>
          </div>
          <div className="apply-discounts-optional-values">
            <IonItem lines="none">
              <IonText>Require a PIN above:</IonText>
              <IonInput
                value={applyDiscountsDollarAmount}
                className={applyDiscountsDollarCssClass}
                disabled={isDisabled}
                placeholder="e.g. 50"
                type="number"
                onIonChange={(e) => onChangeApplyDiscountsDollarAmount(e.detail.value)}
                data-testid="apply-discounts-dollar-amount"
              />
              <IonText> and/or </IonText>
              <IonInput
                value={applyDiscountsPercent}
                className={applyDiscountsPercentCssClass}
                disabled={isDisabled}
                placeholder="e.g. 30"
                type="number"
                onIonChange={(e) => onChangeApplyDiscountsPercentAmount(e.detail.value)}
                data-testid="apply-discounts-percent"
              />
            </IonItem>
          </div>
          <IonItem lines="none" className="setting">
            <IonToggle
              checked={modifyPrice}
              className="ion-align-self-center"
              disabled={isDisabled}
              onIonChange={(e) => onSetModifyPrice(e.detail.checked)}
              data-testid="modify-price-toggle"
            />
            <IonText>Modify Price</IonText>
          </IonItem>
          <IonItem lines="none" className="setting">
            <IonToggle
              checked={returnThresholdEnabled}
              className="ion-align-self-center"
              disabled={isDisabled}
              onIonChange={(e) => onSetReturnThreshold(e.detail.checked)}
              data-testid="return-threshold-toggle"
            />
            <IonText>Return a product</IonText>
          </IonItem>
          <div className="apply-discounts-optional-text">
            <IonText>Optionally, you can specify a threshold above which a PIN will be required:</IonText>
          </div>
          <div className="apply-discounts-optional-values">
            <IonItem lines="none">
              <IonText>Require a PIN above:</IonText>
              <IonInput
                value={returnThresholdDollarAmount}
                disabled={isDisabled}
                className={returnThresholdCssClass}
                placeholder="e.g. 50"
                type="number"
                onIonChange={(e) => onChangeReturnThresholdDollarAmount(e.detail.value)}
                data-testid="return-threshold-dollar-amount"
              />
            </IonItem>
          </div>
          {cashCountFeatureEnabled && cashCountEnabled && (
            <IonItem lines="none" className="setting">
              <IonToggle
                checked={manageDrawer}
                className="ion-align-self-center"
                disabled={isDisabled}
                onIonChange={(e) => onSetManageDrawer(e.detail.checked)}
                data-testid="manage-drawer-toggle"
              />
              <IonText>Manage the POS Drawer</IonText>
            </IonItem>
          )}
          {showStandAloneReturnsOption && (
            <IonItem lines="none" className="setting">
              <IonToggle
                checked={enableStandAloneReturns && standAloneReturn}
                className="ion-align-self-center"
                disabled={!enableStandAloneReturns}
                onIonChange={(e) => onSetStandAloneReturn(e.detail.checked)}
                data-testid="stand-alone-return-toggle"
              />
              <IonText>Enable Stand Alone Return</IonText>
            </IonItem>
          )}
        </LeftRightContent>
        <div className="buttonsHolder" hidden={onboardingWorkflow === true}>
          <IonButton data-testid="save-settings-page" disabled={isDisabled || !canSave} onClick={() => save()} className="save-setting">
            Save Changes
          </IonButton>
        </div>
        <div className="modal-buttons-holder" hidden={onboardingWorkflow === false}>
          <IonButton data-testid="back-onboarding-workflow" onClick={() => onBack()} className="save-setting modal-back-button">
            Go Back
          </IonButton>
          <IonButton data-testid="save-onboarding-workflow" color="primary" onClick={() => save()} className="save-setting ion-float-right">
            <IonLabel>Done with Settings</IonLabel>
          </IonButton>
        </div>
      </SettingsCard>
    </>
  );
};

export default POSDeviceSettings;

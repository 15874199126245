import React from "react";
import { Button, Image } from "react-bootstrap";
import { AmazonConstants } from "../../../../../constants/ECommerceConstants";
import { CreateAmazonAccount } from "../../../../../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase
} from "../../accountSetup/ECommerceAccountSetupModal";

interface AmazonAccountTypeModalProps extends ECommerceAccountSetupModalPropsBase {}

const AmazonAccountTypeModal: React.FC<AmazonAccountTypeModalProps> = (props) => {
  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Account Type",
    headerSubTitle: "Select if your account is Fulfillment By Amazon (FBA) or Fulfillment By Merchant (FBM or MFN)",
    hideFooter: true
  };

  const onSelect = (isFba: boolean) => {
    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    tempAccount.isFba = isFba;
    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(tempAccount));

    if (props.onNext) props.onNext();
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className="amazon-account-types">
          <Button className="amazon-account-type-btn card-button button" onClick={() => onSelect(true)}>
            <Image className="image" src="assets/img/globe.svg" />
            <div className="title">FBA</div>
            <div className="sub-title">Fulfillment By Amazon</div>
          </Button>
          <Button className="amazon-account-type-btn card-button button" onClick={() => onSelect(false)}>
            <Image className="image" src="assets/img/store.svg" />
            <div className="title">FBM</div>
            <div className="sub-title">Fulfillment By Merchant</div>
          </Button>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default AmazonAccountTypeModal;

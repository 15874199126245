import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { Claims } from "../constants/Claims";
import { FeatureFlags } from "../constants/FeatureFlags";
import { Paths } from "../constants/Paths";
import { UserPermissions } from "../interfaces/ApplicationPortal";
import { hasPermissionTo } from "./claimsHelper";

export const getDefaultPath = (showWelcomeModal: boolean, permissions: Array<UserPermissions> | undefined, flags: LDFlagSet | null) => {
  if (showWelcomeModal) {
    return Paths.Welcome;
  }

  if (flags && flags[FeatureFlags.LabelsPage] && !hasPermissionTo(Claims.Owner, permissions) && hasPermissionTo(Claims.Labels, permissions)) {
    return Paths.ManageLabels;
  }

  if (
    flags &&
    flags[FeatureFlags.GlobalProductCatalogPage] &&
    !hasPermissionTo(Claims.Owner, permissions) &&
    hasPermissionTo(Claims.GlobalProductCatalogPage, permissions)
  ) {
    return Paths.GlobalProductCatalog;
  }

  return Paths.Locations;
};

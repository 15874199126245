import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ECommerceLocation } from "../../../interfaces/integrations/ecommerce/ECommerceLocation";

import "../../../theme/integrations/ecommerce/ECommerceAccountLocationsModal.css";
import ECommerceAccountLocationsTable from "./ECommerceAccountLocationsTable";

interface ECommerceAccountLocationsModalProps {
    show: boolean;
    onHide: Function;
    useFulfillmentStrategy: boolean;
    locations: ECommerceLocation[];
    onChange: Function;
};

const ECommerceAccountLocationsModal: React.FC<ECommerceAccountLocationsModalProps> = (props) => {
    const onHide = () => {
        let hasSaleLocation = props.locations.filter(l => l.isSaleLocation).length > 0;
        if (props.useFulfillmentStrategy && !hasSaleLocation) {
            alert("You must select a Sale Location.");
            return;
        }

        props.onHide();
    };

    return (
        <>
            <Modal 
                className="ecommerce-account-locations-modal"
                show={props.show}
                onHide={() => onHide()}
                size="lg"
                centered
                dialogClassName="ecommerce-account-locations-modal-dialog">
                <Modal.Header
                    bsPrefix="ecommerce-account-locations-modal-header">
                    <Modal.Title
                        bsPrefix="ecommerce-account-locations-modal-title">
                        <div>Configure Locations</div>
                    </Modal.Title>
                    <div>Select the default locations used for inventory sourcing and optionally, a sale location.</div>
                </Modal.Header>
                <Modal.Body className="ecommerce-account-locations-modal-body">
                    <div className="instructions">
                        Inventory locations determine which on-hand quantities are combined to push to your ecommerce integration's products.
                        <br/><br/>
                        A sale location should <b>ONLY BE USED</b> when you would like to record a sale at a set location for each order, regardless of which inventory sourcing location the order is fulfilled from.
                    </div>
                    <div className="locations-table">
                        <ECommerceAccountLocationsTable useFulfillmentStrategy={props.useFulfillmentStrategy} locations={props.locations} onChange={props.onChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer className="ecommerce-account-locations-modal-footer">
                    <Button className="ok-button" onClick={() => onHide()} disabled={props.useFulfillmentStrategy && props.locations.filter(l => l.isSaleLocation).length <= 0}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ECommerceAccountLocationsModal;
import { AmazonAccount, CreateAmazonAccount } from "../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import { LwaAuthorization } from "../interfaces/integrations/ecommerce/amazon/LwaAuthorization";
import { DoRicsApiXhr } from "./XhrService";

export const authorizeAccount = async (authCode: string, sellerId: string, accountId?: string): Promise<LwaAuthorization | undefined> => {
  return new Promise((resolve) => {
    const onSuccess = (response: any) => {
      const result: LwaAuthorization = JSON.parse(response);
      resolve(result);
    };

    const onFailure = () => resolve(undefined);

    const endpoint = "v1/amazon/accounts/authorize";

    let payload = {
      AuthCode: authCode,
      SellerId: sellerId,
      AccountId: accountId
    };

    DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
  });
};

export const getAccounts = async (active: boolean): Promise<Array<AmazonAccount>> => {
  return new Promise((resolve) => {
    const onSuccess = (response: any) => {
      const result: Array<AmazonAccount> = JSON.parse(response);
      resolve(result ?? new Array<AmazonAccount>());
    };

    const onFailure = () => resolve(new Array<AmazonAccount>());

    const endpoint = "v1/amazon/accounts" + (active ? "?active=true" : "");

    DoRicsApiXhr(endpoint, undefined, onSuccess, onFailure);
  });
};

export const enableAccount = async (accountId: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const onSuccess = () => resolve(true);

    const onFailure = () => resolve(false);

    const endpoint = `v1/amazon/accounts/${accountId}/enable`;

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "POST");
  });
};

export const disableAccount = async (accountId: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const onSuccess = () => resolve(true);

    const onFailure = () => resolve(false);

    const endpoint = `v1/amazon/accounts/${accountId}/disable`;

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "POST");
  });
};

export const getAccount = async (accountId: string): Promise<AmazonAccount | undefined> => {
  return new Promise((resolve) => {
    const onSuccess = (response: any) => {
      const result: AmazonAccount = JSON.parse(response);
      resolve(result);
    };

    const onFailure = () => resolve(undefined);

    const endpoint = `v1/amazon/accounts/${accountId}`;

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

export const saveAccount = async (account: AmazonAccount): Promise<AmazonAccount | undefined> => {
  return new Promise((resolve) => {
    const onSuccess = (response: any) => {
      const result: AmazonAccount = JSON.parse(response);
      resolve(result);
    };

    const onFailure = () => resolve(undefined);

    const endpoint = `v1/amazon/accounts/${account.accountId}`;

    DoRicsApiXhr(endpoint, account, onSuccess, onFailure, "PUT");
  });
};

export const createAccount = async (account: CreateAmazonAccount): Promise<AmazonAccount | undefined> => {
  return new Promise((resolve) => {
    const onSuccess = (response: any) => {
      const result: AmazonAccount = JSON.parse(response);
      resolve(result);
    };

    const onFailure = () => resolve(undefined);

    const endpoint = "v1/amazon/accounts";

    DoRicsApiXhr(endpoint, account, onSuccess, onFailure, "POST");
  });
};

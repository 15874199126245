import React from 'react';

/* Theme variables */
import '../../theme/variables.css';

import '../../theme/AddExistingUsersRow.css';

import { ExistingUser } from '../../interfaces/ExistingUser';

interface AddExistingUsersRowProps {
    user: ExistingUser;
    index: number;
    onUpdateSelected: Function;
}

const AddExistingUsersRow: React.FC<AddExistingUsersRowProps> = ({ user, index, onUpdateSelected }) => {
    const updateCheck = () => {
        var updatedUser = user;
        updatedUser.SelectedToImport = !user.SelectedToImport;
        onUpdateSelected(updatedUser.SelectedToImport);
    };

    return (
        <tr className='user-lineItem'>
            <td className="user-selected">
                <div className="checkboxHolder">
                    <label className="checkbox checkmarkEnabled">
                        <input type="checkbox" checked={user.SelectedToImport} onChange={() => updateCheck()} data-testid="select-checkbox" />
                        <span className="checkmark" />
                    </label>
                </div>
            </td>
            <td className='existing-user-id'>{user.UserId}</td>
            <td className='existing-user-first-name'>{user.FirstName}</td>
            <td className='existing-user-last-name'>{user.LastName}</td>
            <td className="existing-user-email">
                <input value={user.Email} placeholder="Email..." disabled />
            </td>
        </tr>
    );
};

export default AddExistingUsersRow;
import React from "react";
import {
  IonText,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLabel,
} from "@ionic/react";
import { arrowForward } from "ionicons/icons";

/* Theme variables */
import "../../theme/variables.css";

import "../../theme/OnboardingWorkflow.css";
import "../../theme/UsersWorkflowCard.css";
import WorkflowCard from "./WorkflowCard";
import UserImport from "../users/UserImport";

interface WorkflowCardProps {
  isComplete: boolean;
  setComplete: Function;
}

const UsersWorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(0);
  const [modalCssClass, setModalCssClass] = React.useState(
    "modal-card user-import-intro"
  );

  const openModal = () => {
    setShowInfoModal(true);
  };

  const closeModal = () => {
    props.setComplete();
    setShowInfoModal(false);
  };

  const swapModals = () => {
    setShowInfoModal(false);
    setShowImportModal(true);
  };

  const closeModalWithoutSaving = () => {
    setShowImportModal(false);
    setModalView(0);
    setModalCssClass("modal-card user-import-intro");
  };

  const onImportSuccess = () => {
    props.setComplete();
    setShowInfoModal(false);
    setShowImportModal(false);
  };

  return (
    <WorkflowCard
      title="Import Users"
      text="Import users and add them to locations so they can use the point of sale."
      icon="assets/img/user-add.svg"
      isComplete={props.isComplete}
      onButtonClick={openModal}
    >
      <IonModal
        onDidDismiss={() => setShowInfoModal(false)}
        isOpen={showInfoModal}
        className={modalCssClass}
      >
        <IonGrid hidden={modalView !== 0}>
          <IonRow>
            <IonCol className="user-intro-message">
              <div className="user-intro-holder ion-text-center">
                <IonText>
                  <h2>User Management</h2>
                  Since you're the administrator, you have already been added as
                  a user for each location. You can import additional users now,
                  or skip this step and manage them later in the Users Tab.
                </IonText>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="user-intro-buttons">
              <div className="user-intro-holder">
                <IonLabel
                  onClick={() => closeModal()}
                  color="primary"
                  className="import-later ion-float-left"
                >
                  I'll import users later
                </IonLabel>
                <IonButton
                  onClick={() => swapModals()}
                  className="intro-import-users-button ion-float-right"
                >
                  <IonText>Import Users</IonText>
                  <IonIcon src={arrowForward} />
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <UserImport
        isOpen={showImportModal}
        setIsOpen={setShowImportModal}
        closeModalWithoutSaving={closeModalWithoutSaving}
        onImportSuccess={onImportSuccess}
      />
    </WorkflowCard>
  );
};

export default UsersWorkflowCard;

import React, { useEffect } from "react";
import { Tax, ProductTaxCondition } from "../../../../interfaces/Taxes";
import AdvancedTaxesContainer from "../AdvancedTaxesContainer";
import AdvancedTaxConfigurationGrid from "./AdvancedTaxConfigurationGrid";
import { usePortal } from "../../../PortalProvider";

import "../../../../theme/locationDetails/taxes/jurisdictions/AdvancedTaxConfiguration.css";

interface AdvancedTaxConfigurationProps {
  taxes: Tax[];
  closeModal: Function;
  saveTaxes: Function;
}

const AdvancedTaxConfiguration: React.FC<AdvancedTaxConfigurationProps> = (
  props
) => {
  const portal = usePortal();

  const [taxes, setTaxes] = React.useState(props.taxes);
  const [selectedTaxId, setSelectedTaxId] = React.useState<string>(
    props.taxes[0].taxId!
  );
  const [productTaxes, setProductTaxes] = React.useState<ProductTaxCondition[]>(
    []
  );
  const [availableProductTypes, setAvailableProductTypes] = React.useState<
    string[]
  >([]);

  useEffect(() => {
    let selectedProductTaxes = taxes.find(
      (x) => x.taxId === selectedTaxId
    )?.productTaxes;
    createProductTaxes(selectedProductTaxes, selectedTaxId);
  }, [taxes, selectedTaxId]);

  useEffect(() => {
    const currentlyUsedProductTypes = productTaxes.map(
      (tax) => tax.productType
    );
    const availableTypes = Object.keys(portal!.constants.productTypes).filter(
      (type) => !currentlyUsedProductTypes.includes(type)
    );

    setAvailableProductTypes(availableTypes);
  }, [productTaxes, portal]);

  const onChangeSelectedTax = (taxId: string) => {
    setSelectedTaxId(taxId);
    let selectedProductTaxes = taxes.find(
      (x) => x.taxId === taxId
    )?.productTaxes;
    createProductTaxes(selectedProductTaxes, taxId);
  };

  const createProductTaxes = (
    productTaxes: ProductTaxCondition[] | undefined,
    taxId: string
  ) => {
    let currentProductTaxes: ProductTaxCondition[] = [];

    for (let i = 0; i < 30; i++) {
      if (!!productTaxes && i < productTaxes.length) {
        currentProductTaxes.push(productTaxes[i]);
      } else {
        currentProductTaxes.push({
          productType: undefined,
          taxRate: undefined,
          threshold: undefined,
          onlyTaxAboveThreshold: false,
        } as ProductTaxCondition);
      }
    }

    setProductTaxes(currentProductTaxes);

    let currentTaxes = taxes;
    let currentTax = currentTaxes.find((x) => x.taxId === taxId);
    currentTax!.productTaxes = currentProductTaxes;
    setTaxes(currentTaxes);
  };

  return (
    <>
      <AdvancedTaxesContainer
        taxes={props.taxes}
        closeModal={props.closeModal}
        selectedTaxId={selectedTaxId}
        onChangeSelectedTax={onChangeSelectedTax}
        onSaveChanges={props.saveTaxes}
      >
        <AdvancedTaxConfigurationGrid
          availableProductTypes={availableProductTypes}
          setAvailableProductTypes={setAvailableProductTypes}
          taxId={selectedTaxId}
          productTaxes={productTaxes}
        />
      </AdvancedTaxesContainer>
    </>
  );
};

export default AdvancedTaxConfiguration;

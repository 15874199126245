import { IonCheckbox, IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import TenantLevelPageHeader from "../components/TenantLevelPageHeader";

import { useFlags } from "launchdarkly-react-client-sdk";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import Dropdown, { DropdownSelectValue } from "../components/Dropdown";
import Modal from "../components/Modal";
import { usePortal } from "../components/PortalProvider";
import Tooltip from "../components/Tooltip";
import StockLabelsGrid from "../components/stockLabels/StockLabelsGrid";
import { FeatureFlags } from "../constants/FeatureFlags";
import { LabelTemplates } from "../constants/LabelTemplates";
import { roleNames } from "../constants/Roles";
import { SessionStorage } from "../constants/SessionStorage";
import { PrinterType } from "../enums/PrinterType";
import { IStockLabel } from "../interfaces/IStockLabel";
import { Location } from "../interfaces/Location";
import { getQueryParam } from "../services/DataService";
import { StockLabelService } from "../services/StockLabelService";
import "../theme/ManageLabels.css";
import { Printer } from "../types/Printer";

export type StockLabelDisplay = {
  StockLabel: IStockLabel;
  Selected?: boolean;
};

type StockLabelPrintSet = {
  Id: string;
  Count: number;
};

const ManageLabels: React.FC = () => {
  const flags = useFlags();
  const locationParam = getQueryParam("locationId");
  const labelsPerPage = 30;
  const browserPrint = new ZebraBrowserPrintWrapper();
  const portal = usePortal();
  const labelsContentRef = useRef<HTMLDivElement>(null);
  const [stockLabels, setStockLabels] = useState<Array<StockLabelDisplay>>([]);
  const [latestPrintSet, setLatestPrintSet] = useState<StockLabelPrintSet>();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [selectedPrinter, setSelectedPrinter] = useState<Printer>();
  const [allSelected, setAllSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [canPrint, setCanPrint] = useState(false);
  const [showInvalid, setShowInvalid] = useState(false);
  const inkjetPrinter: Printer = { id: "Laser/Inkjet", name: "Laser/Inkjet", printerType: PrinterType.Inkjet };
  const [allPrinters, setAllPrinters] = useState<Array<Printer>>([inkjetPrinter]);
  const [sortAscending, setSortAscending] = useState(true);
  const [showMissingUpcModal, setShowMissingUpcModal] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  const getLocations = () => {
    let userLocations: Array<string> = [];
    let isOwner: boolean = false;
    portal?.State?.currentUser?.roles?.forEach((r) => {
      if (r.identityRole === roleNames.Owner) {
        isOwner = true;
        return;
      }

      if (r.locationId === undefined || r.identityRole != roleNames.Inventory) {
        return;
      }

      userLocations.push(r.locationId);
    });

    let dropdownLocations: Array<DropdownSelectValue> = [{ id: "All", displayValue: "All Locations" }];
    portal!.State.locations.forEach((l) => {
      if (!isOwner && !userLocations.some((ul) => ul === l.locationId)) {
        return;
      }

      let value: DropdownSelectValue = {
        id: l.locationId,
        displayValue: l.name
      };

      dropdownLocations.push(value);
    });

    return dropdownLocations;
  };

  const getTemplates = () => {
    let hasZebra = allPrinters.some((p) => p.printerType === PrinterType.Zebra);
    return Object.values(LabelTemplates).map((t) => {
      let value: DropdownSelectValue = {
        id: t,
        displayValue: t,
        disabled: t != LabelTemplates.Laser && !hasZebra
      };

      return value;
    });
  };

  const getPrinters = () => {
    return allPrinters.map((p) => {
      let value: DropdownSelectValue = {
        id: p.id,
        displayValue: p.name
      };

      return value;
    });
  };

  const [locations, setLocations] = useState<Array<DropdownSelectValue>>(getLocations());
  const [templates, setTemplates] = useState<Array<DropdownSelectValue>>(getTemplates());
  const [printers, setPrinters] = useState<Array<DropdownSelectValue>>(getPrinters());

  const loadLabels = async (skip?: number, take?: number, locationId?: string) => {
    try {
      let labels = locationId
        ? await StockLabelService.getStockLabelsForLocation(locationId, skip, take)
        : await StockLabelService.getStockLabels(skip, take);
      let labelsToDisplay = labels.map((l) => {
        let display: StockLabelDisplay = {
          StockLabel: l,
          Selected: false
        };

        return display;
      });

      let updatedLabels = skip === 0 ? labelsToDisplay : stockLabels.concat(labelsToDisplay);
      updatedLabels.sort((a, b) => {
        return a.StockLabel.dateEntered >= b.StockLabel.dateEntered ? -1 : 1;
      });
      setStockLabels(updatedLabels);

      if (updatedLabels.length < 1) {
        return;
      }

      let nextPage = locationId
        ? await StockLabelService.getStockLabelsForLocation(locationId, updatedLabels.length, 1)
        : await StockLabelService.getStockLabels(updatedLabels.length, 1);
      setHasMore(nextPage.length > 0);
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setLoading(false);
      portal?.navigation.isLoading(false);
    }
  };

  const loadLatestPrintSet = async () => {
    try {
      let printSetId = await StockLabelService.getLatestPrintSetId();
      if (printSetId === undefined) {
        setLatestPrintSet(undefined);
        return;
      }

      let printSetLabels = await StockLabelService.getPrintSet(printSetId);
      let count = 0;
      printSetLabels.forEach((l) => (count += l.quantity));

      setLatestPrintSet({ Id: printSetId, Count: count });
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const labelsNumber = (labels: Array<StockLabelDisplay>, filterSelected: boolean) => {
    let count = 0;
    let labelsToUse = labels;
    if (filterSelected) {
      labelsToUse = labels.filter((l) => l.Selected);
    }

    labelsToUse.forEach((l) => {
      count += l.StockLabel.quantity;
    });

    return count;
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - 50) {
      if (hasMore && !loading) {
        setLoading(true);
        loadLabels(stockLabels.length, labelsPerPage, selectedLocation?.locationId);
      }
    }
  };

  const onSelectLocation = (locationId: string) => {
    let selected = portal?.State?.locations?.find((l) => l.locationId === locationId);
    setSelectedLocation(selected);

    if (selected == undefined) {
      window.sessionStorage.removeItem(SessionStorage.StockLabelsSelectedLocation);
    } else {
      window.sessionStorage.setItem(SessionStorage.StockLabelsSelectedLocation, JSON.stringify(selected));
    }

    portal?.navigation.isLoading(true);
    loadLabels(0, labelsPerPage, selected?.locationId);
  };

  const onSelectTemplate = (templateId: string) => {
    let selected = Object.values(LabelTemplates).find((t) => t === templateId);
    setSelectedTemplate(selected);
    window.sessionStorage.setItem(SessionStorage.StockLabelsSelectedTemplate, JSON.stringify(selected));

    printers.forEach((p) => {
      p.disabled = selected !== LabelTemplates.Laser ? p.id === inkjetPrinter.id : p.id !== inkjetPrinter.id;
    });

    setPrinters([...printers]);
    if (selected !== LabelTemplates.Laser) {
      setSelectedPrinter(undefined);
      window.sessionStorage.removeItem(SessionStorage.StockLabelsSelectedPrinter);
      return;
    }

    onSelectPrinter(inkjetPrinter.id);
  };

  const onSelectPrinter = (printerId: string) => {
    let selected = allPrinters.find((p) => p.id === printerId);
    setSelectedPrinter(selected);
    window.sessionStorage.setItem(SessionStorage.StockLabelsSelectedPrinter, JSON.stringify(selected));
  };

  const onSelectAll = () => {
    let selected = !allSelected;
    let updatedLabels = stockLabels.map((l) => {
      l.Selected = selected;
      return l;
    });

    setStockLabels(updatedLabels);
    setAllSelected(selected);
  };

  const onUpdateSelectedLabels = (selectedLabels: Array<StockLabelDisplay>) => {
    setStockLabels([...selectedLabels]);
    setAllSelected(selectedLabels.every((l) => l.Selected));
  };

  const onLabelDeleted = (stockLabelId: string) => {
    portal?.navigation.isLoading(true);
    StockLabelService.deleteStockLabels([stockLabelId])
      .then(() => {
        removeLabel(stockLabelId);
      })
      .catch((r) => {
        alert("Unable to delete label");
        console.error(r);
        portal?.navigation.isLoading(false);
      });
  };

  const removeLabel = (stockLabelId: string) => {
    let index = stockLabels.findIndex((l) => l.StockLabel.stockLabelId === stockLabelId);
    if (index < 0) {
      return;
    }

    stockLabels.splice(index, 1);
    setStockLabels([...stockLabels]);

    if (stockLabels.length < labelsPerPage && hasMore) {
      portal?.navigation.isLoading(true);
      loadLabels(stockLabels.length, labelsPerPage - stockLabels.length, selectedLocation?.locationId);
      return;
    }

    portal?.navigation.isLoading(false);
  };

  const removePrintedLabels = (idsToDelete: Array<string>) => {
    if (idsToDelete.length < 0) {
      return;
    }

    let remainingLabels = stockLabels.filter((l) => l.Selected === false);

    setStockLabels(remainingLabels);

    if (stockLabels.length < labelsPerPage && hasMore) {
      portal?.navigation.isLoading(true);
      loadLabels(stockLabels.length, labelsPerPage - stockLabels.length, selectedLocation?.locationId);
      return;
    }
    portal?.navigation.isLoading(false);
  };

  const onDeleteAll = async () => {
    portal?.navigation.isLoading(true);

    for (let i = 0; i < stockLabels.length; i += labelsPerPage) {
      const chunk = stockLabels.slice(i, i + labelsPerPage);
      await StockLabelService.deleteStockLabels(chunk.flatMap((l) => l.StockLabel.stockLabelId)).catch((r) => {
        alert("Unable to delete labels");
        console.error(r);
      });
    }

    labelsContentRef.current?.scrollTo(0, 0);
    setShowDeleteModal(false);
    await loadLabels(0, labelsPerPage, selectedLocation?.locationId);
  };

  const onDeleteSelected = async () => {
    portal?.navigation.isLoading(true);

    const labelsToDelete = stockLabels.filter((l) => l.Selected);

    for (let i = 0; i < labelsToDelete.length; i += labelsPerPage) {
      const chunk = labelsToDelete.slice(i, i + labelsPerPage);
      await StockLabelService.deleteStockLabels(chunk.flatMap((l) => l.StockLabel.stockLabelId)).catch((r) => {
        alert("Unable to delete labels");
        console.error(r);
      });
    }

    labelsContentRef.current?.scrollTo(0, 0);
    setShowDeleteModal(false);
    await loadLabels(0, labelsPerPage, selectedLocation?.locationId);
  };

  const onShowDeleteModal = (deleteAllLabels: boolean) => {
    setDeleteAll(deleteAllLabels);
    setShowDeleteModal(true);
  };

  const setupZebra = () => {
    browserPrint
      .getAvailablePrinters()
      .then((accessiblePrinters: Device[]) => {
        let zebraPrinters = accessiblePrinters.map((p) => {
          let zp: Printer = {
            id: p.uid,
            name: `Zebra: ${p.name}`,
            printerType: PrinterType.Zebra
          };

          return zp;
        });

        let printers = [inkjetPrinter, ...zebraPrinters];
        setAllPrinters(printers);
      })
      .catch((r) => {
        console.error(r);
      });
  };

  const onPrint = () => {
    if (!canPrint) {
      setShowInvalid(true);
      return;
    }

    let labelsToPrint = stockLabels.filter((l) => l.Selected).flatMap((l) => l.StockLabel);
    let missingUpcs = labelsToPrint.some((l) => l.gtins == null || l.gtins.length === 0);
    if (missingUpcs) {
      setShowMissingUpcModal(true);
      portal?.navigation.isLoading(false);
      return;
    }

    printLabels(false);
  };

  const printLabels = (generateMissingUpcs: boolean) => {
    portal?.navigation.isLoading(true);
    setShowMissingUpcModal(false);
    let labelsToPrint = stockLabels.filter((l) => l.Selected).flatMap((l) => l.StockLabel);
    if (selectedPrinter?.printerType == PrinterType.Inkjet) {
      StockLabelService.printAveryLabels(labelsToPrint, generateMissingUpcs, selectedLocation?.locationId)
        .then((v) => {
          loadLatestPrintSet();
          deletePrintedLabels(labelsToPrint).finally(() => {
            window.location.href = v;
          });
        })
        .catch((r) => {
          console.error(r);
          portal?.navigation.isLoading(false);
        });
    } else if (selectedPrinter?.printerType == PrinterType.Zebra) {
      StockLabelService.printZebraLabels(labelsToPrint, selectedTemplate!, generateMissingUpcs, selectedLocation?.locationId)
        .then((v) => {
          printZebra(v);
          loadLatestPrintSet();
          deletePrintedLabels(labelsToPrint).then(() => {
            loadLabels(0, labelsPerPage, selectedLocation?.locationId);
          });
        })
        .catch((r) => {
          console.error(r);
          portal?.navigation.isLoading(false);
        });
    } else {
      portal?.navigation.isLoading(false);
    }
  };

  const printZebra = (zpl: string) => {
    portal?.navigation.isLoading(true);
    browserPrint
      .getAvailablePrinters()
      .then((accessiblePrinters: Device[]) => {
        let selectedZebraPrinter = accessiblePrinters.find((z) => z.uid === selectedPrinter?.id);
        if (selectedZebraPrinter === undefined) {
          return;
        }

        browserPrint.setPrinter(selectedZebraPrinter);
        browserPrint.print(zpl);
        portal?.navigation.isLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert(err);
        portal?.navigation.isLoading(false);
      });
  };

  const deletePrintedLabels = async (labelsToPrint: Array<IStockLabel>) => {
    let idsToDelete = labelsToPrint.flatMap((l) => l.stockLabelId);
    for (let i = 0; i < idsToDelete.length; i += labelsPerPage) {
      const chunk = idsToDelete.slice(i, i + labelsPerPage);
      await StockLabelService.deleteStockLabels(chunk).catch((r) => {
        alert("Unable to delete printed labels");
        console.error(r);
        portal?.navigation.isLoading(false);
      });
    }

    labelsContentRef.current?.scrollTo(0, 0);
    await loadLabels(0, labelsPerPage, selectedLocation?.locationId);
  };

  const restorePrintSet = async () => {
    if (latestPrintSet == undefined) {
      return;
    }

    portal?.navigation.isLoading(true);
    await StockLabelService.restorePrintSet(latestPrintSet.Id)
      .then((restoredLabels) => {
        let labelsToDisplay = restoredLabels.map((l) => {
          let display: StockLabelDisplay = {
            StockLabel: l,
            Selected: true
          };

          return display;
        });

        let labels = labelsToDisplay.concat(stockLabels);
        setStockLabels([...labels]);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        portal?.navigation.isLoading(false);
      });
  };

  const sortGrid = (sortColumn: string) => {
    switch (sortColumn) {
      case "Entered On":
        stockLabels.sort((a, b) => {
          return a.StockLabel.dateEntered >= b.StockLabel.dateEntered ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Quantity":
        stockLabels.sort((a, b) => {
          return a.StockLabel.quantity >= b.StockLabel.quantity ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Sku":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.sku ?? "") >= (b.StockLabel.sku ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Description":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.description ?? "") >= (b.StockLabel.description ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Brand":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.brand ?? "") >= (b.StockLabel.brand ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Size 1":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.size1 ?? "") >= (b.StockLabel.size1 ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Size 2":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.size2 ?? "") >= (b.StockLabel.size2 ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
      case "Color":
        stockLabels.sort((a, b) => {
          return (a.StockLabel.color ?? "") >= (b.StockLabel.color ?? "") ? (sortAscending ? 1 : -1) : sortAscending ? -1 : 1;
        });
        setSortAscending(!sortAscending);
        break;
    }

    setStockLabels([...stockLabels]);
  };

  const initializeLocation = () => {
    let sessionLocationString = window.sessionStorage.getItem(SessionStorage.StockLabelsSelectedLocation);
    let sessionLocation: Location | undefined = sessionLocationString ? JSON.parse(sessionLocationString) : undefined;
    let locationId = locationParam ?? sessionLocation?.locationId;
    let location = portal?.State.locations.find((l) => l.locationId == locationId);
    setSelectedLocation(location);

    return location?.locationId;
  };

  const initializeTemplate = () => {
    let sessionTemplateString = window.sessionStorage.getItem(SessionStorage.StockLabelsSelectedTemplate);
    let sessionTemplate: string | undefined = sessionTemplateString ? JSON.parse(sessionTemplateString) : undefined;
    let hasZebra = allPrinters.some((p) => p.printerType === PrinterType.Zebra);
    let template = Object.values(LabelTemplates).find((t) => {
      let isDisabled = t != LabelTemplates.Laser && !hasZebra;
      let isMatch = t == sessionTemplate;
      return isMatch && !isDisabled;
    });
    setSelectedTemplate(template);
  };

  const initializePrinter = () => {
    let sessionPrinterString = window.sessionStorage.getItem(SessionStorage.StockLabelsSelectedPrinter);
    let sessionPrinter: Printer | undefined = sessionPrinterString ? JSON.parse(sessionPrinterString) : undefined;
    let printer = allPrinters.find((p) => p.id === sessionPrinter?.id);
    setSelectedPrinter(printer);
  };

  useEffect(() => {
    portal?.navigation.isLoading(true);
    setupZebra();
    initializeTemplate();
    initializePrinter();
    loadLatestPrintSet();
    let selectedLocationId = initializeLocation();
    loadLabels(0, labelsPerPage, selectedLocationId);
  }, []);

  useEffect(() => {
    let canPrint = labelsNumber(stockLabels, true) > 0 && selectedPrinter !== undefined && selectedTemplate !== undefined;
    setCanPrint(canPrint);
    setAllSelected(stockLabels.every((l) => l.Selected));
  }, [stockLabels, selectedPrinter, selectedTemplate]);

  useEffect(() => {
    setPrinters(getPrinters());
    setTemplates(getTemplates());
  }, [allPrinters]);

  return (
    <>
      <IonPage>
        <TenantLevelPageHeader headerText={`Manage Labels (${labelsNumber(stockLabels, false)})`} headerIconFileUrl="assets/img/integration.svg">
          <div slot="end">
            <button
              className="labels-content-button labels-delete-button"
              disabled={labelsNumber(stockLabels, true) < 1}
              onClick={() => onShowDeleteModal(false)}>
              Delete Labels{labelsNumber(stockLabels, true) > 0 ? ` (${labelsNumber(stockLabels, true)})` : ""}
            </button>
            <button className="labels-content-button" disabled={labelsNumber(stockLabels, true) < 1} onClick={onPrint}>
              Print Labels{labelsNumber(stockLabels, true) > 0 ? ` (${labelsNumber(stockLabels, true)})` : ""}
            </button>
          </div>
        </TenantLevelPageHeader>
        <IonContent>
          <div className="labels-content-holder">
            <div className="labels-content" onScroll={onScroll} ref={labelsContentRef}>
              {/* Couldn't find a better way to make both headers sticky */}
              <div className="labels-content-header-holder">
                <div className="labels-content-header">
                  <div className="labels-content-button-holder">
                    <button
                      className="labels-content-button"
                      disabled={latestPrintSet == undefined || latestPrintSet.Count < 1}
                      onClick={restorePrintSet}>
                      Restore Last Label Set{latestPrintSet && latestPrintSet.Count > 0 ? ` (${latestPrintSet.Count})` : ""}
                    </button>
                  </div>
                  <div className="labels-content-header-dropdowns">
                    <Dropdown
                      className="labels-content-header-dropdown"
                      placeholder="Select Location"
                      values={locations}
                      onSelect={onSelectLocation}
                      selectedValue={selectedLocation?.name}
                    />
                    <Dropdown
                      className={"labels-content-header-dropdown" + (selectedTemplate === undefined && showInvalid ? " invalid" : "")}
                      placeholder="Select Template"
                      values={templates}
                      onSelect={onSelectTemplate}
                      selectedValue={selectedTemplate}
                    />
                    <div className="dropdown-with-tooltip">
                      <Dropdown
                        className={"labels-content-header-dropdown" + (selectedPrinter === undefined && showInvalid ? " invalid" : "")}
                        placeholder="Select Printer"
                        values={printers}
                        onSelect={onSelectPrinter}
                        selectedValue={selectedPrinter?.name}
                      />
                      <Tooltip>
                        <div className="missing-printers-tooltip">
                          <div>Having trouble locating your Zebra printer?</div>
                          <a
                            href="https://support.ricssoftware.com/hc/en-us/articles/16016643895949-Setting-up-the-ZD411-203DPI-Zebra-Browser-Print/"
                            target="_blank">
                            Click here for additional directions.
                          </a>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="stock-labels-grid-header">
                  <IonCheckbox
                    className="stock-labels-grid-select"
                    onIonChange={onSelectAll}
                    checked={allSelected}
                    disabled={stockLabels.length < 1}
                  />
                  <div className="stock-labels-grid-values">
                    <div className="stock-labels-header-value large" onClick={() => sortGrid("Entered On")}>
                      Entered On
                    </div>
                    <div className="stock-labels-header-value small" onClick={() => sortGrid("Quantity")}>
                      Quantity
                    </div>
                    <div className="stock-labels-header-value large" onClick={() => sortGrid("Sku")}>
                      Sku
                    </div>
                    <div className="stock-labels-header-value large" onClick={() => sortGrid("Description")}>
                      Description
                    </div>
                    <div className="stock-labels-header-value medium" onClick={() => sortGrid("Brand")}>
                      Brand
                    </div>
                    <div className="stock-labels-header-value small center" onClick={() => sortGrid("Size 1")}>
                      Size 1
                    </div>
                    <div className="stock-labels-header-value small center" onClick={() => sortGrid("Size 2")}>
                      Size 2
                    </div>
                    <div className="stock-labels-header-value xlarge" onClick={() => sortGrid("Color")}>
                      Color
                    </div>
                  </div>
                  <button className="stock-labels-grid-delete" onClick={() => onShowDeleteModal(true)} disabled={stockLabels.length < 1}>
                    <img className="delete-all" src="assets/img/remove-alt.svg" />
                  </button>
                </div>
              </div>
              {stockLabels.length > 0 ? (
                <StockLabelsGrid
                  stockLabels={stockLabels}
                  onUpdateSelectedLabels={onUpdateSelectedLabels}
                  showLoadingIcon={loading}
                  onLabelDeleted={onLabelDeleted}
                />
              ) : (
                <div className="no-labels-content">No Labels To Print</div>
              )}
            </div>
          </div>
        </IonContent>
      </IonPage>
      <Modal hidden={!showDeleteModal}>
        <div className="delete-all-modal-contents">
          <button className="delete-all-modal-close-button" onClick={() => setShowDeleteModal(false)}>
            <img src="assets/img/close.svg" />
          </button>
          <div className="delete-all-modal-text-content">
            <div className="delete-all-modal-text delete-all-modal-text-loud">Attention!</div>
            <div className="delete-all-modal-text">You are about to delete {deleteAll ? "all" : "the selected"} labels from this queue.</div>
            <div className="delete-all-modal-text">Are you sure you want to proceed?</div>
          </div>
          <div className="delete-all-modal-buttons">
            <button className="delete-all-modal-button" onClick={() => (deleteAll ? onDeleteAll() : onDeleteSelected())}>
              Yes
            </button>
            <button className="delete-all-modal-button" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal hidden={!showMissingUpcModal}>
        <div className="missing-upc-modal-contents">
          <button className="missing-upc-modal-close-button" onClick={() => setShowMissingUpcModal(false)}>
            <img src="assets/img/close.svg" />
          </button>
          <div className="missing-upc-modal-text-content">
            <div className="missing-upc-modal-text delete-all-modal-text-loud">Attention!</div>
            <div className="missing-upc-modal-text">One or more of the selected items is not associated with a UPC.</div>
            <div className="missing-upc-modal-text" hidden={!flags[FeatureFlags.RicsApiGenerateIdentifiers]}>
              Click <b>Generate & Print</b> to have the system auto-generate UPCs for the selected products without UPCs.
            </div>
            <div className="missing-upc-modal-text">
              Click <b>Print w/o UPCs</b> to print labels without barcodes for the selected products without UPCs.
            </div>
            <div className="missing-upc-modal-text">
              Click <b>Cancel</b> to return to the printing queue.
            </div>
          </div>
          <div className="missing-upc-modal-buttons">
            <button className="missing-upc-modal-button" onClick={() => printLabels(true)} hidden={!flags[FeatureFlags.RicsApiGenerateIdentifiers]}>
              Generate & Print
            </button>
            <button className="missing-upc-modal-button" onClick={() => printLabels(false)}>
              Print w/o UPCs
            </button>
            <button className="missing-upc-modal-button" onClick={() => setShowMissingUpcModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageLabels;

import { IIdentityService } from "../interfaces/services/IIdentityService";
import { Identity } from "../interfaces/User";
import { DoRicsApiXhr } from "./XhrService";

const getCurrentIdentity = (version: string, onSuccess: (identity: Identity) => void, onFailure: () => void) => {
  const endpoint = `v${version}/Identities/Current`;

  const onSuccessCallback = (response: any) => {
    let identity: Identity = JSON.parse(response);
    onSuccess(identity);
  };

  DoRicsApiXhr(endpoint, undefined, onSuccessCallback, onFailure, "GET");
};

const getIdentities = (version: string, onSuccess: (identities: Array<Identity>) => void, onFailure: () => void) => {
  const endpoint = `v${version}/Identities`;

  const onSuccessCallback = (response: any) => {
    let identities: Array<Identity> = JSON.parse(response);
    onSuccess(identities);
  };

  DoRicsApiXhr(endpoint, undefined, onSuccessCallback, onFailure, "GET");
};

const addRoleToIdentity = (
  identityId: string,
  tenantId: string,
  locationId: string,
  roleName: string,
  version: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/${identityId}/Roles/${tenantId}/${locationId}/${roleName}`;
  DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "PUT");
};

const saveIdentity = (
  identity: Identity,
  saveToRics9: boolean,
  version: string,
  onSuccess: (newIdentity: Identity) => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities${saveToRics9 ? "?saveRics9User=true" : ""}`;

  const onSuccessCallback = (response: any) => {
    let newIdentity: Identity = JSON.parse(response);
    onSuccess(newIdentity);
  };

  DoRicsApiXhr(endpoint, identity, onSuccessCallback, onFailure, "PUT");
};

const getIdentity = (identityId: string, version: string, onSuccess: (identity: Identity) => void, onFailure: () => void) => {
  const endpoint = `v${version}/Identities/${identityId}`;

  const onSuccessCallback = (response: any) => {
    let identity: Identity = JSON.parse(response);
    onSuccess(identity);
  };

  DoRicsApiXhr(endpoint, null, onSuccessCallback, onFailure, "GET");
};

const getIdentityPin = (identityId: string, version: string, onSuccess: (pin: string) => void, onFailure: () => void) => {
  const endpoint = `v${version}/Identities/${identityId}/Pin`;

  const onSuccessCallback = (response: any) => {
    let pin: string = JSON.parse(response);
    onSuccess(pin);
  };

  DoRicsApiXhr(endpoint, null, onSuccessCallback, onFailure, "GET");
};

const createIdentityPin = (emailAddress: string, version: string, onSuccess: () => void, onFailure: () => void) => {
  const endpoint = `v${version}/Identities/CreatePin`;
  const payload = {
    emailAddress: emailAddress
  };

  DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");
};

export const IdentityService: IIdentityService = {
  getCurrentIdentity: getCurrentIdentity,
  getIdentities: getIdentities,
  addRoleToIdentity: addRoleToIdentity,
  createIdentityPin: createIdentityPin,
  getIdentity: getIdentity,
  getIdentityPin: getIdentityPin,
  saveIdentity: saveIdentity
};

import { IonApp } from "@ionic/react";
import { setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
/* Bootstrap */
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { DataServiceProvider } from "./components/DataServiceProvider";
import { PortalProvider } from "./components/PortalProvider";
import RicsRouter from "./components/RicsRouter";
import { XhrServiceProvider } from "./components/XhrServiceProvider";
import { AmazonServiceProvider } from "./services/providers/AmazonServiceProvider";
import { ECommerceServiceProvider } from "./services/providers/ECommerceServiceProvider";
/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {
  setupIonicReact({
    mode: 'md'
  });

  return (
    <IonApp>
      <PortalProvider>
        <XhrServiceProvider>
          <DataServiceProvider>
            <AmazonServiceProvider>
              <ECommerceServiceProvider>
                <RicsRouter></RicsRouter>
              </ECommerceServiceProvider>
            </AmazonServiceProvider>
          </DataServiceProvider>
        </XhrServiceProvider>
      </PortalProvider>
    </IonApp>
  );
};

export default App;

import { IonContent, IonImg, IonPage, useIonViewDidEnter } from '@ionic/react';

import React, { useEffect, useState } from 'react';

import '../theme/AllLocations.css';
import LocationsGrid from '../components/locations/LocationsGrid';
import TenantLevelPageHeader from '../components/TenantLevelPageHeader';
import ListAndCardsToggle from '../components/users/ListAndCardsToggle';
import { Location } from "../interfaces/Location";
import { usePortal } from '../components/PortalProvider';
import { useDataService } from '../components/DataServiceProvider';

const AllLocations: React.FC = () => {
  const dataService = useDataService();
  const portal = usePortal();
  const [locationsCount, setLocationsCount] = useState(portal!.State.locations?.length);
  const [locations, setLocations] = useState<Array<Location>>(portal!.State.locations);

  portal!.features = portal!.features || {};
  const [checkedLocations, setCheckedLocations] = useState<Map<string, boolean>>(new Map());
  const [isCardMode, setIsCardMode] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [numberSelected, setNumberSelected] = useState<number>(0);

  useIonViewDidEnter(() => {
    let numLocationsToUpdate = portal!.State.locationIdsToUpdate?.length;

    let onLocationRefresh = () => {
      numLocationsToUpdate--;
      if (numLocationsToUpdate == 0) {
        loadLocationsStateFromPortalState()
      } else {
        updateNextLocation();
      }
    }

    let onFailure = () => {
      alert("Could not retrieve latest location information. Location information may be out of date.")
      portal!.navigation.isLoading(false);
    }

    let updateNextLocation = () => {
      dataService!.getLocation(portal!.State.locationIdsToUpdate[0], onLocationRefresh, onFailure)
    }

    if (portal!.State.shouldUpdateAllLocations) {
      portal!.navigation.isLoading(true);
      dataService!.refreshLocations(0, loadLocationsStateFromPortalState, onFailure);
    }
    else if (numLocationsToUpdate > 0) {
      portal!.navigation.isLoading(true);
      updateNextLocation();
    }

  });

  const loadLocationsStateFromPortalState = () => {
    setLocations([]);
    setLocations(portal!.State.locations);
    setLocationsCount(portal!.State.locations?.length);
    portal!.State.shouldUpdateAllLocations = false;
    portal!.State.locationIdsToUpdate = [];
    portal!.navigation.isLoading(false);
  }

  useEffect(() => {
    let checked = Array.from(checkedLocations.values()).filter(x => x === true);

    setNumberSelected(checked.length);
  }, [checkedLocations]);

  const filterListbySearchTerm = (searchTerm: string) => {
    searchTerm = searchTerm.toUpperCase();

    let filteredLocations = locations.map((l: Location) => {
      if (l.name?.toUpperCase().includes(searchTerm)
        || l.contact?.address?.city?.toUpperCase().includes(searchTerm)
        || l.contact?.address?.line1?.toUpperCase().includes(searchTerm)
        || l.contact?.address?.line2?.toUpperCase().includes(searchTerm)
        || l.contact?.address?.state?.toUpperCase().includes(searchTerm)
        || l.contact?.address?.zipCode?.toUpperCase().includes(searchTerm)) {
        l.hidden = false;
      } else {
        l.hidden = true;
      }

      return l;
    })

    setLocations(filteredLocations);
  }

  const checkAllLocations = (checked: boolean) => {
    let updatedCheckedLocations = new Map(checkedLocations);
    Array.from(updatedCheckedLocations.keys()).forEach((key) => {
      updatedCheckedLocations.set(key, checked);
    });
    setCheckedLocations(updatedCheckedLocations);
  };

  const checkLocation = (elementClassList: DOMTokenList | undefined, id: string, checked: boolean | undefined) => {
    // If the user is clicking the actions button for the row, don't check the row 
    if (!elementClassList || !(elementClassList.contains("user-actions-button") || elementClassList.contains("user-actions-icon"))) {
      let updatedCheckedLocations = new Map(checkedLocations);
      let checkedState = !checkedLocations.get(id);

      if (checked !== undefined) {
        checkedState = checked;
      }

      updatedCheckedLocations.set(id, checkedState);
      setCheckedLocations(updatedCheckedLocations);
    }
  };

  return (
    <>
      <IonPage>
        <TenantLevelPageHeader
          headerText="All Locations"
          headerIconFileUrl="assets/img/locations.svg"
          itemsCount={locationsCount}
        >
          <ListAndCardsToggle
            isCardMode={isCardMode}
            setIsCardMode={setIsCardMode}
            onChangeSearchText={filterListbySearchTerm}
            showDisabledUsers={false}
            toggleShowDisabledUsers={() => { }}
            isDisabledUserEnabled={false}
            showSearchInput={showSearchInput}
            setShowSearchInput={setShowSearchInput}
            searchText={searchText}
            setSearchText={setSearchText}
            searchPlaceholder="Search for locations" />
        </TenantLevelPageHeader>

        <IonContent class="all-locations-content">
          <div id="locationSection">
            <div id="locationsLayoutContainer">
              {locationsCount > 0 &&
                <LocationsGrid
                  cssClass="all-locations-grid"
                  isCardMode={isCardMode}
                  locations={locations}
                  numberSelected={numberSelected}
                  checkAllLocations={checkAllLocations}
                  checkedLocations={checkedLocations}
                  setCheckedLocations={setCheckedLocations}
                  checkLocation={checkLocation}
                />
              }
              {locationsCount === 0 &&
                <div className="noState">
                  <div className="content">
                    <div className="iconHolder">
                      <IonImg src="assets/img/locations.svg"></IonImg>
                    </div>
                    <div className="heading">No Locations exist</div>
                  </div>
                </div>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default AllLocations;
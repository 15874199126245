import React from "react";
import { IDataService } from "../interfaces/IDataService";
import { DataService } from "../services/DataService";

const { createContext, useContext } = React;

const DataServiceContext = createContext<IDataService>(DataService);

export const DataServiceProvider = (props: any) => {
  return <DataServiceContext.Provider value={props.DataService ?? DataService}>{props.children}</DataServiceContext.Provider>;
};

export const useDataService = () => {
  return useContext(DataServiceContext);
};

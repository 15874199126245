import React, { useState } from "react";
import { ECommerceConstants, ECommercePricingStrategyConstants } from "../../../../constants/ECommerceConstants";
import { CreateECommerceAccountBase, ECommercePricingStrategy } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupPricingModalProps extends ECommerceAccountSetupModalPropsBase {
  updateAccount(account: CreateECommerceAccountBase): void;
}

const ECommerceAccountSetupPricingModal: React.FC<ECommerceAccountSetupPricingModalProps> = (props) => {
  const [retailPriceOffset, setRetailPriceOffset] = useState<number | undefined>();
  const [pricingStrategy, setPricingStrategy] = useState<ECommercePricingStrategy>(ECommercePricingStrategy.Manual);

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  const getPricingStrategyValue = () => {
    if (pricingStrategy === ECommercePricingStrategy.Retail) {
      return ECommercePricingStrategyConstants.Retail;
    }

    return ECommercePricingStrategyConstants.Manual;
  };

  const setPricingStrategyValue = (value: string) => {
    if (value === ECommercePricingStrategyConstants.Retail) {
      setPricingStrategy(ECommercePricingStrategy.Retail);
      return;
    }

    setPricingStrategy(ECommercePricingStrategy.Manual);
  };

  const updateTempAccount = () => {
    let tempAccount: CreateECommerceAccountBase = JSON.parse(window.sessionStorage.getItem(ECommerceConstants.tempAccountKey)!);
    tempAccount.pricingStrategy = pricingStrategy;
    tempAccount.retailPriceOffset = retailPriceOffset;

    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(tempAccount));

    props.updateAccount(tempAccount);
  };

  const onGoBack = () => {
    setPricingStrategy(ECommercePricingStrategy.Manual);
    setRetailPriceOffset(undefined);

    if (props.onGoBack) props.onGoBack();
  };

  const onNext = () => {
    updateTempAccount();

    if (props.onNext) {
      props.onNext();
    }
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Pricing",
    headerSubTitle: `Send prices from RICS with an optional offset or manage manually in ${props.vendor}`,
    overrideNextButtonText: "Finish",
    onGoBack: onGoBack,
    onNext: onNext
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body")}>
          <div className="row">
            <div className={getClass("body-edit-field-holder") + " w-50"}>
              <label className={getClass("body-edit-field-holder-header")}>Pricing Strategy</label>
              <select
                className="edit-field-holder-select"
                defaultValue={getPricingStrategyValue()}
                onChange={(e) => setPricingStrategyValue(e.target.value)}>
                <option
                  id={ECommercePricingStrategyConstants.None}
                  label={ECommercePricingStrategyConstants.None}
                  value={ECommercePricingStrategyConstants.None}
                />
                <option
                  id={ECommercePricingStrategyConstants.Retail}
                  label={ECommercePricingStrategyConstants.Retail}
                  value={ECommercePricingStrategyConstants.Retail}
                />
                <option
                  id={ECommercePricingStrategyConstants.Manual}
                  label={ECommercePricingStrategyConstants.Manual}
                  value={ECommercePricingStrategyConstants.Manual}
                />
              </select>
            </div>
            <div className={getClass("body-edit-field-holder") + " w-50"} hidden={pricingStrategy !== ECommercePricingStrategy.Retail}>
              <label className={getClass("body-edit-field-holder-header")}>Retail Price Offset</label>
              <input
                className={getClass("body-edit-field-holder-input")}
                defaultValue={retailPriceOffset}
                value={retailPriceOffset}
                type="number"
                step={0.01}
                onChange={(e) => setRetailPriceOffset(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupPricingModal;

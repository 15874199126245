import { IonText, IonLabel, IonButton, IonTextarea, IonImg, IonIcon, IonToggle, IonItem } from '@ionic/react';
import React, { useEffect } from 'react';
import { closeOutline } from 'ionicons/icons';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import '../theme/BrandingSettings.css';
import SettingsCard from './SettingsCard';
import { useDataService } from './DataServiceProvider';
import { usePortal } from './PortalProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../constants/FeatureFlags';

export interface BrandingSettingsProps {
  settings: BrandingSettingsSettings;
  locationId?: string;
  onboardingWorkflow?: Boolean;
  setShowModal?: Function;
  setComplete?: Function;
}

export interface BrandingSettingsSettings {
  receiptIntroText?: string;
  receiptFooterText?: string;
  logos?: Images;
  locationsCanOverride?: boolean;
  locationId?: string;
  additionalReceiptInformation?: boolean;
  additionalReceiptText?: string;
}

export interface Images {
  smallLogoUrl?: string;
  largeLogoUrl?: string;
}

const BrandingSettings: React.FC<BrandingSettingsProps> = ({ settings, locationId, onboardingWorkflow = false, setShowModal, setComplete }) => {
  const portal = usePortal();
  const dataService = useDataService();
  const flags = useFlags();
  const [brandingSettings, setBrandingSettings] = React.useState(settings ?? {});
  const [thumbnail, setThumbnail] = React.useState('img/image-placeholder.svg')
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [logoClassNames, setLogoClassNames] = React.useState("image-upload branding-valid");
  const [receiptIntroClassNames, setReceiptIntroClassNames] = React.useState("branding-valid");
  const [receiptFooterClassNames, setReceiptFooterClassNames] = React.useState("branding-valid");
  const uploadInput = React.useRef<HTMLInputElement>(null);
  const [additionalReceiptTextClassNames, setAdditionalReceiptTextClassNames] = React.useState("branding-valid");
  const [additionalReceiptInformationFeatureFlag, setAdditionalReceiptInformationFeatureFlag] = React.useState(false);

  useEffect(() => {
    if (brandingSettings.logos && brandingSettings.logos.smallLogoUrl) {
      setThumbnail(brandingSettings.logos.smallLogoUrl);
    }

    if (locationId && brandingSettings.locationsCanOverride === false) {
      setIsDisabled(true);
    }

    getAdditionalReceiptInformationFeatureFlag();
  }, [flags]);

  const getAdditionalReceiptInformationFeatureFlag = () => {
    var featureFlag = flags[FeatureFlags.AdditionalReceiptInformation];
    setAdditionalReceiptInformationFeatureFlag(featureFlag);
  };

  const uploadBrandingImage = (e: any) => {
    let endpoint = "v1/Branding/UploadLogo";
    portal!.navigation.isLoading(true);

    var uploadImage = e.target.files[0];
    if (!uploadImage) {
      portal!.navigation.isLoading(false);
      return;
    }

    if (!/^.+(\.jpeg|\.jpg|\.png)$/.test(uploadImage.name.toLowerCase())) {
      alert("Please provide an image with a valid file extention. Accepted image extensions: .png, .jpeg, .jpg");
      portal!.navigation.isLoading(false);
      return;
    }

    var onSuccess = function (response: any) {
      response = JSON.parse(response);
      var fileUrl = response.fileUrl;

      var resolve = function () {
        portal!.navigation.isLoading(false);
      };

      var newLogos = { smallLogoUrl: fileUrl };
      if (brandingSettings.logos) {
        newLogos = { ...brandingSettings.logos, smallLogoUrl: fileUrl };
      }

      setBrandingSettings({ ...brandingSettings, logos: newLogos });
      setLogoClassNames("image-upload branding-valid");
      setThumbnail(fileUrl);
      resolve();
    };

    dataService!.uploadFile(endpoint, uploadImage, onSuccess);
  };

  const setLocationsCanOverride = (canOverride: boolean) => {
    setBrandingSettings({ ...brandingSettings, locationsCanOverride: canOverride });
  };

  const setReceiptIntroText = (text: string) => {
    if (text.length > 0) {
      setReceiptIntroClassNames("branding-valid");
    }
    setBrandingSettings({ ...brandingSettings, receiptIntroText: text });
  };

  const setReceiptFooterText = (text: string) => {
    if (text.length > 0) {
      setReceiptFooterClassNames("branding-valid");
    }
    setBrandingSettings({ ...brandingSettings, receiptFooterText: text });
  };

  const setAdditionalReceiptText = (text: string) => {
    if (text.length > 0) {
      setAdditionalReceiptTextClassNames("branding-valid");
    }
    setBrandingSettings({ ...brandingSettings, additionalReceiptText: text });
  };

  const setAdditionalReceiptInformation = (value: boolean) => {
    setBrandingSettings({ ...brandingSettings, additionalReceiptInformation: value });
  };

  const validateAndSaveBranding = () => {
    var isValid = true;
    if (!brandingSettings.logos || !brandingSettings.logos.smallLogoUrl) {
      setLogoClassNames("image-upload branding-invalid")
      isValid = false;
    }

    if (!brandingSettings.receiptIntroText || brandingSettings.receiptIntroText.length === 0) {
      setReceiptIntroClassNames("branding-invalid")
      isValid = false;
    }

    if (!brandingSettings.receiptFooterText || brandingSettings.receiptFooterText.length === 0) {
      setReceiptFooterClassNames("branding-invalid")
      isValid = false;
    }

    if (brandingSettings.additionalReceiptInformation && (!brandingSettings.additionalReceiptText || brandingSettings.additionalReceiptText.length === 0)) {
      setAdditionalReceiptTextClassNames("branding-invalid");
      isValid = false;
    }

    if (isValid) {
      saveBranding();
    }
  };

  const saveBranding = () => {
    var onSuccess2 = function () {
      dataService!.loadConfigurations(null, onComplete, onFailure);
    };

    var onComplete = function () {
      if (setShowModal) {
        setShowModal(false);
      }

      if (setComplete) {
        setComplete(true);
      }

      portal!.navigation.isLoading(false);
    };

    var onFailure = function (response: string) {
      portal!.navigation.isLoading(false);
      alert('Something went wrong');
    };

    portal!.navigation.isLoading(true);

    var onSuccess = function () {
      dataService!.getBrandingSettings(onSuccess2, onFailure);
    };

    var settings = { ...brandingSettings, locationId: locationId };

    if (brandingSettings.locationId) {
      settings.locationsCanOverride = undefined;
      dataService!.enqueueLocationForUpdate(locationId);
    }
    else {
      portal!.State.shouldUpdateAllLocations = true;
    }

    dataService!.saveBrandingSettings(settings, onSuccess, onFailure);
  };

  const changeImage = () => {
    if (!isDisabled && uploadInput && uploadInput.current) {
      uploadInput.current.click();
    }
  };

  const clearImage = () => {
    if (brandingSettings.logos) {
      var newLogos = { ...brandingSettings.logos, smallLogoUrl: undefined }
      setBrandingSettings({ ...brandingSettings, logos: newLogos });
    }

    setThumbnail('img/image-placeholder.svg');
  };

  return (
    <SettingsCard
      header="Customize receipts and branding"
      subHeading="Branding for your receipts"
      LocationsOverrideProps={{ "onChange": setLocationsCanOverride, "locationsCanOverride": brandingSettings.locationsCanOverride ?? true }}
      isDisabled={locationId !== undefined}>
      <div className="section">
        <IonLabel>Receipt Branding</IonLabel>
      </div>
      <div className="section">
        <IonLabel color="gray" className="sub-header">Choose a logo you would like to display on the top of printed and emailed receipts. By default, all child locations will inherit the same logo.</IonLabel>
      </div>
      <div className={logoClassNames}>
        <input ref={uploadInput} type="file" accept=".jpg, .png, .jpeg" onChange={e => uploadBrandingImage(e)} className="file-upload" />
        <IonImg src={thumbnail} className="branding-image" onClick={() => changeImage()} />
        <IonIcon src={closeOutline} className="branding-delete-button" onClick={() => clearImage()} hidden={isDisabled || thumbnail === 'img/image-placeholder.svg'} />
      </div>
      <div className="section">
        <IonLabel>Receipt Text</IonLabel>
      </div>
      <div className="receipt-text section">
        <IonText color="gray">Intro text</IonText>
        <IonTextarea value={brandingSettings.receiptIntroText} className={receiptIntroClassNames} disabled={isDisabled} onIonChange={e => setReceiptIntroText(e.detail.value!)} />
      </div>
      <div className="receipt-text section">
        <IonText color="gray">Footer Text</IonText>
        <IonTextarea value={brandingSettings.receiptFooterText} className={receiptFooterClassNames} disabled={isDisabled} onIonChange={e => setReceiptFooterText(e.detail.value!)} />
      </div>
      {
        additionalReceiptInformationFeatureFlag &&
        <>
          <div className="section">
            <IonLabel>Additional Receipt Step</IonLabel>
            <div className='sub-heading-wrapper'>
              <IonText color="gray">
                <IonLabel className='sub-heading'>When enabled, displays additional receipt text after each sale and requires a customer signature.</IonLabel>
              </IonText>
            </div>
          </div>
          <div className="receipt-text section">
            <IonItem lines="none" className="setting">
              <IonToggle checked={brandingSettings.additionalReceiptInformation} className="ion-align-self-center" disabled={isDisabled} onIonChange={e => setAdditionalReceiptInformation(e.detail.checked)} />
              <IonText>Additional Receipt Information</IonText>
            </IonItem>
          </div>
          <div hidden={!brandingSettings.additionalReceiptInformation} className="receipt-text section">
            <IonText color="gray">Additional Receipt Text</IonText>
            <IonTextarea value={brandingSettings.additionalReceiptText} className={additionalReceiptTextClassNames} disabled={isDisabled} onIonChange={e => setAdditionalReceiptText(e.detail.value!)} />
          </div>
        </>
      }
      <div hidden={onboardingWorkflow === true}>
        <IonButton onClick={() => validateAndSaveBranding()} disabled={isDisabled}>Save Changes</IonButton>
      </div>
      <div hidden={!onboardingWorkflow}>
        <IonButton onClick={() => validateAndSaveBranding()} className="save-setting ion-float-right">Done with Branding</IonButton>
      </div>
    </SettingsCard>
  );
};

export default BrandingSettings;
import { IonIcon, IonItem } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React from "react";

import "../theme/ScrollSpy.css";

const SPY_INTERVAL = 100;

interface SpyItem {
  inView: boolean;
  element: HTMLElement;
  displayName: string;
}

interface ScrollspyProps {
  elements: ScrollSpyElements[];
  offset: number;
  itemContainerClassName?: string;
  activeItemClassName?: string;
  itemClassName?: string;
  ionContentId: string;
  ionGridId: string;
}

interface ScrollSpyElements {
  id: string;
  displayName: string;
}

interface ScrollspyState {
  items: SpyItem[];
}

class Scrollspy extends React.Component<ScrollspyProps, ScrollspyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: []
    };
  }

  public static defaultProps: Partial<ScrollspyProps> = {
    offset: 0
  };

  private timer: number = 0;

  private spy() {
    const items = this.props.elements
      .map((e, index) => {
        const element = document.getElementById(e.id);
        if (element) {
          return {
            inView: this.isInView(element, index),
            element: element,
            displayName: e.displayName
          } as SpyItem;
        } else {
          return;
        }
        return;
      })
      .filter((item) => item);

    const firstTrueItem = items.find((item) => !!item && item.inView);

    if (!firstTrueItem) {
      return; // dont update state
    } else {
      const update = items.map((item) => {
        return { ...item, inView: item === firstTrueItem } as SpyItem;
      });

      this.setState({ items: update });
      this.setScrollHeight(update);
    }
  }

  public componentDidMount() {
    this.timer = window.setInterval(() => this.spy(), SPY_INTERVAL);
  }

  public componentWillUnmount() {
    window.clearInterval(this.timer);
  }

  private setScrollHeight = (items: SpyItem[]) => {
    var lastItem = items[items.length - 1];
    const settingsRect = lastItem.element.getBoundingClientRect();
    var ionContent = document.getElementById(this.props.ionContentId) as HTMLIonContentElement;
    const contentRect = ionContent.getBoundingClientRect();

    var style = "margin-bottom: calc(100vh - " + contentRect.top + "px - " + settingsRect.height + "px)";
    var ionGrid = document.getElementById(this.props.ionGridId) as HTMLIonGridElement;
    ionGrid.setAttribute("style", style);
  };

  private isInView = (element: HTMLElement, index: number) => {
    if (!element) {
      return false;
    }
    const { offset } = this.props;
    const rect = element.getBoundingClientRect();

    let elementIsFullyInView = rect.top >= 0 - offset && rect.bottom <= window.innerHeight + offset;
    let elementIsFirstAndTopInView = rect.top >= 0 && index === 0;
    return elementIsFullyInView ? true : elementIsFirstAndTopInView;
  };

  private scrollTo(element: HTMLElement) {
    var ionContent = document.getElementById(this.props.ionContentId) as HTMLIonContentElement;
    ionContent.scrollToPoint(0, element.offsetTop, 500);
  }

  public render() {
    const { itemContainerClassName, activeItemClassName, itemClassName } = this.props;
    return (
      <div className={itemContainerClassName}>
        {this.state.items.map((item, k) => {
          return (
            <IonItem
              className={itemClassName + (item.inView && activeItemClassName ? ` ${activeItemClassName}` : "")}
              key={k}
              onClick={() => this.scrollTo(item.element)}
              lines="none">
              {item.displayName}
              <IonIcon slot="end" src={chevronForward} className="scroll-spy-arrow" />
            </IonItem>
          );
        })}
      </div>
    );
  }
}

export default Scrollspy;

import React from 'react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import { IonText, IonLabel } from '@ionic/react';
import LocationsOverride from '../components/LocationsOverride';

interface SettingsCardProps {
    header: string;
    subHeading: string;
    LocationsOverrideProps?: LocationsOverrideProps;
    isDisabled?: boolean;
    children?: React.ReactNode;
}

interface LocationsOverrideProps {
    locationsCanOverride: boolean;
    isEnabled?: boolean;
    onChange: Function;
}

const SettingsCard: React.FC<SettingsCardProps> = (props) => {
    const locationOverride = () => {
        if (props.LocationsOverrideProps) {
            return <LocationsOverride locationsCanOverride={props.LocationsOverrideProps.locationsCanOverride} onChange={props.LocationsOverrideProps.onChange} isDisabled={props.isDisabled} />;
        }
    };

    return (
        <div className='contentSection'>
            {locationOverride()}
            <div className="ion-text-start">
                <IonLabel className='settings-card-heading'>{props.header}</IonLabel>
                <div className='sub-heading-wrapper'>
                    <IonText color="gray">
                        <IonLabel className='sub-heading'>{props.subHeading}</IonLabel>
                    </IonText>
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default SettingsCard;

import React from 'react';

/* Theme variables */
import '../theme/variables.css';

import '../theme/Settings.css';
import { IonText, IonGrid, IonRow, IonCol } from '@ionic/react';

interface LeftRightContentProps {
    heading: string;
    preamble: string | (string | JSX.Element)[];
    hidden?: boolean;
    children?: React.ReactNode;
}

const LeftRightContent: React.FC<LeftRightContentProps> = (props) => {
    return (
        <IonGrid hidden={props.hidden} className="left-right-content-holder">
            <IonRow>
                <IonCol size="2" className="left-heading-content">
                    <IonText color="gray">{props.heading}</IonText>
                </IonCol>
                <IonCol className="right-preamble-content">
                    <IonText color="gray">{props.preamble}</IonText>
                </IonCol>
            </IonRow>
            <IonRow className="right-content">
                <IonCol offset="2">{props.children}</IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default LeftRightContent;

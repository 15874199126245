import React, { useEffect } from "react";
import { Paths } from "../../../../constants/Paths";
import { useAmazonService } from "../../../../services/providers/AmazonServiceProvider";
import { usePortal } from "../../../PortalProvider";
import ECommerceAccountsGrid from "../ECommerceAccountsGrid";
import ECommerceAccountsGridRow from "../ECommerceAccountsGridRow";

const AmazonAccountsGrid: React.FC = () => {
    const portal = usePortal();
    const amazonService = useAmazonService();

    portal!.State.integrations = portal!.State.integrations || {};
    portal!.State.integrations.eCommerce = portal!.State.integrations.eCommerce || {};
    portal!.State.integrations.eCommerce.amazonAccounts = portal!.State.integrations.eCommerce.amazonAccounts || [];

    const [amazonAccounts, setAmazonAccounts] = React.useState(portal!.State.integrations.eCommerce.amazonAccounts);

    useEffect(() => {
        getAmazonAccounts();
    }, []);

    const getAmazonAccounts = async () => {
        portal!.navigation.isLoading(true);

        let accounts = await amazonService!.getAccounts(false);

        setAmazonAccounts(accounts);
        portal!.State.integrations.eCommerce.amazonAccounts = accounts;
        portal!.navigation.isLoading(false);
    };

    const createAmazonAccountsRows = () => {
        return amazonAccounts && amazonAccounts.map((account) => 
            <ECommerceAccountsGridRow
                accountId={account.accountId}
                label={account.label}
                storeName={account.sellerId}
                contactEmail={account.contactEmails[0]}
                startDate={account.adapterStartDate}
                lastUpdatedDate={account.dateUpdated}
                accountStatus={account.accountStatus}
                route={Paths.AmazonAccounts}
            />
        )
    };

    return (
        <>
            <ECommerceAccountsGrid
                storeNameHeader="Seller Id"
                createGridRows={createAmazonAccountsRows}
            />
        </>
    );
};

export default AmazonAccountsGrid;
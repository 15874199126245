import { IonCheckbox } from "@ionic/react";
import React, { useState } from "react";
import { toLocalString } from "../../helpers/dateHelpers";
import { StockLabelDisplay } from "../../pages/ManageLabels";

type StockLabelsGridRowProps = {
  stockLabel: StockLabelDisplay;
  onSelected: (stockLabelId: string, selected: boolean) => void;
  onDeleted: (stockLabelId: string) => void;
};

const StockLabelsGridRow: React.FC<StockLabelsGridRowProps> = ({ stockLabel, onSelected, onDeleted }) => {
  const [quantity, setQuantity] = useState(stockLabel.StockLabel.quantity);

  const onQuantityChanged = (updatedQuantity: number) => {
    setQuantity(updatedQuantity);
    stockLabel.StockLabel.quantity = updatedQuantity;

    if (isNaN(updatedQuantity)) {
      onSelected(stockLabel.StockLabel.stockLabelId, false);
      return;
    }

    onSelected(stockLabel.StockLabel.stockLabelId, true);
  };

  return (
    <div className="stock-labels-grid-row">
      <IonCheckbox
        className="stock-labels-grid-select"
        checked={stockLabel.Selected}
        onIonChange={() => onSelected(stockLabel.StockLabel.stockLabelId, !stockLabel.Selected)}
        disabled={isNaN(quantity)}
      />
      <div className="stock-labels-grid-values">
        <div className="stock-labels-grid-value large">{toLocalString(new Date(stockLabel.StockLabel.dateEntered))}</div>
        <div className="stock-labels-grid-value stock-labels-grid-quantity small">
          <input type="number" value={quantity} min={1} onChange={(e) => onQuantityChanged(e.target.valueAsNumber)} />
        </div>
        <div className="stock-labels-grid-value large">{stockLabel.StockLabel.sku}</div>
        <div className="stock-labels-grid-value large">{stockLabel.StockLabel.description}</div>
        <div className="stock-labels-grid-value medium">{stockLabel.StockLabel.brand}</div>
        <div className="stock-labels-grid-value small center">{stockLabel.StockLabel.size1}</div>
        <div className="stock-labels-grid-value small center">{stockLabel.StockLabel.size2}</div>
        <div className="stock-labels-grid-value xlarge">{stockLabel.StockLabel.color}</div>
      </div>
      <button className="stock-labels-grid-delete" onClick={() => onDeleted(stockLabel.StockLabel.stockLabelId)}>
        <img src="assets/img/remove-alt.svg" />
      </button>
    </div>
  );
};

export default StockLabelsGridRow;

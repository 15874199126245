import { IonContent } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { ProductTaxCondition } from "../../../../interfaces/Taxes";
import AdvancedSpecialTaxEventConfigurationRow from "./AdvancedSpecialTaxEventConfigurationRow";

interface AdvancedSpecialTaxEventConfigurationGridProps {
  availableProductTypes: string[];
  eventId: string;
  productTaxes: ProductTaxCondition[];
  setAvailableProductTypes: Dispatch<SetStateAction<string[]>>;
}

const AdvancedSpecialTaxEventConfigurationGrid: React.FC<
  AdvancedSpecialTaxEventConfigurationGridProps
> = (props) => {
  return (
    <div className="advanced-taxes-content">
      <table className="advanced-taxes-content-grid">
        <tbody>
          <tr>
            <th className="special-event-advanced-product-type-column advanced-tax-header-row">
              Product Type
            </th>
            <th className="special-event-advanced-tax-rate-column advanced-tax-header-row">
              Tax Rate
            </th>
            <th className="special-event-advanced-delete-column advanced-tax-header-row"></th>
          </tr>
        </tbody>
      </table>
      <IonContent className="advanced-taxes-content-rows-container">
        <table>
          <tbody>
            {props.productTaxes.map((productTax, index) => {
              return (
                <AdvancedSpecialTaxEventConfigurationRow
                  availableProductTypes={props.availableProductTypes}
                  setAvailableProductTypes={props.setAvailableProductTypes}
                  key={props.eventId + index}
                  productTaxCondition={productTax}
                />
              );
            })}
          </tbody>
        </table>
      </IonContent>
    </div>
  );
};

export default AdvancedSpecialTaxEventConfigurationGrid;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import AmazonAuthorizationModal from "../../../../components/integrations/ecommerce/amazon/AmazonAuthorizationModal";
import ECommerceAccountManagement from "../../../../components/integrations/ecommerce/ECommerceAccountManagement";
import { usePortal } from "../../../../components/PortalProvider";
import { AmazonConstants } from "../../../../constants/ECommerceConstants";
import { Paths } from "../../../../constants/Paths";
import { AmazonAccount } from "../../../../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import { ECommerceAccountBase } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { getQueryParam } from "../../../../services/DataService";
import { useAmazonService } from "../../../../services/providers/AmazonServiceProvider";

const AmazonAccountManagement: React.FC = () => {
  const portal = usePortal();
  const amazonService = useAmazonService();
  const history = useHistory();

  const [selectedAccount, setSelectedAccount] = useState<AmazonAccount | null>(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(true);

  portal!.State.integrations = portal!.State.integrations || {};
  portal!.State.integrations.eCommerce = portal!.State.integrations.eCommerce || {};
  portal!.State.integrations.eCommerce.amazonAccounts = portal!.State.integrations.eCommerce.amazonAccounts || [];

  const exchangeAuthCode = async (accountId: string, authCode: string, sellerId: string) => {
    portal?.navigation.isLoading(true);

    let auth = await amazonService!.authorizeAccount(authCode, sellerId, accountId);

    if (auth) {
      history.push(`${Paths.AmazonAccounts}?accountId=${accountId}`);
      setIsAuthorized(true);
    }

    portal?.navigation.isLoading(false);
  };

  let sellerId: string | null = null;
  let mwsAuthorizationToken: string | null = null;
  let spApiOauthCode: string | null = null;

  let accountId = getQueryParam("accountId");
  let state = getQueryParam("state");
  if (state && state === window.sessionStorage.getItem("amazon-oauth-state")) {
    sellerId = getQueryParam("selling_partner_id") || "";
    mwsAuthorizationToken = getQueryParam("mws_auth_token") || "";
    spApiOauthCode = getQueryParam("spapi_oauth_code") || "";
    let stateParts = atob(state).split("|");
    accountId = stateParts && stateParts.length > 1 ? stateParts[1] : "";

    window.sessionStorage.removeItem("amazon-oauth-state");

    exchangeAuthCode(accountId, spApiOauthCode, sellerId);
  }

  useEffect(() => {
    if (reload) {
      getAmazonAccount();
      setReload(false);
    }
  }, [reload]);

  const setAmazonAccount = (account: AmazonAccount) => {
    setIsAuthorized(account.lwaAccessToken != null);
    setSelectedAccount(account);
  };

  const getAmazonAccount = async () => {
    portal?.navigation.isLoading(true);

    if (!accountId) {
      portal?.navigation.isLoading(false);
      return;
    }

    let account = await amazonService!.getAccount(accountId);
    if (account) {
      setAmazonAccount(account);
    }

    portal?.navigation.isLoading(false);
  };

  const getAmazonAccountDetails = () => {
    return (
      <div className="row">
        <div className="column w-50">
          <div className="edit-field-holder">
            <div className="heading">Seller Id</div>
            <div className="label">{selectedAccount?.sellerId}</div>
          </div>
        </div>
      </div>
    );
  };

  const getFBALocationDetails = () => {
    let fbaLocationId = selectedAccount?.fbaLocationIds && selectedAccount.fbaLocationIds.length > 0 ? selectedAccount.fbaLocationIds[0] : "";
    let fbaLocation =
      portal?.State.locations && portal?.State.locations.find((l) => l.originalLocationId === fbaLocationId || l.locationId === fbaLocationId);

    return (
      <>
        <div className="heading">FBA Location Configuration</div>
        <div className="label">{fbaLocation && fbaLocation.name}</div>
      </>
    );
  };

  const onSave = async (updates: ECommerceAccountBase) => {
    portal?.navigation.isLoading(true);

    let updatedAccount: AmazonAccount = {
      ...selectedAccount!,
      contactEmails: updates.contactEmails,
      ricsSourcingLocations: updates.ricsSourcingLocations,
      saleLocations: updates.saleLocations,
      useFulfillmentStrategy: updates.useFulfillmentStrategy,
      inventoryThreshold: updates.inventoryThreshold,
      orderSaleConfiguration: updates.orderSaleConfiguration,
      syncPricing: updates.syncPricing,
      retailPriceOffset: updates.retailPriceOffset,
      pricingStrategy: updates.pricingStrategy
    };

    let account = await amazonService!.saveAccount(updatedAccount);
    if (account) {
      setAmazonAccount(account);
    } else {
      setReload(true);
    }

    portal?.navigation.isLoading(false);
  };

  const authorizeAccount = () => {
    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    let redirectUri = encodeURIComponent(`${baseUrl}/integrations/amazon/accounts`);
    let state = encodeURIComponent(btoa(`sampleState|${accountId}`));
    let appId = encodeURIComponent(process.env.REACT_APP_AMAZON_APP_ID ?? "");
    window.sessionStorage.setItem("amazon-oauth-state", state);
    let url = `${AmazonConstants.oAuthUrl}?application_id=${appId}&state=${state}&redirect_uri=${redirectUri}&version=beta`;
    window.location.href = url;
  };

  const disableAccount = async () => {
    portal?.navigation.isLoading(true);

    if (await amazonService!.disableAccount(selectedAccount!.accountId)) {
      setReload(true);
    }

    portal?.navigation.isLoading(false);
  };

  const enableAccount = async () => {
    portal?.navigation.isLoading(true);

    if (await amazonService!.enableAccount(selectedAccount!.accountId)) {
      setReload(true);
    }

    portal?.navigation.isLoading(false);
  };

  return (
    <>
      {selectedAccount != null ? (
        <>
          <ECommerceAccountManagement
            id="amazon-account-management"
            account={selectedAccount}
            logoUrl="assets/img/amazon-logo.png"
            hideFulfillmentSection={selectedAccount.fbaLocationIds != undefined && selectedAccount.fbaLocationIds.length > 0}
            hidePricingSection={selectedAccount.fbaLocationIds != undefined && selectedAccount.fbaLocationIds.length > 0}
            hideCustomerSection={true}
            getIntegrationSpecificGeneralDetails={getAmazonAccountDetails}
            getFBALocationDetails={selectedAccount.fbaLocationIds && selectedAccount.fbaLocationIds.length > 0 ? getFBALocationDetails : undefined}
            onSave={onSave}
            disableAccount={disableAccount}
            enableAccount={enableAccount}
          />
          <AmazonAuthorizationModal show={show || !isAuthorized} onHide={() => setShow(false)} onAuthorize={authorizeAccount} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AmazonAccountManagement;

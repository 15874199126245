import { IonCheckbox, IonIcon, IonItem, IonList, IonPopover, IonText } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import React, { useEffect } from "react";
import { roleNames } from "../../constants/Roles";
import { Location, SelectedLocation } from "../../interfaces/Location";
import { User } from "../../interfaces/User";
import "../../theme/UserLocationsPopover.css";
import { usePortal } from "../PortalProvider";

interface UserLocationsPopoverProps {
  user: User;
  index: number;
  onUserChanged: (updatedUser: User) => void;
}

const UserLocationsPopover: React.FC<UserLocationsPopoverProps> = (props) => {
  const portal = usePortal();
  const invalidControlStyle = "user-control-invalid";
  const validControlStyle = "users-dropdown";
  const [allSelected, setAllSelected] = React.useState(false);
  const [locations, setLocations] = React.useState<Array<SelectedLocation>>([]);
  const [locationsDisplay, setLocationsDisplay] = React.useState<string>("");
  const [locationsAreDisabled, setLocationsAreDisabled] = React.useState(false);
  const [controlStyle, setControlStyle] = React.useState(validControlStyle);
  const [showLocationPopover, setShowLocationPopover] = React.useState<{
    open: boolean;
    event: Event | undefined;
  }>({ open: false, event: undefined });

  useEffect(() => {
    let preselectedLocations = preselectLocations(portal!.State.locations);
    setLocations(preselectedLocations);
    setAllSelected(preselectedLocations.every((l) => l.selected === true));
  }, [props.user]);

  useEffect(() => {
    formatLocationDisplay(locations);
  }, [locations]);

  useEffect(() => {
    setLocationsBasedOnRoles();
  }, [props.user.Roles]);

  const setLocationsBasedOnRoles = () => {
    let hasOwnerRole = props.user.Roles.some((r) => r.Name === roleNames.Owner);

    setLocationsAreDisabled(hasOwnerRole);

    if (hasOwnerRole && locations.length > 0) {
      setAllSelected(true);

      let locationsToUpdate = [...locations];

      locationsToUpdate.forEach((location) => {
        location.selected = true;
      });

      setLocations(locationsToUpdate);
    }
  };

  const preselectLocations = (tenantLocations: Location[]) => {
    let hasOwnerRole = props.user.Roles.some((r) => r.Name === roleNames.Owner);
    let tenantLocationsOptions = tenantLocations.map((l: any) => {
      return JSON.parse(JSON.stringify(l)) as SelectedLocation;
    });
    tenantLocationsOptions.forEach((locationOption: SelectedLocation) => {
      if (hasOwnerRole) {
        locationOption.selected = true;
        locationOption.disabled = true;
      } else {
        if (props.user.Locations.includes(locationOption.locationId)) {
          locationOption.selected = true;
        }
      }
    });
    return tenantLocationsOptions;
  };

  const formatLocationDisplay = (locations: SelectedLocation[]) => {
    let selectedLocations = locations.filter((location) => location.selected);
    let style = validControlStyle;

    let display = "";

    if (locations.every((l) => l.selected)) {
      display = "All";
    } else {
      if (locations.some((l) => l.selected)) {
        selectedLocations.every((location) => {
          display += location.name;
          if (display.length > 10) {
            display = display.substr(0, 10) + "...";
            return false;
          }
          return true;
        });
      } else {
        display = "None";
        style = `${validControlStyle} ${invalidControlStyle}`;
      }
    }

    setControlStyle(style);
    setLocationsDisplay(display);
  };

  const onAllClicked = () => {
    let value = !allSelected;
    setAllSelected(value);

    let locationsToUpdate = [...locations];

    locationsToUpdate.forEach((location) => {
      location.selected = value;
    });

    setLocations(locationsToUpdate);

    let updatedUser: User = { ...props.user };
    let selectedLocations = locationsToUpdate.filter((location) => location.selected);
    let locationIds = selectedLocations.map((location) => location.locationId);
    updatedUser.Locations = locationIds;

    props.onUserChanged(updatedUser);
  };

  const setLocationSelect = (locationId: string) => {
    let userLocations = Array.from(locations);
    let i = userLocations.findIndex((l) => l.locationId === locationId);
    let location = userLocations[i];
    location.selected = !location.selected;
    userLocations[i] = location;
    setLocations(userLocations);
    setAllSelected(false);

    let updatedUser: User = { ...props.user };
    let selectedLocations = locations.filter((location) => location.selected);
    let locationIds = selectedLocations.map((location) => location.locationId);
    updatedUser.Locations = locationIds;

    props.onUserChanged(updatedUser);
  };

  return (
    <>
      <div onClick={(e) => setShowLocationPopover({ open: true, event: e.nativeEvent })} className={controlStyle}>
        {locationsDisplay}
        <IonIcon slot="end" icon={chevronDown} className="icon" />
      </div>
      <IonPopover
        isOpen={showLocationPopover.open}
        onDidDismiss={() => setShowLocationPopover({ open: false, event: undefined })}
        className="location-popover"
        showBackdrop={false}
        event={showLocationPopover.event}>
        <IonList>
          <IonItem lines="none" key="all">
            <IonCheckbox checked={allSelected} disabled={locationsAreDisabled} onIonChange={() => onAllClicked()}></IonCheckbox>
            <IonText>Select All</IonText>
          </IonItem>
          {locations.map((location) => {
            return (
              <IonItem lines="none" key={location.locationId}>
                <IonCheckbox checked={location.selected} disabled={locationsAreDisabled} onIonChange={() => setLocationSelect(location.locationId)} />
                <IonText>{location.name}</IonText>
              </IonItem>
            );
          })}
        </IonList>
      </IonPopover>
    </>
  );
};

export default UserLocationsPopover;

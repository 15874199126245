import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { usePortal } from "../../../PortalProvider";
import ECommerceAccountSetupModal, { ECommerceAccountSetupModalProps, ECommerceAccountSetupModalPropsBase } from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupFinishedModalProps extends ECommerceAccountSetupModalPropsBase {
  createAccount(): Promise<boolean>;
  redirectPath: string;
}

const ECommerceAccountSetupFinishedModal: React.FC<ECommerceAccountSetupFinishedModalProps> = (props) => {
  const history = useHistory();
  const portal = usePortal();

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: `${props.vendor} Account Setup Finished`,
    hideFooter: true
  };

  const onFinishSetup = () => {
    if (props.onClose) props.onClose();

    history.push(props.redirectPath);
    history.go(0);
  };

  const createAccount = async () => {
    portal!.navigation.isLoading(true);

    let result = await props.createAccount();
    if (!result) {
      portal!.navigation.isLoading(false);
      return;
    }

    onFinishSetup();
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <Button className="button" onClick={createAccount}>
          Ok
        </Button>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupFinishedModal;

import { IonButton, IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import AmazonAccountSetupModal from "../../../../components/integrations/ecommerce/amazon/accountSetup/AmazonAccountSetupModal";
import AmazonAccountsGrid from "../../../../components/integrations/ecommerce/amazon/AmazonAccountsGrid";
import IntegrationLogo from "../../../../components/integrations/IntegrationLogo";
import { usePortal } from "../../../../components/PortalProvider";
import TenantLevelPageHeader from "../../../../components/TenantLevelPageHeader";
import { AmazonConstants, ECommerceConstants, ECommerceWizardSteps } from "../../../../constants/ECommerceConstants";
import { getBoolean } from "../../../../helpers/stringHelpers";
import { CreateAmazonAccount } from "../../../../interfaces/integrations/ecommerce/amazon/AmazonAccount";
import { CreateECommerceAccountBase, ECommerceOrderSaleConfiguration } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { getQueryParam } from "../../../../services/DataService";
import { useAmazonService } from "../../../../services/providers/AmazonServiceProvider";
import "../../../../theme/integrations/ecommerce/amazon/Amazon.css";

const Amazon: React.FC = () => {
  const portal = usePortal();
  const amazonService = useAmazonService();

  const [setupModalStep, setSetupModalStep] = useState(-1);

  const startWizard = () => {
    let createBaseAccount: CreateECommerceAccountBase = {
      adapterStartDate: new Date(),
      contactEmail: "",
      label: "",
      orderSaleConfiguration: ECommerceOrderSaleConfiguration.PaymentCapturedAndFulfilled,
      saveCustomersOnSale: false,
      syncPricing: false,
      useFulfillmentStrategy: false
    };

    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(createBaseAccount));

    let createAccount: CreateAmazonAccount = {
      ...createBaseAccount,
      lwaAccessToken: "",
      lwaAccessTokenExpiration: new Date(),
      lwaRefreshToken: "",
      mwsAuthorizationToken: "",
      sellerId: "",
      isFba: false
    };

    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(createAccount));
    setSetupModalStep(ECommerceWizardSteps.info);
  };

  let setup = getQueryParam("setup");
  useEffect(() => {
    if (setup && getBoolean(setup)) {
      startWizard();
    }
  }, [setup]);

  const exchangeAuthCode = async (authCode: string, sellerId: string, mwsAuthorizationToken: string) => {
    portal!.navigation.isLoading(true);

    let auth = await amazonService!.authorizeAccount(authCode, sellerId);

    if (!auth) {
      portal!.navigation.isLoading(false);
      setSetupModalStep(ECommerceWizardSteps.authFailure);
      return;
    }

    let tempAccount: CreateAmazonAccount = JSON.parse(window.sessionStorage.getItem(AmazonConstants.tempAccountKey)!);
    tempAccount.lwaAccessToken = auth.accessToken;
    tempAccount.lwaAccessTokenExpiration = auth.accessTokenExpiration;
    tempAccount.lwaRefreshToken = auth.refreshToken;
    tempAccount.sellerId = sellerId;
    tempAccount.mwsAuthorizationToken = mwsAuthorizationToken;

    window.sessionStorage.setItem(AmazonConstants.tempAccountKey, JSON.stringify(tempAccount));

    portal!.navigation.isLoading(false);
    setSetupModalStep(ECommerceWizardSteps.authSuccess);
  };

  let sellerId: string | null = null;
  let mwsAuthorizationToken: string | null = null;
  let spApiOauthCode: string | null = null;

  let state = getQueryParam("state");
  if (state && state === window.sessionStorage.getItem("amazon-oauth-state")) {
    sellerId = getQueryParam("selling_partner_id") || "";
    mwsAuthorizationToken = getQueryParam("mws_auth_token") || "";
    spApiOauthCode = getQueryParam("spapi_oauth_code") || "";

    window.sessionStorage.removeItem("amazon-oauth-state");

    exchangeAuthCode(spApiOauthCode, sellerId, mwsAuthorizationToken);
  }

  const authorizeAccount = () => {
    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    let redirectUri = encodeURIComponent(`${baseUrl}/integrations/amazon`);
    let state = encodeURIComponent(btoa("sampleState"));
    let appId = encodeURIComponent(process.env.REACT_APP_AMAZON_APP_ID ?? "");
    window.sessionStorage.setItem("amazon-oauth-state", state);
    let url = `${AmazonConstants.oAuthUrl}?application_id=${appId}&state=${state}&redirect_uri=${redirectUri}&version=beta`;
    window.location.href = url;
  };

  return (
    <IonPage className="amazon-page">
      <TenantLevelPageHeader headerText="Integrations" headerIconFileUrl="assets/img/integration.svg">
        <IonButton fill="clear" slot="end" className="add-account-header-button" onClick={() => startWizard()}>
          Add New
        </IonButton>
      </TenantLevelPageHeader>
      <IonContent className="amazon-content">
        <IntegrationLogo logoUrl="assets/img/amazon-logo.png" />
        <AmazonAccountsGrid />
      </IonContent>
      <AmazonAccountSetupModal wizardStep={setupModalStep} setWizardStep={setSetupModalStep} onAuthorize={authorizeAccount} />
    </IonPage>
  );
};

export default Amazon;

import React from "react";
import { IonModal } from "@ionic/react";

/* Theme variables */
import "../../theme/variables.css";

import "../../theme/POSWorkflowCard.css";
import PaymentDetails from "../PaymentDetails";
import Refunds from "../Refunds";
import WorkflowCard from "./WorkflowCard";
import POSDeviceSettings from "../POSDeviceSettings";
import { useXhrService } from "../XhrServiceProvider";
import { usePortal } from "../PortalProvider";

interface WorkflowCardProps {
  isComplete: boolean;
  setComplete: Function;
}

const POSWorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  const portal = usePortal();
  portal!.State.saleSettings = portal!.State.saleSettings || {};
  const xhrService = useXhrService();
  const [showModal, setShowModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(0);
  const [modalCssClass, setModalCssClass] = React.useState(
    "modal-card payment-details"
  );

  const openModal = () => {
    setShowModal(true);
  };

  const incrementModalView = (increment: number) => {
    var maxView = 2;
    var newView = modalView + increment;
    if (newView === 0) {
      setModalCssClass("modal-card payment-details");
    } else if (newView === 1) {
      setModalCssClass("modal-card refunds");
    } else if (newView === 2) {
      setModalCssClass("modal-card pos-device-settings");
    }
    setModalView(newView);
    if (newView < 0) {
      setModalView(0);
      setShowModal(false);
    }

    if (newView > maxView) {
      setModalView(0);
      setModalCssClass("modal-card payment-details");
      props.setComplete();
      setShowModal(false);
    }
  };

  return (
    <WorkflowCard
      title="Configure POS Settings"
      text="Specify details about how the point of sale system can be used at each location."
      icon="assets/img/settings.svg"
      isComplete={props.isComplete}
      onButtonClick={openModal}
    >
      <IonModal
        onDidDismiss={() => setShowModal(false)}
        className={modalCssClass}
        isOpen={showModal}
      >
        <div hidden={modalView !== 0}>
          <PaymentDetails
            locationSettings={portal!.State.saleSettings["Tenant"]}
            onComplete={incrementModalView}
            onboardingWorkflow={true}
          />
        </div>
        <div hidden={modalView !== 1}>
          <Refunds
            locationSettings={portal!.State.saleSettings["Tenant"]}
            onComplete={incrementModalView}
            onboardingWorkflow={true}
            posDeviceSettingsEnabled={true}
          />
        </div>
        <div hidden={modalView !== 2}>
          <POSDeviceSettings
            locationSettings={portal!.State.saleSettings["Tenant"]}
            onComplete={incrementModalView}
            onboardingWorkflow={true}
          />
        </div>
      </IonModal>
    </WorkflowCard>
  );
};

export default POSWorkflowCard;

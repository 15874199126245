import { IonIcon, IonPopover } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { APIVersions } from "../../constants/APIVersions";
import { roleNames } from "../../constants/Roles";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import { getRoleDisplayName, getSelectedRoles } from "../../helpers/rolesHelpers";
import { Role } from "../../interfaces/Role";
import { User } from "../../interfaces/User";
import "../../theme/UserRolesPopover.css";
import UserRoleSelectionItem from "./UserRoleSelectionItem";

interface UserRolesPopoverProps {
  user: User;
  onUserChanged: (updatedUser: User) => void;
}

const UserRolesPopover: React.FC<UserRolesPopoverProps> = (props) => {
  const flags = useFlags();
  const [selectedRoles, setSelectedRoles] = React.useState<Array<Role>>(props.user.Roles);
  const [showRolePopover, setShowRolePopover] = React.useState<{
    open: boolean;
    event: Event | undefined;
  }>({ open: false, event: undefined });

  useEffect(() => {
    setSelectedRoles(props.user.Roles);
  }, [props.user.Roles]);

  const onSelectRoles = (roles: Array<Role>) => {
    setSelectedRoles(roles);
    let updatedUser: User = { ...props.user };
    updatedUser.Roles = roles;
    props.onUserChanged(updatedUser);
  };

  const onSelectedRoleChanged = (role: Role) => {
    let roles: Array<Role> = role.Selected ? [...selectedRoles, role] : selectedRoles.filter((r) => r.Id !== role.Id);
    onSelectRoles(roles);
  };

  const hideRole = (role: Role) =>
    role.Hidden ||
    (role.Name === roleNames.Inventory && getVersion(flags) !== APIVersions.TwoDotOne && getVersion(flags) !== APIVersions.TwoDotTwo) ||
    (role.Name === roleNames.Buyer && getVersion(flags) !== APIVersions.TwoDotTwo);

  return (
    <>
      <div onClick={(e) => setShowRolePopover({ open: true, event: e.nativeEvent })} className="users-dropdown">
        {getRoleDisplayName(selectedRoles)}
        <IonIcon slot="end" icon={chevronDown} className="icon" />
      </div>
      <IonPopover
        isOpen={showRolePopover.open}
        onDidDismiss={() => setShowRolePopover({ open: false, event: undefined })}
        className="role-popover"
        showBackdrop={false}
        event={showRolePopover.event}>
        {getSelectedRoles(selectedRoles).map((role) => {
          if (hideRole(role)) {
            return;
          }

          return <UserRoleSelectionItem key={role.Id} role={role} onSelectedRoleChanged={(r) => onSelectedRoleChanged(r)} />;
        })}
      </IonPopover>
    </>
  );
};

export default UserRolesPopover;

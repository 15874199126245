import React from 'react';
import { RicsSideMenuTab } from '../../../interfaces/RicsSideMenuTab';
import { DrawerHistory } from './DrawerHistory';
import RicsSideMenuTabHeader from '../../RicsSideMenuTabHeader';

interface LocationDetailsDrawerHistoryProps {
	selectedTab: RicsSideMenuTab;
	locationId?: string;
	getDevices: Function;
}

export const LocationDetailsDrawerHistory: React.FC<LocationDetailsDrawerHistoryProps> = (props) => {
    return (
        <>
            <RicsSideMenuTabHeader selectedTab={props.selectedTab} />
			<div id="location-details-details" >
				<div className="tabbedSection">
					<DrawerHistory 
						locationId={props.locationId} 
						getDevices={props.getDevices} />
				</div>
			</div>
        </>
    );
};
import React, { createContext, useContext } from "react";
import { IAmazonService } from "../../interfaces/integrations/ecommerce/amazon/AmazonService";
import { authorizeAccount, createAccount, disableAccount, enableAccount, getAccount, getAccounts, saveAccount } from "../AmazonService";

const AmazonServiceContext = createContext<IAmazonService | undefined>(undefined);

interface AmazonServiceProviderProps {
  children?: React.ReactNode;
}

export const AmazonServiceProvider: React.FC<AmazonServiceProviderProps> = ({children}) => {
  const amazonService: IAmazonService = {
    getAccounts: getAccounts,
    authorizeAccount: authorizeAccount,
    enableAccount: enableAccount,
    disableAccount: disableAccount,
    getAccount: getAccount,
    saveAccount: saveAccount,
    createAccount: createAccount
  };

  return (
    <>
      <AmazonServiceContext.Provider value={amazonService}>{children}</AmazonServiceContext.Provider>
    </>
  );
};

export const useAmazonService = () => {
  return useContext(AmazonServiceContext);
};

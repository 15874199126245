import React from "react";
import "../theme/Tooltip.css";

type TooltipProps = {
  children: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({children}) => {
  return (
    <div className="rics-tooltip">
      <div className="rics-tooltip-popup">
        <div className="rics-tooltip-popup-content">{children}</div>
      </div>
      <img src="assets/img/info-alt.svg" />
    </div>
  );
};

export default Tooltip;

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { IntegrationsConstants } from "../../constants/IntegrationsConstants";
import ECommerceIntegrationsAccordionItem from "./ecommerce/ECommerceIntegrationsAccordionItem";

interface IntegrationsAccordionInnerProps {
    isAllIntegrations: boolean;
}

const IntegrationsAccordionInner: React.FC<IntegrationsAccordionInnerProps> = (props) => {
    const activeKeys: Array<string> = [IntegrationsConstants.eCommerceHeader];

    return (
        <>
            <Accordion className="integrations-accordion-inner" defaultActiveKey={activeKeys} alwaysOpen>
                <ECommerceIntegrationsAccordionItem isAllIntegrations={props.isAllIntegrations} />
            </Accordion>
        </>
    );
};

export default IntegrationsAccordionInner;
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDataService } from "../components/DataServiceProvider";
import { usePortal } from "../components/PortalProvider";
import { Paths } from "../constants/Paths";
import { SessionStorage } from "../constants/SessionStorage";
import { getVersion } from "../helpers/apiVersionsHelpers";
import { getQueryParam } from "../services/DataService";

const Home: React.FC = () => {
  const portal = usePortal();
  const flags = useFlags();
  const dataService = useDataService();
  const history = useHistory();

  const checkForRedirect = function () {
    portal?.navigation.isLoading(true);

    let path: string = Paths.Default;
    let redirectPath = getQueryParam("redirectPath");
    if (redirectPath) {
      path = redirectPath;
    }

    let vanityUrl = getQueryParam("vanityUrl");
    if (vanityUrl) {
      window.sessionStorage.setItem(SessionStorage.VanityUrl, vanityUrl);
    }

    const onSuccess = () => {
      portal?.navigation.isLoading(false);
      history.replace(path);
    };

    const onFailure = () => {
      window.localStorage.clear();
      window.location.pathname = Paths.Default;
      portal?.navigation.isLoading(false);
    };

    const version = getVersion(flags);
    dataService.identities.getCurrentIdentity(version, onSuccess, onFailure);
  };

  useEffect(() => {
    checkForRedirect();
  }, []);

  return <></>;
};

export default Home;

import { IonImg, IonModal } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { Location } from "../../interfaces/Location";
import { Identity } from "../../interfaces/User";
import { useDataService } from "../DataServiceProvider";
import { usePortal } from "../PortalProvider";
import { useXhrService } from "../XhrServiceProvider";

interface FinishAndInviteUsersModalProps {
  Location?: Location;
  showModal: boolean;
  setShowModal: Function;
}

const FinishAndInviteUsersModal: React.FC<FinishAndInviteUsersModalProps> = (props) => {
  const xhrService = useXhrService();
  const dataService = useDataService();
  let portal = usePortal();
  let history = useHistory();

  const finishAndInvite = function () {
    props.setShowModal(false);
    portal!.navigation.isLoading(true);
    var finalSuccessCallback = function () {
      portal!.State.setWorkflowButton();
      history.go(0);
      portal!.navigation.isLoading(false);
    };

    var onSuccess = function () {
      setupCompleteConfigurations(finalSuccessCallback, onFailure);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    sendInvitationEmails(onSuccess, onFailure);
    createPosTerminal(); // Isn't necessary for this to succeed, just a preemptive call
  };

  var sendInvitationEmails = function (finalSuccess: Function, onFailure: Function) {
    var userCount = -1;
    var nextSuccess = function () {
      userCount++;
      if (!portal!.State.currentLocation.usersAtLocation || userCount >= portal!.State.currentLocation.usersAtLocation.length) {
        finalSuccess();
        return;
      }

      var user = portal!.State.currentLocation.usersAtLocation[userCount];

      sendVerificationEmailFromApi(user, portal!.State.currentLocation, nextSuccess, onFailure);
    };

    nextSuccess();
  };

  const sendVerificationEmailFromApi = function (identity: Identity, location: Location, resolve: Function, reject: Function) {
    let endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    let redirectUri = `${baseUrl}/password/`;

    let payload = {
      identityId: identity.identityId,
      tenantId: portal!.State.tenantInfo.tenantId,
      locationId: location.locationId,
      redirectUri: redirectUri
    };

    let onSuccess = function () {
      resolve();
    };

    let onFailure = function () {
      reject();
    };

    xhrService!.DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");
  };

  var setupCompleteConfigurations = function (successCallback: Function, failureCallback: Function) {
    var setupCompleteConfiguration = {
      ConfigurationName: portal!.configurations.SetupWizardComplete,
      ConfigurationValue: true,
      LocationId: portal!.State.currentLocation.locationId
    };

    var endpoint = "v1/Configurations";

    var onSuccess = function () {
      dataService!.loadConfigurations(null, successCallback, failureCallback);
    };

    xhrService!.DoRicsApiXhr(endpoint, setupCompleteConfiguration, onSuccess, failureCallback, "POST");
  };

  var createPosTerminal = function () {
    var endpoint = "v1/Rics9/CreatePosTerminal";
    var payload = {
      LocationId: portal!.State.currentLocation.locationId,
      Description: "RICS Checkout"
    };

    xhrService!.DoRicsApiXhr(endpoint, payload, null, null, "POST");
  };

  return (
    <IonModal isOpen={props.showModal} onDidDismiss={() => props.setShowModal(false)} className="finish-and-invite-users-modal">
      <div id="modalBody">
        <div id="finishAndInviteModal">
          <div className="topRow">
            <IonImg class="finish-and-invite-image" src="img/finish-and-invite.svg"></IonImg>
            <div className="heading">Are you ready to send invitations</div>
            <div className="subHeading">
              You are about to invite <b>{props.Location?.usersAtLocation.length} users</b> to {props.Location?.name}. Invited users will receive an
              email with login <br />
              details for accessing the point of sale system.
              <br />
              <br />
              Are you ready to send invitations?
            </div>
          </div>
          <div className="middleRow"></div>
          <div className="bottomRow">
            <button className="buttonDefault cancelButton" onClick={() => props.setShowModal(false)}>
              No, not yet.
            </button>
            <div className="labelButton noSelect finishAndInviteButton" onClick={finishAndInvite}>
              <div>Yes, invite all users!</div>
              <IonImg src="img/send.svg" class="iconButton"></IonImg>
            </div>
          </div>
          <IonImg src="img/cancel.svg" class="exitButton" onClick={() => props.setShowModal(false)}></IonImg>
        </div>
      </div>
    </IonModal>
  );
};

export default FinishAndInviteUsersModal;

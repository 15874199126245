import React from 'react';
import { XhrService } from '../interfaces/XhrService';
import { DoRics9Xhr, DoRicsApiXhr, DoRicsXhr, DoXhr, FileUploadXhr } from '../services/XhrService';

const { createContext, useContext } = React;

const XhrServiceContext = createContext<XhrService | null>(null);

export const XhrServiceProvider = (props: any) => {
    const value = props.XhrService ?? {
        DoXhr: DoXhr,
        DoRicsXhr: DoRicsXhr,
        DoRicsApiXhr: DoRicsApiXhr,
        DoRics9Xhr: DoRics9Xhr,
        FileUploadXhr: FileUploadXhr
    };

    return (
        <XhrServiceContext.Provider value={value}>
            {props.children}
        </XhrServiceContext.Provider>
    );
};

export const useXhrService = () => {
    return useContext(XhrServiceContext);
};
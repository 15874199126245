import { IonContent, IonImg, IonPage } from "@ionic/react";
import React from "react";
import { usePortal } from "../components/PortalProvider";
import { Paths } from "../constants/Paths";
import { showWelcomeModal } from "../interfaces/Configuration";
import "../theme/PrivacyPolicy.css";
const PrivacyPolicy: React.FC = (props) => {
  const portal = usePortal();
  let backRoute: string = Paths.Default;
  if (showWelcomeModal(portal!.configurations)) {
    backRoute = Paths.Welcome;
  }

  return (
    <IonPage>
      <IonContent>
        <div className="privacyPolicyPage">
          <div className="topBarWrapper">
            <div className="innerWrapper">
              <IonImg src="img/rics-logo.svg" title="RICS-logo-full-colorAsset 1" style={{ width: "240px" }}></IonImg>
              <div className="buttonHolder">
                <a href={backRoute}>
                  <button className="buttonDefault returnButton">Back</button>
                </a>
              </div>
            </div>
          </div>
          <div className="privacyPolicy">
            <div className="privacyPolicyHolder">
              <div className="privacyPolicyIntroHolder">
                <h1 className="heading">Privacy Policy</h1>
                <div className="textContent">
                  This privacy notice discloses the privacy practices for the website{" "}
                  <a href={process.env.REACT_APP_PORTAL_BASE_URL}>portal.rics.io</a>. This privacy notice applies solely to information collected by
                  this website. It will notify you of the following:{" "}
                </div>
                <ol>
                  <li className="textContent">
                    What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
                  </li>
                  <li className="textContent">What choices are available to you regarding the use of your data.</li>
                  <li className="textContent">The security procedures in place to protect the misuse of your information.</li>
                  <li className="textContent">How you can correct any inaccuracies in the information.</li>
                </ol>
              </div>
              <div className="informationCollectionHolder">
                <h2 className="heading">Information Collection, Use, and Sharing</h2>
                <div className="textContent">
                  We are the sole owners of the information collected on this site. We only have access to / collect information that you voluntarily
                  give us via email or other direct contact from you. We will not sell or rent this information to anyone. We will use your
                  information to respond to you, regarding the reason you contacted us. We will not share your information with any third party
                  outside of our organization, other than as necessary to fulfill your request, e.g.to ship an order. Unless you ask us not to, we may
                  contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
                </div>
              </div>
              <div className="informationAccessHolder">
                <h2 className="heading">Your Access to and Control Over Information</h2>
                <div className="textContent">
                  You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email
                  address or phone number given on our website:
                </div>
                <ul>
                  <li className="textContent">See what data we have about you, if any.</li>
                  <li className="textContent">Change/correct any data we have about you.</li>
                  <li className="textContent">Have us delete any data we have about you.</li>
                  <li className="textContent">Express any concern you have about our use of your data.</li>
                </ul>
              </div>
              <div className="securityHolder">
                <h2 className="heading">Security</h2>
                <div className="textContent">
                  We take precautions to protect your information. When you submit sensitive information via the website, your information is
                  protected both online and offline. Wherever we collect sensitive information(such as credit card data), that information is
                  encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for
                  "https" at the beginning of the address of the Web page. While we use encryption to protect sensitive information transmitted
                  online, we also protect your information offline. Only employees who need the information to perform a specific job (for example,
                  billing or customer service) are granted access to personally identifiable information. The computers / servers in which we store
                  personally identifiable information are kept in a secure environment. If you feel that we are not abiding by this privacy policy,
                  you should contact us immediately via telephone or via email at <a href="mailto:portal@ricssoftware.com">portal@ricssoftware.com</a>
                  .
                </div>
              </div>
              <div className="cookiesHolder">
                <h2 className="heading">Cookies</h2>
                <div className="textContent">
                  We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to
                  our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in
                  with your email and password more than once, thereby saving time while on our site. Cookies can also enable us to track and target
                  the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally
                  identifiable information on our site.
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default PrivacyPolicy;

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { getQueryParam } from "./services/DataService";
import * as serviceWorker from "./serviceWorker";

// Extending the window definition to include a portal object
// corresponding to the "old code" window.portal object for
// accessing "old" data and functions, plus references
// to React, ReactDOM, and our custom components for the
// "old code" to use for injecting the react components

declare global {
  interface Window {
    portal: any;
  }
}

var portal = window.portal;
portal.navigation = portal.navigation || {};

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${process.env.REACT_APP_LD_CLIENT_SIDE_ID}`,
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    deferInitialization: false
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<LDProvider>
        <App />
       </LDProvider>);
};

const entryPointSetup = function () {
  var portalContentFrame = document.getElementById("portalContentFrame");

  portal.navigation.menuExpanded = true;

  var loadingOverlay = portal.loadingOverlay.createNew();
  portalContentFrame?.appendChild(loadingOverlay);

  let token = getQueryParam("token");
  if (token) {
    window.localStorage.setItem("Token", token);
  }

  renderApp();
};

const endpointSetup = function () {
  portal.common.baseUrl = process.env.REACT_APP_BASE_URL;
  portal.common.environment = process.env.REACT_APP_ENVIRONMENT;
};

if (document.addEventListener) {
  document.addEventListener(
    "DOMContentLoaded",
    async function () {
      document.removeEventListener(
        "DOMContentLoaded",
        function () {
          arguments.callee();
        },
        false
      );
      portal.navigation.isLoading(true);
      endpointSetup();
      entryPointSetup();
    },
    false
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//todo: look for instances of reentrypointsetup calls and showWelcomeModalDecision calls and make sure they're importing from here now

import { ItemReorderEventDetail } from "@ionic/core/components";
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonReorder, IonReorderGroup, IonRow, IonText, IonToggle } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import React, { useEffect } from "react";
import SettingsCard from "../components/SettingsCard";

/* Theme variables */
import "../theme/variables.css";

import { useFlags } from "launchdarkly-react-client-sdk";
import LeftRightContent from "../components/LeftRightContent";
import { FeatureFlags } from "../constants/FeatureFlags";
import { MerchantTypes } from "../interfaces/salesettings/MerchantSettings";
import { ReturnMethods } from "../interfaces/salesettings/ReturnMethods";
import { SaleSetting } from "../interfaces/salesettings/SaleSettings";
import "../theme/Settings.css";
import { useDataService } from "./DataServiceProvider";
import EnableStandAloneReturns from "./EnableStandAloneReturns";
import { usePortal } from "./PortalProvider";

const selectOptions = {
    cssClass: "select-setting",
    showBackdrop: false
};

export interface RefundsProps {
    locationSettings: SaleSetting;
    locationId?: string;
    onboardingWorkflow?: Boolean;
    onComplete?: Function;
    posDeviceSettingsEnabled?: Boolean;
    onClutchEnabledChanged?: Function;
    onEnableStandAloneReturnsChanged?: Function;
    showStandAloneReturnsOption?: boolean;
}

export const Refunds: React.FC<RefundsProps> = ({
    locationSettings,
    locationId,
    onboardingWorkflow = false,
    onComplete,
    posDeviceSettingsEnabled,
    onClutchEnabledChanged,
    onEnableStandAloneReturnsChanged,
    showStandAloneReturnsOption
}) => {
    const portal = usePortal();
    const flags = useFlags();
    const dataService = useDataService();
    const [returnMethods, setReturnMethods] = React.useState(locationSettings.returnMethods);
    const [tenderTypeOptions, setTenderTypeOptions] = React.useState(locationSettings.tenderTypeOptions);
    const [isReturnMethodsValid, setIsReturnMethodsValid] = React.useState(!locationSettings.returnMethods!.every((x) => x.enabled === false));
    const [returnReasons, setReturnReasons] = React.useState(locationSettings.returnReasons);
    const [enableStandAloneReturns, setEnableStandAloneReturns] = React.useState(locationSettings.enableStandAloneReturns ?? false);
    const [locationsCanOverride, setLocationsCanOverride] = React.useState(true);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [canSave, setCanSave] = React.useState(false);
    const [unlinkedRefundsFeatureFlagEnabled, setUnlinkedRefundsFeatureFlag] = React.useState(false);
    const [isRicsPayMerchant, setIsRicsPayMerchant] = React.useState(
        locationSettings.merchantSettings?.merchantType == MerchantTypes.RicsPay ?? false
    );

    const inventoryBehaviors: { id: string; label: string }[] = [
        { id: "Resellable", label: "Item is resellable" },
        { id: "Nonsellable", label: "Item is NOT resellable" }
    ];

    const creditCardReturnId = "credit";
    const alternateCreditCardReturnSubTypeId = "AlternateCreditCard";

    useEffect(() => {
        let canOverride = portal!.State.saleSettings["Tenant"].locationsCanOverrideRefunds ?? true;

        if (locationId && !canOverride) {
            setIsDisabled(true);
        }
        if (returnMethods == undefined) {
            return;
        }
        let sortedReturnMethods = sortReturnMethods(returnMethods);
        setReturnMethods(sortedReturnMethods);
        setLocationsCanOverride(canOverride);
    }, []);

    useEffect(() => {
        getUnlinkedRefundsFeatureFlag();
    }, [flags]);

    useEffect(() => {
        let creditCardRefundEnabled = locationSettings.merchantSettings?.merchantType == MerchantTypes.RicsPay ?? false;
        setIsRicsPayMerchant(creditCardRefundEnabled);
    }, [returnMethods]);

    const getUnlinkedRefundsFeatureFlag = () => {
        let featureFlag = flags[FeatureFlags.UnlinkedRefunds];
        setUnlinkedRefundsFeatureFlag(featureFlag);
    };

    const sortReturnMethods = (returnMethods: ReturnMethods[]) => {
        const sortedReturnMethods = returnMethods.slice();
        sortedReturnMethods.sort((a, b) => {
            if (a.returnMethodId === creditCardReturnId && !a.returnMethodSubTypeId) {
                return -1;
            }
            if (b.returnMethodId === creditCardReturnId && !b.returnMethodSubTypeId) {
                return 1;
            }
            if (a.returnMethodId === creditCardReturnId && a.returnMethodSubTypeId === alternateCreditCardReturnSubTypeId) {
                return -1;
            }
            if (b.returnMethodId === creditCardReturnId && b.returnMethodSubTypeId === alternateCreditCardReturnSubTypeId) {
                return 1;
            }
            return a.returnMethodLabel.localeCompare(b.returnMethodLabel);
        });
        return sortedReturnMethods;
    };

    const onUpdateItems = (index: number) => {
        let currentOptions = [...returnMethods!];
        let alternateCreditCard = currentOptions.find((x) => x.returnMethodSubTypeId === alternateCreditCardReturnSubTypeId);
        let credit = currentOptions.find((x) => x.returnMethodId === creditCardReturnId && x.returnMethodSubTypeId == undefined);

        if (currentOptions[index].returnMethodSubTypeId === alternateCreditCardReturnSubTypeId && !currentOptions[index].enabled) {
            if (credit != undefined && !credit?.enabled) {
                credit.enabled = true;
            }
        } else if (
            currentOptions[index].returnMethodId === creditCardReturnId &&
            currentOptions[index].returnMethodSubTypeId == undefined &&
            currentOptions[index].enabled
        ) {
            if (alternateCreditCard != undefined && alternateCreditCard.enabled) {
                alternateCreditCard.enabled = false;
            }
        }
        currentOptions[index].enabled = !currentOptions[index].enabled;
        setReturnMethods(currentOptions);
        let isReturnMethodValid = !currentOptions.every((x) => x.enabled === false);
        setIsReturnMethodsValid(isReturnMethodValid);
        setCanSave(isReturnMethodValid);

        if (
            currentOptions[index].returnMethodId == "GiftCard" &&
            currentOptions[index].returnMethodSubTypeId == "Clutch" &&
            currentOptions[index].enabled
        ) {
            var clutchTenderType = tenderTypeOptions?.find((x) => x.tenderTypeOptionId == "GiftCard" && x.tenderTypeOptionSubTypeId == "Clutch");
            if (clutchTenderType) {
                clutchTenderType.enabled = true;
            }

            setTenderTypeOptions(tenderTypeOptions);
        }
    };

    const save = () => {
        portal!.navigation.isLoading(true);
        setCanSave(false);

        let salesSettings = {} as SaleSetting;
        if (locationId) {
            salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings["Tenant"];
        } else {
            salesSettings = portal!.State.saleSettings["Tenant"];
        }

        let settingsToSave: SaleSetting = {
            tenderTypeOptions: tenderTypeOptions,
            returnMethods: returnMethods,
            returnReasons: returnReasons,
            locationId: locationId,
            enableStandAloneReturns: enableStandAloneReturns
        };

        Object.assign(salesSettings, settingsToSave);

        if (!locationId) {
            salesSettings.locationsCanOverrideRefunds = locationsCanOverride;
        } else {
            if (salesSettings.deviceSettings) {
                salesSettings.deviceSettings.locationsCanOverride = undefined;
            }
            salesSettings.locationsCanOverridePaymentDetails = undefined;
            salesSettings.locationsCanOverrideRefunds = undefined;
        }

        let onFailure = function () {
            portal!.navigation.isLoading(false);
            alert("Something unexpected went wrong");
        };

        let onSuccess = function () {
            dataService!.enqueueLocationForUpdate(locationId);
            portal!.navigation.isLoading(true);

            let onSuccess2 = function () {
                if (onClutchEnabledChanged) {
                    onClutchEnabledChanged();
                }
                if (onEnableStandAloneReturnsChanged) {
                    onEnableStandAloneReturnsChanged(salesSettings.enableStandAloneReturns ?? false);
                }
                portal!.navigation.isLoading(false);
            };

            dataService!.getSaleSettings(onSuccess2, onFailure);
        };

        dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);

        if (onboardingWorkflow === true && onComplete) {
            onComplete(1);
        }
    };

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        var items = event.detail.complete(returnReasons);
        setReturnReasons(items);
        setCanSave(isReturnMethodsValid);
    }

    const setReturnReasonEnabled = (index: number) => {
        var currentReasons = returnReasons!;
        currentReasons[index].enabled = !currentReasons[index].enabled;
        setReturnReasons(currentReasons);
        setCanSave(isReturnMethodsValid);
    };

    const setInventoryBehavior = (index: number, detail: string) => {
        var currentReasons = [...returnReasons!];
        var selectedBehavior = inventoryBehaviors.find((i) => i.id === detail);
        currentReasons[index].inventoryBehavior = selectedBehavior?.id || currentReasons[index].inventoryBehavior;
        setReturnReasons(currentReasons);
        setCanSave(isReturnMethodsValid);
    };

    const onBack = () => {
        if (onComplete) {
            onComplete(-1);
        }
    };

    const onSetLocationsCanOverride = (canOverride: boolean) => {
        setLocationsCanOverride(canOverride);
        setCanSave(true);
    };

    const onSetEnableStandAloneReturns = (enableStandAloneReturns: boolean) => {
        setEnableStandAloneReturns(enableStandAloneReturns);
        setCanSave(true);
    };

    const standAloneReturns = () => {
        if (showStandAloneReturnsOption) {
            return (
                <EnableStandAloneReturns
                    enableStandAloneReturns={enableStandAloneReturns}
                    onChange={onSetEnableStandAloneReturns}
                    isDisabled={false}
                />
            );
        }
    };

    const returnMethodTypeHidden = (index: number) => {
        if (returnMethods == undefined) {
            return false;
        }

        if (returnMethods[index].returnMethodSubTypeId === alternateCreditCardReturnSubTypeId) {
            return !unlinkedRefundsFeatureFlagEnabled || !isRicsPayMerchant;
        }

        return false;
    };

    return (
        <SettingsCard
            header='Refunds'
            subHeading='Specify supported refund methods and reasons for return.'
            LocationsOverrideProps={{ onChange: onSetLocationsCanOverride, locationsCanOverride: locationsCanOverride }}
            isDisabled={locationId !== undefined}>
            <LeftRightContent heading='Refund Methods' preamble='How can sales associates offer refunds?'>
                <IonGrid>
                    <IonRow>
                        {returnMethods!.map((returnMethod, index) => {
                            return (
                                <IonCol
                                    key={returnMethod.returnMethodId + returnMethod.returnMethodSubTypeId}
                                    size='6'
                                    hidden={returnMethodTypeHidden(index)}>
                                    <IonItem lines='none' className='setting' style={{ overflow: "visible" }}>
                                        <div className='ion-float-right refund-settings-tooltip-holder ion-align-self-center'>
                                            <div className='refund-settings ion-align-items-center'>
                                                <div className='ion-float-right refund-settings-tooltip-holder ion-align-self-center'>
                                                    <div className='refund-settings ion-align-items-center'>
                                                        <IonToggle
                                                            checked={returnMethod.enabled}
                                                            disabled={isDisabled}
                                                            onClick={() => onUpdateItems(index)}
                                                        />
                                                        <IonText>{returnMethod.returnMethodSubTypeLabel ?? returnMethod.returnMethodLabel}</IonText>
                                                        <div
                                                            className='refund-settings-tooltip'
                                                            hidden={returnMethod.returnMethodSubTypeId !== alternateCreditCardReturnSubTypeId}>
                                                            <IonIcon src='assets/img/info.svg' className='info'></IonIcon>
                                                            <span className='tooltiptext' style={{ height: "90px" }}>
                                                                Allows salespeople to process refunds to a different credit card than the one used for
                                                                the original transaction. Note: Credit Card refunds must be enabled to use Alternate
                                                                Credit Card refunds.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </IonItem>
                                    {returnMethod.returnMethodId === "GiftCard" &&
                                    returnMethod.returnMethodSubTypeId === "Clutch" &&
                                    returnMethod.enabled &&
                                    !locationId ? (
                                        <IonItem lines='none' className='setting'>
                                            <IonText color='danger'>Configure at Location(s)</IonText>
                                        </IonItem>
                                    ) : (
                                        <></>
                                    )}
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </IonGrid>
                <IonLabel hidden={isReturnMethodsValid} color='danger'>
                    You must enable at least one return type.
                </IonLabel>
            </LeftRightContent>
            <LeftRightContent
                heading='Return Reasons'
                preamble="When an item is returned, associates are required to specify a reason.  We have provided some defaults, but you're welcome to add your own, and drag to reorder them.">
                <IonReorderGroup disabled={isDisabled} onIonItemReorder={doReorder}>
                    {returnReasons!.map((returnReason, index) => {
                        return (
                            <IonItem key={returnReason.returnReasonId} className='setting return-reason-setting'>
                                <div className='return-reason-column'>
                                    <IonReorder slot='start' className='ion-align-self-center' />
                                    <IonToggle
                                        checked={returnReason.enabled}
                                        className='ion-align-self-center'
                                        disabled={isDisabled}
                                        onClick={() => setReturnReasonEnabled(index)}
                                    />
                                    <IonLabel className='ion-align-self-center'>{returnReason.returnReasonLabel}</IonLabel>
                                </div>
                                <select
                                    className='return-reason-select'
                                    disabled={isDisabled}
                                    onChange={(e) => setInventoryBehavior(index, e.target.value)}>
                                    <option
                                        selected={returnReason.inventoryBehavior == inventoryBehaviors[0].label}
                                        className='select-option'
                                        value={inventoryBehaviors[0].id}>
                                        {inventoryBehaviors[0].label}
                                    </option>
                                    <option
                                        selected={returnReason.inventoryBehavior == inventoryBehaviors[1].label}
                                        className='select-option'
                                        value={inventoryBehaviors[1].id}>
                                        {inventoryBehaviors[1].label}
                                    </option>
                                </select>
                            </IonItem>
                        );
                    })}
                </IonReorderGroup>
            </LeftRightContent>
            <LeftRightContent heading='Miscellaneous' preamble='Other settings' hidden={!showStandAloneReturnsOption}>
                <IonGrid>
                    <IonRow>{standAloneReturns()}</IonRow>
                </IonGrid>
            </LeftRightContent>
            <div className='buttonsHolder' hidden={onboardingWorkflow === true}>
                <IonButton color='primary' disabled={isDisabled || !canSave} onClick={() => save()} className='save-setting'>
                    Save Changes
                </IonButton>
            </div>
            <div className='modal-buttons-holder' hidden={onboardingWorkflow === false}>
                <IonButton onClick={() => onBack()} className='save-setting modal-back-button'>
                    Go Back
                </IonButton>
                <IonButton color='primary' onClick={() => save()} className='save-setting ion-float-right'>
                    {posDeviceSettingsEnabled && (
                        <>
                            <IonLabel>Next: Device Settings</IonLabel>
                            <IonIcon src={arrowForward} className='icon-icon-arrow-right' />
                        </>
                    )}
                    {!posDeviceSettingsEnabled && <IonLabel>Done with Settings</IonLabel>}
                </IonButton>
            </div>
        </SettingsCard>
    );
};

export default Refunds;

import { IonButton, IonContent, IonPopover, IonText } from '@ionic/react';
import React, { useEffect } from 'react';

import '../../../theme/locationDetails/taxes/LocationDetailsTaxes.css';
import { SpecialTaxEvent, Tax } from '../../../interfaces/Taxes';
import { RicsSideMenuTab } from '../../../interfaces/RicsSideMenuTab';
import TaxJurisdictionsCard from './jurisdictions/TaxJurisdictionsCard';
import SpecialTaxEventCard from './specialTaxEvents/SpecialTaxEventCard';
import { usePortal } from '../../PortalProvider';
import { useXhrService } from '../../XhrServiceProvider';
import { useDataService } from '../../DataServiceProvider';
import RicsSideMenuTabHeader from '../../RicsSideMenuTabHeader';

interface LocationDetailsTaxesProps {
    selectedTab: RicsSideMenuTab;
    locationId: string;
}

const LocationDetailsTaxes: React.FC<LocationDetailsTaxesProps> = (props) => {
    const xhrService = useXhrService();
    const dataService = useDataService();
    const portal = usePortal();
    const [taxes, setTaxes] = React.useState<Tax[]>([]);
    const [specialTaxEvents, setSpecialTaxEvents] = React.useState<SpecialTaxEvent[]>([]);
    const [failures, setFailures] = React.useState<string[]>([]);
    const [showFailureAlert, setShowFailureAlert] = React.useState(false);

    useEffect(() => {
        getTaxes(getSpecialTaxEvents);
    }, []);

    //TODO: Use the "state" Taxes once everything has been migrated over.
    //Can't use the current State taxes since they manipulate the numeric data on load and save,
    //and we're doing that in the React components for display purposes.
    const getTaxes = (successCallback?: Function) => {
        portal!.navigation.isLoading(true);
        let endpoint = `v1/Taxes/Locations/${props.locationId}`;

        let onSuccess = function (response: string) {
            var taxes = JSON.parse(response) as Tax[];
            setTaxes(taxes);

            if (!!successCallback) {
                successCallback();
            } else {
                portal!.navigation.isLoading(false);
            }
        };

        xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onShowFailureAlert, "GET");
    };

    const getSpecialTaxEvents = (successCallback?: Function) => {
        portal!.navigation.isLoading(true);
        let endpoint = `v1/Taxes/Events/Locations/${props.locationId}`;

        let onSuccess = function (response: string) {
            var taxEvents = JSON.parse(response) as SpecialTaxEvent[];
            setSpecialTaxEvents(taxEvents);

            if (!!successCallback) {
                successCallback();
            } else {
                portal!.navigation.isLoading(false);
            }
        };

        xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onShowFailureAlert, "GET");
    };

    const saveTaxes = () => {
        portal!.navigation.isLoading(true);

        let numberOfSuccesses = 0;
        let refreshTaxEvents = false;

        let onFinalSuccess = () => {
            dataService!.enqueueLocationForUpdate(props.locationId);
            if (refreshTaxEvents) {
                getSpecialTaxEvents();
            } else {
                portal!.navigation.isLoading(false);
            }
        };

        let onSuccess = (response: string) => {
            numberOfSuccesses++;
            let updatedTax = JSON.parse(response) as Tax;
            let currentTaxes = [...taxes];
            currentTaxes[numberOfSuccesses - 1] = updatedTax;
            setTaxes(currentTaxes);

            if (numberOfSuccesses >= taxes.length) {
                dataService!.refreshTaxes(null, onFinalSuccess, onShowFailureAlert);
            } else {
                next();
            }
        };

        let next = () => {
            let taxToUpdate = taxes[numberOfSuccesses];

            if (!taxToUpdate.locationIds || taxToUpdate.locationIds.indexOf(props.locationId) < 0) {
                refreshTaxEvents = true;
                clearTaxFromEvents(taxToUpdate.taxId!);
            }

            if (taxToUpdate.taxId?.includes("temp-")) {
                taxToUpdate.taxId = undefined;
            }

            taxToUpdate.productTaxes = taxToUpdate.productTaxes?.filter(x => !!x.productType);

            xhrService!.DoRicsApiXhr("v1/Taxes", taxToUpdate, onSuccess, onShowFailureAlert, 'PUT');
        };

        next();
    };

    const saveSpecialTaxEvent = (taxEvent: SpecialTaxEvent, callback?: Function) => {
        portal!.navigation.isLoading(true);

        let onSuccess = () => {
            if (!!callback) {
                callback();
            } else {
                getSpecialTaxEvents();
            }
        };

        xhrService!.DoRicsApiXhr('v1/Taxes/Events', taxEvent, onSuccess, onShowFailureAlert, "PUT");
    };

    const onShowFailureAlert = (response: string) => {
        portal!.navigation.isLoading(false);
        let results = JSON.parse(response);

        if (!!results.errors && Array.isArray(results.errors)) {
            setFailures(results.errors);
        } else {
            setFailures([]);
        }

        setShowFailureAlert(true);
    };

    const clearTaxFromEvents = (taxId: string) => {
        let eventsToUpdate = specialTaxEvents.filter(x => x.applicableTaxIds!.indexOf(taxId) >= 0);

        if (!!eventsToUpdate && eventsToUpdate.length > 0) {
            let numberOfSuccesses = 0;

            let next = () => {
                if (numberOfSuccesses < eventsToUpdate.length) {
                    let eventToUpdate = eventsToUpdate[numberOfSuccesses++];
                    eventToUpdate.applicableTaxIds!.splice(eventToUpdate.applicableTaxIds!.indexOf(taxId), 1);

                    if (!!eventToUpdate.productTaxes) {
                        eventToUpdate.productTaxes = eventToUpdate.productTaxes.filter(x => x.taxId !== taxId);
                    }

                    saveSpecialTaxEvent(eventToUpdate, next);
                }
            };

            next();
        }
    };

    return (
        <>
            <RicsSideMenuTabHeader selectedTab={props.selectedTab} />
            <IonContent id="locationsPageContentInjectionContainer" className="users-page" >
                <div className="tabbedSection">
                    <div className="tabContent">
                        <div className="taxes-page-grid">
                            <div className="taxes-card-container">
                                <TaxJurisdictionsCard
                                    locationId={props.locationId}
                                    taxes={taxes}
                                    setTaxes={setTaxes}
                                    saveTaxes={saveTaxes} />
                            </div>
                            <div className="taxes-card-container">
                                <SpecialTaxEventCard
                                    specialTaxEvents={specialTaxEvents}
                                    setSpecialTaxEvents={setSpecialTaxEvents}
                                    taxes={taxes}
                                    locationId={props.locationId}
                                    saveSpecialTaxEvent={saveSpecialTaxEvent}
                                    getSpecialTaxEvents={getSpecialTaxEvents} />
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <IonPopover
                className="save-taxes-error-popover"
                isOpen={showFailureAlert}
                onDidDismiss={() => setShowFailureAlert(false)}>
                <div>
                    <IonText><h2>Something unexpected went wrong when trying to save your taxes.</h2></IonText>
                </div>
                <ul className="failures-list">
                    {failures.map((failure, index) => {
                        return (
                            <li key={index}>{failure}</li>
                        )
                    })}
                </ul>
                <IonButton color="primary" className="ion-float-right" onClick={() => setShowFailureAlert(false)}>OK</IonButton>
            </IonPopover>
        </>
    );
};

export default LocationDetailsTaxes;

import React from "react";
import { StockLabelDisplay } from "../../pages/ManageLabels";
import StockLabelsGridRow from "./StockLabelsGridRow";

type StockLabelsGridProps = {
  stockLabels: Array<StockLabelDisplay>;
  onUpdateSelectedLabels: (labels: Array<StockLabelDisplay>) => void;
  onLabelDeleted: (stockLabelId: string) => void;
  showLoadingIcon?: boolean;
};

const StockLabelsGrid: React.FC<StockLabelsGridProps> = ({ stockLabels, onUpdateSelectedLabels, showLoadingIcon, onLabelDeleted }) => {
  const onSelected = (stockLabelId: string, selected: boolean) => {
    let label = stockLabels.find((l) => l.StockLabel.stockLabelId === stockLabelId);
    if (label === undefined) {
      return;
    }

    label.Selected = selected;

    onUpdateSelectedLabels([...stockLabels]);
  };

  return (
    <>
      <div className="stock-labels-grid-body">
        {stockLabels.map((g) => {
          return <StockLabelsGridRow key={g.StockLabel.stockLabelId} stockLabel={g} onSelected={onSelected} onDeleted={onLabelDeleted} />;
        })}
        <div className="stock-labels-grid-loading" hidden={!showLoadingIcon}>
          <img src="assets/img/loading.svg" />
        </div>
      </div>
    </>
  );
};

export default StockLabelsGrid;

import { IonButton, IonIcon, IonItem, IonModal, IonPopover, IonText } from '@ionic/react';
import React, { useEffect } from 'react';
import { SpecialTaxEvent, Tax } from '../../../../interfaces/Taxes';
import { format } from 'date-fns';
import EditSpecialTaxEvent, { SpecialTaxEventApplicableTaxes } from './EditSpecialTaxEvent';

interface SpecialTaxEventSummaryProps {
    taxEvent: SpecialTaxEvent;
    taxes: Tax[];
    deleteSpecialTaxEvent: Function;
    duplicateSpecialTaxEvent: Function;
    closeModal: Function;
    showSpecialEventAdvancedConfiguration: Function;
    saveSpecialTaxEvent: Function;
}

const SpecialTaxEventSummary: React.FC<SpecialTaxEventSummaryProps> = (props) => {
    const [taxJurisdictionsDisplay, setTaxJuristidctionsDisplay] = React.useState<string>("None.");
    const [productCategoriesAffectedDisplay, setProductCategoriesAffectedDisplay] = React.useState<string>("");
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [showDuplicateModal, setShowDuplicateModal] = React.useState<boolean>(false);
    const [showCreateSpecialTaxEvent, setShowCreateSpecialTaxEvent] = React.useState(false);
    const [applicableTaxes, setApplicableTaxes] = React.useState<SpecialTaxEventApplicableTaxes[]>([]);
    const [showPopover, setShowPopover] = React.useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });

    useEffect(() => {
        let applicableTaxes = props.taxes.map(x => {
            return (
                {
                    taxId: x.taxId,
                    name: x.description,
                    checked: props.taxEvent.applicableTaxIds!.indexOf(x.taxId!) >= 0
                } as SpecialTaxEventApplicableTaxes
            )
        });

        setApplicableTaxes(applicableTaxes);

        let selectedTaxNames = props.taxEvent.applicableTaxIds!.map(x => {
            return (
                props.taxes.find(y => y.taxId === x)?.description
            )
        });

        selectedTaxNames = selectedTaxNames.filter(x => !!x);

        if (selectedTaxNames.length > 0) {
            setTaxJuristidctionsDisplay(selectedTaxNames.join(", "));
        }

        if (props.taxEvent.applyToEverything) {
            setProductCategoriesAffectedDisplay("Everything Sold");
        } else if (!!props.taxEvent.productTaxes && props.taxEvent.productTaxes.length === 0) {
            setProductCategoriesAffectedDisplay("No Product Categories configured yet.")
        } else {
            setProductCategoriesAffectedDisplay(props.taxEvent.productTaxes!.map(x => {
                return (
                    x.productType
                )
            }).join(", "));
        }
    }, [props.taxes, props.taxEvent]);

    const deleteTaxEvent = () => {
        setShowDeleteModal(false);
        props.deleteSpecialTaxEvent(props.taxEvent);
    };

    const duplicateTaxEvent = () => {
        setShowDuplicateModal(false);
        props.duplicateSpecialTaxEvent(props.taxEvent);
    };

    const showAdvancedModal = () => {
        props.showSpecialEventAdvancedConfiguration(props.taxEvent);
    };

    const onCloseEditModa = (refreshEvents: boolean) => {
        setShowCreateSpecialTaxEvent(false);
        props.closeModal(refreshEvents);
    };

    return (
        <div className="tax-event-container">
            <div className="tax-event-header">
                <IonText className="tax-event-name">{props.taxEvent.eventName}</IonText>
                <IonIcon
                    src="img/overflow.svg"
                    className="ion-float-right tax-event-menu-icon"
                    onClick={e => {
                        e.persist();
                        setShowPopover({ open: true, event: e.nativeEvent });
                    }}></IonIcon>
                <IonText
                    className="ion-float-right tax-event-date">{format(new Date(props.taxEvent.startDate!), "MM/dd/yyyy")} - {format(new Date(props.taxEvent.endDate!), "MM/dd/yyyy")}</IonText>

                <IonPopover
                    isOpen={showPopover.open}
                    event={showPopover.event}
                    showBackdrop={false}
                    className="special-tax-event-menu"
                    onDidDismiss={() => setShowPopover({ open: false, event: undefined })}>
                    <IonButton
                        fill="clear"
                        expand="full"
                        onClick={() => {
                            setShowCreateSpecialTaxEvent(true);
                            setShowPopover({ open: false, event: undefined });
                        }}
                        className="popup-edit-button">
                        <span className="popup-button-contents">
                            <IonIcon src="img/edit.svg" />
                            <IonText>Edit</IonText>
                        </span>
                    </IonButton>
                    <IonButton
                        fill="clear"
                        expand="full"
                        onClick={() => {
                            setShowDuplicateModal(true);
                            setShowPopover({ open: false, event: undefined });
                        }}
                        className="popup-duplicate-button">
                        <span className="popup-button-contents">
                            <IonIcon src="img/duplicate.svg" />
                            <IonText>Duplicate</IonText>
                        </span>
                    </IonButton>
                    <IonButton
                        fill="clear"
                        expand="full"
                        onClick={() => {
                            setShowDeleteModal(true);
                            setShowPopover({ open: false, event: undefined });
                        }}
                        className="popup-delete-button">
                        <span className="popup-button-contents">
                            <IonIcon src="img/delete.svg" />
                            <IonText>Delete</IonText>
                        </span>
                    </IonButton>
                </IonPopover>
            </div>
            <div>
                Tax jurisdictions affected: {taxJurisdictionsDisplay}
            </div>
            <div>
                Product Categories affected: {productCategoriesAffectedDisplay}
            </div>
            <div hidden={props.taxEvent.applyToEverything}>
                <IonItem className="action-items" onClick={() => showAdvancedModal()} lines="none">
                    <IonIcon color="primary" src='img/filters.svg' className="advanced-taxes-icon" />
                    <IonText color="primary">
                        Click for Advanced Tax Event Configuration...
                    </IonText>
                </IonItem>
            </div>
            <IonModal
                isOpen={showCreateSpecialTaxEvent}
                onDidDismiss={() => onCloseEditModa(false)}
                className="create-special-tax-event-modal">
                <EditSpecialTaxEvent
                    specialTaxEvent={props.taxEvent}
                    taxes={applicableTaxes}
                    closeModal={onCloseEditModa}
                    saveSpecialTaxEvent={props.saveSpecialTaxEvent} />
            </IonModal>
            <IonModal
                isOpen={showDeleteModal}
                onDidDismiss={() => setShowDeleteModal(false)}
                className="delete-special-tax-event-modal">
                <div className="special-event-modal-delete-text">
                    <IonText>Are you sure you want to delete this special tax event?</IonText>
                </div>
                <div className="special-event-modal-delete-buttons">
                    <IonButton
                        className="special-event-modal-no-button"
                        onClick={() => setShowDeleteModal(false)}>No</IonButton>
                    <IonButton onClick={() => deleteTaxEvent()}>Yes</IonButton>
                </div>
            </IonModal>
            <IonModal
                isOpen={showDuplicateModal}
                onDidDismiss={() => setShowDuplicateModal(false)}
                className="delete-special-tax-event-modal">
                <div className="special-event-modal-delete-text">
                    <IonText>Are you sure you want to duplicate this special tax event?</IonText>
                </div>
                <div className="special-event-modal-delete-buttons">
                    <IonButton
                        className="special-event-modal-no-button"
                        onClick={() => setShowDuplicateModal(false)}>No</IonButton>
                    <IonButton onClick={() => duplicateTaxEvent()}>Yes</IonButton>
                </div>
            </IonModal>
        </div>
    );
};

export default SpecialTaxEventSummary;

import React from 'react';
import { IonModal } from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

import '../../theme/BrandingWorkflowCard.css';
import BrandingSettings from '../BrandingSettings';
import WorkflowCard from './WorkflowCard';
import { usePortal } from '../PortalProvider';

interface WorkflowCardProps {
    isComplete: boolean;
    setComplete: Function;
}

const BrandingWorkflowCard: React.FC<WorkflowCardProps> = (props) => {
    const portal = usePortal();
    portal!.State.brandingSettings = portal!.State.brandingSettings || {};
    const [showModal, setShowModal] = React.useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    return (
        <WorkflowCard
            title="Customize Receipt Branding"
            text="Upload your logo and let us know the fine print of your receipts."
            icon="assets/img/designer.svg"
            isComplete={props.isComplete}
            onButtonClick={openModal}>
            <IonModal onDidDismiss={() => setShowModal(false)} className="modal-card branding-card" isOpen={showModal}>
                <BrandingSettings settings={portal!.State.brandingSettings['Tenant']} setShowModal={setShowModal} setComplete={props.setComplete} onboardingWorkflow={true} />
            </IonModal>
        </WorkflowCard>
    );
};

export default BrandingWorkflowCard;

import { IonButton, IonContent, IonIcon, IonModal, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { roles } from "../../constants/Roles";
import { Identity } from "../../interfaces/User";
import AddExistingUsersRow from "./AddExistingUsersRow";

import { useFlags } from "launchdarkly-react-client-sdk";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import { ExistingUser } from "../../interfaces/ExistingUser";
import "../../theme/AddExistingUsers.css";
import { useDataService } from "../DataServiceProvider";
import { usePortal } from "../PortalProvider";

export interface AddExistingUsersProps {
  isOpen: boolean;
  closeModalWithoutSaving: Function;
  onImportSuccess: Function;
  setIsOpen: Function;
  locationId: string;
}

const AddExistingUsers: React.FC<AddExistingUsersProps> = (props) => {
  const dataService = useDataService();
  const portal = usePortal();
  const flags = useFlags();
  const [allUsersSelected, setAllUsersSelected] = React.useState(false);
  const [users, setUsers] = React.useState(Array<ExistingUser>());
  const [numberChecked, setNumberChecked] = React.useState(0);

  useEffect(() => {
    if (props.isOpen) {
      setNumberChecked(0);

      var dataSource = Array<ExistingUser>();
      if (portal!.State.tenantIdentities && portal!.State.tenantIdentities.length > 0) {
        for (var i = 0; i < portal!.State.tenantIdentities.length; i++) {
          var employee = portal!.State.tenantIdentities[i];

          if (!isUserAssociatedWithLocation(employee)) {
            dataSource.push(employeeObjectFromIdentity(employee));
          }
        }
        setUsers(dataSource);
      }
    }
  }, [props.isOpen]);

  const updateAllChecks = () => {
    var isChecked = !allUsersSelected;
    var numberSelected = 0;
    var updatedUsers = users.map((user) => {
      user.SelectedToImport = isChecked;
      numberSelected++;
      return user;
    });
    setUsers(updatedUsers);
    setAllUsersSelected(isChecked);

    if (isChecked) {
      setNumberChecked(numberSelected);
    } else {
      setNumberChecked(0);
    }
  };

  const updateSelected = (isSelected: boolean) => {
    if (isSelected) {
      setNumberChecked((current) => current + 1);
    } else {
      setNumberChecked((current) => current - 1);
    }
  };

  const importUsers = () => {
    portal!.navigation.isLoading(true);

    let selectedUsers = users.filter((x) => x.SelectedToImport);

    let count = -1;

    let onNext = () => {
      count++;
      if (count >= selectedUsers.length) {
        props.onImportSuccess();
        dataService!.enqueueLocationForUpdate(props.locationId);
        return;
      }

      let user = selectedUsers[count];
      const version = getVersion(flags);
      dataService.identities.addRoleToIdentity(
        user.UserId,
        portal!.State.tenantInfo.tenantId,
        props.locationId,
        user.Role.Name,
        version,
        onNext,
        onFailure
      );
    };

    let onFailure = () => {
      portal!.navigation.isLoading(false);

      alert("Something unexpected went wrong adding these users to this location. Please try again.");
    };

    onNext();
  };

  var employeeObjectFromIdentity = function (employee: Identity) {
    var newEmployee: ExistingUser = {
      UserId: employee.identityId,
      Email: employee.emailAddress,
      FirstName: employee.firstName,
      LastName: employee.lastName,
      Rics9UserId: employee.rics9UserId,
      RicsXIdentityId: employee.identityId,
      SelectedToImport: false,
      Role: getRoleFromIdentity(employee)
    };

    return newEmployee;
  };

  const getRoleFromIdentity = (user: Identity) => {
    let role = roles[0];

    if (user.roles && user.roles.length > 0) {
      let tenantRole = user.roles.find((x) => x.tenantId === portal!.State.tenantInfo.tenantId);

      if (tenantRole) {
        let mappedRole = roles.find((x) => x.Name === tenantRole?.identityRole && !x.Hidden);
        if (mappedRole) {
          role = mappedRole;
        }
      }
    }

    return role;
  };

  let isUserAssociatedWithLocation = (user: Identity) => {
    if (user.roles) {
      if (
        user.roles.findIndex(
          (x) => x.tenantId === portal!.State.tenantInfo.tenantId && (x.identityRole === "Owner" || x.locationId === props.locationId)
        ) > -1
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <IonModal isOpen={props.isOpen} onDidDismiss={() => props.setIsOpen(false)} className="modal-card add-existing-users-card">
        <div>
          <IonIcon
            className="ion-float-right user-close-button"
            src={closeOutline}
            onClick={() => props.closeModalWithoutSaving()}
            data-testid="existing-close-icon"
          />
          <div>
            <div className="ion-text-center import-user-heading">
              <h2>Add existing users to this location:</h2>
            </div>
          </div>
          <div className="user-grid-padding">
            <table className="add-existing-user-header-table">
              <tbody>
                <tr className="user-lineItem">
                  <th className="user-selected">
                    <div className="checkboxHolder">
                      <label className="checkbox checkmarkEnabled">
                        <input type="checkbox" checked={allUsersSelected} onChange={() => updateAllChecks()} data-testid="select-all-checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </th>
                  <th className="user-header existing-user-id">User ID</th>
                  <th className="user-header existing-user-first-name">First Name</th>
                  <th className="user-header existing-user-last-name">Last Name</th>
                  <th className="user-header existing-user-email">Email</th>
                </tr>
              </tbody>
            </table>
            <IonContent className="add-existing-users-grid">
              <table>
                <tbody>
                  {users.map((user, index) => {
                    return <AddExistingUsersRow key={index} user={user} index={index} onUpdateSelected={updateSelected} />;
                  })}
                </tbody>
              </table>
            </IonContent>
          </div>
          <IonButton onClick={() => props.setIsOpen(false)} className="ion-float-left import-users-button" data-testid="existing-cancel-button">
            <IonText>Cancel</IonText>
          </IonButton>
          <IonButton
            disabled={!(numberChecked > 0)}
            onClick={() => importUsers()}
            className="ion-float-right import-users-button"
            data-testid="existing-import-button">
            <IonText>Add {numberChecked} User(s)</IonText>
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default AddExistingUsers;

import { IonText, IonToggle } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import { ECommerceOrderSaleConfigurationConstants, ECommercePricingStrategyConstants } from "../../../constants/ECommerceConstants";
import {
  ECommerceAccountBase,
  ECommerceAccountStatus,
  ECommerceOrderSaleConfiguration,
  ECommercePricingStrategy
} from "../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import { ECommerceLocation } from "../../../interfaces/integrations/ecommerce/ECommerceLocation";
import "../../../theme/integrations/ecommerce/ECommerceAccountDetails.css";
import { usePortal } from "../../PortalProvider";
import ECommerceAccountLocationsModal from "./ECommerceAccountLocationsModal";

interface ECommerceAccountDetailsProps {
  account: ECommerceAccountBase;
  hideGeneralSection?: boolean;
  hideLocationsSection?: boolean;
  hideFulfillmentSection?: boolean;
  hidePricingSection?: boolean;
  hideCustomerSection?: boolean;
  getIntegrationSpecificGeneralDetails?: Function;
  getFBALocationDetails?: Function;
  onSave: Function;
  disableAccount: Function;
  enableAccount: Function;
}

const ECommerceAccountDetails: React.FC<ECommerceAccountDetailsProps> = (props) => {
  const history = useHistory();
  const portal = usePortal();
  let accountStatus = props.account.accountStatus;
  let modifiedDate = new Date(props.account.dateUpdated);
  let startDate = new Date(props.account.adapterStartDate);

  let eCommerceLocations =
    (portal?.State.locations &&
      portal.State.locations.map((l) => {
        let isInventoryLocation = props.account.ricsSourcingLocations?.find((r) => r === (l.originalLocationId || l.locationId)) != null;
        let isSaleLocation = props.account.saleLocations?.find((r) => r === (l.originalLocationId || l.locationId)) != null;

        let eCommerceLocation: ECommerceLocation = {
          id: l.originalLocationId || l.locationId,
          name: l.name,
          isInventoryLocation: isInventoryLocation,
          isSaleLocation: isSaleLocation
        };

        return eCommerceLocation;
      })) ||
    new Array<ECommerceLocation>();

  const [locations, setLocations] = useState(eCommerceLocations);
  const [contactEmail, setContactEmail] = useState(props.account.contactEmails.length > 0 ? props.account.contactEmails[0] : "");
  const [useFulfillmentStrategy, setUseFulfillmentStrategy] = useState(props.account.useFulfillmentStrategy);
  const [defaultInventoryThreshold, setDefaultInventoryThreshold] = useState<number | undefined>(props.account.inventoryThreshold);
  const [orderSaleConfig, setOrderSaleConfig] = useState(props.account.orderSaleConfiguration);

  const [syncPricing, setSyncPricing] = useState(props.account.syncPricing);
  const [flatPriceOffset, setFlatPriceOffset] = useState<number | undefined>(props.account.retailPriceOffset);
  const [pricingStrategy, setPricingStrategy] = useState<ECommercePricingStrategy | undefined>(props.account.pricingStrategy);

  const [saveCustomers, setSaveCustomers] = useState(props.account.saveCustomersOnSale);

  const [showLocationsModal, setShowLocationsModal] = useState(false);

  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setLocations(eCommerceLocations);
    setContactEmail(props.account.contactEmails.length > 0 ? props.account.contactEmails[0] : "");
    setUseFulfillmentStrategy(props.account.useFulfillmentStrategy);
    setDefaultInventoryThreshold(props.account.inventoryThreshold);
    setOrderSaleConfig(props.account.orderSaleConfiguration);
    setSyncPricing(props.account.syncPricing);
    setFlatPriceOffset(props.account.retailPriceOffset);
    setPricingStrategy(props.account.pricingStrategy);
    setSaveCustomers(props.account.saveCustomersOnSale);
  }, [props.account]);

  useEffect(() => {
    updateCanSave();
  }, [
    contactEmail,
    useFulfillmentStrategy,
    defaultInventoryThreshold,
    orderSaleConfig,
    syncPricing,
    flatPriceOffset,
    pricingStrategy,
    saveCustomers,
    locations
  ]);

  const toggleUseFulfillmentStrategy = () => {
    let newValue = !useFulfillmentStrategy;
    setUseFulfillmentStrategy(newValue);
    if (newValue) {
      setShowLocationsModal(true);
    }
  };

  const getOrderSaleConfigValue = () => {
    if (orderSaleConfig === ECommerceOrderSaleConfiguration.PaymentCaptured) {
      return ECommerceOrderSaleConfigurationConstants.PaymentCaptured;
    } else {
      return ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled;
    }
  };

  const setOrderSaleConfigValue = (value: string) => {
    if (value === ECommerceOrderSaleConfigurationConstants.PaymentCaptured) {
      setOrderSaleConfig(ECommerceOrderSaleConfiguration.PaymentCaptured);
    } else if (value === ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled) {
      setOrderSaleConfig(ECommerceOrderSaleConfiguration.PaymentCapturedAndFulfilled);
    }
  };

  const getPricingStrategyValue = () => {
    if (pricingStrategy === ECommercePricingStrategy.Retail) {
      return ECommercePricingStrategyConstants.Retail;
    } else if (pricingStrategy === ECommercePricingStrategy.Manual) {
      return ECommercePricingStrategyConstants.Manual;
    }

    return ECommercePricingStrategyConstants.None;
  };

  const setPricingStrategyValue = (value: string) => {
    if (value === ECommercePricingStrategyConstants.Retail) {
      setPricingStrategy(ECommercePricingStrategy.Retail);
    } else if (value === ECommercePricingStrategyConstants.Manual) {
      setPricingStrategy(ECommercePricingStrategy.Manual);
    } else if (value === ECommercePricingStrategyConstants.None) {
      setPricingStrategy(undefined);
    }
  };

  const updateCanSave = () => {
    let locationsChanged = locations !== eCommerceLocations;
    let contactEmailChanged = contactEmail !== (props.account.contactEmails.length > 0 ? props.account.contactEmails[0] : "");
    let useFulfillmentStrategyChanged = useFulfillmentStrategy !== props.account.useFulfillmentStrategy;
    let defaultInventoryThresholdChanged = defaultInventoryThreshold !== props.account.inventoryThreshold;
    let orderSaleConfigChanged = orderSaleConfig !== props.account.orderSaleConfiguration;
    let syncPricingChanged = syncPricing !== props.account.syncPricing;
    let flatPriceOffsetChanged = flatPriceOffset !== props.account.retailPriceOffset;
    let pricingStrategyChanged = pricingStrategy !== props.account.pricingStrategy;
    let saveCustomersChanged = saveCustomers !== props.account.saveCustomersOnSale;

    setCanSave(
      locationsChanged ||
        contactEmailChanged ||
        useFulfillmentStrategyChanged ||
        defaultInventoryThresholdChanged ||
        orderSaleConfigChanged ||
        syncPricingChanged ||
        flatPriceOffsetChanged ||
        pricingStrategyChanged ||
        saveCustomersChanged
    );
  };

  const onSave = () => {
    let inventoryLocations: string[] = [];
    let saleLocations: string[] = [];

    locations.map((l) => {
      if (l.isInventoryLocation) {
        inventoryLocations.push(l.id);
      }

      if (l.isSaleLocation) {
        saleLocations.push(l.id);
      }
    });

    if (useFulfillmentStrategy && saleLocations.length <= 0) {
      alert("You must select a Sale Location.");
      return;
    }

    let updatedAccount: ECommerceAccountBase = {
      ...props.account,
      contactEmails: [contactEmail],
      ricsSourcingLocations: inventoryLocations,
      saleLocations: saleLocations,
      useFulfillmentStrategy: useFulfillmentStrategy,
      inventoryThreshold: defaultInventoryThreshold,
      orderSaleConfiguration: orderSaleConfig,
      syncPricing: syncPricing,
      retailPriceOffset: flatPriceOffset,
      pricingStrategy: pricingStrategy,
      saveCustomersOnSale: saveCustomers
    };

    props.onSave(updatedAccount);
  };

  return (
    <>
      <div className="ecommerce-account-details">
        <div className="account-details-card-container">
          <div className="account-details-card">
            <div className="account-details-card-header">
              <div className="heading">Account Details</div>
              <Button
                hidden={accountStatus === ECommerceAccountStatus.Active}
                className="ecommerce-account-button enable-account-button"
                onClick={() => props.enableAccount()}>
                Enable
              </Button>
              <Button
                hidden={accountStatus === ECommerceAccountStatus.Inactive}
                className="ecommerce-account-button disable-account-button"
                onClick={() => props.disableAccount()}>
                Disable
              </Button>
            </div>
            <div className="account-details-card-body">
              <div className="row">
                <div className="category-header column w-25">General</div>
                <div className="category-body column w-75">
                  {props.getIntegrationSpecificGeneralDetails && props.getIntegrationSpecificGeneralDetails()}
                  <div className="row">
                    <div className="column w-50">
                      <div className="edit-field-holder">
                        <label className="heading">Label</label>
                        <div className="label">{props.account.label}</div>
                      </div>
                    </div>
                    <div className="column w-50">
                      <div className="edit-field-holder">
                        <div className="heading">Status</div>
                        <div className={"status-label" + (accountStatus === ECommerceAccountStatus.Active ? " active" : "")}>{accountStatus}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column w-50">
                      <div className="edit-field-holder">
                        <label className="heading">Scheduled Start Date</label>
                        <div className="label">{startDate.toDateString()}</div>
                      </div>
                    </div>
                    <div className="column w-50">
                      <div className="edit-field-holder">
                        <div className="heading">Modified Date</div>
                        <div className="label">{modifiedDate.toDateString()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="edit-field-holder w-50">
                      <div className="edit-field-holder">
                        <label className="heading">Contact Email</label>
                        <input className="input-box" value={contactEmail} type="email" onChange={(e) => setContactEmail(e.target.value)} required />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="category-header column w-25">Locations</div>
                <div className="category-body column w-75">
                  <div className="row">
                    <div className="edit-field-holder">
                      {props.getFBALocationDetails != undefined ? (
                        props.getFBALocationDetails()
                      ) : (
                        <>
                          <div className="heading">Default Location Configuration</div>
                          <Button className="image-button rics-button" onClick={() => setShowLocationsModal(true)}>
                            <Image className="image-button-img" src="img/edit.svg" />
                            <div className="image-button-label">Edit</div>
                          </Button>
                          <ECommerceAccountLocationsModal
                            useFulfillmentStrategy={useFulfillmentStrategy}
                            locations={locations}
                            show={showLocationsModal}
                            onHide={() => setShowLocationsModal(false)}
                            onChange={setLocations}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="category-header column w-25">Fulfillment</div>
                <div className="category-body column w-75">
                  <div className="row">
                    <div className="edit-field-holder">
                      <div className="toggle-switch">
                        <IonToggle className="toggle-switch-toggle" checked={useFulfillmentStrategy} onClick={() => toggleUseFulfillmentStrategy()} />
                        <IonText className="toggle-switch-label">Use Fulfillment Strategy</IonText>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column w-60">
                      <div className="edit-field-holder">
                        <div className="heading">Record Sale in RICS</div>
                        <select className="select" defaultValue={getOrderSaleConfigValue()} onChange={(e) => setOrderSaleConfigValue(e.target.value)}>
                          <option
                            id={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                            label={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                            value={ECommerceOrderSaleConfigurationConstants.PaymentCaptured}
                          />
                          <option
                            id={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                            label={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                            value={ECommerceOrderSaleConfigurationConstants.PaymentCapturedAndFulfilled}
                          />
                        </select>
                      </div>
                    </div>
                    <div className="column w-40">
                      <div className="edit-field-holder">
                        <label className="heading">Default Inventory Threshold</label>
                        <input
                          className="input-box"
                          defaultValue={defaultInventoryThreshold}
                          value={defaultInventoryThreshold}
                          type="number"
                          min={0}
                          pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                          onChange={(e) => setDefaultInventoryThreshold(Number(e.target.value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="category-header column w-25">Pricing</div>
                <div className="category-body column w-75">
                  <div className="row">
                    <div className="edit-field-holder">
                      <div className="toggle-switch">
                        <IonToggle className="toggle-switch-toggle" checked={syncPricing} onClick={() => setSyncPricing(!syncPricing)} />
                        <IonText className="toggle-switch-label">Sync Pricing</IonText>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column w-60">
                      <div className="edit-field-holder">
                        <div className="heading">Pricing Strategy</div>
                        <select className="select" defaultValue={getPricingStrategyValue()} onChange={(e) => setPricingStrategyValue(e.target.value)}>
                          <option
                            id={ECommercePricingStrategyConstants.None}
                            label={ECommercePricingStrategyConstants.None}
                            value={ECommercePricingStrategyConstants.None}
                          />
                          <option
                            id={ECommercePricingStrategyConstants.Retail}
                            label={ECommercePricingStrategyConstants.Retail}
                            value={ECommercePricingStrategyConstants.Retail}
                          />
                          <option
                            id={ECommercePricingStrategyConstants.Manual}
                            label={ECommercePricingStrategyConstants.Manual}
                            value={ECommercePricingStrategyConstants.Manual}
                          />
                        </select>
                      </div>
                    </div>
                    <div className="column w-40" hidden={pricingStrategy !== ECommercePricingStrategy.Retail}>
                      <div className="edit-field-holder">
                        <label className="heading">Default Flat Price Offset</label>
                        <input
                          className="input-box"
                          value={flatPriceOffset}
                          type="number"
                          onChange={(e) => setFlatPriceOffset(Number(e.target.value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" hidden={props.hideCustomerSection}>
                <div className="category-header column w-25">Customer</div>
                <div className="category-body column w-75">
                  <div className="row">
                    <div className="edit-field-holder">
                      <div className="toggle-switch">
                        <IonToggle className="toggle-switch-toggle" checked={saveCustomers} onClick={() => setSaveCustomers(!saveCustomers)} />
                        <IonText className="toggle-switch-label">Save Customers</IonText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="account-details-card-footer">
              <Button className="account-details-save-button" disabled={!canSave} onClick={onSave}>
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ECommerceAccountDetails;

import { IonHeader, IonToolbar, IonTitle, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";

const selectOptions = {
    cssClass: 'rics-menu-select',
};

interface LocationDetailsRicsSideMenuHeaderProps {
    menuCollapsed: boolean;
    location: any;
    locationSelectOptions: Array<any>;
};

const LocationDetailsRicsSideMenuHeader: React.FC<LocationDetailsRicsSideMenuHeaderProps> = (props) => {
    let history = useHistory();

    function navigateToLocation(locationId: string) {
        var locationRoute = '/locationdetails/' + encodeURIComponent(locationId);
        history.push(locationRoute);
        history.go(0);
    };

    const mappedSelectOptions = props.locationSelectOptions.map(l =>
        <IonSelectOption key={l.Name} value={l.LocationId}>{l.Name}</IonSelectOption>
    );

    return (
        <>
            <IonHeader>
                <IonToolbar class="rics-menu-title">
                    <IonTitle>
                        {/*
                            HACK: Not sure what the best approach is to "collapse" the select to just an icon.
                            Can't target shadow dom css from this file.
                            Using two selects with the same (pre-rendered) options at the moment.
                            Could maybe just use a button and popover with a list in it.
                        */}
                        <IonSelect
                            hidden={props.menuCollapsed}
                            selectedText={props.location.Name}
                            class="locationPicker"
                            id="locationPicker"
                            interface="popover"
                            interfaceOptions={selectOptions}
                            onIonChange={e => navigateToLocation(e.detail.value)}
                        >
                            {mappedSelectOptions}
                        </IonSelect>
                        <IonSelect
                            hidden={!props.menuCollapsed}
                            selectedText={props.location.Name}
                            id="collapsedLocationPicker"
                            interface="popover"
                            interfaceOptions={selectOptions}
                            onIonChange={e => navigateToLocation(e.detail.value)}
                        >
                            {mappedSelectOptions}
                        </IonSelect>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default LocationDetailsRicsSideMenuHeader;

/* Theme variables */
import '../theme/variables.css';
import '../theme/Settings.css';

import { IonInput, IonItem, IonLabel, IonText, IonToggle, IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import SettingsCard from './SettingsCard';
import LeftRightContent from './LeftRightContent';
import { SaleSetting } from '../interfaces/salesettings/SaleSettings';
import { usePortal } from './PortalProvider';
import { ClutchSettings } from '../interfaces/salesettings/ClutchSettings';
import { useDataService } from './DataServiceProvider';

export interface ClutchSettingsProps {
    locationSettings: SaleSetting;
    locationId: string;
};

const ClutchSettingsCard: React.FC<ClutchSettingsProps> = ({ locationSettings, locationId }) => {
    const portal = usePortal();
    const dataService = useDataService();
    const [canSave, setCanSave] = React.useState(false);
    const [apiKey, setApiKey] = React.useState(locationSettings.clutchSettings?.apiKey);
    const [apiSecret, setApiSecret] = React.useState(locationSettings.clutchSettings?.apiSecret);
    const [brandId, setBrandId] = React.useState(locationSettings.clutchSettings?.brandId);
    const [clutchLocationId, setClutchLocationId] = React.useState(locationSettings.clutchSettings?.locationId);
    const [terminalId, setTerminalId] = React.useState(locationSettings.clutchSettings?.terminalId);
    const [requirePIN, setRequirePin] = React.useState(locationSettings.clutchSettings?.giftCardRequirePIN ?? false);
    const [clutchSettings, setClutchSettings] = React.useState(locationSettings?.clutchSettings);

    useEffect(() => updateCanSave(), [apiKey, apiSecret, brandId, clutchLocationId, terminalId, requirePIN]);

    const onApiKeyChanged = (value: string | null | undefined) => {
        setApiKey(value == null ? undefined : value);
    };

    const onApiSecretChanged = (value: string | null | undefined) => {
        setApiSecret(value == null ? undefined : value);
    };

    const onBrandIdChanged = (value: string | null | undefined) => {
        setBrandId(value == null ? undefined : value);
    };
    
    const onLocationIdChanged = (value: string | null | undefined) => {
        setClutchLocationId(value == null ? undefined : value);
    };
    
    const onTerminalIdChanged = (value: string | null | undefined) => {
        setTerminalId(value == null ? undefined : value);
    };
    
    const onRequirePINChanged = (value: boolean) => {
        setRequirePin(value);
    };

    const isEmpty = (value: string | null | undefined): boolean => {
        return value == null || value === "";
    }

    const updateCanSave = () => {
        let allClutchSettingsSet: boolean = 
            !isEmpty(clutchSettings?.apiKey) &&
            !isEmpty(clutchSettings?.apiSecret) &&
            !isEmpty(clutchSettings?.brandId) &&
            !isEmpty(clutchSettings?.locationId) &&
            !isEmpty(clutchSettings?.terminalId);
        
        let allClutchSettingsNotSet: boolean = 
            isEmpty(clutchSettings?.apiKey) &&
            isEmpty(clutchSettings?.apiSecret) &&
            isEmpty(clutchSettings?.brandId) &&
            isEmpty(clutchSettings?.locationId) &&
            isEmpty(clutchSettings?.terminalId);

        let allRequiredFieldsSet: boolean = 
            !isEmpty(apiKey) &&
            !isEmpty(apiSecret) &&
            !isEmpty(brandId) &&
            !isEmpty(clutchLocationId) &&
            !isEmpty(terminalId);

        let allRequiredFieldsNotSet: boolean = 
            isEmpty(apiKey) &&
            isEmpty(apiSecret) &&
            isEmpty(brandId) &&
            isEmpty(clutchLocationId) &&
            isEmpty(terminalId);

        let anyChanged: boolean = 
            (!isEmpty(clutchSettings?.apiKey) && !isEmpty(apiKey) && clutchSettings?.apiKey !== apiKey) ||
            (!isEmpty(clutchSettings?.apiSecret) && !isEmpty(apiSecret) && clutchSettings?.apiSecret !== apiSecret) ||
            (!isEmpty(clutchSettings?.brandId) && !isEmpty(brandId) && clutchSettings?.brandId !== brandId) ||
            (!isEmpty(clutchSettings?.locationId) && !isEmpty(clutchLocationId) && clutchSettings?.locationId !== clutchLocationId) ||
            (!isEmpty(clutchSettings?.terminalId) && !isEmpty(terminalId) && clutchSettings?.terminalId !== terminalId) ||
            ((clutchSettings?.giftCardRequirePIN ?? false) !== requirePIN);
        
        var reset = allClutchSettingsSet && allRequiredFieldsNotSet;
        var create = allClutchSettingsNotSet && allRequiredFieldsSet;
        var update = allClutchSettingsSet && allRequiredFieldsSet && anyChanged;
        setCanSave(reset || create || update);
    }
    
    const save = () => {
        portal!.navigation.isLoading(true);

        let salesSettings = portal!.State.saleSettings[locationId] ? portal!.State.saleSettings[locationId] : portal!.State.saleSettings['Tenant'];

        let settingsToSave: ClutchSettings = {
            apiKey: apiKey,
            apiSecret: apiSecret,
            brandId: brandId,
            locationId: clutchLocationId,
            terminalId: terminalId,
            giftCardRequirePIN: requirePIN
        };

        let noRequiredFields: boolean = 
            isEmpty(settingsToSave.apiKey) &&
            isEmpty(settingsToSave.apiSecret) &&
            isEmpty(settingsToSave.brandId) &&
            isEmpty(settingsToSave.locationId) &&
            isEmpty(settingsToSave.terminalId);

        salesSettings.clutchSettings = salesSettings.clutchSettings || {};
        if (noRequiredFields)
        {
            salesSettings.clutchSettings = undefined;
            setRequirePin(false);
        }
        else
        {
            Object.assign(salesSettings.clutchSettings, settingsToSave);
        }

        let onFailure = function () {
            portal!.navigation.isLoading(false);
            alert('Something unexpected went wrong');
          };
      
        let onSuccess = function () {
            portal!.navigation.isLoading(true);
    
            let onSuccess2 = function () {
                portal!.navigation.isLoading(false);
                setCanSave(false);
                setClutchSettings(salesSettings.clutchSettings);
            };
      
            dataService!.getSaleSettings(onSuccess2, onFailure);
        }

        dataService!.saveSaleSettings(salesSettings, onSuccess, onFailure);
    };
    
    return(
        <>
            <SettingsCard
                header="Clutch Integration Settings"
                subHeading="Configure preferences and settings for Clutch."
                isDisabled={locationId !== undefined}>
                <LeftRightContent heading="Authentication" preamble="Authentication credentials provided by Clutch">
                    <div className="clutch-setting">
                        <IonItem lines="none">
                            <IonLabel>API Key</IonLabel>
                            <IonInput
                                value={apiKey}
                                onIonChange={e => onApiKeyChanged(e.detail.value)}
                                placeholder="API Key"
                                type="text" />
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel>API Secret</IonLabel>
                            <IonInput
                                value={apiSecret}
                                onIonChange={e => onApiSecretChanged(e.detail.value)}
                                placeholder="API Secret"
                                type="password" />
                        </IonItem>
                    </div>
                </LeftRightContent>
                <LeftRightContent heading="Location" preamble="Location credentials provided by Clutch">
                    <div className="clutch-setting">
                        <IonItem lines="none">
                            <IonLabel>Brand ID</IonLabel>
                            <IonInput
                                value={brandId}
                                onIonChange={e => onBrandIdChanged(e.detail.value)}
                                placeholder="Brand ID"
                                type="text" />
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel>Location ID</IonLabel>
                            <IonInput
                                value={clutchLocationId}
                                onIonChange={e => onLocationIdChanged(e.detail.value)}
                                placeholder="Location ID"
                                type="text" />
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel>Terminal ID</IonLabel>
                            <IonInput
                                value={terminalId}
                                onIonChange={e => onTerminalIdChanged(e.detail.value)}
                                placeholder="Terminal ID"
                                type="text" />
                        </IonItem>
                    </div>
                </LeftRightContent>
                <LeftRightContent heading="Gift Cards" preamble="Require a PIN when processing a Gift Card">
                    <IonItem lines="none" className="setting clutch-setting">
                        <IonToggle
                            checked={requirePIN}
                            onIonChange={e => onRequirePINChanged(e.detail.checked)}
                            className="ion-align-self-center" />
                        <IonText>Require PIN</IonText>
                    </IonItem>
                </LeftRightContent>
                <div className='buttonsHolder'>
                    <IonButton
                        onClick={() => save()}
                        disabled={!canSave}
                        data-testid="save-settings-page"
                        className="save-setting">
                        Save Changes
                    </IonButton>
                </div>
            </SettingsCard>
        </>
    );
};

export default ClutchSettingsCard;

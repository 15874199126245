import React, { useState } from "react";
import { useHistory } from "react-router";
import { Integration } from "../../interfaces/integrations/Integration";
import IntegrationLogo from "./IntegrationLogo";
import IntegrationIcon from "./IntegrationIcon";
import { useFlags } from "launchdarkly-react-client-sdk";
import IntegrationsNoAccessModal from "./IntegrationsNoAccessModal";

interface IntegrationCardProps {
    integration: Integration;
}

const IntegrationCard: React.FC<IntegrationCardProps> = (props) => {
    let history = useHistory();
    const flags = useFlags();

    const [showNoAccessModal, setShowNoAccessModal] = useState(false);

    const onClick = () => {
        if (props.integration.IsConfigured) {
            history.push(props.integration.Route);
            return;
        }

        if (!props.integration.IsConfigured && (!props.integration.IsPro || flags[props.integration.IsEnabledFF])) {
            history.push(props.integration.UnconfiguredRoute);
            return;
        }

        setShowNoAccessModal(true);
    }

    return (
        <>
            <div className="integration-card" onClick={onClick}>
                <div className="integration-card-content">
                    <div className="integration-card-image-row">
                        <IntegrationIcon isConfigured={props.integration.IsConfigured} isPro={props.integration.IsPro} />
                        <IntegrationLogo logoUrl={props.integration.LogoUrl} />
                    </div>
                    <div className="integration-card-details-row">
                        <div className="integration-card-name">{props.integration.Name}</div>
                        <div className="integration-card-description">{props.integration.Description}</div>
                    </div>
                    <div className="integration-card-configure-row">
                        <div className="integration-card-configure-holder" hidden={!props.integration.IsConfigured}>
                            <div className="integration-card-configure-text">Configure</div>
                        </div>
                        <div className="integration-card-add-holder" hidden={props.integration.IsConfigured}>
                            <div className="integration-card-add-text">+ Add</div>
                        </div>
                    </div>
                </div>
            </div>
            <IntegrationsNoAccessModal show={showNoAccessModal} onHide={() => setShowNoAccessModal(false)} />
        </>
    );
};

export default IntegrationCard;

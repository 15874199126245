import { IonContent, IonImg, IonInput, IonPage } from "@ionic/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDataService } from "../components/DataServiceProvider";
import { usePortal } from "../components/PortalProvider";
import { useXhrService } from "../components/XhrServiceProvider";
import { Paths } from "../constants/Paths";
import { Identity } from "../interfaces/User";

import { getVersion } from "../helpers/apiVersionsHelpers";
import "../theme/Login.css";

interface LoginProps {
  initialize: (onSuccess?: () => void, onFailure?: () => void) => void;
}

const Login: React.FC<LoginProps> = ({ initialize }) => {
  const portal = usePortal();
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const dataService = useDataService();
  const xhrService = useXhrService();
  const history = useHistory();
  const flags = useFlags();

  const [userNameInputValue, setUserNameInputValue] = React.useState("");
  const [passwordInputValue, setPasswordInputValuet] = React.useState("");

  const getAuthToken = function (emailAddress: string, password: string) {
    portal!.navigation.isLoading(true);

    let endpoint = "v1/Auth/CreateToken";
    let payload = {
      emailAddress: emailAddress,
      password: password
    };

    const onFailure = function () {
      alert("Incorrect username or password.");
      portal!.navigation.isLoading(false);
    };

    xhrService!.DoRicsApiXhr(endpoint, payload, parseAuthResponse, onFailure, "PUT");
  };

  const parseAuthResponse = function (response: any) {
    response = JSON.parse(response);
    if (!response.token) {
      alert("Invalid username/password combination. Please try again.");
      portal!.navigation.isLoading(false);
      return;
    }

    //success
    window.localStorage.setItem("Token", response.token);
    window.localStorage.setItem("RefreshToken", response.refreshToken);

    getIdentityAndNavigate();
  };

  const getIdentityAndNavigate = () => {
    portal!.navigation.isLoading(true);
    const version = getVersion(flags);

    const onSuccess = function (identity: Identity) {
      portal!.State.currentUser = identity;
      document.onkeydown = null;
      initialize();
    };

    const onFailure = function () {
      window.localStorage.clear();
      window.sessionStorage.clear();

      alert("Something went wrong logging you in. Please try again.");

      history.push(Paths.Login);
      portal!.navigation.isLoading(false);
    };

    dataService!.identities.getCurrentIdentity(version, onSuccess, onFailure);
  };

  const loginButtonClicked = () => {
    window.localStorage.setItem("LoginEmailAddress", userNameInputValue.trim());
    getAuthToken(userNameInputValue.trim(), passwordInputValue);
  };

  const beforeLoad = () => {
    if (dataService!.getToken()) {
      getIdentityAndNavigate();
      return;
    }

    var allRequiredImages = [
      "img/hexagon.svg",
      "img/tag.svg",
      "img/rics-logo.svg",
      "img/factory.svg",
      "img/box.svg",
      "img/shopping_cart.svg",
      "img/piechart.svg",
      "img/connect.svg",
      "img/note.svg",
      "img/checkmark.svg",
      "img/success.svg"
    ];
    var successReturns = [];
    var allCheckTimers: any[] = [];
    allRequiredImages.forEach(function (imageUrl) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", imageUrl);
      xhr.responseType = "document";
      xhr.onload = function (e) {
        let image = xhr.responseXML?.documentElement;
        portal!.svgcache.cache[imageUrl] = image;
        successReturns.push(true);
        var checkTimer = setInterval(function () {
          if (successReturns.length === allRequiredImages.length) {
            allCheckTimers.forEach(function (timer) {
              clearInterval(timer);
            });
          }
        }, 50);

        allCheckTimers.push(checkTimer);
      };

      xhr.send();
    });
  };

  const onInputChange = function (event: any) {
    loginButtonRef.current!.disabled = true;

    if (userNameInputValue && userNameInputValue.length > 0 && passwordInputValue && passwordInputValue.length > 0) {
      loginButtonRef.current!.removeAttribute("disabled");
    } else {
      return;
    }

    if (event.keyCode === 13) {
      loginButtonClicked();
    }
  };

  const setUsernameInputChange = (e: any) => {
    setUserNameInputValue(e.target.value);
    onInputChange(e);
  };
  const setPasswordInputChange = (e: any) => {
    setPasswordInputValuet(e.target.value);
    onInputChange(e);
  };

  useEffect(() => {
    beforeLoad();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="loginPage">
          <div className="section1">
            <div className="content out">
              <div className="loginForm in">
                <div className="row1">
                  <IonImg className="portalLogo" src="img/rics-logo.svg"></IonImg>
                </div>
                <div className="row2">
                  <IonInput
                    id="userNameInput"
                    placeholder="Email..."
                    className="userNameInput"
                    name="username"
                    value={userNameInputValue}
                    onIonChange={setUsernameInputChange}
                    onIonBlur={setUsernameInputChange}
                    onIonInput={setUsernameInputChange}
                  />
                  <IonInput
                    id="passwordInput"
                    placeholder="Password..."
                    className="passwordInput"
                    type="password"
                    name="password"
                    value={passwordInputValue}
                    onIonChange={setPasswordInputChange}
                    onIonBlur={setPasswordInputChange}
                    onIonInput={setPasswordInputChange}
                  />
                </div>
                <div className="row3">
                  <button ref={loginButtonRef} id="loginButton" className="buttonDefault loginButton" onClick={loginButtonClicked}>
                    Sign In
                  </button>
                  <div className="forgotPasswordLink">
                    <Link to={Paths.Password} id="forgotPasswordLink">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

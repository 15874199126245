import { IonContent, IonIcon } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { ProductTaxCondition } from "../../../../interfaces/Taxes";
import AdvancedTaxConfigurationRow from "./AdvancedTaxConfigurationRow";

interface AdvancedTaxConfigurationGridProps {
  availableProductTypes: string[];
  setAvailableProductTypes: Dispatch<SetStateAction<string[]>>;
  taxId: string;
  productTaxes: ProductTaxCondition[];
}

const AdvancedTaxConfigurationGrid: React.FC<
  AdvancedTaxConfigurationGridProps
> = (props) => {
  return (
    <div className="advanced-taxes-content">
      <table className="advanced-taxes-content-grid">
        <tbody>
          <tr>
            <th className="advanced-product-type-column advanced-tax-header-row">
              Product Type
            </th>
            <th className="advanced-tax-rate-column advanced-tax-header-row">
              Tax Rate
            </th>
            <th className="advanced-threshold-column advanced-tax-header-row">
              Threshold
              <div className="advanced-tax-tooltip">
                <IonIcon
                  src="assets/img/info.svg"
                  className="advanced-tax-info"
                ></IonIcon>
                <span className="advanced-tax-tooltiptext">
                  The dollar amount over which you begin collecting sales tax.
                </span>
              </div>
            </th>
            <th className="advanced-tax-above-column advanced-tax-header-row">
              Only Tax Above Threshold
              <div className="advanced-tax-tooltip">
                <IonIcon
                  src="assets/img/info.svg"
                  className="advanced-tax-info"
                ></IonIcon>
                <span className="advanced-tax-tooltiptext">
                  The tax will only be applied to the amount over the tax
                  threshold.
                </span>
              </div>
            </th>
            <th className="advanced-delete-column advanced-tax-header-row"></th>
          </tr>
        </tbody>
      </table>
      <IonContent className="advanced-taxes-content-rows-container">
        <table>
          <tbody>
            {props.productTaxes.map((productTax, index) => {
              return (
                <AdvancedTaxConfigurationRow
                  availableProductTypes={props.availableProductTypes}
                  setAvailableProductTypes={props.setAvailableProductTypes}
                  key={props.taxId + index}
                  productTaxCondition={productTax}
                />
              );
            })}
          </tbody>
        </table>
      </IonContent>
    </div>
  );
};

export default AdvancedTaxConfigurationGrid;

import { IonToolbar, IonHeader, IonIcon, IonTitle, IonText } from '@ionic/react';
import React from 'react';

import { RicsSideMenuTab } from '../interfaces/RicsSideMenuTab';

import '../theme/RicsSideMenuTabHeader.css';

interface RicsSideMenuTabHeaderProps {
  selectedTab: RicsSideMenuTab;
  children?: React.ReactNode;
}

const RicsSideMenuTabHeader: React.FC<RicsSideMenuTabHeaderProps> = (props) => {
  return (
    <IonHeader className="rics-side-menu-tab-header">
      <IonToolbar className="header-toolbar">
        <div className="header-circle" slot="start">
          <IonIcon size="large" src={props.selectedTab.tabIconFileUri} className="header-icon"></IonIcon>
        </div>
        <IonTitle className="header-title" slot="start">
          <IonText>{props.selectedTab.tabHeaderText}</IonText>
        </IonTitle>
        {props.children}
      </IonToolbar>
    </IonHeader>
  );
};

export default RicsSideMenuTabHeader;

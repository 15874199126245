import { history } from "../components/ExtendedRouter";
import { Paths } from "../constants/Paths";
import { isEmpty } from "../helpers/stringHelpers";
import { ApplicationPortal, UserPermissions } from "../interfaces/ApplicationPortal";
import { IDataService } from "../interfaces/IDataService";
import { Identity } from "../interfaces/User";
import { AuthService } from "./AuthService";
import { IdentityService } from "./IdentityService";
import { TenantService } from "./TenantService";
import { DoRicsApiXhr, FileUploadXhr } from "./XhrService";

const portal: ApplicationPortal = window.portal || {};
portal.features = portal.features || {};
portal.configurations = portal.configurations || {};
portal.constants = portal.constants || {};

portal!.dataService = portal!.dataService || {};

portal!.dataService.messageStatuses = portal!.dataService.messageStatuses || [];
portal!.dataService.totalMessageStatuses = portal!.dataService.totalMessageStatuses || 0;
portal!.dataService.signalSuccess = portal!.dataService.signalSuccess || {};
portal!.dataService.signalFailure = portal!.dataService.signalFailure || {};

//TODO: Clean this up
portal!.configurations.SetupWizardComplete = "POSPortalSetupWizardCompleted";
portal!.configurations.CustomerSignatureRequired = "POSCustomerSignatureRequired";
portal!.configurations.ReceiptIntroText = "POSIntroText";
portal!.configurations.ReceiptFooterText = "POSFooterText";
portal!.configurations.SetupPaymentWizardComplete = "POSPortalPaymentWizardCompleted";
portal!.configurations.SetupUserWizardComplete = "POSPortalUserImportWizardCompleted";
portal!.configurations.SetupSettingsWizardComplete = "POSPortalSettingsWizardCompleted";
portal!.configurations.SetupBrandingWizardComplete = "POSPortalBrandingWizardCompleted";

// Main entry point to load everything needed for initial load
export async function getIdentity(identitiesVersion: string, successCallback: Function, failCallback: Function) {
  portal!.dataService.totalMessageStatuses = 0;

  portal!.dataService.signalSuccess = successCallback;
  portal!.dataService.signalFailure = failCallback;

  let initialPostbackNumber = portal!.dataService.totalMessageStatuses;
  const onSuccess = function (response: Identity) {
    portal!.State.currentUser = response;

    let userPermissions = new Array<UserPermissions>();
    response.roles.map((role) => {
      if (role.tenantId && role.locationId == undefined) {
        let existing = userPermissions.filter((u) => u.tenantId == role.tenantId && u.locationId == undefined)[0];
        if (existing) {
          let claims = existing.claims.concat(role.identityScopes);
          existing.claims = claims.filter((v, i, s) => {
            return s.indexOf(v) === i;
          });
          return;
        }

        let newPermissions: UserPermissions = {
          tenantId: role.tenantId,
          claims: role.identityScopes
        };

        userPermissions.push(newPermissions);
        return;
      }

      if (role.tenantId && role.locationId) {
        let existing = userPermissions.filter((u) => u.tenantId == role.tenantId && u.locationId == role.locationId)[0];
        if (existing) {
          let claims = existing.claims.concat(role.identityScopes);
          existing.claims = claims.filter((v, i, s) => {
            return s.indexOf(v) === i;
          });
          return;
        }

        let newPermissions: UserPermissions = {
          tenantId: role.tenantId,
          locationId: role.locationId,
          claims: role.identityScopes
        };

        userPermissions.push(newPermissions);
      }
    });

    portal!.State.userPermissions = userPermissions;

    postbackMessageStatus(initialPostbackNumber, true);
  };

  const onFailure = function () {
    postbackMessageStatus(initialPostbackNumber, false);
  };

  const genericOnSuccess = function (successCallbackNumber: number) {
    postbackMessageStatus(successCallbackNumber, true);
  };

  const genericOnFailure = function (successCallbackNumber: number) {
    postbackMessageStatus(successCallbackNumber, false);
  };

  portal.features.refreshTokenEnabled = await getRefreshTokenFeatureEnablementStatus();
  await getTenantInfo(++portal!.dataService.totalMessageStatuses, genericOnSuccess, genericOnFailure);

  await getTaxes(++portal!.dataService.totalMessageStatuses, genericOnSuccess, genericOnFailure);
  await loadConstants(++portal!.dataService.totalMessageStatuses);
  await loadConfigurations(++portal!.dataService.totalMessageStatuses, genericOnSuccess, genericOnFailure);
  await getLocations(++portal!.dataService.totalMessageStatuses, genericOnSuccess, genericOnFailure);
  await getTenantIdentities(identitiesVersion, ++portal!.dataService.totalMessageStatuses, genericOnSuccess, genericOnFailure);

  IdentityService.getCurrentIdentity(identitiesVersion, onSuccess, onFailure);
}

function getRefreshTokenFeatureEnablementStatus(): Promise<boolean> {
  return new Promise((resolve) => {
    let endpoint = "v1/Features/PortalRefreshToken";
    var onSuccess = function (response: string) {
      var feature = JSON.parse(response);
      resolve(feature.isEnabled);
    };
    var onFailure = function () {
      resolve(false);
    };
    DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
}

function loadConstants(successCallbackNumber: any) {
  return new Promise((resolve, reject) => {
    var onSuccess = function (response: any) {
      var responseObj = JSON.parse(response);
      portal.constants.states = responseObj.stateCodes;
      portal.constants.countries = responseObj.countryCodes;
      portal.constants.gender = responseObj.genders;
      portal.constants.productTypes = responseObj.productTypeData;
      portal.constants.seasons = responseObj.seasons;
      portal.constants.sports = responseObj.sports;
      portal.constants.years = responseObj.years;
      portal.constants.weightUnits = responseObj.weightUnits;
      portal.constants.lengthUnits = responseObj.lengthUnits;
      portal.constants.sizes = responseObj.sizes;
      portal.constants.colors = responseObj.colors;
      portal.dataService.postbackMessageStatus(successCallbackNumber, true);
      resolve(successCallbackNumber);
    };

    var onFailure = function () {
      portal.dataService.postbackMessageStatus(successCallbackNumber, false);
      reject();
    };

    var endpoint = "v1.1/TenantProducts/ValidationConstants";

    var payload = [
      "CountryCodes",
      "StateCodes",
      "Genders",
      "ProductTypeData",
      "Seasons",
      "Sports",
      "Years",
      "WeightUnits",
      "LengthUnits",
      "Sizes",
      "Colors"
    ];

    DoRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
  });
}

export function loadConfigurations(successCallbackNumber: any, successCallback: any, failureCallback: any) {
  return new Promise((resolve, reject) => {
    var onSuccess = function (response: any) {
      var responseObj = JSON.parse(response);
      portal.configurations.configurations = {};

      if (!responseObj || responseObj.length < 1) {
        successCallback(successCallbackNumber);
        resolve(successCallbackNumber);
        return;
      }

      var mappedResults = responseObj.map((item: any) => {
        for (var key in item) {
          var upper = key.charAt(0).toUpperCase() + key.slice(1);
          // check if it already wasn't uppercase
          if (upper !== key) {
            item[upper] = item[key];
            delete item[key];
          }
        }
        return item;
      });

      for (var i = 0; i < mappedResults.length; i++) {
        var currentConfiguration = mappedResults[i];

        portal.configurations.configurations[currentConfiguration.ConfigurationName] =
          portal.configurations.configurations[currentConfiguration.ConfigurationName] || [];
        portal.configurations.configurations[currentConfiguration.ConfigurationName].push(currentConfiguration);
      }

      successCallback(successCallbackNumber);
      resolve(successCallbackNumber);
    };

    var onFailure = function (response: any) {
      failureCallback(successCallbackNumber);
      reject();
    };

    var endpoint = "v1/Configurations?";

    var names = [
      portal.configurations.SetupWizardComplete,
      portal.configurations.CustomerSignatureRequired,
      portal.configurations.ReceiptIntroText,
      portal.configurations.ReceiptFooterText,
      portal.configurations.SetupPaymentWizardComplete,
      portal.configurations.SetupUserWizardComplete,
      portal.configurations.SetupSettingsWizardComplete,
      portal.configurations.SetupBrandingWizardComplete
    ];

    names.forEach((name) => {
      endpoint = endpoint + "configurationNames=" + name + "&";
    });

    endpoint = endpoint.slice(0, -1);

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
}

export function setConfiguration(configurationName: any, configurationValue: any, locationId: any, successCallback: any, failureCallback: any) {
  var setConfiguration = {
    ConfigurationName: configurationName,
    ConfigurationValue: configurationValue,
    LocationId: locationId
  };

  var endpoint = "v1/Configurations";

  var onSuccess = function () {
    loadConfigurations(null, successCallback, failureCallback);
  };

  DoRicsApiXhr(endpoint, setConfiguration, onSuccess, failureCallback, "POST");
}

function postbackMessageStatus(index: number, status: boolean) {
  portal!.dataService.messageStatuses[index] = status;
  if (status === false) {
    portal!.dataService.signalFailure();
    portal!.dataService.messageStatuses = [];
    return;
  }

  for (var i = 0; i < portal!.dataService.totalMessageStatuses; i++) {
    if (!portal!.dataService.messageStatuses[i]) {
      return;
    }
  }

  setupLocations();
  portal!.dataService.messageStatuses = [];
  portal!.dataService.signalSuccess();
}

const setupLocations = function () {
  const setupLocation = function (location: any) {
    location.usersAtLocation = [];
    if (portal!.State.tenantIdentities && portal!.State.tenantIdentities.length > 0) {
      for (let i = 0; i < portal!.State.tenantIdentities.length; i++) {
        var currentIdentity = portal!.State.tenantIdentities[i];
        var sanitizedLocationId = location.locationId.toLowerCase();

        if (
          currentIdentity.roles &&
          currentIdentity.roles.length > 0 &&
          currentIdentity.roles.findIndex(
            (x) => x.tenantId === portal!.State.tenantInfo.tenantId && (x.identityRole === "Owner" || x.locationId === sanitizedLocationId)
          ) > -1
        ) {
          location.usersAtLocation.push(currentIdentity);
        }
      }
    }
  };

  if (!portal!.State.locations) {
    return;
  }

  for (var i = 0; i < portal!.State.locations.length; i++) {
    var currentLocation = portal!.State.locations[i];
    setupLocation(currentLocation);
  }
};

const getTenantInfo = function (successCallbackNumber: number, successCallback: Function, failureCallback: Function) {
  return new Promise((resolve, reject) => {
    const endpoint = "v1/Tenants/Info";

    const onSuccess = function (response: any) {
      let result = JSON.parse(response);
      portal!.State.tenantInfo = result.currentTenant;
      portal!.State.allTenantInfo = result.allTenants;

      successCallback(successCallbackNumber);
      resolve(successCallbackNumber);
    };

    const onFailure = function () {
      failureCallback(successCallbackNumber);
      reject();
    };

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

const getTaxes = function (successCallbackNumber: number | null, successCallback: Function, failureCallback: Function) {
  return new Promise((resolve, reject) => {
    const endpoint = "v1/Taxes";

    const onSuccess = function (response: any) {
      let result = JSON.parse(response);

      if (result && result.length > 0) {
        portal!.State.taxes = result;
      }

      if (portal!.State.taxes) {
        portal!.State.taxDecimals = 4;
        portal!.State.thresholdDecimals = 2;

        for (var i = 0; i < portal!.State.taxes.length; i++) {
          var currentTax = portal!.State.taxes[i];
          if (currentTax.taxRate && currentTax.taxRate <= 1 && currentTax.taxRate >= -1) {
            currentTax.taxRate = currentTax.taxRate * 100.0;
            currentTax.taxRate = Number(currentTax.taxRate.toFixed(portal!.State.taxDecimals));
          } else {
            currentTax.taxRate = 0;
          }

          if (currentTax.productTaxes && currentTax.productTaxes.length > 0) {
            let productTaxes = currentTax.productTaxes;

            for (var j = 0; j < productTaxes.length; j++) {
              var productTax = productTaxes[j];
              if (productTax.taxRate && productTax.taxRate <= 1 && productTax.taxRate >= -1) {
                productTax.taxRate = productTax.taxRate * 100.0;
                productTax.taxRate = Number(productTax.taxRate.toFixed(portal!.State.taxDecimals));
              } else {
                productTax.taxRate = 0;
              }

              if (productTax.threshold && productTax.threshold > 0) {
                productTax.threshold = Number(productTax.threshold.toFixed(portal!.State.thresholdDecimals));
              }
            }
          }
        }
      }

      successCallback(successCallbackNumber);
      resolve(successCallbackNumber);
    };

    const onFailure = function () {
      failureCallback(successCallbackNumber);
      reject();
    };

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

const getLocations = function (successCallbackNumber: number, successCallback: Function, failureCallback: Function) {
  return new Promise((resolve, reject) => {
    const endpoint = "v1/Locations";
    const onSuccess = function (response: any) {
      let result = JSON.parse(response);

      portal!.State.locations = result.filter((l: any) => l.disabledOn == undefined);
      portal!.State.shouldUpdateAllLocations = false;
      portal!.State.locationIdsToUpdate = [];
      if (!portal!.State.locations || portal!.State.locations.length < 1) {
        onFailure();
        return;
      }

      var onSuccess2 = function () {
        var onSuccess3 = function () {
          successCallback(successCallbackNumber);
        };

        var onFailure3 = function () {
          failureCallback(successCallbackNumber);
        };

        getBrandingSettings(onSuccess3, onFailure3);
      };

      var failure2 = function () {
        failureCallback(successCallbackNumber);
      };

      getSaleSettings(onSuccess2, failure2);

      setupLocations();
      resolve(successCallbackNumber);
    };

    var onFailure = function () {
      failureCallback(successCallbackNumber);
      reject();
    };

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

export const getLocation = function (locationId: string, successCallback: Function, failureCallback: Function) {
  return new Promise((resolve, reject) => {
    const endpoint = "v1/Locations/" + locationId;
    const onSuccess = function (response: any) {
      let location = JSON.parse(response);

      let cachedLocation = portal!.State.locations.filter((l) => l.locationId == location.locationId)[0];
      let cachedLocationIndex = portal!.State.locations.indexOf(cachedLocation);
      portal!.State.locations[cachedLocationIndex] = location;

      successCallback();
      resolve(location);
    };

    var onFailure = function () {
      failureCallback();
      reject();
    };

    DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

export const getLocationBrandingSettings = function (locationId: string, successCallback: Function, failureCallback: Function) {
  var endpoint = "v1/Branding/Settings/Locations/" + locationId;
  var onSuccess = function (response: any) {
    var settings = JSON.parse(response);
    if (!settings) {
      onFailure();
      return;
    }

    portal.State.brandingSettings[locationId] = settings;
    successCallback();
  };

  var onFailure = function () {
    failureCallback();
  };

  DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
};

export const getLocationSaleSettings = function (locationId: string, successCallback: Function, failureCallback: Function) {
  let endpoint = "v1.2/SaleSettings/" + locationId;

  let onSuccess = function (response: any) {
    let settings = JSON.parse(response);
    if (!settings || settings.length === 0) {
      onFailure();
      return;
    }

    portal.State.saleSettings[locationId] = settings;
    successCallback();
  };

  let onFailure = function () {
    failureCallback();
  };

  DoRicsApiXhr(endpoint, undefined, onSuccess, onFailure, "GET");
};

export const enqueueLocationForUpdate = (locationId: string | null | undefined) => {
  if (!isEmpty(locationId) && portal!.State.locationIdsToUpdate.indexOf(locationId!) === -1) portal!.State.locationIdsToUpdate.push(locationId!);
};

const getTenantIdentities = function (
  identitiesVersion: string,
  successCallbackNumber?: number | null,
  successCallback?: Function,
  failureCallback?: Function
): Promise<void> {
  return new Promise((resolve, reject) => {
    var onSuccess = function (identities: Array<Identity>) {
      if (!identities || identities.length === 0) {
        if (successCallback) {
          successCallback(successCallbackNumber);
        }
        return;
      }

      portal!.State.tenantIdentities = identities;
      if (successCallback) {
        successCallback(successCallbackNumber);
      }
      resolve();
    };

    var onFailure = function () {
      if (failureCallback) {
        failureCallback(successCallbackNumber);
      }
      reject();
    };

    IdentityService.getIdentities(identitiesVersion, onSuccess, onFailure);
  });
};

export function getToken() {
  return window.localStorage.getItem("Token");
}

export function uploadFile(endpoint: string, file: any, successCallback: Function) {
  try {
    var loFormData = new FormData();
    loFormData.append("file", file);

    var onSuccess = function (result: any) {
      successCallback(result);
      portal.navigation.isLoading(false);
    };

    var onFailure = function () {
      alert("File upload has failed."); // TODO: NK: better failure handling
      portal.navigation.isLoading(false);
    };

    FileUploadXhr(endpoint, loFormData, "POST", onSuccess, onFailure);
  } catch (ex) {
    alert(ex);
  }
}

export function logout() {
  portal!.activitylog.stopPullingActivityLog();
  window.localStorage.clear();
  window.sessionStorage.clear();

  //only redirect to login if user hasn't already been redirected to account setup complete page (due to lacking login permissions)
  var currentRoute = window.location.pathname || Paths.Default;
  if (currentRoute !== Paths.AccountSetupComplete) {
    history.push(Paths.Login);
    history.go(0); //without this, occasionally whitescreens
  }
  portal!.common.logger?.setup({
    IdentityId: null,
    TenantId: null,
    TenantName: null,
    EmailAddress: null
  });

  portal.navigation.isLoading(false);
}

export const saveSaleSettings = function (saleSettings: any, onSuccess: Function, onFailure: Function) {
  portal.navigation.isLoading(true);
  var endpoint = "v1.2/SaleSettings";

  var success = function () {
    portal.navigation.isLoading(false);
    onSuccess();
  };

  var fail = function () {
    alert("Something went wrong");
    portal.navigation.isLoading(false);
    onFailure();
  };

  DoRicsApiXhr(endpoint, saleSettings, success, fail, "PUT");
};

export const getSaleSettings = function (successCallback: Function, failureCallback: Function) {
  let endpoint = "v1.2/SaleSettings";

  let onSuccess = function (response: any) {
    let result = JSON.parse(response);
    if (!result || result.length === 0) {
      onFailure();
      return;
    }

    portal.State.saleSettings = {};
    for (let x = 0; x < result.length; x++) {
      let setting = result[x];
      let location = setting.locationId ? setting.locationId : "Tenant";
      portal.State.saleSettings[location] = setting;
    }

    successCallback();
  };

  let onFailure = function () {
    failureCallback();
  };

  DoRicsApiXhr(endpoint, undefined, onSuccess, onFailure, "GET");
};

export const saveBrandingSettings = function (brandingSettings: any, successCallback: Function, failureCallback: Function) {
  portal.navigation.isLoading(true);
  var endpoint = "v1/Branding/Settings";

  DoRicsApiXhr(endpoint, brandingSettings, successCallback, failureCallback, "POST");
};

export const getBrandingSettings = function (successCallback: Function, failureCallback: Function) {
  var endpoint = "v1/Branding/Settings";
  var onSuccess = function (response: any) {
    var result = JSON.parse(response);
    if (!result) {
      onFailure();
      return;
    }

    portal.State.brandingSettings = {};
    for (var x = 0; x < result.length; x++) {
      var setting = result[x];
      var location = setting.locationId ? setting.locationId : "Tenant";
      portal.State.brandingSettings[location] = setting;
    }

    successCallback();
  };

  var onFailure = function () {
    failureCallback();
  };

  var locationList = [];
  for (var x = 0; x < portal.State.locations.length; x++) {
    locationList.push(portal.State.locations[x].locationId);
  }

  var parameters = {
    LocationIds: locationList
  };

  DoRicsApiXhr(endpoint, parameters, onSuccess, onFailure);
};

export const getQueryParam = function (name: string) {
  let regexString = name + "=([^&]*)";
  let regex = new RegExp(regexString);
  let match = window.location.search.match(regex);

  return match && match.length > 1 ? match[1] : null;
};

//for old code to be able to conditionally use this service
portal!.dataService.refreshTenantInfo = getTenantInfo;
portal!.dataService.refreshTaxes = getTaxes;
portal!.dataService.refreshLocations = getLocations;
portal!.dataService.refreshTenantIdentities = getTenantIdentities;

portal!.dataService.postbackMessageStatus = postbackMessageStatus;
portal!.dataService.getIdentity = getIdentity;

export const DataService: IDataService = {
  getIdentity: getIdentity,
  loadConfigurations: loadConfigurations,
  setConfiguration: setConfiguration,
  logout: logout,
  saveSaleSettings: saveSaleSettings,
  getSaleSettings: getSaleSettings,
  saveBrandingSettings: saveBrandingSettings,
  getBrandingSettings: getBrandingSettings,
  refreshTenantInfo: getTenantInfo,
  refreshTaxes: getTaxes,
  refreshLocations: getLocations,
  refreshTenantIdentities: getTenantIdentities,
  getToken: getToken,
  uploadFile: uploadFile,
  enqueueLocationForUpdate: enqueueLocationForUpdate,
  getLocation: getLocation,
  identities: IdentityService,
  tenants: TenantService,
  auth: AuthService
};

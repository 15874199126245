import { IonButton, IonContent, IonIcon, IonPage, IonPopover } from "@ionic/react";

import React, { useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useDataService } from "../components/DataServiceProvider";
import { usePortal } from "../components/PortalProvider";
import TenantLevelPageHeader from "../components/TenantLevelPageHeader";
import ListAndCardsToggle from "../components/users/ListAndCardsToggle";
import UserImport from "../components/users/UserImport";
import UsersGrid from "../components/users/UsersGrid";
import { FeatureFlags } from "../constants/FeatureFlags";
import { roles } from "../constants/Roles";
import { getVersion } from "../helpers/apiVersionsHelpers";
import { Location } from "../interfaces/Location";
import { Identity, ScopedRole, User } from "../interfaces/User";
import "../theme/AllUsers.css";
import "../theme/LocationDetailsHeader.css";

const AllUsers: React.FC = () => {
  const dataService = useDataService();
  const portal = usePortal();
  portal!.features = portal!.features || {};
  const [users, setUsers] = useState<Array<User>>([]);
  const [checkedUsers, setCheckedUsers] = useState<Map<string, boolean>>(new Map());
  const [showDisabledUsers, setShowDisabledUsers] = React.useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined
  });
  const [isCardMode, setIsCardMode] = useState(false);
  const [deleteUserEnabled, setDeleteUserEnabled] = React.useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [numberSelected, setNumberSelected] = useState<number>(0);
  const flags = useFlags();

  useEffect(() => {
    getUsers();
  }, [flags[FeatureFlags.LabelsPage], flags[FeatureFlags.GlobalProductCatalogPage]]);

  useEffect(() => {
    let checked = Array.from(checkedUsers.values()).filter((x) => x === true);

    setNumberSelected(checked.length);
  }, [checkedUsers]);

  useEffect(() => {
    getDeleteUserFeatureEnabledStatus();
  }, [flags[FeatureFlags.DeleteUser]]);

  useEffect(() => {
    let checkedUsersMap = new Map(users.map((d: User) => [d.UserId, d.IsSelected]));
    setCheckedUsers(checkedUsersMap);
  }, [users]);

  const getDeleteUserFeatureEnabledStatus = () => {
    let featureFlag = flags[FeatureFlags.DeleteUser];
    setDeleteUserEnabled(featureFlag);
  };

  const getUsers = () => {
    portal!.navigation.isLoading(true);
    const version = getVersion(flags);

    const onSuccess = (identities: Array<Identity>) => {
      const refreshCurrentIdentity = () => {
        let currentIdentity = portal!.State.tenantIdentities.find((x) => x.identityId === portal!.State.currentUser.identityId);

        if (!!currentIdentity) {
          portal!.State.currentUser = currentIdentity;
        }

        portal!.navigation.isLoading(false);
      };

      let mappedUsers = [];
      if (!identities) {
        onFailure();
        return;
      }

      for (let i = 0; i < identities.length; i++) {
        let identity = identities[i];
        mappedUsers.push(getUserFromIdentity(identity));
        continue;
      }

      setUsers(mappedUsers);
      setUsersCount(mappedUsers.length);
      filterListByDisabledStatus(showDisabledUsers);
      setCheckedUsers(new Map(mappedUsers.map((d: User) => [d.UserId, false])));
      setSearchText("");
      setShowSearchInput(false);
      //TODO: Remove once Portal is no longer using RicsX for Identities
      dataService!.refreshTenantIdentities(version, null, refreshCurrentIdentity, onFailure);
    };

    const onFailure = function () {
      portal!.navigation.isLoading(false);
      alert("Something went wrong while trying to retrieve users.");
      return;
    };

    dataService.identities.getIdentities(version, onSuccess, onFailure);
  };

  const checkAllUsers = (checked: boolean) => {
    let updatedCheckedUsers = new Map(checkedUsers);
    Array.from(updatedCheckedUsers.keys()).forEach((key) => {
      updatedCheckedUsers.set(key, checked);
    });
    setCheckedUsers(updatedCheckedUsers);
  };

  const getUserFromIdentity = (identity: Identity) => {
    let locationIds: Array<string> = [];
    identity.roles
      .filter((x) => x.locationId != undefined)
      .forEach((x) => {
        if (locationIds.some((l) => l === x.locationId)) {
          return;
        }

        locationIds.push(x.locationId!);
      });
    let userRoles = roles.filter((r) => identity.roles.some((ir) => ir.identityRole === r.Name));

    let mappedUser: User = {
      UserId: identity.identityId,
      Email: identity.emailAddress,
      FirstName: identity.firstName,
      LastName: identity.lastName,
      DeletedOn: identity.deletedOn,
      Login: "",
      PhoneNumber: identity.phoneNumber,
      RicsUserId: identity.rics9UserId,
      RicsXIdentityId: identity.identityId,
      LocationLabel: formatLocationsText(locationIds),
      Locations: locationIds,
      Roles: userRoles,
      SelectedToImport: false,
      SelectedToEdit: false,
      ErrorString: "",
      Valid: emailIsValid(identity.emailAddress),
      IsActivated: identity.isActivated,
      IsVerified: identity.isVerified,
      IsSelected: false
    };

    return mappedUser;
  };

  const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const formatLocationsText = (locations: Array<string | undefined>) => {
    if (locations.length === 0) {
      return "No Locations";
    } else if (locations.length === 1) {
      return portal!.State.locations.find((x: Location) => x.locationId === locations[0])?.name ?? "No Locations";
    } else if (locations.length > 1) {
      return "Multiple";
    }

    return "No Locations";
  };

  const closeUserImportModalWithoutSaving = () => {
    setShowImportModal(false);
  };

  const onImportSuccess = () => {
    getUsers();
    setShowImportModal(false);
  };

  const importClicked = () => {
    setShowPopover({ open: false, event: undefined });
    portal!.navigation.isLoading(true);
    setShowImportModal(true);
  };

  const filterListbySearchTerm = (searchTerm: string) => {
    searchTerm = searchTerm.toUpperCase();

    let filteredUsers = users.map((u) => {
      if (
        u.LastName?.toUpperCase().includes(searchTerm) ||
        u.FirstName?.toUpperCase().includes(searchTerm) ||
        u.Email?.toUpperCase().includes(searchTerm) ||
        u.UserId?.toUpperCase().includes(searchTerm) ||
        u.RicsUserId?.toUpperCase().includes(searchTerm) ||
        u.RicsXIdentityId?.toUpperCase().includes(searchTerm)
      ) {
        u.Hidden = false;
      } else {
        u.Hidden = true;
      }

      return u;
    });

    setUsers(filteredUsers);
  };

  const toggleShowDisabledUsers = (showDisabledUsers: boolean) => {
    setShowDisabledUsers(showDisabledUsers);
    filterListByDisabledStatus(showDisabledUsers);
  };

  const filterListByDisabledStatus = (showDisabledUsers: boolean) => {
    //TODO: Implement. Not currently implemented
    // let filteredUsers = [...users];
    // if(!showDisabledUsers){
    //   filteredUsers = filteredUsers.filter(u => u.Enabled === true);
    // }
    // setFilteredUsers(filteredUsers);
  };

  const updateSingleUser = (userToUpdate: User) => {
    portal!.navigation.isLoading(true);

    const onSuccess = () => {
      getUsers();
    };

    const onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    updateIdentity(userToUpdate, onSuccess, onFailure);
  };

  const updateAllSelectedUsers = () => {
    portal!.navigation.isLoading(true);

    let usersToUpdate: Array<User> = [];

    let count = -1;
    let onNext = function () {
      count++;

      if (count >= usersToUpdate.length) {
        getUsers();

        return;
      }

      //The API only allows 10 requests per second. Throttling to avoid 429 errors
      setTimeout(() => {
        updateIdentity(usersToUpdate[count], onNext, onFailure);
      }, 100);
    };

    let onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    checkedUsers.forEach((value: boolean, key: string, map: Map<string, boolean>) => {
      if (value) {
        let selectedUser = users.find((x) => x.UserId === key);
        if (!selectedUser?.Hidden) {
          usersToUpdate.push(selectedUser!);
        }
      }
    });

    onNext();
  };

  const updateIdentity = (userToUpdate: User, onSuccess: () => void, onFailure: () => void) => {
    let locationIds = new Array<string>();
    userToUpdate.Locations.forEach((locationId) => {
      if (locationId) {
        locationIds.push(locationId);
      }
    });

    let userRoles: Array<ScopedRole> = [];
    userToUpdate.Roles.forEach((r) => {
      let scopedRole = {
        tenantId: portal!.State.tenantInfo.tenantId,
        identityRole: r.Name
      } as ScopedRole;

      userRoles.push({ ...scopedRole });

      locationIds.forEach((l) => {
        userRoles.push({ ...scopedRole, locationId: l });
      });
    });

    let identity: Identity = {
      identityId: userToUpdate.UserId,
      emailAddress: userToUpdate.Email,
      firstName: userToUpdate.FirstName,
      lastName: userToUpdate.LastName,
      deletedOn: userToUpdate.DeletedOn,
      phoneNumber: userToUpdate.PhoneNumber,
      rics9UserId: userToUpdate.RicsUserId,
      tenants: [portal!.State.tenantInfo.tenantId],
      roles: userRoles,
      isActivated: userToUpdate.IsActivated,
      isVerified: userToUpdate.IsVerified
    };

    const onSuccessCallback = (newIdentity: Identity) => {
      onSuccess();
    };

    const version = getVersion(flags);
    dataService.identities.saveIdentity(identity, false, version, onSuccessCallback, onFailure);
  };

  return (
    <IonPage>
      <TenantLevelPageHeader headerText="All Users" headerIconFileUrl="assets/img/user-alt.svg" itemsCount={usersCount}>
        <ListAndCardsToggle
          isCardMode={isCardMode}
          setIsCardMode={setIsCardMode}
          onChangeSearchText={filterListbySearchTerm}
          showDisabledUsers={showDisabledUsers}
          toggleShowDisabledUsers={toggleShowDisabledUsers}
          isDisabledUserEnabled={deleteUserEnabled}
          showSearchInput={showSearchInput}
          setShowSearchInput={setShowSearchInput}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Search for users"
        />
        <IonButton fill="clear" slot="end" className="add-user-button" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
          Add User
        </IonButton>
        <IonButton fill="clear" slot="end" className="add-user-button" onClick={() => updateAllSelectedUsers()} disabled={numberSelected === 0}>
          Save Changes
        </IonButton>
        <IonPopover isOpen={showPopover.open} event={showPopover.event} onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}>
          {/* TODO: Will be implemented in a future story */}
          {/* <IonButton fill="clear" className="add-user-popover-buttons">
              <IonIcon src="img/add.svg" className="button-left-icon add-user-popover-icon" />
                  Create a New User
              </IonButton> */}

          <IonButton fill="clear" className="add-user-popover-buttons" onClick={() => importClicked()}>
            <IonIcon src="img/download.svg" className="button-left-icon add-user-popover-icon" />
            Import User from RICS 9
          </IonButton>
        </IonPopover>
        <UserImport
          isOpen={showImportModal}
          setIsOpen={setShowImportModal}
          closeModalWithoutSaving={closeUserImportModalWithoutSaving}
          onImportSuccess={onImportSuccess}
        />
      </TenantLevelPageHeader>
      <IonContent class="all-users-content">
        <UsersGrid
          cssClass="all-users-grid"
          users={users}
          checkedUsers={checkedUsers}
          setCheckedUsers={setCheckedUsers}
          checkAllUsers={checkAllUsers}
          setUsers={setUsers}
          updateSingleUser={updateSingleUser}
          updateAllSelectedUsers={updateAllSelectedUsers}
          locationId={undefined}
          isCardMode={isCardMode}
          deleteUserIsEnabled={deleteUserEnabled}
          numberSelected={numberSelected}
        />
      </IonContent>
    </IonPage>
  );
};

export default AllUsers;

import { IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonIcon, IonText, IonPopover } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useXhrService } from '../../XhrServiceProvider';
import { usePortal } from '../../PortalProvider';
import { CashCountTransaction } from '../../../interfaces/CashCountTransaction';
import { Device } from "../../../interfaces/Device";
import { toLocalIsoString } from "../../../helpers/dateHelpers";
import { currencyFormatter, toCapitalizedWords } from "../../../helpers/stringHelpers";

import '../../../theme/DrawerHistory.css';

export interface DrawerHistoryProps {
  locationId?: string;
  getDevices: Function;
}

export const DrawerHistory: React.FC<DrawerHistoryProps> = (props: DrawerHistoryProps) => {
  const portal = usePortal();
  const xhrService = useXhrService();
  const [terminals, setTerminals] = useState<Array<Device>>([]);
  const [selectedTerminal, setSelectedTerminal] = useState('');
  const [transactions, setTransactions] = useState<Array<CashCountTransaction>>([]);

  const selectOptions = {
    cssClass: 'select-setting',
    showBackdrop: false
  };

  useEffect(() => {
    if (props.locationId && selectedTerminal) {
      getCashCountTransactions(props.locationId, selectedTerminal);
    }

  }, [selectedTerminal]);

  useEffect(() => {
    props.getDevices(setListState);
  }, []);

  const getCashCountTransactions = function(locationId: string, terminalId: string) {
    portal!.navigation.isLoading(true);
    var tomorrow = new Date();
    var yesterday = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0,0,0,0);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0,0,0,0);

    var localEndDateTime = toLocalIsoString(tomorrow);
    var localStartDateTime = toLocalIsoString(yesterday);

    var endpoint = `v2/CashCount/${locationId}?terminalId=${terminalId}&startDateTime=${localStartDateTime}&endDateTime=${localEndDateTime}`;

    var onSuccess = function (response: any) {
      var result = JSON.parse(response);
      if (!result) {
        onFailure();
        return;
      }

      portal!.navigation.isLoading(false);
      var sorted = result.sort((a: CashCountTransaction, b: CashCountTransaction) => {
        if (a.transactionDate < b.transactionDate) {
          return 1;
        }

        if (a.transactionDate > b.transactionDate) {
          return -1;
        }

        return 0;
      });

      setTransactions(sorted);
    };

    var onFailure = function () {
      portal!.navigation.isLoading(true);
      alert("Something went wrong.");
    };

    xhrService!.DoRicsApiXhr(endpoint, null, onSuccess, onFailure);
  };

  const getDistinctActiveDevices = (devices: Array<Device>) => {
    var activeDevices = devices.filter(device => device.deviceLocations.some(location => location.locationId == props.locationId && location.status === "Active" && location.lastActive !== undefined));
    var distinctDevices: Device[] = [];

    activeDevices.forEach(currDevice => {
      var deviceInList = distinctDevices.find(device => device.name == currDevice.name);

      if (!deviceInList) {
        distinctDevices.push(currDevice);
      } else {
        var prevDeviceLocation = deviceInList.deviceLocations.find(deviceLocation => deviceLocation.locationId === props.locationId);
        var currDeviceLocation = currDevice.deviceLocations.find(deviceLocation => deviceLocation.locationId === props.locationId);
        var index = distinctDevices.indexOf(deviceInList);

        if (Date.parse(prevDeviceLocation!.lastActive) < Date.parse(currDeviceLocation!.lastActive)) {
          distinctDevices[index] = currDevice;
        }
      }
    });

    return distinctDevices;
  };

  const setListState = (devices: Array<Device>) => {
    var distinctDevices = getDistinctActiveDevices(devices);
    setTerminals(distinctDevices);
  };

  const isDateEqual = (firstTransDate: string, currTransDate: string) => {
    var currDate = new Date(currTransDate).getDate();
    var firstDate = new Date(firstTransDate).getDate();

    return firstDate === currDate;
  };

  return (
    <>
        <div className="drawer-history-header">Terminal</div>
        <IonSelect
                value={selectedTerminal}
                placeholder="Select a terminal"
                interface="popover"
                interfaceOptions={selectOptions}
                class="terminal-select"
                data-testid="terminal-select"
                onIonChange={e => setSelectedTerminal(e.detail.value)}>
          { terminals.map((terminal) =>
            <IonSelectOption class="select-option" key={terminal.id} value={terminal.name}>
              {terminal.name}
            </IonSelectOption>
          )}
        </IonSelect>

        <div className="tabContent">
          <IonGrid class={`drawer-history-grid`} fixed={true}>
            <IonRow class="drawer-history-grid-header">
              <IonCol size='.25'>&nbsp;</IonCol>
              <IonCol size='2'>Drawer Action</IonCol>
              <IonCol size='2'>User</IonCol>
              <IonCol size='2'>Date &amp; Time</IonCol>
              <IonCol size='1'>Counted Total</IonCol>
              <IonCol size='1'>Expected Total</IonCol>
              <IonCol size='1'>Cash In</IonCol>
              <IonCol size='1'>Cash Out</IonCol>
            </IonRow>
            { transactions.map((transaction, i) =>
              <IonRow key={transaction.transactionId} className={isDateEqual(transactions[0].transactionDate, transaction.transactionDate) ? 'drawer-history-grid-row' : 'drawer-history-grid-alt-row'}>
                <IonCol size='.25'>
                  { transaction.transactionType === "CashOut" && transaction.memo !== undefined &&
                    <div className="drawer-history-tooltip">
                      <IonIcon src="assets/img/info.svg" className="drawer-history-info" />
                      <span className="drawer-history-tooltiptext">{transaction.memo}</span>
                    </div>
                  }
                  { transaction.transactionType === "EndDrawer" && transaction.transactionAmount !== transaction.expectedTotal &&
                    <div className="drawer-history-tooltip">
                      <IonIcon src="assets/img/alert.svg" className="drawer-history-alert" />
                      <span className="drawer-history-tooltiptext">Counted Total does not match Expected Total.</span>
                    </div>
                  }
                </IonCol>
                <IonCol size='2' className='bold-text'>{toCapitalizedWords(transaction.transactionType)}</IonCol>
                <IonCol size='2'>{transaction.salesAssociateName}</IonCol>
                <IonCol size='2'>{new Date(transaction.transactionDate).toLocaleDateString()} at {new Date(transaction.transactionDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</IonCol>
                { transaction.transactionType === "StartDrawer" || transaction.transactionType === "EndDrawer"
                  ? transaction.transactionAmount !== transaction.expectedTotal
                      ? <IonCol className="red-text">{currencyFormatter.format(transaction.transactionAmount).padEnd(2, '0')}</IonCol>
                      : <IonCol>{currencyFormatter.format(transaction.transactionAmount).padEnd(2, '0')}</IonCol>
                  : <IonCol>--</IonCol>
                }
                { transaction.expectedTotal
                  ? <IonCol size='1'>{currencyFormatter.format(transaction.expectedTotal).padEnd(2, '0')}</IonCol>
                  : <IonCol size='1'>--</IonCol>
                }
                { transaction.transactionType === "CashIn"
                  ? <IonCol size='1' className="green-text">{currencyFormatter.format(transaction.transactionAmount).padEnd(2, '0')}</IonCol>
                  : <IonCol size='1'>--</IonCol>
                }
                { transaction.transactionType === "CashOut"
                  ? <IonCol size='1' className="red-text">({currencyFormatter.format(transaction.transactionAmount).padEnd(2, '0').replace('-', '')})</IonCol>
                  : <IonCol size='1'>--</IonCol>
                }
              </IonRow>
            )}
            { transactions.length === 0 &&
              <IonRow class="drawer-history-grid-row">
                <IonCol size='11' className='center-text'>
                  No transactions to display
                </IonCol>
              </IonRow>
            }
            <IonPopover className='drawer-history-memo-popover' isOpen={false} showBackdrop={false}>
              <IonText></IonText>
            </IonPopover>
          </IonGrid>
        </div>
    </>
  );
};

import { IonPage, IonContent } from "@ionic/react";
import React from "react";
import ECommerceAccountDetails from "./ECommerceAccountDetails";
import IntegrationLogo from "../IntegrationLogo";
import TenantLevelPageHeader from "../../TenantLevelPageHeader";
import { ECommerceAccountBase } from "../../../interfaces/integrations/ecommerce/ECommerceAccountBase";

interface ECommerceAccountManagementProps {
    id?: string;
    account: ECommerceAccountBase;
    logoUrl: string;
    hideGeneralSection?: boolean;
    hideLocationsSection?: boolean;
    hideFulfillmentSection?: boolean;
    hidePricingSection?: boolean;
    hideCustomerSection?: boolean;
    getIntegrationSpecificGeneralDetails?: Function;
    getFBALocationDetails?: Function;
    onSave: Function;
    disableAccount: Function;
    enableAccount: Function;
};

const ECommerceAccountManagement: React.FC<ECommerceAccountManagementProps> = (props) => {
    return (
        <IonPage className="ecommerce-account-management-page" id={props.id}>
            <TenantLevelPageHeader
                headerText="Integrations"
                headerIconFileUrl="assets/img/integration.svg">
            </TenantLevelPageHeader>
            <IonContent className="ecommerce-account-management-content">
                <IntegrationLogo logoUrl={props.logoUrl} />
                <ECommerceAccountDetails
                    account={props.account}
                    hideGeneralSection={props.hideGeneralSection}
                    hideLocationsSection={props.hideLocationsSection}
                    hideFulfillmentSection={props.hideFulfillmentSection}
                    hidePricingSection={props.hidePricingSection}
                    hideCustomerSection={props.hideCustomerSection}
                    getIntegrationSpecificGeneralDetails={props.getIntegrationSpecificGeneralDetails}
                    getFBALocationDetails={props.getFBALocationDetails}
                    onSave={props.onSave}
                    disableAccount={props.disableAccount}
                    enableAccount={props.enableAccount} />
            </IonContent>
        </IonPage>
    );
};

export default ECommerceAccountManagement;
import { IonButton, IonIcon, IonText } from "@ionic/react";
import React from "react";

/* Theme variables */
import "../../theme/variables.css";

import "../../theme/WorkflowCard.css";

interface WorkflowCardProps {
  title: string;
  text: string;
  icon: string;
  isComplete: boolean;
  isEnabled?: boolean;
  onButtonClick: Function;
  children?: React.ReactNode;
}

const WorkflowCard: React.FC<WorkflowCardProps> = (props) => {
  return (
    <div className="item-card">
      <div className={props.isComplete ? "circle-complete" : "circle-incomplete"}>
        <IonIcon src={props.icon} className={props.isComplete ? "workflow-img-complete" : "workflow-img-incomplete"} />
      </div>
      <div className="card-text">
        <IonText className="card-title">{props.title}</IonText>
        <br />
        <IonText className="card-subtitle" color="gray">
          {props.text}
        </IonText>
      </div>
      <div className="card-status-holder">
        <div slot="end" hidden={props.isComplete}>
          <IonText color="gray" className="card-statustext">
            Not Started!
          </IonText>
        </div>
        <IonButton
          slot="end"
          className="card-button"
          disabled={props.isEnabled === false}
          color={props.isComplete ? "complete" : "button"}
          onClick={() => props.onButtonClick()}>
          {props.isComplete === true ? "Done" : "Start"}
        </IonButton>
      </div>
      {props.children}
    </div>
  );
};

export default WorkflowCard;

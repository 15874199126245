import React, { useState } from "react";
import { Image, ListGroup } from "react-bootstrap";
import { ECommerceConstants } from "../../../../constants/ECommerceConstants";
import { CreateECommerceAccountBase } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import ECommerceAccountSetupModal, { ECommerceAccountSetupModalProps, ECommerceAccountSetupModalPropsBase } from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupUseFulfillmentStrategyModalProps extends ECommerceAccountSetupModalPropsBase {
  updateAccount(account: CreateECommerceAccountBase): void;
}

const ECommerceAccountSetupUseFulfillmentStrategyModal: React.FC<ECommerceAccountSetupUseFulfillmentStrategyModalProps> = (props) => {
  const [useFulfillmentStrategy, setUseFulfillmentStrategy] = useState<boolean | undefined>(undefined);

  const onGoBack = () => {
    setUseFulfillmentStrategy(undefined);
    if (props.onGoBack) props.onGoBack();
  };

  const onNext = () => {
    let tempAccount: CreateECommerceAccountBase = JSON.parse(window.sessionStorage.getItem(ECommerceConstants.tempAccountKey)!);
    tempAccount.useFulfillmentStrategy = useFulfillmentStrategy!;
    window.sessionStorage.setItem(ECommerceConstants.tempAccountKey, JSON.stringify(tempAccount));

    props.updateAccount(tempAccount);

    if (props.onNext) props.onNext();
  };

  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Select Fulfillment Strategy",
    headerSubTitle:
      "The Fulfillment Strategy automatically sources items from the location with the most inventory in RICS at the time the order is recorded and, if needed, transfers those items to a preferred sale location.",
    disableNextButton: useFulfillmentStrategy === undefined,
    onGoBack: onGoBack,
    onNext: onNext
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className="select-fulfillment-strategy">
          <ListGroup className="list-group">
            <ListGroup.Item
              className={`fulfillment-strategy-item${useFulfillmentStrategy === undefined || !useFulfillmentStrategy ? "" : "-active"} card-button`}
              action
              onClick={() => setUseFulfillmentStrategy(true)}>
              <Image className="image" src="assets/img/store.svg" />
              <div className="title">Use Fulfillment Strategy</div>
              <div className="sub-title">Automatically fulfill and record orders</div>
            </ListGroup.Item>
            <ListGroup.Item
              className={`fulfillment-strategy-item${useFulfillmentStrategy === undefined || useFulfillmentStrategy ? "" : "-active"} card-button`}
              action
              onClick={() => setUseFulfillmentStrategy(false)}>
              <Image className="image" src="assets/img/store.svg" />
              <div className="title">Don't Use Fulfillment Strategy</div>
              <div className="sub-title">Manually fulfill and record orders</div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupUseFulfillmentStrategyModal;

import React from "react";
import { Button } from "react-bootstrap";
import { ECommerceVendor } from "../../../../interfaces/integrations/ecommerce/ECommerceAccountBase";
import ECommerceAccountSetupModal, {
  ECommerceAccountSetupModalProps,
  ECommerceAccountSetupModalPropsBase,
  getClassName
} from "./ECommerceAccountSetupModal";

interface ECommerceAccountSetupAuthorizeModal extends ECommerceAccountSetupModalPropsBase {
  onAuthorize: Function;
}

const ECommerceAccountSetupAuthorizeModal: React.FC<ECommerceAccountSetupAuthorizeModal> = (props) => {
  let baseProps: ECommerceAccountSetupModalProps = {
    ...props,
    headerTitle: "Authorization",
    headerSubTitle: `Authorize RICS to communicate with your ${
      props.vendor == ECommerceVendor.Amazon ? "Amazon Seller Central" : props.vendor
    } account`,
    hideFooter: true
  };

  const getClass = (suffix?: string) => {
    return getClassName(suffix, props.overrideClassName);
  };

  return (
    <>
      <ECommerceAccountSetupModal {...baseProps}>
        <div className={getClass("body-auth-button-holder")}>
          <Button onClick={() => props.onAuthorize()} className="button">
            Continue
          </Button>
        </div>
      </ECommerceAccountSetupModal>
    </>
  );
};

export default ECommerceAccountSetupAuthorizeModal;

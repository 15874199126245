import React, { useEffect, useRef, useState } from "react";

/* Theme variables */
import "../theme/variables.css";

import "../theme/Settings.css";
import { IonToggle, IonIcon, IonLabel } from "@ionic/react";

interface LocationsOverrideProps {
  locationsCanOverride: boolean;
  isDisabled?: boolean;
  onChange: Function;
}

const LocationsOverride: React.FC<LocationsOverrideProps> = (props) => {
  const [isInsideModal, setIsInsideModal] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let node: Node | null = ref.current;
    while (node != null) {
      if (node.nodeName === "ION-MODAL") {
        setIsInsideModal(true);
        break;
      }
      node = node.parentNode;
    }
  }, []);
  const tooltipClass = isInsideModal ? "modaltooltiptext" : "tooltiptext";

  return (
    <div className="ion-float-right locations-can-overrride-tooltip-holder ion-align-self-center">
      <div className="locations-override ion-align-items-center">
        <IonToggle
          className="locations-override-toggle"
          checked={props.locationsCanOverride}
          onIonChange={(e) => props.onChange(e.detail.checked)}
          disabled={props.isDisabled}
        />
        <IonLabel className="locations-override-info">
          Locations can override
        </IonLabel>
        <div className="locations-can-overrride-tooltip">
          <IonIcon src="assets/img/info.svg" className="info"></IonIcon>
          <span className={tooltipClass} ref={ref}>
            When toggled on, individual locations can override these global
            settings with their own. When toggled off, all locations will revert
            back to the global settings.
          </span>
        </div>
      </div>
    </div>
  );
};

export default LocationsOverride;

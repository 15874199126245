export interface MerchantSettings {
    locationsCanOverride?: boolean;
    merchantType: MerchantTypes;
    merchantId?: string | null;
    storeAndForwardEnabled?: boolean | undefined;
    storeAndForwardTransactionThreshold?: number | undefined;
    storeAndForwardOutageThreshold?: number | undefined;
}

export enum MerchantTypes {
    Manual = "NonIntegrated",
    Payload = "Payload", //Can be removed once Payload is removed from RicsApi
    RicsPay = "RicsPay"
}

export const MerchantTypesLabels = new Map<string, string>([
    [MerchantTypes.Manual, 'Non-Integrated'],
    [MerchantTypes.Payload, 'Payload'], //Can be removed once Payload is removed from RicsApi
    [MerchantTypes.RicsPay, 'RICS>Pay']
]);
export const toLocalIsoString = (dt: Date) => {
  var timezone_offset_min: number = new Date().getTimezoneOffset(),
    offset_hrs: string = Math.abs(timezone_offset_min / 60).toString(),
    offset_min: string = Math.abs(timezone_offset_min % 60).toString(),
    timezone_standard: string = "Z";

  if (parseInt(offset_hrs) < 10) offset_hrs = "0" + offset_hrs;

  if (parseInt(offset_min) < 10) offset_min = "0" + offset_min;

  if (timezone_offset_min < 0) timezone_standard = "+" + offset_hrs + ":" + offset_min;
  else if (timezone_offset_min > 0) timezone_standard = "-" + offset_hrs + ":" + offset_min;

  var current_date = dt.getDate().toString().padStart(2, "0"),
    current_month = (dt.getMonth() + 1).toString().padStart(2, "0"),
    current_hrs = dt.getHours().toString().padStart(2, "0"),
    current_mins = dt.getMinutes().toString().padStart(2, "0"),
    current_secs = dt.getSeconds().toString().padStart(2, "0"),
    current_year = dt.getFullYear();

  return current_year + "-" + current_month + "-" + current_date + "T" + current_hrs + ":" + current_mins + ":" + current_secs + timezone_standard;
};

export const toLocalString = (dt: Date) => {
  let timezone_offset_min: number = new Date().getTimezoneOffset(),
    offset_hrs: string = Math.abs(timezone_offset_min / 60).toString(),
    offset_min: string = Math.abs(timezone_offset_min % 60).toString(),
    timezone_standard: string = "Z";

  if (parseInt(offset_hrs) < 10) offset_hrs = "0" + offset_hrs;

  if (parseInt(offset_min) < 10) offset_min = "0" + offset_min;

  if (timezone_offset_min < 0) timezone_standard = "+" + offset_hrs + ":" + offset_min;
  else if (timezone_offset_min > 0) timezone_standard = "-" + offset_hrs + ":" + offset_min;

  let current_date = dt.getDate(),
    current_month = dt.getMonth() + 1,
    current_hrs = dt.getHours(),
    current_mins = dt.getMinutes().toString().padStart(2, "0"),
    current_year = dt.getFullYear(),
    period = current_hrs > 12 ? "PM" : "AM";

  return `${current_month}/${current_date}/${current_year} ${current_hrs > 12 ? current_hrs - 12 : current_hrs}:${current_mins} ${period}`;
};

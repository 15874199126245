import { IonButton, IonHeader, IonIcon, IonImg, IonLabel, IonPopover, IonToolbar } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Claims } from "../constants/Claims";
import { FeatureFlags } from "../constants/FeatureFlags";
import { Paths } from "../constants/Paths";
import { SessionStorage } from "../constants/SessionStorage";
import { hasPermissionTo } from "../helpers/claimsHelper";
import { IDictionary } from "../interfaces/IDictionary";
import "../theme/RicsToolbar.css";
import { useDataService } from "./DataServiceProvider";
import { usePortal } from "./PortalProvider";

const RicsToolbar: React.FC = () => {
  const portal = usePortal();
  const dataService = useDataService();
  const allLocationsButton = useRef<HTMLIonButtonElement>(null);
  const flags = useFlags();

  let path = window.location.pathname;
  const [selectedPageKey, setSelectedPageKey] = useState(path.slice(1));

  const [adminButtonsMode, setAdminButtonsMode] = useState(true);
  const [switchAppButtonsText, setSwitchAppButtonsText] = useState("POS Admin");

  const [vanityUrl, setVanityUrl] = useState<string | undefined>(window.sessionStorage.getItem(SessionStorage.VanityUrl) ?? undefined);

  function switchAppButtonsMode() {
    if (!adminButtonsMode) {
      setSwitchAppButtonsText("POS Admin");
      setAdminButtonsMode(true);
    } else {
      setSwitchAppButtonsText("Run My Store");
      setAdminButtonsMode(false);
    }
  }

  const [showPopover, setShowPopover] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined
  });

  const [isSetupButtonVisible, setIsSetupButtonVisible] = useState(true);

  // HACK until the locations page is converted over to React.
  useEffect(() => {
    setWorkflowButtonVisibility();
    setSelectedPageKey(path.slice(1));
  }, [path]);

  const setWorkflowButtonVisibility = (portal!.State.setWorkflowButton = function () {
    var isSetupComplete = true;
    for (var i = 0; i < portal!.State.locations?.length; i++) {
      var location = portal!.State.locations[i];
      if (!location.isActive) {
        isSetupComplete = false;
        break;
      }
    }

    setIsSetupButtonVisible(!isSetupComplete);
  });

  function logoutClicked() {
    setShowPopover({ open: false, event: undefined });
    dataService!.logout();
  }

  const getNavDirectionFor = (pageName: string) => {
    return pageIndex[selectedPageKey] > pageIndex[pageName] ? "back" : "forward";
  };

  const navigateToBackOffice = () => {
    if (vanityUrl === undefined) {
      return;
    }

    window.localStorage.clear();
    let url = vanityUrl.replace("https://", "").replace("http://", "");
    window.location.replace(`https://${url}`);
  };

  const pageIndex: IDictionary<number> = {
    default: 0,
    locations: 1,
    users: 2,
    devices: 3,
    settings: 4,
    integrations: 5,
    managelabels: 6,
    welcome: 7
  };

  return (
    <IonHeader>
      <IonToolbar class="rics-toolbar">
        <IonButton
          fill="clear"
          slot="start"
          routerDirection={getNavDirectionFor("default")}
          routerLink={Paths.Default}
          onClick={() => setSelectedPageKey("default")}
          id="logoContainer">
          <IonImg src="assets/img/rics-logo.svg" className="rics-logo" />
          {portal!.common.environment.toUpperCase() !== "PROD" && <div id="notProductionIndicator">{portal!.common.environment.toUpperCase()}</div>}
        </IonButton>

        <IonButton
          hidden={!hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          ref={allLocationsButton}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "locations" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("locations")}
          routerLink={Paths.Locations}
          onClick={() => setSelectedPageKey("locations")}>
          All Locations
        </IonButton>
        <IonButton
          hidden={!adminButtonsMode || !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "users" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("users")}
          routerLink={Paths.Users}
          onClick={() => setSelectedPageKey("users")}>
          All Users
        </IonButton>
        <IonButton
          hidden={!adminButtonsMode || !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "devices" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("devices")}
          routerLink={Paths.Devices}
          onClick={() => setSelectedPageKey("devices")}>
          All Devices
        </IonButton>
        <IonButton
          hidden={!adminButtonsMode || !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "settings" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("settings")}
          routerLink={Paths.Settings}
          onClick={() => setSelectedPageKey("settings")}>
          Settings
        </IonButton>
        <IonButton
          hidden={!adminButtonsMode || !flags[FeatureFlags.IntegrationsPage] || !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "integrations" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("integrations")}
          routerLink={Paths.Integrations}
          onClick={() => setSelectedPageKey("integrations")}>
          Integrations
        </IonButton>

        <IonButton
          hidden={!adminButtonsMode || !flags[FeatureFlags.LabelsPage] || !hasPermissionTo(Claims.Labels, portal!.State.userPermissions)}
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "managelabels" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("managelabels")}
          routerLink={Paths.ManageLabels}
          onClick={() => setSelectedPageKey("managelabels")}>
          Manage Labels
        </IonButton>

        <IonButton
          hidden={
            !adminButtonsMode ||
            !flags[FeatureFlags.GlobalProductCatalogPage] ||
            !hasPermissionTo(Claims.GlobalProductCatalogPage, portal!.State.userPermissions)
          }
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "globalproductcatalog" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("globalproductcatalog")}
          routerLink={Paths.GlobalProductCatalog}
          onClick={() => setSelectedPageKey("globalproductcatalog")}>
          Global Product Catalog
        </IonButton>

        <IonButton
          hidden={
            !adminButtonsMode ||
            !flags[FeatureFlags.ReputationPage] ||
            !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)
          }
          fill="clear"
          className={"rics-toolbar-button " + (selectedPageKey === "reputation" ? "selected" : undefined)}
          routerDirection={getNavDirectionFor("reputation")}
          routerLink={Paths.Reputation}
          onClick={() => setSelectedPageKey("reputation")}>
          Reputation
        </IonButton>
        
        <IonButton
          hidden={!isSetupButtonVisible || !adminButtonsMode || !hasPermissionTo(Claims.Owner, portal!.State.userPermissions)}
          size="small"
          className="rics-setup-wizard"
          routerLink={Paths.Welcome}>
          <IonImg src="assets/img/wand.svg" class="rics-toolbar-img" />
          <IonLabel class="rics-setup-label">Setup Wizard</IonLabel>
        </IonButton>

        <IonButton
          slot="end"
          fill="clear"
          className="rics-toolbar-button"
          disabled={vanityUrl === undefined}
          hidden={vanityUrl === undefined}
          onClick={navigateToBackOffice}>
          <IonImg src="assets/img/external.svg" class="rics-toolbar-img" />
          <IonLabel class="rics-setup-label">Return to Backoffice</IonLabel>
        </IonButton>

        {/* TODO: uncomment once some "Run My Store" and "POS Admin" functionalities are implemented and distinguishable */}
        {/* <IonButton expand="full" onClick={switchAppButtonsMode} slot="end" fill="clear" class="rics-toolbar-button admin-button">
          {switchAppButtonsText}
          <div slot="end" className="circle">
            <IonImg src="img/Grid.svg" class="app-buttons-icon" />
          </div>
        </IonButton> */}

        <IonButton slot="end" fill="clear" className="rics-toolbar-button vertical-line"></IonButton>

        <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={() => (showPopover.open ? setShowPopover({ open: false, event: undefined }) : {})}
          className="my-account-popover">
          <IonButton fill="clear" className="rics-toolbar-button" onClick={logoutClicked}>
            <IonIcon src="img/logout.svg" className="my-account-icons button-left-icon" />
            Logout
          </IonButton>
        </IonPopover>
        <IonButton slot="end" fill="clear" className="rics-toolbar-button" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
          <IonImg slot="start" src="img/adam-white-bg.png" className="account-image" />
          My Account
          <IonIcon slot="end" icon={chevronDown} className="my-account-icons button-right-icon" />
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default RicsToolbar;
